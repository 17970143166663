import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  Card,
  CardHeader,
  CardTitle,
  Table,
  FormGroup,
  Input,
} from "reactstrap";
import { Activity, BarChart2 } from "react-feather";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import StatisticsCard from "../../components/statisticsCard/StatisticsCard";
import { Redirect } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import moment from "moment";
import "moment-timezone";
var spdetails = [];
var spDetailsCopy = [];
var orderList = [];
var activeOrderList = [];
var cancelledOrderList = [];
var DriverDetails = [];

// CRUD permission details
var crudPermission;
// super admin ID
var saID;

// firebase.auth().onAuthStateChanged(function (user) {
//   if (user) {
//     // User is signed in.
//     console.log("The current user: ", user.email);
//     saID = user.email;
//     saID = saID.replace("@", "_");
//     saID = saID.replace(".", "_");

//     firebase
//       .database()
//       .ref("/twaddan_admin/userPermission/" + saID + "/crud_permission/")
//       .on("value", function (snapshot) {
//         crudPermission = snapshot.val();
//       });
//   } else {
//     // No user is signed in.
//   }
// });

class Booking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalActiveOrders: 0,
      totalCompletedOrders: 0,
      modal: false,
      modalDriver: false,
      modalcancel: false,
      newOrder: 0,
      searchBarID: "",
      currentOrderId: "",
      currentDriverId: "",
      currentSpId: "",
      currentOrderStatus: "",
      currentCustomerId: "",
      currentOrderAmount: 0,
      currentOrdermethod: 0,
    };
    this.toggle = this.toggle.bind(this);
    this.toggledriver = this.toggledriver.bind(this);
    this.toggleCancel = this.toggleCancel.bind(this);
  }
  componentDidMount() {
    this.getdata();
  }
  getdata = () => {
    spdetails = [];
    var completed_orders = 0;
    var active_orders = 0;
    const dr1 = firebase.database().ref("service_providers/");
    dr1.on("value", (snapshot) => {
      var drs = snapshot.val();
      spdetails = [];
      if (drs != null) {
        var keys = Object.keys(drs);
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          active_orders =
            active_orders +
            ((drs[k].orders &&
              drs[k].orders.active_orders &&
              Object.keys(drs[k].orders.active_orders).length) ||
              0);
          completed_orders =
            completed_orders +
            ((drs[k].orders &&
              drs[k].orders.completed_orders &&
              Object.keys(drs[k].orders.completed_orders).length) ||
              0);
          drs[k].orders &&
            spdetails.push({
              name: drs[k].personal_information.name,
              activeOrders: drs[k].orders.active_orders || 0,
              completedOrders: drs[k].orders.completed_orders || 0,
              id: k,
            });
        }
      }
    });
    this.setState({
      isloaded: true,
      totalActiveOrders: active_orders,
      totalCompletedOrders: completed_orders,
    });
    spDetailsCopy = spdetails;
    console.log(spdetails);
  };
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });

    if (stateName == "searchBarID") {
      if (value == "") {
        spdetails = spDetailsCopy;
      } else {
        spdetails = [];
        Object.keys(spDetailsCopy).map((line, key) => {
          var IDSearch = spDetailsCopy[line].name.toLowerCase();

          if (IDSearch.startsWith(value.toLowerCase())) {
            spdetails.push(spDetailsCopy[line]);
          }
        });
      }
    }
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  toggledriver() {
    console.log(this.state.modalDriver);
    this.setState({
      modalDriver: !this.state.modalDriver,
    });
  }
  toggleCancel() {
    console.log(this.state.modalcancel);
    this.setState({
      modalcancel: !this.state.modalcancel,
    });
  }
  getbookings = (idd) => {
    return firebase
      .database()
      .ref("service_providers")
      .child(idd)
      .child("orders")
      .child("completed_orders")
      .once("value")
      .then(
        function (snapshot) {
          var reads = [];
          orderList = [];
          snapshot.forEach(function (childSnapshot) {
            var id = childSnapshot.key;
            var promise = firebase
              .database()
              .ref("orders")
              .child("all_orders")
              .child(id)
              .once("value")
              .then(
                function (snap) {
                  // The Promise was fulfilled.
                  var drs = snap.val();
                  if (drs != null) {
                    orderList.push({
                      name: drs.customer_name,
                      price: drs.total_price_of_order,
                      time: drs.time_order_placed,
                      orderId: drs.order_id_number,
                    });
                  }
                },
                function (error) {
                  // The Promise was rejected.
                  console.error(error);
                }
              );
            reads.push(promise);
          });
          return Promise.all(reads);
        },
        function (error) {
          // The Promise was rejected.
          console.error(error);
        }
      )
      .then(() => {
        orderList.sort(function (a, b) {
          return b.time - a.time;
        });
        console.log(activeOrderList);
        this.getcancelledbookings(idd);
      });
  };
  getactivebookings = (idd) => {
    return firebase
      .database()
      .ref("service_providers")
      .child(idd)
      .child("orders")
      .child("active_orders")
      .once("value")
      .then(
        function (snapshot) {
          var reads = [];
          activeOrderList = [];
          snapshot.forEach(function (childSnapshot) {
            var id = childSnapshot.key;
            var promise = firebase
              .database()
              .ref("orders")
              .child("all_orders")
              .child(id)
              .once("value")
              .then(
                function (snap) {
                  // The Promise was fulfilled.
                  var drs = snap.val();
                  if (drs != null) {
                    activeOrderList.push({
                      name: drs.customer_name,
                      price: drs.total_price_of_order,
                      time: drs.time_order_placed,
                      orderId: drs.order_id_number,
                      status: drs.status,
                      driver_id: drs.driver_id,
                      amount: drs.total_due,
                      customerId: drs.customer_id,
                      method: drs.payment_mode,
                      id: id,
                    });
                  }
                },
                function (error) {
                  // The Promise was rejected.
                  console.error(error);
                }
              );
            reads.push(promise);
          });
          return Promise.all(reads);
        },
        function (error) {
          // The Promise was rejected.
          console.error(error);
        }
      )
      .then(() => {
        activeOrderList.sort(function (a, b) {
          return b.time - a.time;
        });
        this.getbookings(idd);
      });
  };
  getcancelledbookings = (idd) => {
    return firebase
      .database()
      .ref("service_providers")
      .child(idd)
      .child("orders")
      .child("cancelled_orders")
      .once("value")
      .then(
        function (snapshot) {
          var reads = [];
          cancelledOrderList = [];
          snapshot.forEach(function (childSnapshot) {
            var id = childSnapshot.key;
            var promise = firebase
              .database()
              .ref("orders")
              .child("all_orders")
              .child(id)
              .once("value")
              .then(
                function (snap) {
                  // The Promise was fulfilled.
                  var drs = snap.val();
                  if (drs != null) {
                    cancelledOrderList.push({
                      name: drs.customer_name,
                      price: drs.total_price_of_order,
                      time: drs.time_order_placed,
                      orderId: drs.order_id_number,
                      id: id,
                    });
                  }
                },
                function (error) {
                  // The Promise was rejected.
                  console.error(error);
                }
              );
            reads.push(promise);
          });
          return Promise.all(reads);
        },
        function (error) {
          // The Promise was rejected.
          console.error(error);
        }
      )
      .then(() => {
        cancelledOrderList.sort(function (a, b) {
          return b.time - a.time;
        });
        this.setState({
          modal: true,
        });
        console.log(cancelledOrderList);
      });
  };
  getDriverDetails = (id) => {
    var self = this;
    firebase
      .database()
      .ref("/drivers/" + id + "/personal_information/")
      .on("value", function (snapshot) {
        const Details = snapshot.val();
        DriverDetails = [];
        if (Details !== null) {
          DriverDetails.push({
            name: Details.name,
            number: Details.phone_number,
            email: Details.email,
            vehicle_number: Details.vehicle_number,
          });
        }
        self.setState({
          modalDriver: true,
        });
      });
  };
  navigate = () => {
    this.setState({
      newOrder: 1,
    });
  };

  cancelOrder = () => {
    console.log(this.state.currentOrderAmount);
    var d = new Date();
    var year = d.getFullYear();
    var mon = d.getMonth();
    var m = [
      "jan",
      "feb",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "sept",
      "oct",
      "nov",
      "dec",
    ];
    var month = m[mon];
    var earnings = 0;
    if (this.state.currentOrderStatus == 0) {
      let userRef1 = firebase
        .database()
        .ref(
          "drivers/" +
            this.state.currentDriverId +
            "/new_orders/" +
            this.state.currentOrderId
        );
      userRef1.remove();
    } else {
      var idd = "";
      const dr1 = firebase
        .database()
        .ref(
          "drivers/" + this.state.currentDriverId + "/active_order/order_id/"
        );
      dr1.once("value", (snapshot) => {
        var drs = snapshot.val();
        idd = drs;
        console.log(drs);
        if (idd === this.state.currentOrderId) {
          console.log("inside active order");
          let userRef = firebase
            .database()
            .ref("drivers/" + this.state.currentDriverId + "/active_order/");
          userRef.remove();
        } else {
          console.log("inside upcomming order");
          let userRef2 = firebase
            .database()
            .ref(
              "drivers/" +
                this.state.currentDriverId +
                "/upcomming_order/" +
                this.state.currentOrderId
            );
          userRef2.remove();
        }
      });
    }
    let userRef3 = firebase
      .database()
      .ref("orders/active_orders/" + this.state.currentOrderId);
    userRef3.remove();
    var ref1 = firebase
      .database()
      .ref("drivers/" + this.state.currentDriverId + "/cancelled_order/");
    ref1.update({
      [this.state.currentOrderId]: true,
    });
    var ref = firebase.database().ref("orders/cancelled_orders/");
    ref.update({
      [this.state.currentOrderId]: true,
    });
    var ref = firebase
      .database()
      .ref("orders/all_orders/" + this.state.currentOrderId + "/");
    ref.update({
      status: -1,
    });
    let userRef4 = firebase
      .database()
      .ref(
        "service_providers/" +
          this.state.currentSpId +
          "/orders/active_orders/" +
          this.state.currentOrderId
      );
    userRef4.remove();
    var ref2 = firebase
      .database()
      .ref(
        "service_providers/" +
          this.state.currentSpId +
          "/orders/cancelled_orders/"
      );
    ref2.update({
      [this.state.currentOrderId]: true,
    });
    const dr = firebase
      .database()
      .ref(
        "service_providers/" +
          this.state.currentSpId +
          "/earnings/" +
          year +
          "/" +
          month +
          "/totalEarnings/"
      );
    dr.once("value", (snapshot) => {
      var drs = snapshot.val();
      earnings = drs;
    });
    earnings = earnings - this.state.currentOrderAmount;
    console.log(earnings);
    var ref1 = firebase
      .database()
      .ref(
        "service_providers/" +
          this.state.currentSpId +
          "/earnings/" +
          year +
          "/" +
          month +
          "/"
      );
    ref1.update({
      totalEarnings: earnings,
    });
    //-----------------------user update-------------------------------------------------
    let userRef5 = firebase
      .database()
      .ref(
        "Users/" +
          this.state.currentCustomerId +
          "/active_orders/order_ids/" +
          this.state.currentOrderId
      );
    userRef5.remove();
    var ref3 = firebase
      .database()
      .ref(
        "Users/" + this.state.currentCustomerId + "/cancelled_orders/order_ids/"
      );
    ref3.update({
      [this.state.currentOrderId]: false,
    });
    //-----------------------------------------Refund-------------------------------------------
    if (this.state.currentOrdermethod != 0) {
      const dr5 = firebase
        .database()
        .ref("orders/all_orders/" + this.state.currentOrderId);
      dr5.once("value", (snapshot) => {
        var drs = snapshot.val();
        var ref4 = firebase
          .database()
          .ref("twaddan_admin/refund_orders/" + this.state.currentOrderId);
        ref4.update({
          id: drs.paymentGatewayOrderID,
          value: drs.total_due,
        });
      });
    }
    // var axios = require('axios');

    // var config = {
    //   method: 'get',
    //   url: 'https://us-central1-twaddan-1.cloudfunctions.net/payment/getOrder?token=35336365&id=3c7edd32-05f8-435f-8147-5193f1c9d331',
    //   headers: { }
    // };

    // axios(config)
    // .then(function (response) {
    //   var url=response.data._embedded.payment[0]._embedded['cnp:capture'][0]._links['cnp:refund'].href;
    //   console.log(JSON.stringify(response.data._embedded.payment[0]._embedded['cnp:capture'][0]._links['cnp:refund'].href));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });

    this.toggleCancel();
    this.getactivebookings(this.state.currentSpId);
  };
  // getOrdersHistory=(orderid)=>{
  //   console.log(orderid);
  //   if(orderid!=null)
  //   {
  //   orderList=[];
  //   var reads=[];
  //   var keys =Object.keys(orderid);
  //    for(var dr = 0 ;dr<keys.length;dr++){
  //         var k=keys[dr];
  //         var dr1 =firebase.database().ref('orders/all_orders/'+k).on('value',(snapshot)=>{
  //            var drs =snapshot.val();
  //            if(drs!=null)
  //            {
  //               orderList.push({
  //                name:drs.customer_name,
  //                price:drs.total_price_of_order,
  //                time:drs.time_order_placed,
  //                orderId:drs.order_id_number,
  //              });

  //              }

  //            });
  //            reads.push(dr1);
  //          }
  //        Promise.all(reads).then(() =>{
  //         console.log("done");
  //         this.setState({
  //                modal:true,
  //              })
  //         });
  //       //    if(orderList.length!=0)
  //       //    {
  //       //    this.setState({
  //       //      modal:true,
  //       //    })
  //       //  }
  //        console.log(orderList);
  //     }
  //     else
  //     {
  //      orderList=[];
  //      this.setState({
  //       modal:true,
  //     })
  //     }

  //     }

  render() {
    if (this.state.newOrder === 1) {
      return <Redirect to='/admin/newBooking' />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <div className='h5 mt-8'>
            <h1>Orders</h1>
            <br></br>
            <br></br>
            <Row>
              <Col lg='3' md='6' sm='12'>
                <StatisticsCard
                  hideChart
                  iconBg='success'
                  icon={<Activity className='success' size={30} />}
                  stat={this.state.totalActiveOrders}
                  statTitle='Total Active Orders'
                />
              </Col>
              <Col lg='3' md='6' sm='12'>
                <StatisticsCard
                  hideChart
                  iconBg='success'
                  icon={<BarChart2 className='success' size={30} />}
                  stat={this.state.totalCompletedOrders}
                  statTitle='Total Completed Orders'
                />
              </Col>
            </Row>
            {false && (
              <Button
                color='primary'
                onClick={this.navigate}
                style={{ position: "absolute", top: "250px", right: "10px" }}>
                CREATE NEW ORDER
              </Button>
            )}
            <br></br>
            <br></br>
            <h1>Service Providers</h1>
            <br></br>
            <br></br>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    className='form-control-alternative'
                    defaultValue={this.state.searchBarID}
                    id='input-price'
                    placeholder='Search'
                    type='text'
                    onChange={(e) =>
                      this.onChange("searchBarID", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            {spdetails.map((i) => {
              return (
                <div>
                  <h2>{i.name}</h2>
                  <br></br>
                  <Row>
                    <Col lg='3' md='6' sm='12'>
                      <StatisticsCard
                        hideChart
                        iconBg='success'
                        icon={<Activity className='success' size={30} />}
                        stat={Object.keys(i.activeOrders).length}
                        statTitle='Total Active Orders'
                      />
                    </Col>
                    <Col lg='3' md='6' sm='12'>
                      <StatisticsCard
                        hideChart
                        iconBg='success'
                        icon={<BarChart2 className='success' size={30} />}
                        stat={Object.keys(i.completedOrders).length}
                        statTitle='Total Completed Orders'
                      />
                    </Col>
                    <Col lg='3' md='6' sm='12'>
                      <Button
                        color='info'
                        onClick={() => {
                          this.getactivebookings(i.id);
                          this.setState({
                            currentSpId: i.id,
                          });
                        }}
                        style={{ position: "absolute", top: "50%" }}>
                        SHOW DETAILS
                      </Button>
                    </Col>
                  </Row>
                  <br></br>
                  <br></br>
                </div>
              );
            })}
          </div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
            size='lg'>
            <ModalHeader toggle={this.toggle}>
              <h1>Order Details</h1>
            </ModalHeader>
            <ModalBody>
              {
                <Card>
                  <CardHeader>
                    <CardTitle style={{ color: "blue" }}>
                      Active Orders
                    </CardTitle>
                  </CardHeader>
                  <Row>
                    {activeOrderList.length != 0 ? (
                      activeOrderList.map((i) => {
                        var theDate = new Date(i.time);
                        var gmtdate = theDate.toGMTString();
                        var dateString = moment(gmtdate)
                          .tz("Asia/Dubai")
                          .format("MMMM Do YYYY, h:mm:ss a");
                        return (
                          <Col lg='30' md='12' sm='24'>
                            <Card className='shadow'>
                              <CardBody>
                                <Button
                                  color='danger'
                                  style={{ float: "right" }}
                                  onClick={() => {
                                    this.setState({
                                      modalcancel: true,
                                      currentOrderId: i.id,
                                      currentDriverId: i.driver_id,
                                      currentOrderStatus: i.status,
                                      currentOrderAmount: i.amount,
                                      currentCustomerId: i.customerId,
                                      currentOrdermethod: i.method,
                                    });
                                  }}>
                                  CANCEL
                                </Button>
                                <Button
                                  color='info'
                                  style={{ float: "right" }}
                                  onClick={() => {
                                    this.getDriverDetails(i.driver_id);
                                  }}>
                                  SHOW DRIVER DETAILS
                                </Button>
                                <h5>
                                  {i.name}-{dateString}
                                </h5>
                                <div>
                                  <Stepper
                                    steps={[
                                      { title: "order placed" },
                                      { title: "order accepted" },
                                      {
                                        title:
                                          "driver heading towards customer",
                                      },
                                      {
                                        title:
                                          "driver reached customers location",
                                      },
                                      { title: "driver started servicing" },
                                      { title: "driver finished servicing" },
                                    ]}
                                    activeStep={i.status}
                                  />
                                </div>
                              </CardBody>
                            </Card>
                            <br></br>
                          </Col>
                        );
                      })
                    ) : (
                      <p> No Active Orders Found</p>
                    )}
                  </Row>
                  {/* <Table
                           responsive
                           className="dashboard-table table-hover-animation mb-0 mt-1"
                         >
                           <thead>
                             <tr>
                               <th>ORDER ID</th>
                               <th>NAME</th>
                               <th>TIME</th>
                             </tr>
                           </thead>
                           <tbody>
                             {
                              
                              activeOrderList.length!=0 ?
                               activeOrderList.map((i)=>{
                                var theDate = new Date(i.time);
                                var dateString = theDate.toGMTString();
                                 return(
                                <tr>
                                <td>{i.orderId}</td>
                                <td>{i.name}</td>
                                <td>
                                  <div
                                    className="bg-success"
                                    style={{
                                      height: "10px",
                                      width: "10px",
                                      borderRadius: "50%",
                                      display: "inline-block",
                                      marginRight: "5px"
                                    }}
                                  />
                                  <span>{dateString}</span>
                                </td>
                                  
                              </tr>
                                 );
                               })
                               :<p>No Bookings Found</p>
                             }
                           </tbody>
                         </Table> */}
                </Card>
              }

              {
                <Card>
                  <CardHeader>
                    <CardTitle style={{ color: "blue" }}>
                      Completed Orders
                    </CardTitle>
                  </CardHeader>
                  <Table
                    responsive
                    className='dashboard-table table-hover-animation mb-0 mt-1'>
                    <thead>
                      <tr>
                        <th>ORDER ID</th>
                        <th>NAME</th>
                        <th>TIME</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderList.length != 0 ? (
                        orderList.map((i) => {
                          var theDate = new Date(i.time);
                          var gmtdate = theDate.toGMTString();
                          var dateString = moment(gmtdate)
                            .tz("Asia/Dubai")
                            .format("MMMM Do YYYY, h:mm:ss a");
                          return (
                            <tr>
                              <td>{i.orderId}</td>
                              <td>{i.name}</td>
                              <td>
                                <div
                                  className='bg-success'
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                    display: "inline-block",
                                    marginRight: "5px",
                                  }}
                                />
                                <span>{dateString}</span>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <p>No Bookings Found</p>
                      )}
                    </tbody>
                  </Table>
                </Card>
              }

              {
                <Card>
                  <CardHeader>
                    <CardTitle style={{ color: "blue" }}>
                      Cancelled Orders
                    </CardTitle>
                  </CardHeader>
                  <Table
                    responsive
                    className='dashboard-table table-hover-animation mb-0 mt-1'>
                    <thead>
                      <tr>
                        <th>ORDER ID</th>
                        <th>NAME</th>
                        <th>TIME</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cancelledOrderList.length != 0 ? (
                        cancelledOrderList.map((i) => {
                          var theDate = new Date(i.time);
                          var gmtdate = theDate.toGMTString();
                          var dateString = moment(gmtdate)
                            .tz("Asia/Dubai")
                            .format("MMMM Do YYYY, h:mm:ss a");
                          return (
                            <tr>
                              <td>{i.orderId}</td>
                              <td>{i.name}</td>
                              <td>
                                <div
                                  className='bg-success'
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                    display: "inline-block",
                                    marginRight: "5px",
                                  }}
                                />
                                <span>{dateString}</span>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <p>No Bookings Found</p>
                      )}
                    </tbody>
                  </Table>
                </Card>
              }
            </ModalBody>
            <ModalFooter>
              <Button color='secondary' onClick={this.toggle}>
                CLOSE
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.modalDriver} toggle={this.toggledriver}>
            <ModalBody>
              <Row>
                {DriverDetails.map(() => {
                  return (
                    <Col>
                      <h2>Name : {DriverDetails[0].name}</h2>
                      <h3>Phone Number : {DriverDetails[0].number}</h3>
                      <h3>Email ID : {DriverDetails[0].email}</h3>
                      <h3>
                        Vehicle Number : {DriverDetails[0].vehicle_number}
                      </h3>
                    </Col>
                  );
                })}
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color='info' onClick={this.toggledriver}>
                CLOSE
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.modalcancel} toggle={this.toggleCancel}>
            <ModalBody>Do you want to cancel the order</ModalBody>
            <ModalFooter>
              <Button color='info' onClick={this.toggleCancel}>
                NO
              </Button>
              <Button color='danger' onClick={this.cancelOrder}>
                YES
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </>
    );
  }
}

export default Booking;
