import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import logo from "./twaddan-react.0a3a7e5e.jpg";
import jsPDF from "jspdf";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  CardHeader,
  Form,
  Label,
  FormText,
} from "reactstrap";
import { Truck, CheckCircle, Edit } from "react-feather";
import StarRatings from "react-star-ratings";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import StatisticsCard from "../../components/statisticsCard/StatisticsCard";
import { Redirect } from "react-router-dom";
import RealTime from "services/RealTime";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PageLoader from "../../components/PageLoader/PageLoader";
import "../styles/Drivers.css";
var spdetails = [];

var spDetailsCopy = [];

var driverList = [];
var editDriver = [];
var vehicleList = [];
var spID = null;
var driverDetailsFromSP;

// CRUD permission details
var crudPermission = JSON.parse(localStorage.getItem("crudPermissions"));

// super admin ID
var saID = localStorage.getItem("admin_email");
const rowStyle = (row, rowIndex) => {
  const style = {};
  if (rowIndex % 2 === 0) {
    style.backgroundColor = "#e6e6e6";
  }
  style.cursor = "pointer";
  return style;
};
class EditDriver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: editDriver["name"],
      file: "",
      imagePreviewUrl: "",
      image_url: editDriver["image_url"],
      downloadURL: "",
      edit: 1,
      driverID: editDriver["driverID"],
      phone_number: editDriver["phone_number"],
      vehicle_number: editDriver["vehicle_number"],
      email: editDriver["email"],
      spRelated: editDriver["spRelated"],
      status: editDriver["status"],
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.pushData = this.pushData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
  }

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });

    if (stateName == "vehicle_number" && value == "Select Vehicle") {
      this.setState({
        [stateName]: "",
      });
    }
  };

  pushData = () => {
    var driverID = editDriver["driverID"];
    var driverName = this.state.name;
    var spid = editDriver["spRelated"];
    var spName;
    firebase
      .database()
      .ref("service_providers/" + spid + "/personal_information/")
      .on("value", function (snapshot) {
        spName = snapshot.val();
        spName = spName.name;
      });
    var time = new Date();
    time = time.getTime();
    var logAction =
      "Edited Driver named " +
      driverName +
      " with Driver ID " +
      driverID +
      " under " +
      spName +
      " Service Provider with SP ID " +
      spid;
    var logs = firebase
      .database()
      .ref(
        "/twaddan_admin/super_admin_users/" +
          this.state.saID +
          "/logs/" +
          time +
          "/"
      );
    logs.update({
      action: logAction,
    });

    firebase
      .database()
      .ref("drivers/" + driverID + "/personal_information/")
      .update({
        name: this.state.name,
        phone_number: this.state.phone_number,
        vehicle_number: this.state.vehicle_number,
        email: this.state.email,
      });

    this.setState({
      edit: 0,
    });
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  uploadImage = () => {
    var spRelatedID = editDriver["spRelated"];
    var driverID = editDriver["driverID"];
    var driverName = this.state.name;

    // var spName;
    // firebase.database().ref("service_providers/"+spRelatedID+"/personal_information/").on('value', function(snapshot) {
    //   spName = snapshot.val();
    //   spName = spName.name;
    // })
    // var time = new Date();
    // time = time.getTime();
    // var logAction = "Edited Profile Picture of Driver named "+driverName+" with Driver ID "+driverID+" under "+spName +" Service Provider with SP ID "+spRelatedID;
    // var logs = firebase.database().ref('/twaddan_admin/super_admin_users/'+saID+'/logs/'+time+'/');
    // logs.update({
    //   action: logAction,
    // })

    var imgId = "driver_" + this.state.phone_number + ".png";
    var storageRef = firebase.storage().ref();
    var ImagesRef = storageRef.child(
      "/service_provider/" + spRelatedID + "/drivers_info/" + imgId
    );
    ImagesRef.put(this.state.file)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
      })

      .then((downloadURL) => {
        console.log(
          `Successfully uploaded file and got download link - ${downloadURL}`
        );
        this.setState({
          downloadurl: downloadURL,
          image_url: downloadURL,
        });
        editDriver["image_url"] = downloadURL;

        firebase
          .database()
          .ref("drivers/" + driverID + "/personal_information/")
          .update({
            image_url: downloadURL,
          });
        this.toggle();
      })

      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
  };
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  handleProfileSubmit(event) {
    this.uploadImage();
    event.preventDefault();
  }

  handleSubmit(event) {
    this.pushData();
    event.preventDefault();
  }

  render() {
    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }

    let vehicleListNumber =
      vehicleList.length > 0 &&
      vehicleList.map((key) => {
        return <option>{key}</option>;
      }, this);

    if (this.state.edit === 0) {
      return <Drivers />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Edit Driver Details</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {/* <Button
                          color="primary"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          Settings
                        </Button> */}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <CardImg
                      top
                      className="img-fluid"
                      style={{ width: "250px", height: "250px" }}
                      src={editDriver["image_url"]}
                      alt="card image cap"
                    />
                    <div className="d-flex justify-content-between">
                      <Button
                        className="mr-4"
                        color="info"
                        href="#pablo"
                        onClick={this.toggle}
                        size="sm"
                      >
                        Change Image
                      </Button>
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                      <div className="pl-lg-4 mt-3">
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <FormGroup row>
                              <Label
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                for="price"
                              >
                                Name
                              </Label>
                              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={editDriver["name"]}
                                  id="input-username"
                                  placeholder="Name"
                                  required
                                  type="text"
                                  onChange={(e) =>
                                    this.onChange("name", e.target.value)
                                  }
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <FormGroup row>
                              <Label
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                for="price"
                              >
                                Phone number
                              </Label>
                              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={editDriver["phone_number"]}
                                  id="input-username"
                                  placeholder="Phone Number"
                                  type="text"
                                  required
                                  onChange={(e) =>
                                    this.onChange(
                                      "phone_number",
                                      e.target.value
                                    )
                                  }
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <FormGroup row>
                              <Label
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                for="price"
                              >
                                Email
                              </Label>
                              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  defaultValue={editDriver["email"]}
                                  id="input-username"
                                  placeholder="Email"
                                  type="text"
                                  onChange={(e) =>
                                    this.onChange("email", e.target.value)
                                  }
                                />
                              </Col>
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <FormGroup row>
                              <Label
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                for="price"
                              >
                                Select Vehicle
                              </Label>
                              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Input
                                  type="select"
                                  name="vehicle"
                                  id="vehicle"
                                  disabled
                                  required
                                  value={this.state.vehicle_number}
                                  onChange={(e) =>
                                    this.onChange(
                                      "vehicle_number",
                                      e.target.value
                                    )
                                  }
                                >
                                  {vehicleListNumber}
                                </Input>
                              </Col>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          {/*   <Col xs={12} sm={12} md={5} lg={4} xl={2}>
                           <Button
                            className="buttonConvert"
                            color="primary"
                            block
                            onClick={this.convert}
                          >
                            CONVERT TO ARABIC
                          </Button>
                        </Col> */}
                          <Col
                            className="buttonCancelSubmitCol"
                            xs={6}
                            sm={6}
                            md={6}
                            lg={3}
                            xl={2}
                          >
                            <Button
                              block
                              onClick={() => {
                                this.setState({
                                  edit: 0,
                                });
                              }}
                            >
                              CANCEL
                            </Button>
                          </Col>
                          <Col
                            className="buttonCancelSubmitCol"
                            xs={6}
                            sm={6}
                            md={6}
                            lg={3}
                            xl={2}
                          >
                            <Button block color="success" value="Submit">
                              UPDATE
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <br></br>
                      <br></br>
                      {/* <Button color="primary" value="Submit">
                        UPDATE
                      </Button>
                      <Button
                        color="info"
                        onClick={() => {
                          this.setState({
                            edit: 0,
                          });
                        }}
                      >
                        CANCEL
                      </Button> */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br></br>
            <br></br>
            <br></br>
          </Container>

          <Container className="mt--7" fluid>
            <Row>
              <Card className="shadow">
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className={this.props.className}
                  size="lg"
                >
                  <ModalHeader toggle={this.toggle}>
                    <h1>Update Image Below</h1>
                  </ModalHeader>
                  <ModalBody>
                    <>
                      <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                          <Form onSubmit={this.handleProfileSubmit}>
                            <FormGroup>
                              <Label for="File">Add image</Label>
                              <br></br>
                              <input
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={this._handleImageChange}
                              />
                              <FormText color="muted">
                                Select the Image that you wanted to display.
                              </FormText>
                              <Row>
                                <Col md={4} xs={2}>
                                  <div style={imagestyle}>{$imagePreview}</div>
                                </Col>
                              </Row>
                            </FormGroup>

                            <Button color="primary" value="Submit">
                              UPDATE IMAGE
                            </Button>
                          </Form>
                        </CardBody>
                      </Card>
                    </>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>
                      CANCEL
                    </Button>
                  </ModalFooter>
                </Modal>
              </Card>
            </Row>
          </Container>
        </Container>
      </>
    );
  }
}
const table1Columns = [
  {
    text: "SL.No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Driver Email",
    dataField: "driverIDDisplay",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Driver Name",
    dataField: "name",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Phone Number",
    dataField: "phone_number",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const table1defaultSorted = [
  {
    dataField: "Service Name",
    order: "desc",
  },
];

class DriversBySP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: false,
      modal: false,
      spid: "",
      service: [],
      addOn: [],
      addService: 0,
      edit: 0,
      editAddOn: 0,
      searchBarID: "",
      serviceDetails: [],
      spName: "",
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };
  }
  async componentDidMount() {
    await this.getDriverDetails(localStorage.getItem("sp_id"));
    var adminStatus = await RealTime.getDetails(
      "service_providers/" + localStorage.getItem("sp_id") + "/admin_status"
    );
    console.log("adminStatus", adminStatus);

    this.setState({ adminStatus: adminStatus });
  }
  navigate = () => {
    this.setState({
      addDriver: 1,
    });
  };
  navigateDriverPage = () => {
    this.setState({
      driverPage: 1,
    });
  };
  toggle = () => {
    this.setState({ modal: false });
  };
  enableDisableDriver = async (selectedDriver) => {
    console.log("selectedDriver: ", selectedDriver);
    let status;
    let trackingState = 0;
    let reason;
    let reason_text;
    let prevStatus;
    var time = new Date();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    time = time.getTime();
    var todate = new Date(time).getDate();
    var tomonth = new Date(time).getMonth();
    var toyear = new Date(time).getFullYear();
    var original_date = todate + "-" + months[tomonth] + "-" + toyear;
    console.log("original_date", original_date);

    if (selectedDriver.status === -2) {
      console.log(
        "selectedDriver.status === -2: ",
        selectedDriver.status === -2
      );
      prevStatus = await RealTime.getDetails(
        "drivers/" + selectedDriver.driverID + "/prevStatus/"
      );
      if (prevStatus === 1) {
        trackingState = 1;
        status = 1;
        reason = null;
        if (selectedDriver.vehicle_number)
          RealTime.updateNode(
            "/vehicles/" + selectedDriver.vehicle_number + "/",
            {
              driver_status: true,
              busy_status: false,
            }
          );
      } else {
        status = 0;
        reason = 4;
      }
      prevStatus = null;
      reason_text = "Enabled by SP admin";
    } else {
      trackingState = 0;
      status = -2;
      reason = 3;
      prevStatus = selectedDriver.status;
      reason_text = "Disabled by SP admin";
      if (selectedDriver.vehicle_number)
        RealTime.updateNode(
          "/vehicles/" + selectedDriver.vehicle_number + "/",
          {
            driver_status: false,
          }
        );
    }
    selectedDriver.status = status;
    this.setState({ selectedDriver: selectedDriver });
    console.log("status,reason,reason_text", status, reason, reason_text);
    firebase
      .database()
      .ref("drivers/" + selectedDriver.driverID + "/")
      .update({
        status: status,
        reason: reason,
        prevStatus: prevStatus,
      });
    firebase
      .database()
      .ref(
        "drivers/" +
          selectedDriver.driverID +
          "/driver_activity_tracking/" +
          original_date +
          "/time_tracking/" +
          time +
          "/"
      )
      .set({
        action: reason_text,
        driver_time_engaged: 0,
        state: trackingState,
        time: time,
      });
    await this.getDriverDetails(localStorage.getItem("sp_id"));
  };

  getDriverDetails = async (idd) => {
    var selectedSp = await RealTime.getDetails(
      "service_providers/" + idd + "/"
    );
    return firebase
      .database()
      .ref("service_providers")
      .child(idd)
      .child("drivers_info")
      .child("drivers_id")
      .once("value")
      .then(
        function (snapshot) {
          var reads = [];
          driverList = [];
          let i = 1;
          snapshot.forEach(function (childSnapshot) {
            var id = childSnapshot.key;
            var promise = firebase
              .database()
              .ref("drivers")
              .child(id)
              .once("value")
              .then(
                function (snap) {
                  // The Promise was fulfilled.
                  var drs = snap.val();
                  if (drs != null) {
                    console.log("drs", drs);

                    driverList.push({
                      i: i++,
                      driverID: id,
                      driverIDDisplay: id.replace("_", "@").replace("_", "."),
                      spRelated: idd,
                      image_url: drs.personal_information.image_url,
                      phone_number: drs.personal_information.phone_number,
                      name: drs.personal_information.name,
                      rating: Number(drs.personal_information.rating),
                      vehicle_number: drs.personal_information.vehicle_number,
                      email: drs.personal_information.email,
                      loginStatus: drs?.account_details?.currently_logged_in,
                      status: drs.status,
                      statusBool:
                        !selectedSp.isSuspended &&
                        selectedSp.state === 1 &&
                        drs.status >= -1,
                      reason: selectedSp.isSuspended
                        ? "SP Sunspended"
                        : selectedSp.state !== 1
                        ? "SP Disabled"
                        : drs.status === -2
                        ? "Driver Disabled"
                        : "",
                    });
                  }
                },
                function (error) {
                  // The Promise was rejected.
                  console.error(error);
                }
              );
            reads.push(promise);
          });
          return Promise.all(reads);
        },
        function (error) {
          // The Promise was rejected.
          console.error(error);
        }
      )
      .then(() => {
        this.setState({
          isloaded: true,
        });
        console.log(driverList);
      });
  };
  storeCurrentDriver = (driverDetails) => {
    editDriver["image_url"] = driverDetails.image_url;
    editDriver["name"] = driverDetails.name;
    editDriver["driverID"] = driverDetails.driverID;
    editDriver["phone_number"] = driverDetails.phone_number;
    editDriver["vehicle_number"] = driverDetails.vehicle_number;
    editDriver["email"] = driverDetails.email;
    editDriver["spRelated"] = driverDetails.spRelated;
    editDriver["status"] = driverDetails.status;

    const spRelatedID = editDriver["spRelated"];
    firebase
      .database()
      .ref("/service_providers/" + spRelatedID + "/vehicles")
      .on("value", function (snapshot) {
        const vehicleData = snapshot.val() || "Anonymous";

        if (vehicleData) {
          vehicleList = [];
          vehicleList[0] = "Select Vehicle";
          Object.keys(vehicleData).map((line, key) => {
            vehicleList[key + 1] = line;
          });
        }
      });

    this.setState({
      edit: 1,
    });
  };

  details = (text, data) => {
    return (
      <>
        <Col md="5">
          <p>
            <b>{text}</b>
          </p>
        </Col>
        <Col md="7">
          <p>: {data}</p>
        </Col>
      </>
    );
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          modal: true,
          selectedDriver: driverList[row.i - 1],
        });
      },
    };
    if (this.state.addDriver == 1) {
      return <Redirect to="/admin/addDriver" />;
    }
    if (this.state.addAddonService === 1) {
      return <Redirect to="/admin/addAddonService_new" />;
    }
    if (this.state.driverPage === 1) {
      return <Drivers />;
    }
    if (this.state.edit == 1) {
      return <EditDriver />;
    }
    if (this.state.DriverReport) {
      return (
        <DriverReport
          driverId={this.state.driverIdForReport}
          selectedDriver={this.state.selectedDriver}
        />
      );
    }
    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isloaded}></PageLoader>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-8">
            {/* <Col sm={6} md={6} lg={8} xl={9}> */}
            <Col sm={12} md={12} lg={5} xl={8}>
              <h1 className="mb-0">Drivers</h1>
            </Col>
            {false && (
              <>
                <Col sm={6} md={6} lg={3} xl={2}>
                  <Button
                    block
                    color="primary"
                    onClick={this.navigateDriverPage}
                  >
                    BACK
                  </Button>
                </Col>
                <Col sm={6} md={6} lg={4} xl={2}>
                  <Button
                    block
                    color="primary"
                    className="addOnButton"
                    onClick={this.navigate}
                  >
                    ADD DRIVER
                  </Button>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col className="mt-3">
              {driverList && driverList.length > 0 ? (
                <BootstrapTable
                  keyField="id"
                  data={driverList}
                  columns={table1Columns}
                  filter={filterFactory()}
                  filterPosition="top"
                  defaultSorted={table1defaultSorted}
                  rowStyle={rowStyle}
                  bordered={false}
                  hover
                  wrapperClasses="table-responsive"
                  condensed
                  rowEvents={rowEvents}
                />
              ) : (
                <p className="massage">
                  There is no drvers for{" "}
                  {this.state.spName
                    ? this.state.spName
                    : "this service provider"}
                </p>
              )}
            </Col>
          </Row>

          {this.state.selectedDriver && (
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className={this.props.className}
              size="md"
            >
              <ModalHeader toggle={this.toggle}>
                <h1>{this.state.type} Driver Details</h1>
              </ModalHeader>
              <ModalBody className="p-0">
                {
                  <Row>
                    <Col>
                      <Card>
                        <CardImg
                          top
                          className="img-fluid"
                          src={this.state.selectedDriver.image_url}
                          alt="card image cap"
                        />
                        <CardBody className="pb-0">
                          <Row>
                            {this.details(
                              "Name",
                              this.state.selectedDriver.name
                            )}

                            {this.details(
                              "Phone Number",
                              this.state.selectedDriver.phone_number
                            )}
                            {this.details(
                              "Email",
                              this.state.selectedDriver.email
                            )}
                            {this.details(
                              "Vehicle Number",
                              this.state.selectedDriver.vehicle_number
                            )}
                            {this.details(
                              "Vehicle Active Status",
                              this.state.selectedDriver.statusBool
                                ? this.state.selectedDriver.status === 1
                                  ? "Online"
                                  : "Offline"
                                : "Offline"
                            )}
                            {!this.state.selectedDriver.statusBool &&
                              this.details(
                                "Offline Reason",
                                this.state.selectedDriver.reason
                              )}
                          </Row>
                          <StarRatings
                            rating={this.state.selectedDriver.rating}
                            starRatedColor="blue"
                            starDimension="20px"
                            starSpacing="5px"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                }
              </ModalBody>
              <ModalFooter className="py-1">
                <Button
                  color="warning"
                  onClick={() => {
                    this.setState({
                      DriverReport: true,
                      driverIdForReport: this.state.selectedDriver.driverID,
                    });
                  }}
                >
                  REPORT
                </Button>
                {false && (
                  <>
                    <Button
                      color="info"
                      onClick={() =>
                        this.storeCurrentDriver(this.state.selectedDriver)
                      }
                    >
                      <Edit />
                    </Button>
                  </>
                )}
                {this.state.adminStatus && (
                  <Button
                    color={
                      this.state.selectedDriver.status !== -2
                        ? "danger"
                        : "success"
                    }
                    onClick={() => {
                      this.enableDisableDriver(this.state.selectedDriver);
                    }}
                  >
                    {this.state.selectedDriver.status !== -2
                      ? "DISABLE"
                      : "ENABLE"}
                  </Button>
                )}

                <Button color="secondary" onClick={this.toggle}>
                  CLOSE
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </Container>
      </>
    );
  }
}

const columns = [
  {
    text: "SL.No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Provider ID",
    dataField: "id",
    sort: true,
    hidden: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Provider",
    dataField: "name",
    sort: true,
    align: "left",
    headerAlign: "center",
    filter: textFilter({
      style: {
        minWidth: "500px",
        placeholder: "Enter Service Provider Name ",
      },
    }),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Number of drivers",
    dataField: "drivers",
    footer: false,
    align: "center",
    filter: false,
    headerAlign: "center",
    sort: true,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Active Drivers",
    dataField: "activedrivers",
    footer: false,
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const defaultSorted = [
  {
    dataField: "Service Provider",
    order: "desc",
  },
];
class Drivers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: false,
      modal: false,
      spid: 0,
      selectedSpId: "",
      driverDetails: [],
      searchBarID: "",
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };

    this.toggle = this.toggle.bind(this);
    this.navigateAddDriver = this.navigateAddDriver.bind(this);
  }

  async componentDidMount() {
    const data = await RealTime.getDetails("service_providers/");
    this.getData(data);
  }

  getData = (drs) => {
    spdetails = [];
    let i = 1;
    if (drs != null) {
      var keys = Object.keys(drs);
      for (var dr = 0; dr < keys.length; dr++) {
        var k = keys[dr];
        var driver = 0;
        var activedriver = 0;
        if (k === localStorage.getItem("sp_id")) {
          driver =
            (drs[k].drivers_info &&
              drs[k].drivers_info.drivers_id &&
              Object.keys(drs[k].drivers_info.drivers_id).length) ||
            0;
          activedriver =
            (drs[k].drivers_info &&
              drs[k].drivers_info.active_drivers_id &&
              Object.keys(drs[k].drivers_info.active_drivers_id).length) ||
            0;
          spdetails.push({
            i: i++,
            name: drs[k].personal_information.name,
            drivers: driver,
            activedrivers: activedriver,
            id: k,
          });
        }
      }
    }

    this.setState({
      isloaded: true,
    });
    spDetailsCopy = spdetails;
  };

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });

    if (stateName == "searchBarID") {
      if (value == "") {
        spdetails = spDetailsCopy;
      } else {
        spdetails = [];
        Object.keys(spDetailsCopy).map((line, key) => {
          var IDSearch = spDetailsCopy[line].name.toLowerCase();
          if (IDSearch.startsWith(value.toLowerCase())) {
            spdetails.push(spDetailsCopy[line]);
          }
        });
      }
    }
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  navigateAddDriver = () => {
    this.setState({
      addDriver: 1,
    });
  };

  render() {
    if (this.state.addDriver == 1) {
      return <Redirect to="/admin/addDriver" />;
    }
    if (this.state.edit == 1) {
      return <EditDriver />;
    }
    if (this.state.redirect) {
      return <DriversBySP spId={this.state.selectedSpId} />;
    }
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          redirect: true,
          selectedSpId: row.id,
        });
        spID = row.id;
        // this.getDriverDetails(row.id);
      },
    };
    return (
      <>
        <Header />
        {/* Page content */}
        <PageLoader loading={!this.state.isloaded}></PageLoader>
        <Container className="mt--7" fluid>
          <Row className="mt-8">
            <Col sm={6} md={6} lg={8} xl={9}>
              <h1 className="mb-0">Drivers</h1>
            </Col>
            {false && (
              <>
                <Col sm={6} md={6} lg={4} xl={3}>
                  <Button
                    block
                    color="primary"
                    className="addDriver"
                    onClick={this.navigateAddDriver}
                  >
                    ADD DRIVER
                  </Button>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col className="mt-3">
              {spdetails && (
                <BootstrapTable
                  keyField="id"
                  data={spdetails}
                  columns={columns}
                  rowStyle={rowStyle}
                  filter={filterFactory()}
                  filterPosition="top"
                  pagination={paginationFactory()}
                  defaultSorted={defaultSorted}
                  bordered={false}
                  hover
                  wrapperClasses="table-responsive"
                  condensed
                  rowEvents={rowEvents}
                />
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const ReportTablecolumns = [
  {
    text: "timestamp",
    dataField: "timestamp",
    sort: true,
    align: "center",
    hidden: true,
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Sl No",
    dataField: "i",
    sort: false,
    align: "center",
    hidden: true,
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Date",
    dataField: "day",
    sort: false,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Active Time ",
    dataField: "activeTime",
    sort: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Inactive Time",
    dataField: "inactiveTime",
    sort: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const sessionColumns = [
  {
    text: "Action",
    dataField: "action",
    sort: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Time ",
    dataField: "formatted_time",
    sort: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Engaged Time ",
    dataField: "formatted_driver_time_engaged",
    sort: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const defaultSortedReport = [
  {
    dataField: "timestamp",
    order: "desc",
  },
];
let actionDetails = [];
var timeDetails = [];

class DriverReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: false,
      timeDetails: [],
      timeDetailsCopy: [],
      backToDriverBySp: false,
      dateRange: "",
      session_details: false,
      actionDetails: [],
      actionRow: [],
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };
  }
  async componentDidMount() {
    this.setState({
      timeDetails: [],
    });
    this.getDriverActivityRecord();
  }
  navigateDriver = () => {
    this.setState({
      backToDriverBySp: true,
    });
  };
  getDriverActivityRecord = async () => {
    timeDetails = [];
    const data = await RealTime.getDetails(
      "/drivers/" + this.props.driverId + "/driver_activity_tracking"
    );
    if (data) {
      let i = 1;
      Object.entries(data).forEach(async ([key, value]) => {
        if (value) {
          let records = await this.getArrangedRecords(value);
          let newList = await this.clearDuplicateEntry(records);
          let result = await this.calculateTime(newList, key);
          timeDetails.push({
            i: i,
            day: result.day,
            dayDisplay: result.dayDisplay,
            timestamp: result.timestamp,
            actionDetails: result.actionLogs,
            activeHours: result.effectiveActiveHours,
            activeMinutes: result.effectiveActiveMinutes,
            activeTime: result.activeTime,
            inactiveHours: result.effectiveInactiveHours,
            inactiveMinutes: result.effectiveInactiveMinutes,
            inactiveTime: result.inactiveTime,
            hasCal_error: result.hasCal_error,
          });
          i++;
        }
        this.setState({ isloaded: false });
        this.setState({ isloaded: true });
      });
    }

    this.setState({
      isloaded: true,
      timeDetails: timeDetails,
      timeDetailsCopy: timeDetails,
    });
  };
  getArrangedRecords = async (dayActions) => {
    let actionDetails = [];
    if (dayActions && dayActions.time_tracking) {
      Object.entries(dayActions.time_tracking).forEach(([key, value]) => {
        actionDetails.push({
          action: value.action,
          driver_time_engaged: value.driver_time_engaged,
          formatted_driver_time_engaged:
            value.driver_time_engaged !== 0
              ? String(
                  moment(value.driver_time_engaged)
                    .tz("Asia/Dubai")
                    .format("DD/MM/YYYY hh:mm:ss A")
                )
              : " -- : -- ",
          state: value.state,
          time: value.time,
          formatted_time: value.time
            ? String(
                moment(value.time)
                  .tz("Asia/Dubai")
                  .format("DD/MM/YYYY hh:mm:ss A")
              )
            : " -- : -- ",
        });
      });
    }
    return actionDetails;
  };
  clearDuplicateEntry = async (records) => {
    let newRecord = [];
    if (records) {
      for (let index = 0; index < records.length; index++) {
        if (records[index]?.state !== records[index + 1]?.state) {
          newRecord.push(records[index]);
        }
      }
    }
    return newRecord;
  };
  calculateTime = async (newList, key) => {
    let calculationError = false;
    let activeMinutes = 0;
    let inactiveMinutes = 0;
    for (let index = 0; index < newList.length; index++) {
      // active time calculation
      if (
        newList[index].state === 1 &&
        newList[index + 1] &&
        newList[index + 1].state === 0
      ) {
        let startTime = moment(newList[index].time);
        let endTime = moment(
          newList[index + 1].driver_time_engaged !== 0 &&
            newList[index + 1].driver_time_engaged > newList[index + 1].time
            ? newList[index + 1].driver_time_engaged
            : newList[index + 1].time
        );
        let minutes = await this.calculateTimeDiffrence(startTime, endTime);
        activeMinutes += minutes;
      }
      //inactive time calculation
      if (
        newList[index].state === 0 &&
        newList[index + 1] &&
        newList[index + 1].state === 1
      ) {
        let startTime = moment(
          newList[index].driver_time_engaged !== 0 &&
            newList[index].driver_time_engaged > newList[index].time
            ? newList[index + 1].time > newList[index].driver_time_engaged
              ? newList[index].driver_time_engaged
              : 0
            : newList[index].time
        );
        let endTime = moment(
          newList[index + 1].time > newList[index].driver_time_engaged
            ? newList[index + 1].time
            : 0
        );
        let minutes = await this.calculateTimeDiffrence(startTime, endTime);
        inactiveMinutes += minutes;
      }
    }
    let effectiveActiveHours = parseInt(activeMinutes / 60);
    let effectiveInactiveHours = parseInt(inactiveMinutes / 60);
    let effectiveActiveMinutes = Math.abs(
      activeMinutes - effectiveActiveHours * 60
    );
    let effectiveInactiveMinutes = Math.abs(
      inactiveMinutes - effectiveInactiveHours * 60
    );
    calculationError =
      effectiveActiveHours + effectiveInactiveHours > 24 ||
      effectiveActiveHours >= 24 ||
      effectiveInactiveHours >= 24
        ? true
        : false;

    return {
      actionLogs: !calculationError ? newList : [],
      effectiveActiveHours: !calculationError ? effectiveActiveHours : 0,
      effectiveInactiveHours: !calculationError ? effectiveInactiveHours : 0,
      effectiveActiveMinutes: !calculationError ? effectiveActiveMinutes : 0,
      effectiveInactiveMinutes: !calculationError
        ? effectiveInactiveMinutes
        : 0,
      activeTime: `${!calculationError ? effectiveActiveHours : 0} Hours : ${
        !calculationError ? effectiveActiveMinutes : 0
      } Minutes`,
      inactiveTime: `${
        !calculationError ? effectiveInactiveHours : 0
      } Hours : ${!calculationError ? effectiveInactiveMinutes : 0} Minutes`,
      day: key,
      dayDisplay: String(moment(key).tz("Asia/Dubai").format("DD/MM/YYYY")),
      timestamp: moment(key).valueOf(),
      hasCal_error: calculationError,
    };
  };
  calculateTimeDiffrence = async (startTime, endTime) => {
    var duration = moment.duration(endTime.diff(startTime));
    return parseInt(duration.asMinutes());
  };
  dateRangeHandler = async (event, picker) => {
    this.setState({
      dateRange:
        picker.startDate.format("DD/MM/YYYY") +
        " - " +
        picker.endDate.format("DD/MM/YYYY"),
      timeDetails: this.state.timeDetailsCopy,
    });

    const newTimeDetails = this.state.timeDetails.filter((time) => {
      if (
        moment(time.day) >= picker.startDate.toDate() &&
        moment(time.day) <= picker.endDate.toDate()
      ) {
        return true;
      }
    });

    this.setState({
      timeDetails: newTimeDetails,
    });
  };
  handleCancel = (event, picker) => {
    picker.element.val("");
    this.setState({
      timeDetails: this.state.timeDetailsCopy,
    });
  };
  toggle = () => {
    this.setState({
      session_details: !this.state.session_details,
    });
  };
  pdfGenerator = async () => {
    let date = this.state.actionRow.day;
    let driverName = this.props.selectedDriver.name;
    let service_provider = await RealTime.getDetails(
      `service_providers/${this.props.selectedDriver.spRelated}/personal_information/name`
    );
    let active = this.state.actionRow.activeTime;
    let inactive = this.state.actionRow.inactiveTime;
    const doc = new jsPDF();
    var header = function (data) {
      doc.setFontSize(10);
      doc.addImage(logo, "JPEG", 13, 3, 32, 12);
      doc.setLineWidth(1);
      doc.setDrawColor(62, 142, 222);
      doc.line(14, 14, 196, 14);
      doc.text("Driver Time Report", 14, 19);
      doc.text(`Date : ${date}`, 14, 26);
      doc.text(`Service Provider : ${service_provider}`, 14, 31);
      doc.text(`Active  : ${active}`, 14, 42);
      doc.text(`Inactive : ${inactive}`, 14, 47);
      doc.text(driverName, 197 - driverName.length * 1.8, 19);
    };
    var options = {
      beforePageContent: header,
      margin: {
        top: 100,
      },

      startY: doc.autoTableEndPosY() + 52,
    };
    var res = doc.autoTableHtmlToJson(document.getElementById("my-table"));
    doc.autoTable(res.columns, res.data, options);
    doc.save(`Time Report of ${driverName}`);
  };
  render() {
    const rowEvents = {
      onClick: async (e, row, rowIndex) => {
        this.setState({
          actionDetails: await row.actionDetails,
          actionRow: row,
          session_details: true,
        });
      },
    };
    if (this.state.backToDriverBySp) {
      return <DriversBySP />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <PageLoader loading={!this.state.isloaded}></PageLoader>
        <Container className="mt--7" fluid>
          <Row className="mt-8">
            <Col sm={6} md={6} lg={8} xl={9}>
              <h1 className="mb-0">Driver Session Details</h1>
            </Col>
            {true && (
              <>
                <Col sm={6} md={6} lg={4} xl={3}>
                  <Button
                    block
                    color="primary"
                    className="addDriver"
                    onClick={this.navigateDriver}
                  >
                    BACK
                  </Button>
                </Col>
              </>
            )}
          </Row>
          <br />
          <Row>
            <Col xl="4" lg="4" md="6" sm="12" xs="12">
              <DateRangePicker
                block
                autoApply={true}
                onApply={this.dateRangeHandler}
                onCancel={this.handleCancel}
                showOneCalendar
                initialSettings={{
                  autoUpdateInput: false,
                  locale: {
                    format: "DD/MM/YYYY",
                    cancelLabel: "Clear",
                  },
                  startDate: moment(new Date())
                    .tz("Asia/Dubai")
                    .format("DD MM YYYY hh:mm a"),
                  endDate: moment(new Date())
                    .tz("Asia/Dubai")
                    .format("DD MM YYYY hh:mm a"),
                  ranges: {
                    Today: [
                      moment().tz("Asia/Dubai").toDate(),
                      moment().tz("Asia/Dubai").toDate(),
                    ],
                    Yesterday: [
                      moment().subtract(1, "days").toDate(),
                      moment().subtract(1, "days").toDate(),
                    ],
                    "Last 7 Days": [
                      moment().subtract(6, "days").toDate(),
                      moment().toDate(),
                    ],
                    "Last 30 Days": [
                      moment().subtract(29, "days").toDate(),
                      moment().toDate(),
                    ],
                    "This Month": [
                      moment().startOf("month").toDate(),
                      moment().endOf("month").toDate(),
                    ],
                    "Last Month": [
                      moment().subtract(1, "month").startOf("month").toDate(),
                      moment().subtract(1, "month").endOf("month").toDate(),
                    ],
                  },
                }}
              >
                <input
                  type="text"
                  style={{ display: "inline-block" }}
                  className="form-control"
                  defaultValue=""
                  value={this.state.dateRange}
                  placeholder="Select Date Range"
                  disabled={this.state.timeDetailsCopy.length <= 0}
                />
              </DateRangePicker>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="mt-3">
              {this.state.timeDetails.length > 0 ? (
                <BootstrapTable
                  keyField="id"
                  data={this.state.timeDetails}
                  columns={ReportTablecolumns}
                  rowStyle={rowStyle}
                  filter={filterFactory()}
                  filterPosition="top"
                  pagination={paginationFactory()}
                  defaultSorted={defaultSortedReport}
                  bordered={false}
                  hover
                  wrapperClasses="table-responsive"
                  condensed
                  rowEvents={rowEvents}
                />
              ) : (
                <h2>Data not available </h2>
              )}
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={this.state.session_details}
          toggle={this.toggle}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            <h1>Session Details</h1>
          </ModalHeader>
          <ModalBody>
            <>
              {this.state.actionDetails.length > 0 ? (
                <BootstrapTable
                  id="my-table"
                  keyField="service_provider"
                  data={this.state.actionDetails}
                  columns={sessionColumns}
                  rowStyle={rowStyle}
                  // filter={filterFactory()}
                  // filterPosition='top'
                  // pagination={paginationFactory()}
                  bordered={false}
                  hover
                  wrapperClasses="table-responsive"
                  condensed
                  // rowEvents={rowEvents}
                />
              ) : (
                <h2>No session data available</h2>
              )}
            </>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.pdfGenerator();
              }}
            >
              EXPORT
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default DriversBySP;
