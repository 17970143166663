import React from "react";

// reactstrap components
import {
  Col,
  Row,
  Button,
  Card,
  Container,
  CardImg,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  CardHeader,
  Label,
  InputGroupText,
  Form,
  FormGroup,
} from "reactstrap";
import { Redirect } from "react-router-dom";
// core components
import Header from "components/Headers/Header.jsx";
import { Zap } from "react-feather";
import StatisticsCard from "../../components/statisticsCard/StatisticsCard";
import firebase from "../../firebaseConfig";

const firebaseCredential = require("firebase");

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: [],
      addNotification: 0,
      totalNotification: 0,
      modal: false,
      CouponId: "",
      deletePasswordConfirmation: "",
      password: "",
    };
    this.toggle = this.toggle.bind(this);

    this.deletePasswordConfirmationToggle = this.deletePasswordConfirmationToggle.bind(
      this
    );
    this.deletePasswordConfirm = this.deletePasswordConfirm.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  deletePasswordConfirmationToggle() {
    this.setState({
      deletePasswordConfirmation: !this.state.deletePasswordConfirmation,
    });
  }

  deletePasswordConfirm() {
    const { password } = this.state;
    var user = firebase.auth().currentUser;
    var credential = firebaseCredential.auth.EmailAuthProvider.credential(
      user.email,
      password
    );

    // Prompt the user to re-provide their sign-in credentials
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        // User re-authenticated.
        this.deletePasswordConfirmationToggle();
        this.toggle();
      })
      .catch(function (error) {
        // An error happened.
        alert(error);
      });
  }

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    const dr = firebase.database().ref("twaddan_admin/notification/");
    dr.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        var keys = Object.keys(drs);
        let newService = [];
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          newService.push({
            description: drs[k].description,
            image_url: drs[k].image,
            name: drs[k].name,
            link: drs[k].link,
            id: k,
          });
        }
        this.setState({
          notification: newService,
        });
      }
    });
  };
  deletePromoCode = () => {
    console.log(this.state.CouponId);
    var img = this.state.CouponId + ".png";
    // Create a reference to the file to delete
    var storageRef = firebase.storage().ref();
    var desertRef = storageRef.child("/twaddan_admin/notification/" + img);

    // Delete the file
    desertRef
      .delete()
      .then(function () {
        // File deleted successfully
      })
      .catch(function (error) {
        // Uh-oh, an error occurred!
      });
    let userRef = firebase
      .database()
      .ref("/twaddan_admin/notification/" + this.state.CouponId);
    userRef.remove();
    this.toggle();
  };
  navigate = () => {
    this.setState({
      addNotification: 1,
    });
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    if (this.state.addNotification == 1) {
      return <Redirect to='/admin/addNotification' />;
    }
    const { notification } = this.state;

    let notificationList =
      notification.length > 0 &&
      notification.map((i) => {
        return (
          <Col lg='4' md='6' sm='12'>
            <Card>
              <CardImg
                top
                className='img-fluid'
                src={i.image_url}
                style={{ height: "250px", width: "3000px" }}
                alt='card image cap'
              />
              <CardBody>
                <h4>{i.name}</h4>
                <Button
                  color='danger'
                  style={{ position: "absolute", top: "60%", right: "10px" }}
                  onClick={() =>
                    this.setState({
                      CouponId: i.id,
                      deletePasswordConfirmation: true,
                    })
                  }>
                  DELETE
                </Button>
                <p className='mb-0'>Description: {i.description}</p>
                <a href={i.link} target='_blank'>
                  Know more
                </a>
              </CardBody>
            </Card>
          </Col>
        );
      }, this);
    return (
      <React.Fragment>
        <>
          <Header />
          {/* Page content */}
          <Container className='mt--7' fluid>
            <div className='h5 mt-8'>
              <h1>Notification</h1>
              <Row>
                <Col lg='3' md='6' sm='12'>
                  <StatisticsCard
                    hideChart
                    iconBg='success'
                    icon={<Zap className='success' size={30} />}
                    stat={this.state.notification.length}
                    statTitle='Total Notification'
                  />
                </Col>
              </Row>
              <Button
                color='primary'
                onClick={this.navigate}
                style={{ position: "absolute", top: "250px", right: "20px" }}>
                ADD NOTIFICATION
              </Button>
              <br></br>
              <br></br>
              <h2>Notification List</h2>
              <br></br>
              <br></br>
              <Row>
                {notificationList ? (
                  notificationList
                ) : (
                  <p>No Notification exsits</p>
                )}
              </Row>
            </div>
          </Container>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalBody>Do you want to delete</ModalBody>
            <ModalFooter>
              <Button color='info' onClick={this.toggle}>
                N0
              </Button>
              <Button color='danger' onClick={this.deletePromoCode}>
                YES
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.deletePasswordConfirmation}
            toggle={this.deletePasswordConfirmationToggle}>
            <ModalBody>Confirm Password</ModalBody>
            <ModalFooter>
              <CardBody className='px-lg-5 py-lg-5'>
                <div className='text-center text-muted mb-4'>
                  <large>Verify Password to Proceed with Deletion</large>
                </div>
                <Form role='form'>
                  <FormGroup>
                    <InputGroup className='input-group-alternative mb-3'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-circle-08' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Password'
                        type='password'
                        onChange={(e) =>
                          this.onChange("password", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <Button
                    color='info'
                    onClick={this.deletePasswordConfirmationToggle}>
                    CANCEL
                  </Button>
                  <Button color='danger' onClick={this.deletePasswordConfirm}>
                    PROCEED
                  </Button>
                </Form>
              </CardBody>
            </ModalFooter>
          </Modal>
        </>
      </React.Fragment>
    );
  }
}

export default Notification;
