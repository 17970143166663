import React, { useState } from "react";
import firebase from "firebaseConfig";
import { connect } from "react-redux";
import Select from "react-select";
import Switch from "react-switch";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Container,
  Row,
  Col,
  Badge,
  FormGroup,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import Header from "components/Headers/Header.jsx";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import RealTime from "../services/RealTime";
import StaticCard from "../components/statisticsCard/StatisticsCard.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "./styles/Dashboard.css";
import { Line } from "react-chartjs-2";
import PageLoader from "../components/PageLoader/PageLoader";
// total Orders count
var totalOrders = 0;
// active Orders count
var activeOrders = 0;
// Pending Orders
var pendingOrders = 0;
// active vehicle count
// completed Orders
var completedOrders = 0;
// total service providers
var totalSPs = 0;
// total earning
var totalEarnings = 0;
// current emergency
var currentEmergency = [];
// past emergency
var pastEmergency = [];
// service providers details
var monthArray = [];
var spDetails;
var allOrders;
var newAllOrders = [];
let totalDriver = Number(0);
let totalVehicles = Number(0);
firebase.auth().onAuthStateChanged(async function (user) {
  if (user) {
    var tempDate;
    let spDetailsFromDb = await RealTime.getDetails("/service_providers/");
    if (spDetailsFromDb) {
      var index = 0;
      Object.keys(spDetailsFromDb).map((sp, keySP) => {
        if (
          spDetailsFromDb[sp].past_emergency &&
          sp === localStorage.getItem("sp_id")
        ) {
          Object.keys(spDetailsFromDb[sp].past_emergency).map((line, key) => {
            tempDate = line.split(" ")[0];
            pastEmergency[index] = {
              details: spDetailsFromDb[sp].past_emergency[line],
              date: tempDate,
              spRelated: sp,
            };
            index = index + 1;
          });
        }
      });
      if (pastEmergency.length > 0) {
        pastEmergency.sort(function (a, b) {
          return b.date - a.date;
        });
      }
    }
    // current emergency notification
    var tempTime;
    if (spDetailsFromDb) {
      var index = 0;
      Object.keys(spDetailsFromDb).map((sp, keySP) => {
        if (
          spDetailsFromDb[sp].emergency &&
          sp === localStorage.getItem("sp_id")
        ) {
          Object.keys(spDetailsFromDb[sp].emergency).map((line, key) => {
            // tempDate = line.split(' ')[0];
            // "12 Jun 2020 06:46PM"
            tempDate = spDetailsFromDb[sp].emergency[line].time_request_raised;
            tempDate = tempDate.slice(4);
            tempDate = tempDate.replace(/-/g, " ");
            tempDate = tempDate.replace(",", "");
            tempTime = 0;
            if (tempDate[17] === "P") {
              tempTime = tempDate.slice(12, 14);
              tempTime = parseInt(tempTime) + 12;
              tempTime = tempTime.toString();
              tempDate = tempDate.slice(0, 12) + tempTime + tempDate.slice(14);
            }
            tempDate = tempDate.slice(0, 17) + ":00";
            tempDate = new Date(tempDate);
            tempDate = tempDate.getTime();
            currentEmergency[index] = {
              details: spDetailsFromDb[sp].emergency[line],
              date: tempDate,
              spRelated: sp,
            };
            index = index + 1;
          });
        }
      });
      if (currentEmergency.length > 0) {
        currentEmergency.sort(function (a, b) {
          return b.date - a.date;
        });
      }
    }
  } else {
    // No user is signed in.
  }
});
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      totalOredrs: Number(0),
      totalEarning: Number(0),
      activeOrders: Number(0),
      pendingOrders: Number(0),
      totalDriver: Number(0),
      totalVehicles: Number(0),
      allOrders: [],
      allOrdersByDate: [],
      allOrdersByDateAndEmirate: [],
      allSPs: [],
      allSPsByDate: [],
      allSPsByDateAndEmirate: [],
      allVehicles: [],
      allDrivers: [],
      allVehiclesByDate: [],
      allVehiclesByDateAndEmirate: [],
      earningsPerMonth: [],
      orderPerMonth: [],
      isLoaded: false,
      labels: [],
      monthArray: [],
      addservices: 0,
      newcenter: 0,
      addVehicles: 0,
      addDriver: 0,
      addservices: 0,
      addAddOn: 0,
      drivers: [],
      date: "",
      scheduledOffTime: false,
      scheduledOnTime: false,
      currentScheduledOnTime: false,
      currentScheduledOffTime: false,
      timeRange: [],
    };
    this.toggle = this.toggle.bind(this);
    this.navigateBooking = this.navigateBooking.bind(this);
    this.navigateAddVehicles = this.navigateAddVehicles.bind(this);
    this.navigateAddServices = this.navigateAddServices.bind(this);
    this.navigateAddAddOn = this.navigateAddAddOn.bind(this);
    this.navigateSpCenter = this.navigateSpCenter.bind(this);
    this.myRef = React.createRef();
  }
  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };
  navigateSpCenter = () => {
    this.setState({
      newcenter: 1,
    });
  };
  navigateBooking = () => {
    this.setState({
      booking: 1,
    });
  };
  navigateAddVehicles = () => {
    this.setState({
      addVehicles: 1,
    });
  };
  navigateAdddriver = () => {
    this.setState({
      addDriver: 1,
    });
  };
  navigateAddServices = () => {
    this.setState({
      addservices: 1,
    });
  };
  navigateAddAddOn = () => {
    this.setState({
      addAddOn: 1,
    });
  };
  handleCancel = (event, picker) => {
    picker.element.val("");
    this.clearFilter();
  };

  dateRangeHandler = async (event, picker) => {
    this.setState({
      date:
        picker.startDate.format("DD/MM/YYYY") +
        " - " +
        picker.endDate.format("DD/MM/YYYY"),
    });
    picker.element.val(this.state.date);
    this.setState({ isLoaded: false });
    let result;
    let totalDriver = Number(0);
    let totalVehiclesByDate = Number(0);
    let newOrders = this.state.allOrders.filter((order) => {
      let timeOrderPlaced = moment(order.time_order_placed)
        .tz("Asia/Dubai")
        .format("MM/DD/YYYY hh:mm a");
      return (
        moment(timeOrderPlaced) >= picker.startDate.toDate() &&
        moment(timeOrderPlaced) <= picker.endDate.toDate()
      );
    });
    let newDrivers = Object.entries(this.state.allDrivers).forEach(
      ([keyDriver, driver]) => {
        if (driver.sp_related === localStorage.getItem("sp_id")) {
          let created_on = moment(driver.created_on)
            .tz("Asia/Dubai")
            .format("MM/DD/YYYY hh:mm a");
          return (
            moment(created_on) >= picker.startDate.toDate() &&
            moment(created_on) <= picker.endDate.toDate()
          );
        }
      }
    );

    let newVehicles = this.state.allVehicles.filter((vehicle) => {
      if (vehicle.registeredDate) {
        let registeredDate = moment(vehicle.registeredDate)
          .tz("Asia/Dubai")
          .format("MM/DD/YYYY hh:mm a");
        return (
          moment(registeredDate) >= picker.startDate.toDate() &&
          moment(registeredDate) <= picker.endDate.toDate()
        );
      }
    });
    if (newDrivers) totalDriver = newDrivers.length;
    if (newVehicles) totalVehiclesByDate = newVehicles.length;
    if (newOrders) {
      result = await this.getOrderDetails(newOrders);
    }
    this.setState({
      selectedEmirate: { label: "All", value: "All" },
      allOrdersByDate: result.newAllOrders,
      totalOrders: result.totalOrders,
      totalVehicles: totalVehiclesByDate,
      totalDriver: totalDriver,
      totalEarnings: result.totalEarnings,
      activeOrders: result.activeOrders,
      pendingOrders: result.pendingOrders,
      earningsPerMonth: result.totalMonthEarnings,
      orderPerMonth: result.totalMonthOrders,
      labels: result.totalMonthLabel,
      isLoaded: true,
    });
  };
  handleEmirateChange = async (event) => {
    this.setState({ isLoaded: false });
    let result;
    let newOrders = [];
    let value = event.target.value;
    if (value === "All") {
      newOrders = this.state.allOrdersByDate;
    } else {
      newOrders = this.state.allOrdersByDate.filter((order) => {
        if (order.driver_id) return value === order.driver_id;
      });
    }
    if (newOrders) {
      result = await this.getOrderDetails(newOrders);
    }
    this.setState({
      selectedEmirate: value,
      allOrdersByDateAndEmirate: result.newAllOrders,
      totalOrders: result.totalOrders,
      totalEarnings: result.totalEarnings,
      activeOrders: result.activeOrders,
      pendingOrders: result.pendingOrders,
      totalDriver: totalDriver,
      totalVehicles: totalVehicles,
      earningsPerMonth: result.totalMonthEarnings,
      orderPerMonth: result.totalMonthOrders,
      labels: result.totalMonthLabel,
      isLoaded: true,
    });
  };
  getEmirates = async () => {
    let driversList = [];
    let allDrivers = [];
    driversList.push({
      label: "All",
      value: "All",
    });
    const drivers = await RealTime.getDetails("drivers");
    Object.entries(drivers).forEach(([keyDriver, driver]) => {
      driver.sp_related.map((sp) => {
        if (sp.sp_id === localStorage.getItem("sp_id")) {
          console.log("driver: ", driver, keyDriver);
          driversList.push({
            label: driver.personal_information.name,
            value: keyDriver,
          });
          allDrivers.push(driver);
        }
      });
    });

    return { driversList: driversList, allDrivers: allDrivers };
  };
  clearFilter = async () => {
    this.setState({
      date: "",
    });
    let result;
    let emirates = await this.getEmirates();

    if (this.state.allOrders) {
      result = await this.getOrderDetails(this.state.allOrders);
    }
    this.setState({
      allOrders: this.state.allOrders,
      allOrdersByDate: this.state.allOrders,
      allOrdersByDateAndEmirate: this.state.allOrders,
      allSPs: this.state.allSPs,
      allSPsByDate: this.state.allSPs,
      allSPsByDateAndEmirate: this.state.allSPs,
      allVehicles: this.state.allVehicles,
      allVehiclesByDate: this.state.allVehicles,
      allVehiclesByDateAndEmirate: this.state.allVehicles,
      // emirates: emirates,
      totalOrders: result.totalOrders,
      totalEarnings: result.totalEarnings,
      activeOrders: result.activeOrders,
      pendingOrders: result.pendingOrders,
      totalSPs: totalSPs,
      totalVehicles: totalVehicles,
      totalDriver: totalDriver,
      earningsPerMonth: result.totalMonthEarnings,
      orderPerMonth: result.totalMonthOrders,
      labels: result.totalMonthLabel,
      isLoaded: true,
      selectedEmirate: "",
    });
  };
  enableDisableSP = () => {
    let status = this.state.spStatus === 1 ? 0 : 1;
    this.setState({ spStatus: status });
    firebase
      .database()
      .ref("service_providers/" + localStorage.getItem("sp_id") + "/")
      .update({
        state: status,
      });
  };
  allDriverDisableEnable = (e, action) => {
    e.preventDefault();
    this.state.allDriversDetails.map((driver) => {
      this.enableDisableDriver(driver.id, driver.driver, action);
    });
  };

  enableDisableDriver = async (selectedDriverId, selectedDriverED, action) => {
    console.log("function start------------------------");
    console.log("selectedDriverId: ", selectedDriverId);
    console.log("selectedDriverED: ", selectedDriverED);
    console.log("selectedDriverED.status: ", selectedDriverED.status);
    console.log("action: ", action);
    console.log("------------------------------------");

    let action_performed = false;
    let status;
    let trackingState = 0;
    let reason;
    let reason_text;
    let prevStatus;
    var time = new Date();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    time = time.getTime();
    var todate = new Date(time).getDate();
    var tomonth = new Date(time).getMonth();
    var toyear = new Date(time).getFullYear();
    var original_date = todate + "-" + months[tomonth] + "-" + toyear;
    var currentStatus;
    if (action === "enable") {
      if (selectedDriverED.status === -2) {
        action_performed = true;
        prevStatus = await RealTime.getDetails(
          "drivers/" + selectedDriverId + "/prevStatus/"
        );
        console.log("prevStatus: ", prevStatus);
        if (prevStatus === 1) {
          trackingState = 1;
          status = 1;
          reason = null;
          if (selectedDriverED.personal_information.vehicle_number)
            RealTime.updateNode(
              "/vehicles/" +
                selectedDriverED.personal_information.vehicle_number +
                "/",
              {
                driver_status: true,
                busy_status: false,
              }
            );
        } else {
          status = 0;
          reason = 4; //Enable by SP admin
        }
        prevStatus = null;
        reason_text = "Enable by SP admin";
      }
    } else {
      if (selectedDriverED.status !== -2) {
        action_performed = true;
        trackingState = 0;
        status = -2;
        reason = 3; //Disable by SP admin
        prevStatus = selectedDriverED.status;
        reason_text = "Disable by SP admin";
        if (selectedDriverED.personal_information.vehicle_number)
          RealTime.updateNode(
            "/vehicles/" +
              selectedDriverED.personal_information.vehicle_number +
              "/",
            {
              driver_status: false,
            }
          );
      }
    }
    if (action_performed) {
      const drivers = [];
      this.state.allDriversDetails.map((driver, index) => {
        if (driver.id === selectedDriverId) {
          console.log("driver: ", driver);
          driver.status = status === 1 ? true : false;
          driver.driver.status = status;
        }
        drivers.push(driver);
      });
      this.setState({ allDriversDetails: drivers });
      console.log("drivers: ", drivers);
      console.log(
        "this.state.allDriversDetails: ",
        this.state.allDriversDetails
      );
      firebase
        .database()
        .ref("drivers/" + selectedDriverId + "/")
        .update({
          status: status,
          reason: reason,
          prevStatus: prevStatus,
        });
      firebase
        .database()
        .ref(
          "drivers/" +
            selectedDriverId +
            "/driver_activity_tracking/" +
            original_date +
            "/time_tracking/" +
            time +
            "/"
        )
        .set({
          action: reason_text,
          driver_time_engaged: 0,
          state: trackingState,
          time: time,
        });
    }
  };
  async componentDidMount() {
    let user = localStorage.getItem("sp_id");
    let newAllVehicles = [];
    var adminStatusRef = await firebase
      .database()
      .ref("service_providers/" + user + "/admin_status");
    adminStatusRef.on("value", async (snapshot) => {
      const data = snapshot.val();
      this.setState({
        adminStatus: data,
      });
    });
    let timeRange = [];
    for (let index = 0; index < 24; index++)
      timeRange.push({
        value: index,
        label: `${index}:00`,
      });
    this.setState({
      timeRange: timeRange,
    });
    var spStartTimeRef = await firebase
      .database()
      .ref("service_providers/" + user + "/scheduledOnTime");
    await spStartTimeRef.on("value", async (snapshot) => {
      const data = snapshot.val();
      this.setState({
        currentScheduledOnTime: data,
      });
    });
    var spStopTimeRef = await firebase
      .database()
      .ref("service_providers/" + user + "/scheduledOffTime");
    await spStopTimeRef.on("value", async (snapshot) => {
      const data = snapshot.val();
      this.setState({
        currentScheduledOffTime: data,
      });
    });

    let result;
    let drivers;
    if (user) {
      this.setState({
        totalOrders: totalOrders,
        totalEarnings: totalEarnings,
        activeOrders: activeOrders,
        pendingOrders: pendingOrders,
        totalDriver: totalDriver,
        totalVehicles: totalVehicles,
      });
    }
    spDetails = await RealTime.getDetails("/service_providers/" + user);
    var adminStatus = await RealTime.getDetails(
      "service_providers/" + localStorage.getItem("sp_id") + "/admin_status"
    );
    totalDriver = spDetails.drivers_info.total_drivers
      ? spDetails.drivers_info.total_drivers
      : 0;
    if (spDetails.vehicles) {
      totalVehicles = Object.keys(spDetails.vehicles).length;
      if (spDetails) {
        Object.entries(spDetails.vehicles).forEach(([key, vehicles]) => {
          newAllVehicles.push(vehicles);
        });
      }
    }

    var allDriversDetails = [];
    var driverRef = await firebase.database().ref("/drivers/");
    driverRef.on("value", async (snapshot) => {
      allDriversDetails = [];
      const data = snapshot.val();
      Object.entries(data).forEach(([key, driver]) => {
        driver.sp_related.map(async (sp) => {
          if (sp.sp_id === user)
            allDriversDetails.push({
              driverName: driver?.personal_information?.name,
              number: driver?.personal_information?.phone_number,
              vehicleNumber: driver?.personal_information?.vehicle_number,
              status: driver?.status === 1,
              driver: driver,
              id: key,
              lastSeen: this.getDriverLastSeen(
                driver.driver_activity_tracking,
                driver?.status
              ),
            });
        });
      });
      this.setState({ allDriversDetails: allDriversDetails });
    });

    allOrders = await RealTime.getDetails("/orders/all_orders/");
    if (allOrders) {
      result = await this.getOrderDetails(allOrders);
    }

    if (user) {
      drivers = await this.getEmirates();
      this.setState({
        allOrders: result.newAllOrders,
        allOrdersByDate: result.newAllOrders,
        allOrdersByDateAndEmirate: result.newAllOrders,
        allVehicles: newAllVehicles,
        allDrivers: drivers.allDrivers,
        allDriversDetails: allDriversDetails,
        drivers: drivers.driversList,
        totalOrders: result.totalOrders,
        totalEarnings: result.totalEarnings,
        activeOrders: result.activeOrders,
        pendingOrders: result.pendingOrders,
        totalDriver: totalDriver,
        totalVehicles: totalVehicles,
        earningsPerMonth: result.totalMonthEarnings,
        orderPerMonth: result.totalMonthOrders,
        labels: result.totalMonthLabel,
        isLoaded: true,
        spStatus: spDetails.state,
        adminStatus: adminStatus,
      });
    }
    console.log("data", allDriversDetails);
  }

  getDriverLastSeen = (driver_activity_tracking, status) => {
    let lastDayKey = 0;
    let lastDayTimeStamp = 0;
    let lastTimeKey = 0;
    let lastAction = {};
    if (driver_activity_tracking) {
      Object.entries(driver_activity_tracking).forEach(([keyDay, day]) => {
        if (moment(keyDay).format("x") > lastDayTimeStamp) {
          lastDayKey = keyDay;
          lastDayTimeStamp = moment(keyDay).format("x");
        }
      });

      Object.entries(
        driver_activity_tracking[lastDayKey].time_tracking
      ).forEach(([keyTime, action]) => {
        if (keyTime > lastTimeKey) {
          lastTimeKey = keyTime;
          lastAction = action;
        }
      });
    }

    if (lastAction && lastAction.state === 1 && status === 1)
      return "AVAILABILE";
    else
      return lastAction.time
        ? String(moment(lastAction.time).format("DD/MM/YYYY hh:mm"))
        : "NO DATA";
  };
  setSchedule = () => {
    if (
      this.state.scheduledOnTime &&
      this.state.scheduledOffTime &&
      this.state.scheduledOnTime !== this.state.scheduledOffTime
    ) {
      this.setState({
        currentScheduledOffTime: this.state.scheduledOffTime,
        currentScheduledOnTime: this.state.scheduledOnTime,
      });
      let spID = localStorage.getItem("sp_id");
      firebase.database().ref(`service_providers/${spID}/`).update({
        scheduledOnTime: this.state.scheduledOnTime,
        scheduledOffTime: this.state.scheduledOffTime,
      });
    } else {
      alert("Select Both Start and End Time");
    }
  };

  getOrderDetails = async (allOrders) => {
    var newAllOrders = [];
    var totalMonthEarnings = [];
    var totalMonthLabel = [];
    var totalMonthOrders = [];
    var totalEarnings = 0;
    var totalOrders = 0;
    var pendingOrders = 0;
    var activeOrders = 0;
    var completedOrders = 0;
    var result = {};
    var yearCount = moment().tz("Asia/Dubai").year() - 2020 + 1; // 2020 twaddan starting year
    var currentMonth = moment(new Date()).tz("Asia/Dubai").month() + 1;
    var monthDiffrence = 12 - currentMonth;
    var monthLength = 12 * yearCount - monthDiffrence;
    var labels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    for (let month = 0; month < monthLength; month++) {
      totalMonthEarnings.push(0);
      totalMonthOrders.push(0);
      totalMonthLabel.push(
        `${labels[month % 12]} - ${month / 12 >= 1 ? "21" : "20"}`
      );
    }
    Object.entries(allOrders).forEach(([key, order]) => {
      if (order.status >= 0 && order.sp_id === localStorage.getItem("sp_id")) {
        newAllOrders.push(order);
        // calculating month and year of order
        let month = moment(order.time_order_placed).tz("Asia/Dubai").month();
        let year = moment(order.time_order_placed).tz("Asia/Dubai").year();
        let currentYear = moment().tz("Asia/Dubai").year();
        // finding index to store earnings and order according to month and year
        for (let index = 1; index <= yearCount; index++) {
          if (currentYear - year === yearCount - index) {
            totalMonthEarnings[12 * (index - 1) + month] =
              totalMonthEarnings[12 * (index - 1) + month] + order.total_due;
            totalMonthOrders[12 * (index - 1) + month] =
              totalMonthOrders[12 * (index - 1) + month] + 1;
          }
        }
        if (order.status === 0) {
          pendingOrders += 1;
        } else if (order.status < 5 && order.status > 0) {
          activeOrders += 1;
        } else {
          completedOrders += 1;
        }
        totalEarnings += order.total_due;
      }
    });

    totalOrders = pendingOrders + activeOrders + completedOrders;
    result = {
      totalMonthEarnings: totalMonthEarnings,
      totalMonthOrders: totalMonthOrders,
      totalEarnings: totalEarnings,
      totalOrders: totalOrders,
      totalMonthLabel: totalMonthLabel,
      newAllOrders: newAllOrders,
      pendingOrders: pendingOrders,
      activeOrders: activeOrders,
    };
    console.log("result: ", result);
    return result;
  };

  render() {
    if (this.state.booking == 1) {
      return <Redirect to="/admin/newBooking" />;
    }
    if (this.state.addVehicles == 1) {
      return <Redirect to="admin/addVehicle" />;
    }
    if (this.state.addDriver == 1) {
      return <Redirect to="/admin/addDriver" />;
    }
    if (this.state.addservices == 1) {
      return <Redirect to="/admin/addService" />;
    }
    if (this.state.addAddOn == 1) {
      return <Redirect to="/admin/addAddonService" />;
    }
    if (this.state.newcenter === 1) {
      return <Redirect to="/admin/addServiceCenters" />;
    }
    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isLoaded}></PageLoader>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <br />

          <Container fluid>
            <div className="header-body">
              <Row className="mt-6 visibilitySmallDevice">
                <div className=" mt-3 col-md-6 col-lg-3 col-xl-4">
                  <FormGroup row>
                    <Col>
                      <DateRangePicker
                        block
                        autoApply={true}
                        onApply={this.dateRangeHandler}
                        onCancel={this.handleCancel}
                        showOneCalendar
                        initialSettings={{
                          autoUpdateInput: false,
                          locale: {
                            format: "DD/MM/YYYY",
                            cancelLabel: "Clear",
                          },
                          startDate: moment(new Date())
                            .tz("Asia/Dubai")
                            .format("DD MM YYYY hh:mm a"),
                          endDate: moment(new Date())
                            .tz("Asia/Dubai")
                            .format("DD MM YYYY hh:mm a"),
                          ranges: {
                            Today: [
                              moment().tz("Asia/Dubai").toDate(),
                              moment().tz("Asia/Dubai").toDate(),
                            ],
                            Yesterday: [
                              moment().subtract(1, "days").toDate(),
                              moment().subtract(1, "days").toDate(),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days").toDate(),
                              moment().toDate(),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days").toDate(),
                              moment().toDate(),
                            ],
                            "This Month": [
                              moment().startOf("month").toDate(),
                              moment().endOf("month").toDate(),
                            ],
                            "Last Month": [
                              moment()
                                .subtract(1, "month")
                                .startOf("month")
                                .toDate(),
                              moment()
                                .subtract(1, "month")
                                .endOf("month")
                                .toDate(),
                            ],
                          },
                        }}
                      >
                        <input
                          type="text"
                          style={{ display: "inline-block" }}
                          className="form-control"
                          defaultValue=""
                          placeholder="Select Date Range"
                        />
                      </DateRangePicker>
                    </Col>
                  </FormGroup>
                </div>
                <div className="mt-3 col-md-6 col-lg-3 col-xl-4">
                  <FormGroup row>
                    <Col>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        value={this.state.selectedEmirate}
                        onChange={this.handleEmirateChange}
                      >
                        <option selected disabled value="">
                          Select Driver
                        </option>
                        {this.state.drivers &&
                          this.state.drivers.length > 0 &&
                          this.state.drivers.map((emirate, index) => {
                            return (
                              <option key={index} value={emirate.value}>
                                {emirate.label}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                  </FormGroup>
                </div>
                <div className="mt-3 col-xs-6 col-md-6 col-lg-3 col-xl-2">
                  <FormGroup>
                    <Button color="primary" block onClick={this.clearFilter}>
                      CLEAR FILTER
                    </Button>
                  </FormGroup>
                </div>
                {this.state.adminStatus && (
                  <div className="mt-3 col-xs-6 col-md-6 col-lg-3 col-xl-2">
                    <FormGroup>
                      <Button
                        block
                        color={this.state.spStatus == 1 ? "danger" : "success"}
                        onClick={() => {
                          this.enableDisableSP();
                        }}
                      >
                        {this.state.spStatus == 1 ? "OFFLINE" : "ONLINE"}
                      </Button>
                    </FormGroup>
                  </div>
                )}
              </Row>
              {/* Card stats */}
              <Row>
                <Col xs={12} sm={6} md={6} lg={4} xl={4} key={0}>
                  <StaticCard
                    iconRight={true}
                    hideChart={true}
                    iconBg="danger"
                    icon="address-card"
                    statTitle={
                      <span>
                        <br></br>PENDING<br></br>ORDERS
                      </span>
                    }
                    stat={this.state.pendingOrders}
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={4} key={1}>
                  <StaticCard
                    iconRight={true}
                    hideChart={true}
                    iconBg="success"
                    icon="address-card"
                    statTitle={
                      <span>
                        <br></br>ACTIVE<br></br>ORDERS
                      </span>
                    }
                    stat={this.state.activeOrders}
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={4} key={2}>
                  <StaticCard
                    iconRight={true}
                    hideChart={true}
                    iconBg="info"
                    icon="address-book"
                    statTitle={
                      <span>
                        <br></br>TOTAL<br></br>ORDERS
                      </span>
                    }
                    stat={this.state.totalOrders}
                  />
                </Col>
                <Col
                  className="dashboard_cards"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  key={3}
                >
                  <StaticCard
                    iconRight={true}
                    hideChart={true}
                    iconBg="warning"
                    icon="taxi"
                    statTitle={
                      <span>
                        <br></br>TOTAL<br></br>VEHICLES
                      </span>
                    }
                    stat={this.state.totalVehicles}
                  />
                </Col>
                <Col
                  className="dashboard_cards"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  key={4}
                >
                  <StaticCard
                    iconRight={true}
                    hideChart={true}
                    iconBg="primary"
                    icon="address-book"
                    statTitle={
                      <span>
                        <br></br>TOTAL<br></br>DRIVERS
                      </span>
                    }
                    stat={this.state.totalDriver}
                  />
                </Col>
                <Col
                  className="dashboard_cards"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  key={5}
                >
                  <StaticCard
                    iconRight={true}
                    hideChart={true}
                    iconBg="dark"
                    icon="gift"
                    statTitle={
                      <span>
                        <br></br>TOTAL<br></br>EARNINGS
                      </span>
                    }
                    stat={this.state.totalEarnings}
                  />
                </Col>
              </Row>
            </div>
          </Container>
          <Container fluid>
            <div className="header-body">
              <Row className="mt-4 visibilityMediumDevice">
                <div className=" mt-3 col-md-6 col-lg-3 col-xl-4">
                  <FormGroup row>
                    <Col>
                      <DateRangePicker
                        block
                        autoApply={true}
                        onApply={this.dateRangeHandler}
                        onCancel={this.handleCancel}
                        showOneCalendar
                        initialSettings={{
                          autoUpdateInput: false,
                          locale: {
                            format: "DD/MM/YYYY",
                            cancelLabel: "Clear",
                          },
                          startDate: moment(new Date())
                            .tz("Asia/Dubai")
                            .format("DD MM YYYY hh:mm a"),
                          endDate: moment(new Date())
                            .tz("Asia/Dubai")
                            .format("DD MM YYYY hh:mm a"),
                          ranges: {
                            Today: [
                              moment().tz("Asia/Dubai").toDate(),
                              moment().tz("Asia/Dubai").toDate(),
                            ],
                            Yesterday: [
                              moment().subtract(1, "days").toDate(),
                              moment().subtract(1, "days").toDate(),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days").toDate(),
                              moment().toDate(),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days").toDate(),
                              moment().toDate(),
                            ],
                            "This Month": [
                              moment().startOf("month").toDate(),
                              moment().endOf("month").toDate(),
                            ],
                            "Last Month": [
                              moment()
                                .subtract(1, "month")
                                .startOf("month")
                                .toDate(),
                              moment()
                                .subtract(1, "month")
                                .endOf("month")
                                .toDate(),
                            ],
                          },
                        }}
                      >
                        <input
                          type="text"
                          style={{ display: "inline-block" }}
                          className="form-control"
                          defaultValue=""
                          value={this.state.date}
                          placeholder="Select Date Range"
                        />
                      </DateRangePicker>
                    </Col>
                  </FormGroup>
                </div>
                <div className="mt-3 col-md-6 col-lg-3 col-xl-4">
                  <FormGroup row>
                    <Col>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        value={this.state.selectedEmirate}
                        onChange={this.handleEmirateChange}
                      >
                        <option selected disabled value="">
                          Select Driver
                        </option>
                        {this.state.drivers &&
                          this.state.drivers.length > 0 &&
                          this.state.drivers.map((emirate, index) => {
                            return (
                              <option key={index} value={emirate.value}>
                                {emirate.label}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                  </FormGroup>
                </div>
                <div className="mt-3 col-xs-6 col-md-6 col-lg-3 col-xl-2">
                  <FormGroup>
                    <Button color="primary" block onClick={this.clearFilter}>
                      CLEAR FILTER
                    </Button>
                  </FormGroup>
                </div>
                {this.state.adminStatus && (
                  <div className="mt-3 col-xs-6 col-md-6 col-lg-3 col-xl-2">
                    <FormGroup>
                      <Button
                        block
                        color={this.state.spStatus == 1 ? "danger" : "success"}
                        onClick={() => {
                          this.enableDisableSP();
                        }}
                      >
                        {this.state.spStatus == 1 ? "DISABLE SP" : "ENABLE SP"}
                      </Button>
                    </FormGroup>
                  </div>
                )}

                {/* <div className=' mt-3 col-xs-6 col-md-6 col-lg-3 col-xl-2'>
                  <FormGroup row>
                    <Col>
                      <ButtonDropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggle}
                        style={{ display: "inline" }}>
                        <DropdownToggle caret block color='primary'>
                          <i className={"fas fa-fat-add"} />
                          ADD NEW
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem disabled>
                            Select Add Options
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateAdddriver}>
                            NEW DRIVER
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateAddVehicles}>
                            NEW VEHICLE
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateSpCenter}>
                            NEW SERVICE CENTER
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateAddServices}>
                            NEW SERVICE
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateAddAddOn}>
                            NEW ADD-ON SERVICE
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>{" "}
                    </Col>
                  </FormGroup>
                </div> */}
              </Row>

              <Row className="mt-2">
                <Col xs={12} lg={6}>
                  <Card>
                    <CardBody>
                      <h2>Orders</h2>
                      <div id="chart">
                        <Line
                          data={{
                            labels: this.state.labels,
                            datasets: [
                              {
                                label: "Orders",
                                data: this.state.orderPerMonth,
                                backgroundColor: "rgba(255, 99, 132, 0.2)",
                                borderColor: "rgba(255, 99, 132, 1)",
                                borderWidth: 1,
                              },
                            ],
                          }}
                          width={100}
                          height={200}
                          options={{
                            maintainAspectRatio: false,
                            scales: {
                              yAxes: [
                                {
                                  ticks: {
                                    beginAtZero: true,
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} lg={6}>
                  <Card>
                    <CardBody>
                      <h2>Earnings</h2>
                      <div id="chart">
                        <Line
                          data={{
                            labels: this.state.labels,
                            datasets: [
                              {
                                label: "Earnings",
                                data: this.state.earningsPerMonth,
                                backgroundColor: "rgba(54, 162, 235, 0.2)",
                                borderColor: "rgba(54, 162, 235, 1)",
                                borderWidth: 1,
                              },
                            ],
                          }}
                          width={100}
                          height={200}
                          options={{
                            maintainAspectRatio: false,
                            scales: {
                              yAxes: [
                                {
                                  ticks: {
                                    beginAtZero: true,
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mt-5">
                  <Card className="shadow" style={{ minHeight: "450px" }}>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h2 className="mb-0">Driver status</h2>
                        </div>
                        {this.state.adminStatus && (
                          <div className="col text-right">
                            <Button
                              color="success"
                              href="#pablo"
                              onClick={(e) =>
                                this.allDriverDisableEnable(e, "enable")
                              }
                              size="sm"
                            >
                              All Driver On
                            </Button>
                            <Button
                              color="danger"
                              href="#pablo"
                              onClick={(e) =>
                                this.allDriverDisableEnable(e, "disable")
                              }
                              size="sm"
                            >
                              All Driver Off
                            </Button>
                          </div>
                        )}
                      </Row>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Driver name</th>
                          <th scope="col">Contact Number</th>
                          <th scope="col">Status</th>
                          {this.state.adminStatus && (
                            <th scope="col">Action</th>
                          )}
                          <th>Last Available</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.allDriversDetails &&
                          this.state.allDriversDetails.length > 0 &&
                          this.state.allDriversDetails.map((driver) => {
                            return (
                              <>
                                <tr>
                                  <th scope="row">{driver.driverName}</th>
                                  <td>{driver.number}</td>
                                  <td>
                                    {driver.driver.status === 1 && (
                                      <Badge
                                        color=""
                                        className="badge-dot mr-4"
                                      >
                                        <i className="bg-success" />
                                        Online
                                      </Badge>
                                    )}
                                    {driver.driver.status === 0 && (
                                      <Badge
                                        color=""
                                        className="badge-dot mr-4"
                                      >
                                        <i className="bg-warning" />
                                        Offline
                                      </Badge>
                                    )}
                                    {driver.driver.status === -1 && (
                                      <Badge
                                        color=""
                                        className="badge-dot mr-4"
                                      >
                                        <i className="bg-warning" />
                                        Busy
                                      </Badge>
                                    )}
                                    {driver.driver.status === -2 && (
                                      <Badge
                                        color=""
                                        className="badge-dot mr-4"
                                      >
                                        <i className="bg-warning" />
                                        Closed
                                      </Badge>
                                    )}
                                  </td>
                                  {this.state.adminStatus && (
                                    <td>
                                      <Switch
                                        onChange={() =>
                                          this.enableDisableDriver(
                                            driver.id,
                                            driver.driver,
                                            driver.driver.status === -2
                                              ? "enable"
                                              : "disable"
                                          )
                                        }
                                        checked={driver.status}
                                      />
                                    </td>
                                  )}
                                  <td>{driver.lastSeen}</td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </Container>
      </>
    );
  }
}

export default Index;
