const GenerateUsername = (email) => {
  email = email.replace("@", "_");
  email = email.replace(".", "_");
  email = email.replace(".", "_");
  email = email.replace(".", "_");
  email = email.replace(".", "_");
  email = email.replace(".", "_");
  email = email.replace(".", "_");
  email = email.replace(".", "_");
  email = email.replace(".", "_");
  email = email.replace(".", "_");
  email = email.replace(".", "_");
  email = email.replace(".", "_");
  return email;
};
export default GenerateUsername;
