import Header from 'components/Headers/Header';
import PageLoader from 'components/PageLoader/PageLoader';
import React, { Component } from 'react';
import firebaseConfig from '../../firebaseConfig';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import firebase from '../../firebaseConfig';
import { Redirect } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import moment from 'moment';
import { InfoWindow, withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import {
    FormGroup,
    Label,
    Button,
    Form,
    Input,
    Col,
    CardBody,
    Container,
    Card,
    CardImg,
    CardFooter,
    CardHeader,
    CardTitle,
    Row,
    Badge,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Table,
    FormFeedback,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Toast,
    ToastHeader,
    ToastBody,
} from 'reactstrap';
import RealTime from 'services/RealTime';
import SnackBar from '../../components/Snackbar/SnackBar';
var vehicleTypes = [];
var veh_type_service = [];
const axios = require('axios').default;

const MyMapComponent = compose(
    withProps({
        googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&v=3.exp&libraries=geometry,drawing,places',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) => (
    <GoogleMap
        defaultZoom={props.zoomLevel}
        zoom={props.zoomLevel}
        defaultCenter={props.latLong}
        onClick={props.onMapClick}
        center={props.latLong}
    >
        {props.isMarkerShown && <Marker position={props.latLong} onClick={props.onMarkerClick} />}
    </GoogleMap>
));
class CreateOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isloaded: true,
            redirect: false,
            emitate: '',
            area: '',
            modal: false,
            locationDetails: '',
            placeData: [],
            mapPreview: '',
            latLngTest: null,
            vehicle_data: null,
            results: '',
            location_ok: false,
            vehicleTypes: [],
            isMarkerShown: false,
            zoomLevel: 7,
            latLong: { lat: 25.547944, lng: 55.54828 },
            address_type: null,
            customer_gmail_id: '',
            customer_id: '',
            customer_latitude: null,
            customer_longitude: null,
            customer_mobile_number: null,
            customer_name: '',
            customer_name_english: '',
            customer_raw_address: '',
            driver_id: '',
            driver_name: '',
            driver_name_english: '',
            number_of_addons: 0,
            number_of_services: 0,
            order_id_number: '',
            orderReference: '',
            payment_mode: 0,
            rating: 0,
            review: '',
            serviceCharge: 0,
            services: {},
            sp_id: '',
            sp_name: '',
            sp_name_english: '',
            time_order_placed: null,
            total_addons_price: 0,
            total_discount: 0,
            total_due: 0,
            total_number_of_vehicles: 0,
            total_price_of_order: 0,
            total_services_price: 0,
            total_time: 0,
            vehicle_id: '',
            vehicle_number: '',
            selectedService: {},
            selectedAddOnService: {},
            vehicle_details_fetch: false,
            vehicle_selected: false,
            showSnackbar: false,
            createOrderSuccess: false,
            reOrder: false,
            isValidate: false,
            serviceDetails: null,
        };
    }
    async componentDidMount() {
        vehicleTypes = [];
        var veh_services = [];
        await this.fetchDrivers();

        if (this.props.location.state?.orderId) {
            const state = this.props.location.state;
            console.log('state', state);

            console.log('this.props.location.state.orderDetails', state.orderDetails);
            var customerAddress = null;
            var customerLocation = null;
            var res = state.orderDetails.customer_raw_address.split('Address from Location:');
            var res2 = state.orderDetails.customer_raw_address.split('Address from Location :');
            Object.entries(state.orderDetails.services).forEach(([key, vehicle]) => {
                var servicesName = key.split('&');
                var services = [];
                var add_on_services = [];
                Object.entries(vehicle.services).forEach(([key, service]) => {
                    service['name'] = key;
                    services.push(service);
                });
                if (vehicle.add_on_services)
                    Object.entries(vehicle.add_on_services).forEach(([key, add_on_service]) => {
                        add_on_service['name'] = key;
                        add_on_services.push(add_on_service);
                    });
                console.log('services', servicesName);
                veh_services.push({
                    vehicle_number: servicesName[1],
                    vehicle_type: servicesName[0],
                    services: services,
                    add_on_services: add_on_services,
                });
                // state.orderDetails.service["vehicle_number"] = services[1];
                // state.orderDetails.service["vehicle_type"] = services[0];
            });

            if (res[1]) {
                customerAddress = res[0];
                customerLocation = res[1];
            } else {
                customerAddress = res2[0];
                customerLocation = res2[1];
            }
            this.setState({
                vehicle_details_fetch: true,
            });
            await this.fetchAddress(state.customer_latitude, state.customer_longitude);
            console.log('state.orderDetails?.customer_mobile_number: ', state.orderDetails?.customer_mobile_number);
            this.setState(
                {
                    vehicleTypes: state.vehicleTypes,
                    vehicleTypesDefault: state.vehicleTypes,
                    reOrder: true,
                    customer_name: state.orderDetails?.customer_name,
                    customer_mobile_number: state.orderDetails.customer_mobile_number
                        ? `0${String(state.orderDetails?.customer_mobile_number).slice(-9)}`
                        : '',
                    customer_gmail_id: state.orderDetails?.customer_gmail_id,
                    customer_id: state.orderDetails?.customer_id,
                    customerAddress: customerAddress,
                    customerLocation: customerLocation,
                    nickName: state.orderDetails?.delivery_address?.addressNickName,
                    address_type: state.orderDetails?.address_type,
                    buildingNumber: state.orderDetails?.delivery_address?.buildingName,
                    floor: state.orderDetails?.delivery_address?.floor,
                    house: state.orderDetails?.delivery_address?.house,
                    additionalNote: state.orderDetails?.delivery_address?.notes,
                    office: state.orderDetails?.delivery_address?.office,
                    street: state.orderDetails?.delivery_address?.streetName,
                    serviceDetails: veh_services,
                },
                () => {
                    console.log('this.state', this.state);
                    console.log('veh_services', veh_services);
                    this.getVehicleDetails();
                }
            );
        } else {
            this.fetchVehicleType();
        }
    }
    fetchDrivers = async () => {
        var drivers = await RealTime.getDetails('drivers/');
        this.setState({ drivers: drivers });
    };
    fetchVehicleType = async () => {
        this.setState({
            isLoaded: false,
        });
        var vehicleTypeFromDB = await RealTime.getDetails('twaddan_admin/vehicle_type');
        vehicleTypes = [];
        Object.entries(vehicleTypeFromDB).forEach(([key, vehicleType]) => {
            vehicleTypes.push({
                id: key,
                name: vehicleType.name,
                count: 0,
                index: vehicleType.index,
            });
        });
        vehicleTypes.sort((a, b) => {
            return a.index - b.index;
        });
        this.setState({
            isLoaded: true,
            vehicleTypes: vehicleTypes,
            vehicleTypesDefault: vehicleTypes,
        });
    };
    fetchAddress = async (lat, lng) => {
        this.setState({
            isLoaded: false,
        });
        var address = null;
        const url =
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            lat +
            ',' +
            lng +
            '&key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0';
        const reply = await axios.get(url);
        if (reply.data.results.length === 0) {
            this.setState({
                isLoaded: true,
                showSnackbar: true,
                snackbarMessage: 'Select a valid place',
                snackBarColor: '#e3331b',
            });
        } else {
            address = 'Address from Location :' + reply.data.results[0].formatted_address;
        }
        this.setState({
            isLoaded: true,
            customer_raw_address: address,
            latLong: { lat: lat, lng: lng },
        });
        return address;
    };
    increaseCount = (event, key) => {
        vehicleTypes[key].count += 1;
        this.setState({
            vehicleTypes: vehicleTypes,
        });
    };
    decreaseCount = (event, key) => {
        if (vehicleTypes[key].count > 0) vehicleTypes[key].count -= 1;
        this.setState({
            vehicleTypes: vehicleTypes,
        });
    };
    handleChangePlace = (area) => {
        this.setState({
            area,
        });
    };
    handleSelectPlace = async (area) => {
        this.setState({
            isLoaded: false,
        });
        this.setState({
            area: area,
            mapPreview: area,
        });
        await geocodeByAddress(area)
            .then(async (results) => await getLatLng(results[0]))
            .then((latLng) => {
                this.setState(
                    {
                        latLng: latLng,
                        latLong: { lat: latLng.lat, lng: latLng.lng },
                        zoomLevel: 14,
                        isMarkerShown: true,
                    },
                    () => {
                        this.fetchAddress(this.state.latLng.lat, this.state.latLng.lng);
                    }
                );
            });
    };
    handleMapClick = async (e) => {
        this.fetchAddress(e.latLng.lat(), e.latLng.lng());
        this.setState({
            zoomLevel: 14,
            isMarkerShown: true,
        });
    };
    getVehicleDetails = async () => {
        this.setState({
            isLoaded: false,
        });
        var errors = false;
        var veh_types = [];
        var veh_typesDetail = [];
        var total_number_of_vehicles = 0;
        this.state.vehicleTypes.map((vehicleType, key) => {
            if (vehicleType.count > 0) {
                total_number_of_vehicles += vehicleType.count;
                veh_types.push(vehicleType.id);
                veh_typesDetail.push(vehicleType);
            }
        });
        const url = `https://us-central1-${firebaseConfig.options.projectId}.cloudfunctions.net/getVehiclesV2`;
        if (veh_types.length > 0) {
            if (this.state.customer_raw_address !== '') {
                console.log(this.state);
                try {
                    var reply = await axios.post(url, {
                        lat: this.state.latLong.lat,
                        lng: this.state.latLong.lng,
                        selectedVehicleTypes: veh_types,
                        spId: localStorage.getItem('sp_id'),
                    });
                } catch (error) {
                    // console.log(error.response); // this is the main part. Use the response property from the error object
                    errors = true;
                }
                if (errors) {
                    this.getVehicleDetails();
                } else {
                    var no_veh = false;
                    console.log('reply.data.response: ', reply.data.data.response);
                    reply.data.data.response.map((sp, key) => {
                        console.log('key: ', key);
                        if (sp.vehicles.length > 0)
                            for (let index = 0; index < sp.vehicles.length; index++) {
                                if (
                                    sp.vehicles[index].status &&
                                    (!sp.vehicles[index].busy_status || sp.vehicles[index].shift_available) &&
                                    sp.vehicles[index].driverStatus
                                ) {
                                    no_veh = true;
                                }
                            }
                    });
                    if (no_veh) {
                        this.setState({
                            ...this.state,
                            vehicle_data: reply.data.data.response,
                            customer_latitude: this.state.latLong.lat,
                            customer_longitude: this.state.latLong.lng,
                            selectedVehicleTypes: veh_types,
                            selectedVehicleTypesDetail: veh_typesDetail,
                            total_number_of_vehicles: total_number_of_vehicles,
                            isLoaded: true,
                            vehicle_details_fetch: true,
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            isLoaded: true,
                            showSnackbar: true,
                            snackbarMessage: 'Vehicles are not available for this request',
                            snackBarColor: '#e3331b',
                            vehicle_details_fetch: false,
                            vehicle_data: null,
                        });
                        setTimeout(() => {
                            this.hideSnackbar();
                        }, 4000);
                    }
                }
            } else {
                this.setState({
                    isLoaded: true,
                    showSnackbar: true,
                    snackbarMessage: 'Please select your location',
                    snackBarColor: '#e3331b',
                });
                setTimeout(() => {
                    this.hideSnackbar();
                }, 4000);
            }
        } else {
            this.setState({
                isLoaded: true,
                showSnackbar: true,
                snackbarMessage: 'Please select vehicle type',
                snackBarColor: '#e3331b',
            });
            setTimeout(() => {
                this.hideSnackbar();
            }, 4000);
        }
    };
    hideSnackbar = () => {
        this.setState({ showSnackbar: false });
    };
    secondsToHms = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
        var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
        var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
        return hDisplay + mDisplay + sDisplay;
    };
    getCurrentTime = async () => {
        var errors = false;
        const url = `https://us-central1-${firebase.options.projectId}.cloudfunctions.net/getCurrentTime`;
        const reply = await axios.post(url).catch((error) => {
            console.log('error: ', error);
            errors = true;
        });
        if (errors) {
            this.getCurrentTime();
        }
        console.log('reply: ', reply);
        if (reply) return reply.data;
    };
    generateMsg = async (timeStamp) => {
        console.log('timeStamp: ', timeStamp);
        var currentTime = await this.getCurrentTime();
        console.log('currentTime: ', currentTime);
        var currentTimeDate = new Date(currentTime);
        var etaTimestampDate = new Date(timeStamp);
        var currentDate = currentTimeDate.getDate();
        var etaDate = etaTimestampDate.getDate();
        var time = moment(timeStamp).tz('Asia/Dubai');
        var timestring = time.format('DD MMM, hh:mm a');
        var msg = '';
        if (currentDate === etaDate) {
            msg = 'The estimated time of arrival will be at Today ' + timestring;
        } else if (etaDate - currentDate === 1) {
            msg = 'The estimated time of arrival will be at Tomorrow ' + timestring;
        } else if (etaDate - currentDate === 2) {
            msg = 'The estimated time of arrival will be at Day After Tomorrow ' + timestring;
        } else if (etaDate - currentDate > 2) {
            msg = 'The estimated time of arrival will be at ' + timestring;
        }
        console.log('msg: ', msg);
        return msg;
    };

    selectVehicle = async (vehicle, sp) => {
        console.log('vehicle', vehicle);
        var driver = await RealTime.getDetails('drivers/' + vehicle.driver_id);
        var vehicle_services = [];
        veh_type_service = [];
        var i = 0;
        var vdtls = this.state.selectedVehicleTypesDetail;
        for (let index = 0; index < vdtls.length; index++) {
            for (let j = 0; j < vdtls[index].count; j++) {
                console.log('vdtls[index]', vdtls[index]);
                var add_on_services = [];
                if (vehicle.vehicleType[vdtls[index].id].add_on_services)
                    for (const [key, service1] of Object.entries(
                        vehicle.vehicleType[vdtls[index].id].add_on_services
                    )) {
                        service1['id'] = key;
                        service1['i'] = i;
                        service1['vehicle_type_id'] = vdtls[index].id;
                        add_on_services.push(service1);
                    }
                var major_services = [];
                if (vehicle.vehicleType[vdtls[index].id].major_services)
                    for (const [key, service2] of Object.entries(vehicle.vehicleType[vdtls[index].id].major_services)) {
                        service2['id'] = key;
                        service2['i'] = i;
                        service2['vehicle_type_id'] = vdtls[index].id;
                        major_services.push(service2);
                    }
                vehicle_services.push({
                    veh_type_name: vdtls[index].name,
                    i: i,
                    veh_type_id: vdtls[index].id,
                    add_on_services: add_on_services,
                    major_services: major_services,
                });
                veh_type_service.push({
                    veh_type_name: vdtls[index].name,
                    i: i,
                    veh_type_id: vdtls[index].id,
                    veh_number: null,
                    add_on_services: [],
                    major_services: [],
                });
                i = i + 1;
            }
        }
        console.log('vehicle_services', vehicle_services);

        this.setState({
            vehicle_selected: true,
            selected_vehicle: vehicle,
            vehicle_id: vehicle.id,
            vehicle_number: vehicle.number,
            driver_id: vehicle.driver_id,
            driver_name: driver.personal_information.name,
            driver_name_english: driver.personal_information.name,
            sp_id: sp.id,
            sp_name: sp.name,
            total_due: this.state.total_price_of_order + vehicle.serviceCharge,
            serviceCharge: vehicle.serviceCharge,
            sp_name_english: sp.name,
            vehicle_services: vehicle_services,
        });
    };
    onSelectService = (service, i) => {
        if (!veh_type_service[i].major_services.includes(service)) {
            veh_type_service[i].major_services.push(service);
            this.setState({
                total_price_of_order: this.state.total_price_of_order + parseInt(service.price),
                total_due: this.state.total_due + parseInt(service.price),
                total_services_price: this.state.total_services_price + parseInt(service.price),
                total_time: this.state.total_time + parseInt(service.time_required),
                number_of_services: this.state.number_of_services + 1,
            });
        } else {
            veh_type_service[i].major_services = veh_type_service[i].major_services.filter((x) => x !== service);
            this.setState({
                total_price_of_order: this.state.total_price_of_order - parseInt(service.price),
                total_due: this.state.total_due - parseInt(service.price),
                total_services_price: this.state.total_services_price - parseInt(service.price),
                total_time: this.state.total_time - parseInt(service.time_required),
                number_of_services: this.state.number_of_services - 1,
            });
        }
    };
    onSelectAddOnService = (service, i) => {
        if (!veh_type_service[i].add_on_services.includes(service)) {
            veh_type_service[i].add_on_services.push(service);
            this.setState({
                total_price_of_order: this.state.total_price_of_order + parseInt(service.price),
                total_due: this.state.total_due + parseInt(service.price),
                total_addons_price: this.state.total_addons_price + parseInt(service.price),
                total_time: this.state.total_time + parseInt(service.time_required),
                number_of_addons: this.state.number_of_addons + 1,
            });
        } else {
            veh_type_service[i].add_on_services = veh_type_service[i].add_on_services.filter((x) => x !== service);
            this.setState({
                total_price_of_order: this.state.total_price_of_order - parseInt(service.price),
                total_due: this.state.total_due - parseInt(service.price),
                total_addons_price: this.state.total_addons_price + parseInt(service.price),

                total_time: this.state.total_time - parseInt(service.time_required),
                number_of_addons: this.state.number_of_addons - 1,
            });
        }
    };
    vehicleNumberChange = (i, e) => {
        veh_type_service[i].veh_number = e.target.value;
    };

    validatePage = (e) => {
        e.preventDefault();
        this.setState({
            customer_mobile_number_val: null,
            customer_gmail_id_val: null,
            address_type_val: null,
            validation_error: false,
        });
        var validate = true;
        var phoneno = /^\d{10}$/;
        console.log('this.state.customer_mobile_number: ', this.state.customer_mobile_number);
        if (this.state.customer_mobile_number.length !== 10) {
            validate = false;
            this.setState({
                customer_mobile_number_val: 'Please enter a valid number',
                validation_error: true,
            });
        }
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.customer_gmail_id)
            if (!re.test(String(this.state.customer_gmail_id).toLowerCase())) {
                validate = false;
                this.setState({
                    customer_gmail_id_val: 'Please enter valid email',
                    validation_error: true,
                });
            }

        if (!this.state.address_type) {
            validate = false;
            this.setState({
                address_type_val: 'Please select address type',
                validation_error: true,
            });
        }
        let veh_number_duplicate = false;
        let major_services_status = false;
        veh_type_service.map((vehicle) => {
            if (vehicle.major_services.length === 0) major_services_status = true;
            veh_type_service.map((vehicle2) => {
                if (vehicle.i !== vehicle2.i)
                    if (vehicle.veh_number === vehicle2.veh_number) veh_number_duplicate = true;
            });
        });
        if (veh_number_duplicate) {
            validate = false;
            this.setState({
                isLoaded: true,
                showSnackbar: true,
                snackbarMessage: 'Vehicle Number should be unique',
                snackBarColor: '#e3331b',
            });
            setTimeout(() => {
                this.hideSnackbar();
            }, 4000);
            return 0;
        }
        if (major_services_status) {
            validate = false;
            this.setState({
                isLoaded: true,
                showSnackbar: true,
                isValidate: false,
                snackbarMessage: 'Please select atleast one major service for each vehicle',
                snackBarColor: '#e3331b',
            });
            setTimeout(() => {
                this.hideSnackbar();
            }, 4000);
            return 0;
        }
        if (validate) this.validateVehicle();
    };
    validateVehicle = async () => {
        this.setState({
            isLoaded: false,
        });
        const axios = require('axios').default;
        const url = `https://us-central1-${firebaseConfig.options.projectId}.cloudfunctions.net/validateVehicleV2`;
        var errors = false;
        try {
            var reply = await axios.post(url, {
                serviceTime: this.state.total_time * 60 * 1000,
                chargeRequired: false,
                etaRequired: true,
                reorder: false,
                spId: this.state.sp_id,
                vehicleId: this.state.vehicle_id,
                lat: this.state.latLong.lat,
                lng: this.state.latLong.lng,
                busyStatusRequired: true,
            });
        } catch (error) {
            console.log(error.response); // this is the main part. Use the response property from the error object
            errors = true;
        }
        if (errors) {
            this.validateVehicle();
        } else {
            console.log('reply', reply);
            var response = reply.data.data.response;
            this.setState({
                driver_busy_status: response.busy_status,
                driver_eta: response.eta,
                driver_status: response.status,
                shift_available: response.shift_available,
                order_data: response.shift_available ? response.order_data : null,
                shift_data: response.shift_available ? response.shift_data : null,
                etaTimestamp: response.etaTimestamp,
                etsTimestamp: response.etsTimestamp,
                etaMsg: response.etaMsg,
                modal: true,
                isLoaded: true,
            });
        }
    };

    chooseAnotherVehicle = () => {
        this.setState(
            {
                vehicle_data: null,
                vehicle_selected: false,
                selected_vehicle: {},
                vehicle_id: '',
                vehicle_number: '',
                driver_id: '',
                driver_name: '',
                driver_name_english: '',
                sp_id: '',
                sp_name: '',
                sp_name_english: '',
                total_price_of_order: 0,
                total_time: 0,
                total_due: 0,
                serviceCharge: 0,
                vehicle_services: [],
                modal: false,
            },
            () => {
                this.getVehicleDetails();
            }
        );
    };

    createOrder = async () => {
        var validate = false;
        var customer_id;
        var customer_gmail_id;
        this.setState({
            isLoaded: false,
        });
        if (this.state.reOrder) {
            if (this.state.customer_gmail_id) {
                customer_id = this.state.customer_gmail_id.replace('@', '_');
                customer_id = customer_id.replace('.', '_');
                customer_gmail_id = this.state.customer_gmail_id;
            } else {
                customer_id = this.state.customer_id;
                customer_gmail_id = null;
            }
        } else {
            customer_id = localStorage.getItem('sp_id');
            customer_gmail_id = localStorage.getItem('sp_gmail_id');
        }
        var d = new Date();
        var timeStamp = d.getTime();
        var order_id_number = timeStamp.toString() + ' & ' + customer_id.toString();
        let allServices = {};

        veh_type_service.map((vehicle) => {
            var serviceKey = vehicle.veh_type_id + '&' + vehicle.veh_number;
            allServices[serviceKey] = {
                services: vehicle.major_services.length > 0 ? vehicle.major_services : null,
                add_on_services: vehicle.add_on_services.length > 0 ? vehicle.add_on_services : null,
            };
        });
        var address = '';
        if (this.state.nickName) {
            address = address + this.state.nickName + ' ';
        }
        if (this.state.house) {
            address = address + this.state.house + ' ';
        }
        if (this.state.apartment) {
            address = address + this.state.apartment + ' ';
        }
        if (this.state.office) {
            address = address + this.state.office + ' ';
        }
        if (this.state.floor) {
            address = address + this.state.floor + ' ';
        }
        if (this.state.buildingNumber) {
            address = address + this.state.buildingNumber + ' ';
        }
        if (this.state.street) {
            address = address + this.state.street + ', ';
        }
        var shift = {
            eta: this.state.eta,
            etaTimestamp: this.state.etaTimestamp,
            etsTimestamp: this.state.etsTimestamp,
            shift_available: this.state.shift_available,
        };
        if (this.state.shift_available) {
            shift['order_data'] = this.state.order_data;
            shift['shift_data'] = this.state.shift_data;
        }
        var order = {
            address_type: this.state.address_type,
            coupon_code: '',
            customer_gmail_id: customer_gmail_id,
            customer_id: customer_id,
            customer_latitude: this.state.customer_latitude,
            customer_longitude: this.state.customer_longitude,
            customer_mobile_number: parseInt(this.state.customer_mobile_number),
            customer_name: this.state.customer_name,
            customer_name_english: this.state.customer_name_english,
            customer_raw_address: address + this.state.customer_raw_address,
            delivery_address: {
                address: address + this.state.customer_raw_address,
                addressNickName: this.state.nickName,
                addressType: this.state.address_type,
                buildingName: this.state.buildingNumber,
                floor: this.state.floor,
                house: this.state.house,
                lat: this.state.customer_latitude,
                lng: this.state.customer_longitude,
                notes: this.state.additionalNote,
                office: this.state.office,
                streetName: this.state.street,
            },
            driver_id: this.state.driver_id,
            driver_name: this.state.driver_name,
            driver_name_english: this.state.driver_name,
            number_of_addons: this.state.number_of_addons,
            number_of_services: this.state.number_of_services,
            order_id_number: order_id_number,
            order_placed_from: 'Web',
            orderReference: this.state.orderReference,
            payment_mode: this.state.payment_mode,
            rating: this.state.rating,
            review: this.state.review,
            serviceCharge: this.state.serviceCharge,
            services: allServices,
            sp_id: this.state.sp_id,
            sp_name: this.state.sp_name,
            sp_name_english: this.state.sp_name_english,
            status: 0,
            time_order_placed: timeStamp,
            time_order_received: timeStamp,
            total_addons_price: this.state.total_addons_price,
            total_discount: this.state.total_discount,
            total_due: this.state.total_due,
            total_number_of_vehicles: this.state.total_number_of_vehicles,
            total_price_of_order: this.state.total_price_of_order,
            total_services_price: this.state.total_services_price,
            total_time: this.state.total_time,
            vehicle_id: this.state.vehicle_id,
            vehicle_number: this.state.vehicle_number,
            shift: shift,
        };
        if (this.state.additionalNote) {
            order['additional_note'] = this.state.additionalNote;
        }
        if (!validate) {
            console.log('order', order);

            var responce = await this.uplaodData(order);
            if (this.state.reOrder) {
                await this.fetchVehicleType();
            } else {
                vehicleTypes.map((item) => {
                    item.count = 0;
                });
            }

            this.setState({
                isLoaded: true,
                modal: false,
                reOrder: false,
                showSnackbar: true,
                snackbarMessage: 'Order created successfully',
                snackBarColor: '#21c45a',
                vehicleTypes: vehicleTypes,
                isloaded: true,
                redirect: false,
                emitate: '',
                area: '',
                locationDetails: '',
                placeData: [],
                mapPreview: '',
                latLngTest: null,
                vehicle_data: null,
                results: '',
                location_ok: false,
                isMarkerShown: false,
                zoomLevel: 7,
                latLong: { lat: 25.547944, lng: 55.54828 },
                address_type: null,
                customer_gmail_id: '',
                customer_id: '',
                customer_latitude: null,
                customer_longitude: null,
                customer_mobile_number: null,
                customer_name: '',
                customer_name_english: '',
                customer_raw_address: '',
                driver_id: '',
                driver_name: '',
                driver_name_english: '',
                number_of_addons: 0,
                number_of_services: 0,
                order_id_number: '',
                orderReference: '',
                payment_mode: 0,
                rating: 0,
                review: '',
                serviceCharge: 0,
                services: {},
                sp_id: '',
                sp_name: '',
                sp_name_english: '',
                time_order_placed: null,
                total_addons_price: 0,
                total_discount: 0,
                total_due: 0,
                total_number_of_vehicles: 0,
                total_price_of_order: 0,
                total_services_price: 0,
                total_time: 0,
                vehicle_id: '',
                vehicle_number: '',
                selectedService: {},
                selectedAddOnService: {},
                vehicle_details_fetch: false,
                vehicle_selected: false,
                nickName: '',
                house: '',
                apartment: '',
                office: '',
                floor: '',
                buildingNumber: '',
                street: '',
                additionalNote: '',
            });
            setTimeout(() => {
                this.hideSnackbar();
            }, 4000);
        }
    };
    uplaodData = async (order) => {
        console.log('order', order);

        var errors = false;
        const url = `https://us-central1-${firebaseConfig.options.projectId}.cloudfunctions.net/createOrderV2`;
        try {
            await axios.post(url, order);
        } catch (error) {
            console.log(error.response); // this is the main part. Use the response property from the error object
            errors = true;
        }
        if (errors) {
            this.uplaodData(order);
        } else {
            return true;
        }
    };

    render() {
        return (
            <>
                <Header />
                <PageLoader loading={!this.state.isLoaded}></PageLoader>
                <Container className=" mt--10">
                    <Row className="mt-5">
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <h1 className="mb-0 ">Create Order</h1>
                        </Col>
                    </Row>
                    {!this.state.vehicle_details_fetch && (
                        <>
                            <Row className="mt-5">
                                <Col sm={12} md={12} lg={5} xl={5}>
                                    <Row className="mt-5">
                                        {this.state.vehicleTypes.length > 1 &&
                                            this.state.vehicleTypes.map((vehicleType, key) => {
                                                return (
                                                    <>
                                                        <Col className="mt-1 " xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <h4 className="mb-0 ">{vehicleType.name}</h4>
                                                        </Col>
                                                        <Col className="mt-1 " xs={2} sm={2} md={2} lg={2} xl={2}>
                                                            <Button
                                                                color="danger"
                                                                onClick={(e) => this.decreaseCount(e, key)}
                                                            >
                                                                -
                                                            </Button>
                                                        </Col>
                                                        <Col className="mt-1 " xs={2} sm={2} md={2} lg={2} xl={2}>
                                                            <Button
                                                                color="success"
                                                                onClick={(e) => this.increaseCount(e, key)}
                                                            >
                                                                +
                                                            </Button>
                                                        </Col>

                                                        <Col className="mt-1 " xs={2} sm={2} md={2} lg={2} xl={2}>
                                                            <Button>{vehicleType.count}</Button>
                                                        </Col>
                                                    </>
                                                );
                                            })}
                                    </Row>
                                </Col>
                                <Col sm={12} md={12} lg={7} xl={7}>
                                    <Row>
                                        <Col md={12}>
                                            <PlacesAutocomplete
                                                value={this.state.area}
                                                onChange={this.handleChangePlace}
                                                searchOptions={{
                                                    componentRestrictions: { country: ['ae'] },
                                                }}
                                                // searchOptions={searchOptions}

                                                onSelect={this.handleSelectPlace}
                                            >
                                                {({
                                                    getInputProps,
                                                    getLatLng,
                                                    suggestions,
                                                    getSuggestionItemProps,
                                                    loading,
                                                }) => (
                                                    <div>
                                                        <FormGroup>
                                                            <InputGroup className="input-group-alternative mb-3">
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="ni ni-square-pin" />
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    value={this.state.area}
                                                                    onChange={(e) =>
                                                                        this.setState({
                                                                            area: e.target.value,
                                                                        })
                                                                    }
                                                                    {...getInputProps({
                                                                        placeholder: 'Search Places ...',
                                                                        className: 'location-search-input',
                                                                    })}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>

                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map((suggestion) => {
                                                                const className = suggestion.active
                                                                    ? 'suggestion-item--active'
                                                                    : 'suggestion-item';
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? {
                                                                          backgroundColor: '#88ebfc',
                                                                          cursor: 'pointer',
                                                                      }
                                                                    : {
                                                                          backgroundColor: '#ffffff',
                                                                          cursor: 'pointer',
                                                                      };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}
                                                                    >
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <MyMapComponent
                                                isMarkerShown={this.state.isMarkerShown}
                                                latLong={this.state.latLong}
                                                zoomLevel={this.state.zoomLevel}
                                                onMarkerClick={this.handleMarkerClick}
                                                onMapClick={this.handleMapClick}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col sm={12} md={12} lg={12} xl={12}>
                                    <Button color="success" onClick={this.getVehicleDetails}>
                                        Get Details
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}

                    {!this.state.vehicle_selected && this.state.vehicle_data && (
                        <Row className="mt-3">
                            <Col sm={12} md={12} lg={12} xl={12}>
                                <h4>Select a vehicle</h4>
                            </Col>
                            {this.state.vehicle_data &&
                                this.state.vehicle_data.map((sp, key) => {
                                    if (sp.vehicles.length > 0 && !sp.isSuspended && sp.status)
                                        console.log('sp.vehicles.length: ', sp.vehicles.length);
                                    // for (let index = 0; index < sp.vehicles.length; index++) {
                                    return sp.vehicles.map((vehicle) => {
                                        // console.log("index: ", index);
                                        console.log('vehicle: ', vehicle);
                                        // console.log(
                                        //   " vehicle.status: ",
                                        //   vehicle.status
                                        // );
                                        // console.log(
                                        //   "!vehicle.busy_status: ",
                                        //   !vehicle.busy_status
                                        // );
                                        // console.log(
                                        //   "vehicle.shift_available: ",
                                        //   vehicle.shift_available
                                        // );
                                        // console.log(
                                        //   "vehicle.driverStatus: ",
                                        //   vehicle.driverStatus
                                        // );

                                        if (
                                            vehicle.status &&
                                            (!vehicle.busy_status || vehicle.shift_available) &&
                                            vehicle.driverStatus
                                        )
                                            return (
                                                <Col sm={12} md={12} lg={4} xl={3}>
                                                    <Card style={{ height: '430px' }}>
                                                        <CardImg
                                                            top
                                                            className="img-fluid"
                                                            src={vehicle.vehicleImage}
                                                            alt="card image cap"
                                                            style={{ height: '15vw' }}
                                                        />
                                                        <CardBody>
                                                            <h3 className="mb-0">{vehicle.name}</h3>
                                                            <h5 className="mb-0">
                                                                {
                                                                    this.state.drivers[vehicle.driver_id]
                                                                        .personal_information.name
                                                                }
                                                            </h5>
                                                            <p className="mb-0">{vehicle.etaMsg}</p>
                                                            {vehicle.serviceCharge > 0 && (
                                                                <p className="mb-0" style={{ color: 'red' }}>
                                                                    Service Charge:
                                                                    {vehicle.serviceCharge}
                                                                </p>
                                                            )}
                                                        </CardBody>
                                                        <CardFooter style={{ height: '56px' }}>
                                                            <Button
                                                                color="danger"
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '6px',
                                                                    right: '10px',
                                                                }}
                                                                onClick={() => this.selectVehicle(vehicle, sp)}
                                                            >
                                                                Order
                                                            </Button>
                                                        </CardFooter>
                                                    </Card>
                                                </Col>
                                            );
                                    });

                                    // }
                                })}
                        </Row>
                    )}

                    {this.state.vehicle_selected && (
                        <Form onSubmit={this.validatePage}>
                            <Row className="mt-3">
                                <Col md={4} className="mb-3">
                                    <h2>Billing amount : {this.state.total_due}</h2>
                                </Col>

                                <Col md={4} className="mb-3">
                                    <h2>Total Time : {this.state.total_time}</h2>
                                </Col>
                                <Col md={4} className="mb-3">
                                    <h2>Service Charge : {this.state.serviceCharge}</h2>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="Name">Name</Label>
                                        <Input
                                            type="text"
                                            id="Name"
                                            required
                                            name="Name"
                                            value={this.state.customer_name}
                                            onChange={(e) => {
                                                this.setState({
                                                    customer_name: e.target.value,
                                                    customer_name_english: e.target.value,
                                                });
                                            }}
                                            placeholder="First Name"
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="mobileNumber">Mobile Number</Label>
                                        <Input
                                            type="text"
                                            name="mobileNumber"
                                            required
                                            id="mobileNumber"
                                            placeholder="888888888"
                                            value={this.state.customer_mobile_number}
                                            onChange={(e) => {
                                                this.setState({
                                                    customer_mobile_number: e.target.value,
                                                });
                                            }}
                                        />
                                        {this.state.validation_error && this.state.customer_mobile_number_val && (
                                            <p style={{ color: 'red' }}>{this.state.customer_mobile_number_val}</p>
                                        )}
                                    </FormGroup>
                                </Col>
                                {this.state.customer_gmail_id && this.state.reOrder && (
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="Email">Email</Label>
                                            <Input
                                                type="email"
                                                id="email"
                                                name="email"
                                                disabled
                                                required
                                                value={this.state.customer_gmail_id}
                                                onChange={(e) => {
                                                    this.setState({ customer_gmail_id: e.target.value });
                                                }}
                                                placeholder="Email"
                                            />
                                            {this.state.validation_error && this.state.customer_gmail_id_val && (
                                                <p style={{ color: 'red' }}>{this.state.customer_gmail_id_val}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                )}
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleSelect">Select Address Type</Label>
                                        <Input
                                            type="select"
                                            onChange={(e) => {
                                                this.setState({ address_type: e.target.value });
                                            }}
                                            value={this.state.address_type}
                                            name="select"
                                            id="exampleSelect"
                                        >
                                            <option>Select</option>
                                            <option value="House">House</option>
                                            <option value="Apartment">Apartment</option>
                                            <option value="Office">Office</option>
                                        </Input>
                                        {this.state.validation_error && this.state.address_type_val && (
                                            <p style={{ color: 'red' }}>{this.state.address_type_val}</p>
                                        )}
                                    </FormGroup>
                                </Col>
                                {this.state.address_type && (
                                    <>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="nickName">Nick Name</Label>
                                                <Input
                                                    type="text"
                                                    name="nickName"
                                                    id="nickName"
                                                    placeholder="Enter the nickName"
                                                    value={this.state.nickName}
                                                    onChange={(e) => {
                                                        this.setState({ nickName: e.target.value });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        {this.state.address_type === 'House' && (
                                            <>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="house">House Number</Label>
                                                        <Input
                                                            type="text"
                                                            name="house"
                                                            id="house"
                                                            required
                                                            placeholder="Enter the house number"
                                                            value={this.state.house}
                                                            onChange={(e) => {
                                                                this.setState({ house: e.target.value });
                                                            }}
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        )}
                                        {this.state.address_type === 'Apartment' && (
                                            <>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="apartment">Apartment Number</Label>
                                                        <Input
                                                            type="text"
                                                            name="apartment"
                                                            id="apartment"
                                                            required
                                                            placeholder="Enter the apartment number"
                                                            value={this.state.house}
                                                            onChange={(e) => {
                                                                this.setState({ house: e.target.value });
                                                            }}
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        )}
                                        {this.state.address_type === 'Office' && (
                                            <>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="office">Office Number</Label>
                                                        <Input
                                                            type="text"
                                                            name="office"
                                                            id="office"
                                                            required
                                                            placeholder="Enter the office number"
                                                            value={this.state.office}
                                                            onChange={(e) => {
                                                                this.setState({ office: e.target.value });
                                                            }}
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="floor">Floor</Label>
                                                        <Input
                                                            type="text"
                                                            name="floor"
                                                            id="floor"
                                                            required
                                                            placeholder="Enter the floor"
                                                            value={this.state.floor}
                                                            onChange={(e) => {
                                                                this.setState({ floor: e.target.value });
                                                            }}
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        )}
                                        {(this.state.address_type === 'Apartment' ||
                                            this.state.address_type === 'Office') && (
                                            <>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="buildingNumber">Building Number</Label>
                                                        <Input
                                                            type="text"
                                                            name="buildingNumber"
                                                            id="buildingNumber"
                                                            required
                                                            placeholder="Enter the building number"
                                                            value={this.state.buildingNumber}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    buildingNumber: e.target.value,
                                                                });
                                                            }}
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        )}
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="street">street</Label>
                                                <Input
                                                    type="text"
                                                    name="street"
                                                    id="street"
                                                    required
                                                    placeholder="Enter the street"
                                                    value={this.state.street}
                                                    onChange={(e) => {
                                                        this.setState({ street: e.target.value });
                                                    }}
                                                ></Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="additionalNote">Additional Note</Label>
                                                <Input
                                                    type="text"
                                                    name="additionalNote"
                                                    id="additionalNote"
                                                    placeholder="Enter the additionalNote"
                                                    value={this.state.additionalNote}
                                                    onChange={(e) => {
                                                        this.setState({ additionalNote: e.target.value });
                                                    }}
                                                ></Input>
                                            </FormGroup>
                                        </Col>
                                    </>
                                )}
                            </Row>
                            {this.state.reOrder && (
                                <>
                                    <Row>
                                        <Col xs={12}>
                                            <h3>Cancelled order services details</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {this.state.serviceDetails.length > 0 &&
                                            this.state.serviceDetails.map((vehicle) => {
                                                console.log('vehicle', vehicle);

                                                return (
                                                    <Col sm={12} md={6} lg={4} xl={3}>
                                                        <Card>
                                                            <CardHeader>
                                                                <b>Vehicle Type - </b> {vehicle.vehicle_type}
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col xs={12}>
                                                                        <Label for="vehicleNumber">
                                                                            <b>vehicle number</b>
                                                                        </Label>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <Label for="vehicleNumber">
                                                                            {vehicle.vehicle_number}
                                                                        </Label>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <Label>
                                                                            <b>Price - Service</b>
                                                                        </Label>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        {vehicle.services &&
                                                                            vehicle.services.map((service) => {
                                                                                return (
                                                                                    <FormGroup check inline>
                                                                                        <Label>
                                                                                            {service.price} -{' '}
                                                                                            {service.name}
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                );
                                                                            })}
                                                                    </Col>
                                                                    {vehicle.add_on_services.length > 0 && (
                                                                        <>
                                                                            <Col xs={12} style={{ marginTop: '20px' }}>
                                                                                <Label>
                                                                                    <b>Price - Add On Service </b>
                                                                                </Label>
                                                                            </Col>
                                                                            <Col xs={12}>
                                                                                {vehicle.add_on_services &&
                                                                                    vehicle.add_on_services.map(
                                                                                        (service) => {
                                                                                            return (
                                                                                                <FormGroup check inline>
                                                                                                    <Label>
                                                                                                        {service.price}{' '}
                                                                                                        - {service.name}
                                                                                                    </Label>
                                                                                                </FormGroup>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                            </Col>
                                                                        </>
                                                                    )}
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                );
                                            })}
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="mt-3">
                                            <h3> Current order services details</h3>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Row>
                                {this.state.vehicle_services.map((vehicle) => {
                                    return (
                                        <Col sm={12} md={6} lg={4} xl={3}>
                                            <Card>
                                                <CardHeader>
                                                    <b> {vehicle.veh_type_name}</b>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <Label for="vehicleNumber">
                                                                <b>Enter the vehicle number</b>
                                                            </Label>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <FormGroup>
                                                                <Input
                                                                    type="text"
                                                                    name="vehicleNumber"
                                                                    id={vehicle.i}
                                                                    required
                                                                    placeholder="Vehicle Number"
                                                                    onChange={(e) =>
                                                                        this.vehicleNumberChange(vehicle.i, e)
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <Label>
                                                                <b>Select Service</b>
                                                            </Label>
                                                        </Col>
                                                        <Col xs={12}>
                                                            {vehicle.major_services.map((service) => {
                                                                return (
                                                                    <FormGroup check>
                                                                        <Input
                                                                            id={vehicle.i + service.id}
                                                                            onClick={() =>
                                                                                this.onSelectService(service, vehicle.i)
                                                                            }
                                                                            type="checkbox"
                                                                        />
                                                                        <Label for={vehicle.i + service.id} check>
                                                                            {service.price} - {service.name}
                                                                        </Label>
                                                                    </FormGroup>
                                                                );
                                                            })}
                                                        </Col>

                                                        {vehicle.add_on_services.length > 0 && (
                                                            <>
                                                                <Col xs={12} style={{ marginTop: '20px' }}>
                                                                    <Label>
                                                                        <b>Select Add On Service</b>
                                                                    </Label>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    {vehicle.add_on_services.map((service) => {
                                                                        return (
                                                                            <FormGroup check>
                                                                                <Input
                                                                                    id={vehicle.i + service.id}
                                                                                    onClick={() =>
                                                                                        this.onSelectAddOnService(
                                                                                            service,
                                                                                            vehicle.i
                                                                                        )
                                                                                    }
                                                                                    type="checkbox"
                                                                                />
                                                                                <Label
                                                                                    for={vehicle.i + service.id}
                                                                                    check
                                                                                >
                                                                                    {service.price} - {service.name}
                                                                                </Label>
                                                                            </FormGroup>
                                                                        );
                                                                    })}
                                                                </Col>{' '}
                                                            </>
                                                        )}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    );
                                })}
                            </Row>
                            <Row>
                                <Col xs={12} className="mt-3">
                                    <Button color="success" type="submit">
                                        BOOK
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                    {this.state.showSnackbar && (
                        <SnackBar
                            showSnackbar={this.state.showSnackbar}
                            snackbarMessage={this.state.snackbarMessage}
                            color={this.state.snackBarColor}
                        ></SnackBar>
                    )}
                    <Modal isOpen={this.state.modal} toggle={this.toggle}>
                        <ModalBody>
                            {this.state.driver_status &&
                                !this.state.driver_busy_status &&
                                this.state.shift_available &&
                                this.state.shift_data.msg_eng !== '' && (
                                    <>
                                        <h3>{this.state.shift_data.msg_eng}</h3>
                                    </>
                                )}
                            {this.state.driver_status &&
                                !this.state.driver_busy_status &&
                                this.state.shift_available &&
                                this.state.shift_data.msg_eng === '' && (
                                    <>
                                        <h3>{this.state.etaMsg}</h3>
                                    </>
                                )}
                            {this.state.driver_status && !this.state.driver_busy_status && !this.state.shift_available && (
                                <>
                                    <h3>
                                        The estimated time of arrival will be at Today:{' '}
                                        {moment(Number(this.state.etaTimestamp))
                                            .tz('Asia/Dubai')
                                            .format('DD MMM, hh:mm a')}
                                    </h3>
                                </>
                            )}
                            {(!this.state.driver_status || this.state.driver_busy_status) && (
                                <>
                                    <h3>DRIVER IS NOT AVAILABLE PLEASE CHOOSE ANOTHER VEHICLE</h3>
                                </>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            {this.state.driver_status && !this.state.driver_busy_status && (
                                <Button color="primary" onClick={this.createOrder}>
                                    CONTINUE
                                </Button>
                            )}
                            <Button color="danger" onClick={this.chooseAnotherVehicle}>
                                CHOSE ANOTHER VEHICLE
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Container>
            </>
        );
    }
}
export default CreateOrder;
