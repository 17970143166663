import React, { Component } from "react";

// reactstrap components
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Container,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  CardBody,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";

var spid = "";

// super admin logged in user id
var saID = "superadmin_twaddan_com";
// super admin logged in name
var saName;

// firebase.auth().onAuthStateChanged(function (user) {
//   if (user) {
//     // User is signed in.
//     console.log("The current user: ", user.email);

//     saID = user.email;
//     saID = saID.replace("@", "_");
//     saID = saID.replace(".", "_");

//     firebase
//       .database()
//       .ref("/twaddan_admin/super_admin_users/" + saID + "/")
//       .on("value", function (snapshot) {
//         saName = snapshot.val();
//         saName = saName.personal_information.name;
//       });
//   }
// });

export default class AddDiscount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spidd: "",
      sparray: [],
      booking: 0,
      cancel: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  handleSubmit(event) {
    console.log(this.state.spidd);
    this.storeCurrentSpid();
    event.preventDefault();
  }
  getData = () => {
    const dr = firebase.database().ref("service_providers/");
    let newDiscount = [];
    newDiscount.push({
      name: "select",
    });
    dr.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        var keys = Object.keys(drs);
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          newDiscount.push({
            name: drs[k].personal_information.name,
            id: k,
          });
        }
      }
      this.setState({
        sparray: newDiscount,
      });
    });
  };
  storeCurrentSpid = () => {
    this.state.sparray.map((i) => {
      if (i.name === this.state.spidd) {
        spid = i.id;
        this.setState({
          booking: 1,
        });
        console.log(spid);
      }
    });
  };
  navigate = () => {
    this.setState({
      discount: 1,
    });
  };
  render() {
    const { sparray } = this.state;

    let sparrayList =
      sparray.length > 0 &&
      sparray.map((i) => {
        return <option>{i.name}</option>;
      }, this);
    if (this.state.booking === 1) {
      return <AddDiscounts />;
    }
    if (this.state.cancel === 1) {
      return <Redirect to='/admin/discount' />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <Card>
            <CardHeader>
              <CardTitle>Add Coupon</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                <Row form>
                  <Col md={8}>
                    <FormGroup>
                      <Label for='spid'>Select Service Provider</Label>
                      <Input
                        type='select'
                        name='spid'
                        id='spid'
                        value={this.state.spidd}
                        onChange={(e) => {
                          this.setState({ spidd: e.target.value });
                        }}>
                        {sparrayList}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Button color='primary' onClick={this.navigate}>
                  CANCEL
                </Button>
                <Button color='success' value='Submit'>
                  NEXT
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

class AddDiscounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      percentage: 0,
      cartvalue: 0,
      description: "",
      description_ar: "",
      downloadurl: "",
      file: "",
      imagePreviewUrl: "",
      spemail: "",
      tnc: "",
      tnc_ar: "",
      modal: false,
      discount: 0,
      CouponId: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.updateCheckbox = this.updateCheckbox.bind(this);
  }
  componentDidMount() {
    this.getdiscount();
  }
  getdiscount = () => {
    const dr1 = firebase
      .database()
      .ref("service_providers/" + spid + "/offers/");
    dr1.once("value", (snapshot) => {
      var drs = snapshot.val();
      let newDiscount = [];
      let price = {};
      let status = {};
      if (drs != null) {
        var keys = Object.keys(drs);
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          newDiscount.push({
            id: k,
          });
          price[k] = 0;
          status[k] = false;
        }
        this.setState({
          discount: newDiscount,
          priceFordiscount: price,
          statusFordiscount: status,
        });
      }
    });
  };
  uploadimage = () => {
    var id = this.state.name.toLowerCase();
    id = id.trim();
    id = id.replace(" ", "_");
    id = id + ".png";
    console.log(id);
    var storageRef = firebase.storage().ref();
    var ImagesRef = storageRef.child(
      "/service_provider/" + spid + "/offers/" + id
    );
    ImagesRef.put(this.state.file)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
      })

      .then((downloadURL) => {
        console.log(
          `Successfully uploaded file and got download link - ${downloadURL}`
        );
        this.setState({
          downloadurl: downloadURL,
        });
        this.pushdata();
      })

      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
  };
  pushdata = () => {
    var spName;
    firebase
      .database()
      .ref("service_providers/" + spid + "/personal_information/")
      .on("value", function (snapshot) {
        spName = snapshot.val();
        spName = spName.name;
      });

    var time = new Date();
    time = time.getTime();
    var logAction =
      "Added New Service named " +
      this.state.name +
      " under " +
      spName +
      " Service Provider with SP ID " +
      spid;
    var logs = firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/logs/" + time + "/");
    logs.update({
      action: logAction,
    });

    var id = this.state.name.toLowerCase();
    id = id.trim();
    id = id.replace(" ", "_");
    this.state.discount.map((i) => {
      var ref = firebase
        .database()
        .ref("service_providers/" + spid + "/offers/");
      ref.child(id).set({
        description: this.state.description,
        description_ar: this.state.description_ar,
        image: this.state.downloadurl,
        percentage: Number(this.state.percentage),
        promo_code: this.state.name,
        terms_and_conditions: this.state.tnc,
        terms_and_conditions_ar: this.state.tnc_ar,
        min_cart_value: Number(this.state.cartvalue),
      });
    });
    this.toggle();
  };
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }
  handleSubmit(event) {
    console.log(this.state.file);
    this.uploadimage();
    event.preventDefault();
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  onChange = (id, value) => {
    var price = this.state.priceFordiscount;
    price[id] = value;
    this.setState({
      priceFordiscount: price,
    });
  };
  updateCheckbox = (id, value) => {
    var status = this.state.statusFordiscount;
    status[id] = value;
    this.setState({
      statusFordiscount: status,
    });
  };
  getArabic = async (text, value) => {
    const axios = require("axios").default;
    const url = "https://translation.googleapis.com/language/translate/v2";
    const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios.get(url, {
      params: {
        q: text,
        target: "ar",
        key: apiKey,
      },
    });
    console.log(reply.data.data.translations[0].translatedText);
    this.setState({
      [value]: reply.data.data.translations[0].translatedText,
    });
  };
  convert = () => {
    this.getArabic(this.state.name, "name_ar");
    this.getArabic(this.state.description, "description_ar");
    this.getArabic(this.state.tnc, "tnc_ar");
  };
  navigate = () => {
    this.setState({
      offers: 1,
    });
  };
  render() {
    console.log(this.state.statusFordiscount);
    if (this.state.offers == 1) {
      return <Redirect to='/admin/discount' />;
    }
    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=' mt--7' fluid>
          <Card>
            <CardHeader>
              <CardTitle>Add Promo code</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                <Row form>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='name'>Promo Code</Label>
                      <Input
                        type='name'
                        required
                        name='name'
                        id='name'
                        value={this.state.name}
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                        placeholder='Name'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='price'>Percentage</Label>
                      <Input
                        type='number'
                        required
                        name='percentage'
                        id='percentage'
                        placeholder='Percentage'
                        value={this.state.percentage}
                        onChange={(e) => {
                          this.setState({ percentage: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <h3 style={{ color: "red" }}>
                    Please try to add image of 400x400 pixels
                  </h3>
                  <Label for='File'>Add image</Label>
                  <br></br>
                  <input
                    type='file'
                    accept='image/png, image/jpeg'
                    required
                    onChange={this._handleImageChange}
                  />
                  <Row>
                    <Col md={4} xs={2}>
                      <div style={imagestyle}>{$imagePreview}</div>
                    </Col>
                  </Row>
                </FormGroup>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='description'>Description</Label>
                      <Input
                        type='text'
                        name='description'
                        required
                        id='description'
                        placeholder='Enter the decription'
                        value={this.state.description}
                        onChange={(e) => {
                          this.setState({ description: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='description_ar'>Description in Arabic</Label>
                      <Input
                        type='text'
                        name='description_ar'
                        required
                        id='description_ar'
                        placeholder='Enter the decription'
                        value={this.state.description_ar}
                        onChange={(e) => {
                          this.setState({ description_ar: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='cartvalue'>Minimun cart value</Label>
                      <Input
                        type='number'
                        required
                        name='cartvalue'
                        id='cartvalue'
                        placeholder='mincartvalue'
                        value={this.state.cartvalue}
                        onChange={(e) => {
                          this.setState({ cartvalue: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <FormGroup>
                      <Label for='tnc'>Terms and Conditions</Label>
                      <Input
                        type='text'
                        name='tnc'
                        required
                        id='tnc'
                        placeholder='Enter the Terms and Conditions'
                        value={this.state.tnc}
                        onChange={(e) => {
                          this.setState({ tnc: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <FormGroup>
                      <Label for='tnc_ar'>Terms and Conditions in Arabic</Label>
                      <Input
                        type='text'
                        name='tnc_ar'
                        required
                        id='tnc_ar'
                        placeholder='Enter the Terms and Conditions'
                        value={this.state.tnc_ar}
                        onChange={(e) => {
                          this.setState({ tnc_ar: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br></br>
                <Button color='primary' onClick={this.convert}>
                  CONVERT TO ARABIC
                </Button>
                <br></br>
                <br></br>
                <Button onClick={this.navigate}>CANCEL</Button>{" "}
                <Button color='success' value='Submit'>
                  ADD
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Congratulation Promo Code added Successfully</ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.navigate}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
