import React from "react";
import Header from "components/Headers/Header.jsx";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import PageLoader from "components/PageLoader/PageLoader";
import RealTime from "services/RealTime";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "../styles/ResponsiveIFrame.css";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";

const columns = [
  {
    text: "SL.No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Provider ",
    dataField: "sp_name",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter({
      style: {
        minWidth: "150px",
        placeholder: "Service Provider Name ",
      },
    }),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Emirate",
    dataField: "sp_emirate",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter({
      style: {
        minWidth: "500px",
        placeholder: "Enter Emirate  ",
      },
    }),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Serving Areas",
    dataField: "sp_areaCount",
    footer: false,
    align: "center",
    filter: false,
    headerAlign: "center",
    sort: true,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];

var selected = "";
var locationNew = [];
var areaList;
var location = [];
class EditServingArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: true,
      redirect: false,
      availableArea: "",
      area: "",
      newArea: "",
      location: "",
      newLocationDetails: "",
      maplocation: "",
      mapPreview: false,
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };
  }
  async componentDidMount() {
    this.setState({
      isloaded: false,
    });
    const allArea = await RealTime.getDetails(
      `twaddan_admin/emirates/${selected.sp_emirate}/serviceable_areas`
    );
    this.getData(allArea);
  }

  getData = (allArea) => {
    var availableArea = [];

    if (allArea) {
      if (selected?.sp_servingArea) {
        Object.keys(allArea).map((area) => {
          var flag = true;
          Object.keys(selected.sp_servingArea).map((selectedArea) => {
            if (area === selectedArea) {
              flag = false;
            }
          });
          if (flag) {
            availableArea.push(area);
          }
        });
        this.setState({
          availableArea: availableArea,
          isloaded: true,
        });
      } else {
        this.setState({
          availableArea: Object.keys(allArea),
          isloaded: true,
        });
      }
    }

    this.setState({
      isloaded: true,
    });
  };

  handleSelectNewArea = (area) => {
    this.setState({
      newArea: area,
    });
  };
  handleChangeNewArea = (area) => {
    this.setState({
      newArea: area,
    });
  };

  handleSubmitNewLocation = async (event) => {
    var existFlag1 = true;
    var existFlag2 = true;
    var mapData = [];
    if (this.state.newArea !== "") {
      var mapArea = this.state.newArea.split(",").join("+");
      this.setState({
        maplocation: mapArea.split(" ").join("+"),
      });

      const placeData = await geocodeByAddress(this.state.newArea)
        .then((results) => {
          mapData.push({
            area_name: results[0].address_components[0].long_name,
            administrative_area_level_1: results[0].address_components[1].types.includes(
              "administrative_area_level_1"
            )
              ? results[0].address_components[1].long_name
              : results[0].address_components[2].types.includes(
                  "administrative_area_level_1"
                )
              ? results[0].address_components[2].long_name
              : results[0].address_components[3].types.includes(
                  "administrative_area_level_1"
                )
              ? results[0].address_components[3].long_name
              : " United Arab Emirates",
            country: " United Arab Emirates",
          });
          return results;
        })
        .catch((error) => console.error("Error", error));

      this.state.availableArea.map((area) => {
        if (area === mapData[0].area_name) {
          existFlag1 = false;
        }
      });
      location.map((loc) => {
        if (loc === mapData[0].area_name) {
          existFlag2 = false;
        }
      });
      if (existFlag1 && existFlag2) {
        location.push(mapData[0].area_name);
        locationNew.push({
          id: mapData[0].area_name,
          name: mapData[0].area_name,
          administrative_area_level_1: mapData[0].administrative_area_level_1,
          country: mapData[0].country,
          map_preview: this.state.newArea,
          place_data: JSON.stringify(placeData),
          status: true,
        });
      }

      this.setState({
        newLocationDetails: locationNew,
        location: location,
        newArea: "",
        maplocation: "",
        mapPreview: false,
      });
    }
    mapData = [];
    event.preventDefault();
  };
  mapPreview = () => {
    var mapArea = this.state.newArea.split(",").join("+");
    this.setState({
      maplocation: mapArea.split(" ").join("+"),
      mapPreview: true,
    });
  };
  handleSubmitLocation = () => {
    var existFlag = true;
    location.map((loc) => {
      if (loc === this.state.area) {
        existFlag = false;
      }
    });
    if (existFlag) {
      location.push(this.state.area);
      this.setState({
        location: location,
      });
    }
  };
  removeLocation = (name) => {
    var locationDetails = location;
    const filteredLocation = locationDetails.filter((item) => item !== name);
    location = filteredLocation;
    this.setState({
      location: location,
      maplocation: "",
    });
    if (this.state.newLocationDetails) {
      var newLocationDetails = this.state.newLocationDetails;
      const filteredNewLocation = newLocationDetails.filter(
        (item) => item.name !== name
      );
      this.setState({
        newLocationDetails: filteredNewLocation,
        maplocation: "",
      });
    }
  };

  convertToEnglish = async (text) => {
    const axios = require("axios").default;
    const url = "https://translation.googleapis.com/language/translate/v2";
    const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios.get(url, {
      params: {
        q: text,
        target: "en",
        key: apiKey,
      },
    });
    location.map((loc) => {
      if (loc === text) {
        loc = reply.data.data.translations[0].translatedText;
      } else {
        loc = text;
      }
    });
    this.setState({
      location: location,
    });
  };

  toggle = () => {
    this.setState({
      redirect: true,
    });
    location.length = 0;
  };
  handleStoreAreas = async () => {
    if (this.state.location && true) {
      this.state.location.map((loc) => {
        firebase
          .database()
          .ref("service_providers/" + selected.sp_id + "/serviceable_areas/")

          .update({ [loc]: true });
      });
    }

    location.length = 0;
    this.setState({
      redirect: true,
    });
  };
  render() {
    areaList =
      this.state.availableArea.length > 0 &&
      this.state.availableArea.map((i) => {
        return <option>{i}</option>;
      }, this);

    if (this.state.redirect) {
      return <ServingArea />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <PageLoader loading={!this.state.isloaded}></PageLoader>

        <Container className="mt-1 pt-1">
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <h2 className="mb-0">
                Add New Serving Area for <b>{selected.sp_name} </b> under
                emirate <b>{selected.sp_emirate}</b>{" "}
              </h2>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input
                type="select"
                name="spid"
                id="spid"
                required
                value={this.state.area}
                invalid={this.state.availableArea.length < 1 ? true : false}
                disabled={this.state.availableArea.length < 1 ? true : false}
                // onMouseOver={this.toolTipView}
                value={this.state.area}
                onChange={(e) => {
                  this.setState({ area: e.target.value });
                }}
              >
                <option hidden>Select New Area</option>
                {areaList}
              </Input>
              <FormFeedback
                isOpen={this.state.availableArea.length < 1 ? true : false}
              >
                All areas are assigned or no serviceable area under{" "}
                {selected.sp_emirate}
              </FormFeedback>
            </Col>
            <Col>
              <Button
                type="button"
                color="success"
                disabled={this.state.availableArea.length < 1 ? true : false}
                onClick={this.handleSubmitLocation}
              >
                ADD AREA
              </Button>
            </Col>
          </Row>

          {/* <Row className="mt-5">
            <Col sm={12} md={6} lg={6} xl={6}>
              <PlacesAutocomplete
                value={this.state.newArea}
                onChange={this.handleChangeNewArea}
                searchOptions={{
                  componentRestrictions: { country: ["ae"] },
                }}
                // searchOptions={searchOptions}

                onSelect={this.handleSelectNewArea}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-square-pin" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={this.state.newArea}
                          onChange={(e) =>
                            this.setState({
                              newArea: e.target.value,
                            })
                          }
                          {...getInputProps({
                            placeholder: "Search Places ...",
                            className: "location-search-input",
                          })}
                        />
                      </InputGroup>
                    </FormGroup>

                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#88ebfc",
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
            <Col>
              <Button
                type="button"
                color="success"
                onClick={this.handleSubmitNewLocation}
              >
                ADD AREA
              </Button>
              <Button type="button" color="info" onClick={this.mapPreview}>
                PREVIEW
              </Button>
            </Col>
          </Row> */}
          <Row className="mt-3">
            {this.state.mapPreview && (
              <Col>
                <div className="containerIframe">
                  <iframe
                    class="responsive-iframe"
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&q=${
                      this.state.selected_map ? this.state.selected_map : ""
                    }`}
                    allowfullscreen
                  ></iframe>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              {this.state.location.length > 0 && (
                <div>
                  <Table responsive bordered={false}>
                    <thead>
                      <tr></tr>
                    </thead>
                    {this.state.location.map((location) => (
                      <tbody>
                        <tr>
                          <td>
                            <h3>{location}</h3>
                          </td>

                          <td>
                            <Button
                              color="danger"
                              onClick={() => {
                                this.removeLocation(location);
                              }}
                            >
                              REMOVE
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    ))}{" "}
                  </Table>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.location && (
                <Button
                  type="button"
                  color="primary"
                  onClick={this.handleStoreAreas}
                >
                  STORE AREAS
                </Button>
              )}

              <Button type="button" onClick={this.toggle}>
                CANCEL
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default class ServingArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: true,
      newArea: false,
      conform: false,
      servingAreaDetails: "",
      selectedAreaDetails: "",
      areaDetail: false,
      toggleArea: "",
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };
  }

  async componentDidMount() {
    console.log("called");
    this.setState({
      isloaded: false,
    });

    this.getData();
  }
  getData = async () => {
    const service_providers = await RealTime.getDetails("service_providers");
    const emirate_details = await RealTime.getDetails("twaddan_admin/emirates");
    var service_provider = [];
    if (service_provider && emirate_details) {
      Object.entries(service_providers).map((sp, _keys) => {
        service_provider.push({
          name: sp[1]?.personal_information?.name,
          id: sp[0],
          servingArea: sp[1].serviceable_areas,
          count: sp[1].serviceable_areas
            ? Object.keys(sp[1].serviceable_areas).length
            : 0,
        });
      });
      var service_provider_details = [];
      var i = 1;
      Object.entries(emirate_details).forEach(([key, emirate]) => {
        if (emirate.service_providers)
          Object.entries(emirate.service_providers).forEach(([sp, value]) => {
            service_provider.map((spd) => {
              if (spd.id === sp) {
                service_provider_details.push({
                  i: i++,
                  sp_name: spd.name,
                  sp_id: spd.id,
                  sp_servingArea: spd.servingArea,
                  sp_areaCount: spd.count,
                  sp_emirate: emirate.name,
                });
              }
            });
          });
      });
      this.setState({
        servingAreaDetails: service_provider_details,
      });
    }

    this.setState({
      isloaded: true,
    });
  };
  rowStyle = (_row, rowIndex) => {
    const style = {};
    if (rowIndex % 2 === 0) {
      style.backgroundColor = "#e6e6e6";
    }
    style.cursor = "pointer";
    return style;
  };
  toggle = () => {
    this.setState({
      areaDetail: !this.state.areaDetail,
    });
  };
  toggleToNew = () => {
    this.setState({
      newArea: !this.state.newArea,
    });
    selected = this.state.selectedAreaDetails;
  };
  toggleConfirmation = () => {
    this.setState({
      conform: !this.state.conform,
    });
  };

  toggleServiceableArea = async (e) => {
    e.preventDefault();
    this.setState({
      isloaded: false,
    });
    firebase
      .database()
      .ref(
        "service_providers/" +
          this.state.selectedAreaDetails.sp_id +
          "/serviceable_areas/"
      )
      .update({ [this.state.toggleArea[0]]: !this.state.toggleArea[1] });

    const data = await RealTime.getDetails(
      `twaddan_admin/emirates/${this.state.selectedAreaDetails.sp_emirate}/serviceable_areas/${this.state.toggleArea[0]}/service_providers/${this.state.selectedAreaDetails.sp_id}`
    );

    if (data) {
      firebase
        .database()
        .ref(
          "twaddan_admin/emirates/" +
            this.state.selectedAreaDetails.sp_emirate +
            "/serviceable_areas/" +
            this.state.toggleArea[0] +
            "/service_providers/" +
            this.state.selectedAreaDetails.sp_id
        )
        .update({ status: !this.state.toggleArea[1] });
    }
    this.getData();
    this.toggleConfirmation();
    this.toggle();
    this.setState({
      isloaded: true,
    });
    this.getData();
  };

  render() {
    const rowEvents = {
      onClick: (_e, row, _rowIndex) => {
        this.state.servingAreaDetails.map((area) => {
          if (row.sp_id === area.sp_id) {
            this.setState({
              areaDetail: true,
              selectedAreaDetails: area,
            });
          }
        });
      },
    };
    if (this.state.newArea) {
      return <EditServingArea />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <PageLoader loading={!this.state.isloaded}></PageLoader>
        <Container className="mt-1" fluid>
          <Row>
            <Col sm={12} md={12} lg={5} xl={8}>
              <h1 className="mb-0">Serviceable Areas </h1>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <BootstrapTable
                keyField="id"
                data={this.state.servingAreaDetails}
                columns={columns}
                rowStyle={this.rowStyle}
                filter={filterFactory()}
                filterPosition="top"
                bordered={false}
                hover
                wrapperClasses="table-responsive"
                condensed
                rowEvents={rowEvents}
              />
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={this.state.areaDetail}
          toggle={this.toggle}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            <h1>
              Serviceable Area of {this.state.selectedAreaDetails.sp_name}
            </h1>
          </ModalHeader>
          <ModalBody>
            <>
              <Row>
                {this.state.selectedAreaDetails.sp_servingArea && (
                  <>
                    {Object.entries(
                      this.state.selectedAreaDetails.sp_servingArea
                    ).map((area, key) => (
                      <Col sm={12} md={12} lg={6} xl={6}>
                        <Card>
                          {" "}
                          <CardBody>
                            <Container key={area[0]}>
                              <Row>
                                <Col style={{ margin: "auto" }} xs={7}>
                                  <h4>{area[0]}</h4>
                                </Col>
                                <Col xs={5}>
                                  <Button
                                    color={`${area[1] ? "danger" : "success"}`}
                                    onClick={() => {
                                      this.setState({ toggleArea: area });
                                      this.toggleConfirmation();
                                    }}
                                  >
                                    {area[1] ? "DISABLE" : "ENABLE"}
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </>
                )}

                {!this.state.selectedAreaDetails.sp_servingArea && (
                  <>
                    <h2 align="center">NO RECORDS FOUND</h2>
                  </>
                )}
              </Row>
            </>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleToNew}>
              ADD NEW AREA
            </Button>
            <Button onClick={this.toggle}>CLOSE</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.conform} toggle={this.toggleConfirmation}>
          <ModalBody>Do you really wish to enable/disable</ModalBody>
          <ModalFooter>
            <Form onSubmit={this.toggleServiceableArea}>
              <Button
                color="danger"
                onClick={() => {
                  this.toggleConfirmation();
                }}
              >
                N0
              </Button>
              <Button color="info" type="submit">
                YES
              </Button>
            </Form>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
