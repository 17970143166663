import React from "react";
import RealTime from "../../services/RealTime";
import firebase from "firebaseConfig";
import moment from "moment";
import "moment-timezone";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "components/Headers/Header.jsx";
import { Redirect } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader";
import "../styles/UserNotification.css";
import ConvertArabic from "../../services/ConvertArabic";
const columns = [
  {
    text: "SL.No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Name",
    dataField: "name",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Phone Number",
    dataField: "phoneNumber",
    footer: false,
    align: "center",
    filter: textFilter(),
    headerAlign: "center",
    sort: true,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
    style: {
      minWidth: "300px",
    },
  },
  {
    text: "Email",
    dataField: "email",
    footer: false,
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Type Of User",
    dataField: "typeOfUser",
    footer: false,
    sort: true,
    align: "center",
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
    style: {},
  },
  {
    text: "Emirate",
    dataField: "emirate",
    align: "center",
    headerAlign: "center",
    sort: true,
    filter: textFilter(),
    footerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },

  {
    text: "Number of Order",
    dataField: "numberOfOrder",
    sort: true,
    align: "center",
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Date Of Registration",
    dataField: "dateOfRegString",
    sort: true,
    align: "center",
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Language",
    dataField: "language",
    sort: true,
    filter: textFilter(),
    align: "center",
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "OS",
    dataField: "os",
    sort: true,
    align: "center",
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Device",
    dataField: "device",
    sort: true,
    align: "center",
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const defaultSorted = [
  {
    dataField: "Name",
    order: "desc",
  },
];
class UsersNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionID: "",
      modal: false,
      searchBarID: "",
      emirates: [],
      isLoaded: false,
      selected: [],
      selectedId: [],
      title: "",
      message: "",
      title_arabic: "",
      message_arabic: "",
      notificationSend: false,
      users_with_out_active_device: [],
      users_with__active_device: [],
    };
  }
  getUsers = async () => {
    let allUsers = [];
    let i = 1;
    let savedAddressLength = 0;
    let emirate = "";
    let users = await RealTime.getDetails("/Users/");
    let pastOrder = 0;
    let activeOrder = 0;
    if (users) {
      Object.entries(users).forEach(([key, user]) => {
        savedAddressLength =
          user.saved_address !== undefined
            ? Object.keys(user.saved_address).length
            : 0;
        if (savedAddressLength !== 0) {
          var prop = Object.keys(user.saved_address)[savedAddressLength - 1];
          emirate =
            user.saved_address[prop].emirate !== undefined
              ? user.saved_address[prop].emirate
              : "Nil";
        }
        pastOrder =
          user.past_orders !== undefined
            ? Object.keys(user.past_orders.order_ids).length
            : 0;
        activeOrder =
          user.past_orders !== undefined
            ? Object.keys(user.past_orders.order_ids).length
            : 0;
        var date1 = new Date();
        var lopt =
          user.personal_details !== undefined
            ? user.personal_details.LOPT !== undefined
              ? user.personal_details.LOPT
              : null
            : null;
        // To calculate the time difference of two dates
        var Difference_In_Time = date1.getTime() - lopt;
        var Difference_In_Days = null;
        // To calculate the no. of days between two dates
        if (lopt !== null) {
          Difference_In_Days = parseInt(
            Difference_In_Time / (1000 * 3600 * 24)
          );
          console.log("Difference_In_Days", Difference_In_Days);
        }

        allUsers.push({
          i: i++,
          id: key,
          name:
            user.personal_details !== undefined
              ? user.personal_details.name !== undefined
                ? user.personal_details.name.concat(
                    user.personal_details.lastName !== undefined
                      ? user.personal_details.lastName
                      : ""
                  )
                : "Nil"
              : "Nil",
          phoneNumber:
            user.personal_details !== undefined
              ? user.personal_details.phoneNumber
              : "Nil",
          email:
            user.personal_details !== undefined
              ? user.personal_details.email
              : "Nill",
          typeOfUser:
            user.personal_details !== undefined
              ? user.personal_details.guest !== undefined
                ? user.personal_details.guest
                  ? Difference_In_Days !== null && Difference_In_Days <= 7
                    ? "Active Guest user"
                    : "Guest user"
                  : Difference_In_Days !== null && Difference_In_Days <= 7
                  ? "Active Registered user"
                  : "Registered user"
                : "Nil"
              : "Nil",
          emirate: emirate,
          numberOfOrder: pastOrder + activeOrder,
          dateOfReg:
            user.personal_details !== undefined
              ? user.personal_details.registeredDate != undefined
                ? moment(user.personal_details.registeredDate)
                    .tz("Asia/Dubai")
                    .format("DD/MM/YYYY hh:mm a")
                : "Nil"
              : "Nil",
          dateOfRegString:
            user.personal_details !== undefined
              ? user.personal_details.registeredDate != undefined
                ? moment(user.personal_details.registeredDate)
                    .tz("Asia/Dubai")
                    .format("DD/MM/YYYY hh:mm a")
                : "Nil"
              : "Nil",
          devices:
            user.personal_details !== undefined
              ? user.personal_details.Devices != undefined
                ? user.personal_details.Devices
                : []
              : [],
          os:
            user.personal_details !== undefined
              ? user.personal_details.lastLoggedInDevice != undefined
                ? user.personal_details.Devices != undefined
                  ? user.personal_details.Devices[
                      user.personal_details.lastLoggedInDevice
                    ].os
                  : "Nil"
                : "Nil"
              : "Nil",
          language:
            user.personal_details !== undefined
              ? user.personal_details.lastLoggedInDevice != undefined
                ? user.personal_details.Devices != undefined
                  ? user.personal_details.Devices[
                      user.personal_details.lastLoggedInDevice
                    ].language
                  : "Nil"
                : "Nil"
              : "Nil",
          device:
            user.personal_details !== undefined
              ? user.personal_details.lastLoggedInDevice != undefined
                ? user.personal_details.Devices != undefined
                  ? user.personal_details.Devices[
                      user.personal_details.lastLoggedInDevice
                    ].deviceName
                  : "Nil"
                : "Nil"
              : "Nil",
        });
      });
    }
    return allUsers;
  };
  dateRangeHandler = async (event, picker) => {
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
    let i = 0;
    let users = await this.state.allUsers.filter(function (user) {
      return (
        moment(user.dateOfReg) >= picker.startDate.toDate() &&
        moment(user.dateOfReg) <= picker.endDate.toDate()
      );
    });
    let newUser = [];
    users.filter(function (odr) {
      var user = Object.assign({}, odr);
      i++;
      user.i = i;
      newUser.push(user);
    });
    this.setState({
      selectedEmirate: { label: "All", value: "All" },
      allUsersByDate: newUser,
      allUsersByDateAndEmirate: newUser,
    });
  };
  handleCancel = (event, picker) => {
    picker.element.val("");
    // this.clearFilter();
  };
  handleEmirateChange = async (event) => {
    var users = [];
    let i = 0;
    if (event.target.value === "All") {
      users = this.state.allUsers;
    } else {
      let i = 0;
      users = await this.state.allUsersByDate.filter(function (user) {
        return user.emirate == event.target.value;
      });
    }
    let newUser = [];
    users.filter(function (odr) {
      var user = Object.assign({}, odr);
      i++;
      user.i = i;
      newUser.push(user);
    });
    this.setState({
      selectedEmirate: event.value,
      allUsersByDateAndEmirate: newUser,
    });
  };
  getEmirates = async () => {
    let emirates = await RealTime.getDetails("/twaddan_admin/emirates/");
    let newEmirates = [];
    newEmirates.push({
      label: "All",
      value: "All",
    });
    await Object.entries(emirates).forEach(([key, emirate]) => {
      newEmirates.push({
        label: emirate.name,
        value: emirate.name,
      });
    });
    return newEmirates;
  };
  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row],
        selectedId: [...this.state.selectedId, row.id],
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter((x) => x !== row),
        selectedId: this.state.selectedId.filter((x) => x !== row.id),
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    const row = rows.map((r) => r);
    if (isSelect) {
      this.setState(() => ({
        selected: row,
        selectedId: ids,
      }));
    } else {
      this.setState(() => ({
        selected: [],
        selectedId: [],
      }));
    }
  };
  toggle = () => {
    this.setState({
      notificationSend: !this.state.notificationSend,
    });
  };
  sendNotification = async () => {
    this.setState({ isLoaded: false });
    const { REACT_APP_CLOUD_MESSAGE_SERVER_KEY } = process.env;
    console.log(
      "REACT_APP_CLOUD_MESSAGE_SERVER_KEY",
      REACT_APP_CLOUD_MESSAGE_SERVER_KEY
    );
    console.log("this.state.massage", this.state.message);
    console.log("this.state.title", this.state.title);

    let text_arabic = await ConvertArabic(this.state.message);
    let title_arabic = await ConvertArabic(this.state.title);
    var time = new Date();
    time = time.getTime();
    const axios = require("axios").default;
    var notification = {
      title: this.state.title,
      text: this.state.message,
      click_action: time,
      sound: "default",
      // color: "#ff0000",
      // image:
      // "https://www.talentica.com/wp-content/uploads/2017/08/cloud-messaging-7a.png",
    };
    var notification_arabic = {
      title: this.state.title_arabic,
      text: this.state.message_arabic,
      click_action: time,
      sound: "default",
      // color: "#ff0000",
      // image:
      // "https://www.talentica.com/wp-content/uploads/2017/08/cloud-messaging-7a.png",
    };
    var data = {
      id: time,
    };
    var fcm_tokens = [];
    var fcm_tokens_arabic = [];
    var users_with_active_device = [];
    var users_with_out_active_device = [];
    this.state.selected.map((user) => {
      console.log("user", user);

      if (user.devices !== undefined && Object.keys(user.devices).length > 0) {
        let device_status = false;
        console.log(" user.devices", user.devices);
        Object.entries(user.devices).forEach(([key, device]) => {
          if (device.active) {
            device_status = true;
            console.log("device", device);
            if (!fcm_tokens.includes(device.token)) {
              if (
                device.language !== undefined &&
                device.language === "arabic"
              ) {
                fcm_tokens_arabic.push(device.token);
              } else {
                fcm_tokens.push(device.token);
              }
            } else {
              console.log("fcm_tokens.push(device.token", device.token);
            }
          }
        });
        if (device_status) {
          users_with_active_device.push(user);
        } else {
          users_with_out_active_device.push(user);
        }
      } else {
        console.log("user.id", user.id);
        users_with_out_active_device.push(user);
        // firebase.firestore().collection("DeviceMissingUsers").doc().set({
        //   createdAt: new Date(),
        //   // user: user,
        //   email: user.email,
        //   id: user.id,
        // });
      }
    });
    var notification_body = {
      notification: notification,
      registration_ids: fcm_tokens,
      data: data,
    };
    var notification_body_arabic = {
      notification: notification_arabic,
      registration_ids: fcm_tokens_arabic,
      data: data,
    };
    if (users_with_active_device.length > 0) {
      let notificationSendSuccess = false;
      if (fcm_tokens.length > 0)
        await axios({
          method: "POST",
          url: "https://fcm.googleapis.com/fcm/send",
          headers: {
            "Content-Type": "application/json",
            Authorization: `key=${REACT_APP_CLOUD_MESSAGE_SERVER_KEY}`,
          },
          data: JSON.stringify(notification_body),
        })
          .then((response) => {
            console.log("response", response);
            notificationSendSuccess = true;
          })
          .catch((error) => {
            console.log("error", error);
          });
      if (fcm_tokens_arabic.length > 0)
        await axios({
          method: "POST",
          url: "https://fcm.googleapis.com/fcm/send",
          headers: {
            "Content-Type": "application/json",
            Authorization: `key=${REACT_APP_CLOUD_MESSAGE_SERVER_KEY}`,
          },
          data: JSON.stringify(notification_body_arabic),
        })
          .then((response) => {
            console.log("response", response);
            notificationSendSuccess = true;
          })
          .catch((error) => {
            console.log("error", error);
          });
      console.log("notificationSendSuccess", notificationSendSuccess);

      if (notificationSendSuccess) {
        console.log("notificationSendSuccess", notificationSendSuccess);
        users_with_active_device.map((user) => {
          let ref = firebase
            .database()
            .ref("Users/" + user.id + "/notification/");
          if (ref) {
            firebase
              .database()
              .ref("Users/" + user.id + "/notification/")
              .child(time)
              .set({
                createdAt: new Date(),
                title: this.state.title,
                text: this.state.message,
                timestamp: time,
                text_arabic: this.state.message_arabic,
                title_arabic: this.state.title_arabic,
                read_status: false,
              });
          } else {
            firebase
              .database()
              .ref("Users/" + user.id + "/")
              .set({
                notification: {
                  time: {
                    createdAt: new Date(),
                    title: this.state.title,
                    text: this.state.message,
                    text_arabic: this.state.message_arabic,
                    title_arabic: this.state.title_arabic,
                    timestamp: time,
                    read_status: false,
                  },
                },
              });
          }
        });
        this.setState({
          notificationSend: true,
          notificationText:
            users_with_out_active_device.length > 0
              ? "Congratulation Notifcation Send Successfully But Some Selected Users Don't Have Active Device"
              : "Congratulation Notifcation Send Successfully",
          users_with_out_active_device: users_with_out_active_device,
          isLoaded: true,
        });
      }
    } else {
      this.setState({
        notificationSend: true,
        notificationText: "All Selected Users Don't Have Active Device",
        users_with_out_active_device: users_with_out_active_device,
        isLoaded: true,
      });
    }
  };

  async componentDidMount() {
    console.log(
      "process.env.REACT_APP_CLOUD_MESSAGE_SERVER_KEY",
      process.env.REACT_APP_CLOUD_MESSAGE_SERVER_KEY
    );
    let user = localStorage.getItem("admin_name");
    let allUsers = [];
    let emirates;
    if (user) {
      allUsers = await this.getUsers();
      emirates = await this.getEmirates();
      this.setState({
        allUsers: allUsers,
        allUsersByDate: allUsers,
        allUsersByDateAndEmirate: allUsers,
        emirates: emirates,
        isLoaded: true,
      });
    }
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/admin/userdetails",
            state: { userId: this.state.userId },
          }}
        />
      );
    }
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          redirect: true,
          user: row,
          userId: row.id,
        });
      },
    };
    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selectedId,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };
    return (
      <>
        <PageLoader loading={!this.state.isLoaded}></PageLoader>
        <Header />
        <Container fluid>
          <br />
          <Container fluid>
            <div className="header-body">
              <br></br>
              <h1>Users Notfication</h1>
              <Row className="mt-3">
                <Col lg={12}>
                  <FormGroup>
                    <Label for="title">Title</Label>
                    <Input
                      type="text"
                      name="title"
                      required
                      id="title"
                      placeholder="Enter the title"
                      value={this.state.title}
                      onChange={(e) => {
                        this.setState({ title: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="message">Message</Label>
                    <Input
                      type="text"
                      name="message"
                      required
                      id="message"
                      placeholder="Enter the message"
                      value={this.state.message}
                      onChange={(e) => {
                        this.setState({ message: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="title_arabic">Title Arabic</Label>
                    <Input
                      type="text"
                      name="title_arabic"
                      required
                      id="title_arabic"
                      placeholder="Enter the title arabic"
                      value={this.state.title_arabic}
                      onChange={(e) => {
                        this.setState({ title_arabic: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="message_arabic">Message Arabic</Label>
                    <Input
                      type="text"
                      name="message_arabic"
                      required
                      id="message_arabic"
                      placeholder="Enter the message arabic"
                      value={this.state.message_arabic}
                      onChange={(e) => {
                        this.setState({ message_arabic: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={5} lg={4} xl={2}>
                  <Button
                    className="buttonConvert"
                    color="primary"
                    block
                    onClick={this.sendNotification}
                    disabled={
                      !this.state.title ||
                      !this.state.message ||
                      !this.state.selected.length > 0
                    }
                  >
                    Send Notification
                  </Button>
                </Col>
              </Row>
              <br></br>
              <h3>Select users</h3>
              <Row>
                <div className="h5 mt-3 col-md-4 col-lg-4">
                  <DateRangePicker
                    block
                    autoApply={true}
                    onApply={this.dateRangeHandler}
                    onCancel={this.handleCancel}
                    showOneCalendar
                    initialSettings={{
                      autoUpdateInput: false,
                      locale: {
                        format: "DD/MM/YYYY",
                        cancelLabel: "Clear",
                      },
                      startDate: moment(new Date())
                        .tz("Asia/Dubai")
                        .format("DD MM YYYY hh:mm a"),
                      endDate: moment(new Date())
                        .tz("Asia/Dubai")
                        .format("DD MM YYYY hh:mm a"),
                      ranges: {
                        Today: [
                          moment().tz("Asia/Dubai").toDate(),
                          moment().tz("Asia/Dubai").toDate(),
                        ],
                        Yesterday: [
                          moment().subtract(1, "days").toDate(),
                          moment().subtract(1, "days").toDate(),
                        ],
                        "Last 7 Days": [
                          moment().subtract(6, "days").toDate(),
                          moment().toDate(),
                        ],
                        "Last 30 Days": [
                          moment().subtract(29, "days").toDate(),
                          moment().toDate(),
                        ],
                        "This Month": [
                          moment().startOf("month").toDate(),
                          moment().endOf("month").toDate(),
                        ],
                        "Last Month": [
                          moment()
                            .subtract(1, "month")
                            .startOf("month")
                            .toDate(),
                          moment().subtract(1, "month").endOf("month").toDate(),
                        ],
                      },
                    }}
                  >
                    <input
                      type="text"
                      style={{ maxWidth: "300px", display: "inline-block" }}
                      className="form-control"
                      defaultValue=""
                      placeholder="Select Date Range"
                    />
                  </DateRangePicker>
                </div>

                <div className="h5 mt-3 col-md-4 col-lg-4">
                  <FormGroup row>
                    <Col>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        value={this.state.selectedEmirate}
                        onChange={this.handleEmirateChange}
                      >
                        {this.state.emirates.length > 0 &&
                          this.state.emirates.map((emirate) => {
                            return <option>{emirate.value}</option>;
                          })}
                      </Input>
                    </Col>
                  </FormGroup>
                </div>
                {/* <div className="h5 mt-3 col-md-3">
                  <Button
                    style={{
                      maxWidth: "300px",
                      display: "inline-block",
                      marginLeft: "20px"
                    }}
                    color="info"
                    // onClick={this.pdfGenerator}
                  >
                    Export
                  </Button>
                  </div> */}
              </Row>
              <div>
                {this.state.allUsersByDateAndEmirate && (
                  <BootstrapTable
                    keyField="id"
                    data={this.state.allUsersByDateAndEmirate}
                    columns={columns}
                    filter={filterFactory()}
                    filterPosition="top"
                    pagination={paginationFactory()}
                    defaultSorted={defaultSorted}
                    bordered={false}
                    selectRow={selectRow}
                    hover
                    wrapperClasses="table-responsive"
                    condensed
                    // rowEvents={rowEvents}
                  />
                )}
              </div>
            </div>
          </Container>

          {
            <Container className="mt--7" fluid>
              <div className="h5 mt-8">
                <Row></Row>
              </div>
            </Container>
          }
          {/* {(!userDetails || Object.keys(userDetails).length <= 0) && (
            <Container fluid>
              <div className="header-body">
                <div>
                  <h1>No records found</h1>
                </div>
              </div>
            </Container>
          )} */}
        </Container>
        <Modal isOpen={this.state.notificationSend} toggle={this.toggle}>
          <ModalBody>
            <p>{this.state.notificationText}</p>
            {this.state.users_with_out_active_device.length > 0 && (
              <div class="table-responsive">
                <table className="tableUser">
                  <tr>
                    <th className="tableItem">User ID</th>
                    <th className="tableItem">Name</th>
                    <th className="tableItem">Email</th>
                  </tr>
                  {this.state.users_with_out_active_device.map((user) => {
                    return (
                      <tr>
                        <td className="tableItem">{user.id}</td>
                        <td className="tableItem">{user.name}</td>
                        <td className="tableItem">{user.email}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
export default UsersNotification;
