import Header from "components/Headers/Header";
import PageLoader from "components/PageLoader/PageLoader";
import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  FormGroup,
  Label,
  Button,
  Form,
  Input,
  Col,
  CardBody,
  Container,
  Card,
  CardHeader,
  CardTitle,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import RealTime from "services/RealTime";
import firebase from "../../firebaseConfig";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
const columns = [
  {
    text: "SL.No",
    dataField: "id",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Area",
    dataField: "area",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter({
      style: {
        minWidth: "150px",
        placeholder: "Enter Name ",
      },
    }),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Emirate",
    dataField: "emirate",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter({
      style: {
        minWidth: "150px",
        placeholder: "Enter Emirate ",
      },
    }),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const data = RealTime.getDetails("twaddan_admin/emirates");
var emirates = [];
emirates = Object.keys(data);
var maplocation;
var location = [];
var locationEdit;

class ServiceableAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: true,
      redirect: false,
      emitate: "",
      area: "",
      locationDetails: "",
      placeData: [],
      mapPreview: "",
      latLng: null,
      latLngTest: null,
      results: "",
      location_ok: false,
    };
  }

  async componentDidMount() {
    this.setState({
      isLoaded: false,
    });
    const data = await RealTime.getDetails("twaddan_admin/emirates");
    this.getData(data);
  }
  getData = (data) => {
    emirates = Object.keys(data);
    if (emirates) {
      this.setState({
        isLoaded: true,
      });
    }
    console.log(emirates);
  };

  handleChangePlace = (area) => {
    this.setState({
      area,
    });
  };

  handleSubmitLatLng = async () => {
    const axios = require("axios").default;

    const url =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      this.state.latLngTest +
      "&key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0";
    // const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios.get(url);
    console.log("FULL RESULT", reply.data.results);
    reply.data.results.map((location) => {
      if (location.types.includes("neighborhood")) {
        this.setState({
          area: location.address_components[0].long_name,
        });
        console.log("neighborhood", location);
      }
    });
  };

  handleSelectPlace = async (area) => {
    this.setState({
      isLoaded: false,
    });
    const axios = require("axios").default;

    this.setState({
      area: area,
      mapPreview: area,
    });
    await geocodeByAddress(area)
      .then(async (results) => await getLatLng(results[0]))
      .then((latLng) => {
        this.setState({
          latLng: latLng,
        });
      });
    const url =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      this.state.latLng.lat +
      "," +
      this.state.latLng.lng +
      "&key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0";
    // const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios.get(url);
    console.log(reply.data.results);
    reply.data.results.map((location) => {
      if (
        location.types.includes("neighborhood") ||
        location.types.includes("sublocality")
      ) {
        console.log(location);
      }
    });
    this.setState({
      isLoaded: true,
    });
  };

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };

  handleSubmitLocation = async (event) => {
    this.setState({
      isLoaded: false,
    });
    var mapData = [];
    var area_check = false;
    if (this.state.area !== "") {
      var mapArea = this.state.area.split(",").join("+");
      maplocation = mapArea.split(" ").join("+");

      const placeData = await geocodeByAddress(this.state.area)
        .then((results) => {
          console.log(results);
          console.log(results[0].types);
          console.log(results[0].types.includes("sublocality"));
          if (
            results[0].types.includes("neighborhood") ||
            results[0].types.includes("sublocality")
          ) {
            area_check = true;
            mapData.push({
              area_name: results[0].address_components[0].long_name,
              administrative_area_level_1: results[0].address_components[1].types.includes(
                "administrative_area_level_1"
              )
                ? results[0].address_components[1].long_name
                : results[0].address_components[2].types.includes(
                    "administrative_area_level_1"
                  )
                ? results[0].address_components[2].long_name
                : results[0].address_components[3].types.includes(
                    "administrative_area_level_1"
                  )
                ? results[0].address_components[3].long_name
                : " United Arab Emirates",
              country: " United Arab Emirates",
            });
            return results;
          } else {
            area_check = false;
          }
        })
        .catch((error) => console.error("Error", error));
      this.setState({
        isLoaded: true,
      });
      if (area_check) {
        location.push({
          id: mapData[0].area_name,
          name: mapData[0].area_name,
          administrative_area_level_1: mapData[0].administrative_area_level_1,
          country: mapData[0].country,
          map_preview: this.state.area,
          place_data: JSON.stringify(placeData),
          status: true,
        });
        this.setState({
          locationDetails: location,
        });
      } else {
        alert("Selected Location is not a Serviceable Area");
      }

      locationEdit = false;
      console.log(this.state.locationDetails);
    } else {
      this.setState({
        isLoaded: true,
      });
    }
    this.setState({
      area: "",
      price: "",
      mapPreview: "",
    });
    mapData = [];
    event.preventDefault();
  };
  removeLocation = (name) => {
    var locationDetails = location;
    const filteredLocation = locationDetails.filter(
      (item) => item.name !== name
    );
    location = filteredLocation;
    this.setState({
      locationDetails: location,
    });
    maplocation = "";
  };

  handleSubmitArea = (event) => {
    console.log("emitate:", this.state.emitate);
    console.log("area data:", location);
    location.map((loc) => {
      firebase
        .database()
        .ref(
          "twaddan_admin/emirates/" + this.state.emitate + "/serviceable_areas"
        )
        .child(loc.name)
        .update(loc);
    });

    maplocation = "";
    location.length = 0;
    event.preventDefault();
    this.setState({
      redirect: true,
    });
  };

  render() {
    let emiratesList =
      emirates.length > 0 &&
      emirates.map((i) => {
        return <option>{i}</option>;
      }, this);

    if (this.state.redirect) {
      return <ServiceableAreasList />;
    }
    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isLoaded}></PageLoader>

        <Container className=" mt--10">
          <Row className="mt-5">
            <Col sm={12} md={12} lg={5} xl={8}>
              <h1 className="mb-0 ">
                Add Serviceable Areas Under{" "}
                {this.state.emitate ? this.state.emitate : "Emirate"}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={this.handleSubmitArea}></Form>
              <FormGroup>
                <Label for="spid" className="mt-5">
                  Select Emirates
                </Label>
                <Input
                  type="select"
                  invalid={this.state.emitate ? false : true}
                  name="spid"
                  id="spid"
                  required
                  value={this.state.emitate}
                  onChange={(e) => {
                    this.setState({ emitate: e.target.value });
                  }}
                >
                  <option hidden>Select Emirate</option>
                  {emiratesList}
                </Input>
                <FormFeedback>Select Emirate</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          {this.state.emitate && (
            <Container>
              <Row>
                <Col></Col>
              </Row>
              <Row>
                <Col md={8}>
                  <PlacesAutocomplete
                    value={this.state.area}
                    onChange={this.handleChangePlace}
                    searchOptions={{
                      componentRestrictions: { country: ["ae"] },
                    }}
                    // searchOptions={searchOptions}

                    onSelect={this.handleSelectPlace}
                  >
                    {({
                      getInputProps,
                      getLatLng,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-square-pin" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value={this.state.area}
                              onChange={(e) =>
                                this.setState({
                                  area: e.target.value,
                                })
                              }
                              {...getInputProps({
                                placeholder: "Search Places ...",
                                className: "location-search-input",
                              })}
                            />
                          </InputGroup>
                        </FormGroup>

                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#88ebfc",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Col>
                <Col>
                  <Button
                    type="button"
                    color="success"
                    onClick={this.handleSubmitLocation}
                  >
                    ADD AREA
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
          {false && (
            <Container>
              <Row>
                <Col></Col>
              </Row>
              <Row>
                <Col md={8}>
                  <Input
                    value={this.state.latLngTest}
                    onChange={(e) => {
                      this.setState({
                        latLngTest: e.target.value,
                      });
                    }}
                  ></Input>
                </Col>
                <Col>
                  <Button
                    type="button"
                    color="success"
                    onClick={this.handleSubmitLatLng}
                  >
                    FIND
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col></Col>
              </Row>
            </Container>
          )}

          <Container>
            <Row>
              <Col>
                {this.state.locationDetails.length > 0 && (
                  <div>
                    <Table responsive bordered={false}>
                      <thead>
                        <tr>
                          {/* <th>Area</th>
                                <th>Actions</th> */}
                        </tr>
                      </thead>
                      {this.state.locationDetails.map((location) => (
                        <tbody>
                          <tr>
                            <td>
                              <h3>{location.name}</h3>
                            </td>

                            <td>
                              <Button
                                color="danger"
                                onClick={() => {
                                  this.removeLocation(location.name);
                                }}
                              >
                                REMOVE
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      ))}{" "}
                    </Table>
                  </div>
                )}
              </Col>
              <Col>
                {this.state.mapPreview && (
                  <Col sm={{ size: "auto" }}>
                    <Label for="spid">Location Preview</Label>

                    <iframe
                      width="900"
                      height="400"
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&q=${this.state.mapPreview}`}
                      allowfullscreen
                    ></iframe>
                  </Col>
                )}
              </Col>
            </Row>
          </Container>
          <br />
          <br />
          <br />
          <Row>
            {" "}
            <Col>
              {location.length > 0 && (
                <Button color="primary" onClick={this.handleSubmitArea}>
                  STORE AREAS
                </Button>
              )}

              <Button
                onClick={() => {
                  this.setState({
                    redirect: true,
                  });
                }}
              >
                CANCEL
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default class ServiceableAreasList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      isLoaded: true,
      locationDetails: "",
      map_preview: "",
      selected_area: "",
    };
  }

  async componentDidMount() {
    this.setState({
      isLoaded: false,
    });
    const allEmirates = await RealTime.getDetails("twaddan_admin/emirates");
    if (allEmirates) {
      this.getData(allEmirates);
    }
  }

  getData = async (allEmirates) => {
    const area_details = [];
    var i = 1;
    Object.values(allEmirates).map((emirate, key) => {
      if (emirate.serviceable_areas) {
        Object.values(emirate.serviceable_areas).map((area, key) => {
          area_details.push({
            id: i++,
            emirate: emirate.name,
            area: area.name,
            map_preview: area.map_preview,
          });
        });
      }
    });
    if (area_details) {
      this.setState({
        isLoaded: true,
      });
      this.setState({
        locationDetails: area_details,
      });
    }
  };
  rowStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex % 2 === 0) {
      style.backgroundColor = "#e6e6e6";
    }
    style.cursor = "pointer";
    return style;
  };
  toggle = () => {
    this.setState({
      map_preview: !this.state.map_preview,
    });
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.state.locationDetails.map((loc) => {
          if (loc.area === row.area) {
            this.setState({
              map_preview: true,
              selected_area: loc.map_preview,
            });
          }
        });
      },
    };
    console.log(this.state.map_preview);
    console.log(this.state.selected_area);

    if (this.state.redirect) {
      return <ServiceableAreas />;
    }
    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isLoaded}></PageLoader>
        <Container className="mt--7" fluid>
          <Row className="mt-8 mb-5">
            <Col sm={12} md={7} lg={8} xl={9}>
              <h1 className="mb-0">Serviceable Areas</h1>
            </Col>
            <Col sm={6} md={5} lg={4} xl={3}>
              <Button
                color="primary"
                block
                onClick={() => {
                  this.setState({
                    redirect: true,
                  });
                }}
              >
                ADD NEW LOCATION
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <BootstrapTable
                keyField="id"
                data={this.state.locationDetails}
                columns={columns}
                rowStyle={this.rowStyle}
                filter={filterFactory()}
                filterPosition="top"
                pagination={paginationFactory()}
                bordered={false}
                hover
                wrapperClasses="table-responsive"
                condensed
                rowEvents={rowEvents}
              />
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.map_preview}
          toggle={this.toggle}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            <h1>Location Map Preview</h1>
          </ModalHeader>
          <ModalBody>
            <>
              <iframe
                width="740"
                height="400"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&q=${this.state.selected_area}`}
                allowfullscreen
              ></iframe>
            </>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
