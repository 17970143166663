/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import firebase from "firebaseConfig";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

var ps;

// super admin id

var userID = "superadmin_twaddan_com";

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  onClickToggle = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };

  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.layout === "/admin" &&
        ![
          "New booking",
          "Add VehicleType",
          "Add Vehicle",
          "Add Driver",
          "Add Service",
          "Add Addon Service",
          "Add Service Centers",
          "Add User",
          "Add Restricted Locations",
          "Page Not Found",
          "Add Notification",
          "Add Discount",
          "Active Order Tracking",
          "Completed Order Detail",
          "Cancelled Order Detail",
          "Customers Details",
        ].includes(prop.name) &&
        (userID !== "superadmin_twaddan_com" ||
          (userID == "superadmin_twaddan_com" && true))
      ) {
        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={this.onClickToggle}
              activeClassName='active'>
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      }
    });
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }

    return (
      <Navbar
        className='navbar-vertical fixed-left navbar-light bg-white'
        expand='md'
        id='sidenav-main'>
        <Container fluid>
          <button className='hamburger-menu' onClick={this.onClickToggle}>
            <i class='fas fa-bars' aria-hidden='true'></i>
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className='pt-0' {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className='navbar-brand-img'
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}

          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Navigation */}
            <Nav navbar>{this.createLinks(routes)}</Nav>
            {/* Divider */}
            <hr className='my-3' />
            {/* Heading */}
            {/* <h6 className='navbar-heading text-muted'>Twaddan</h6> */}
            {/* Navigation */}
            <Nav className='mb-md-3' navbar>
              <NavItem>
                {/* <NavLink href='/admin/index'>
                  <i className='ni ni-palette' />
                  About Twaddan
                </NavLink> */}
              </NavItem>

              <NavItem>
                {/* <NavLink href='/admin/index'>
                  <i className='ni ni-spaceship' />
                  Contact Us
                </NavLink> */}
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
