import React, { useState } from "react";
import firebase from "firebaseConfig";

import { connect } from "react-redux";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardTitle,
  Badge,
  CardFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardImg,
  ModalBody,
  ModalHeader,
  Modal,
  ModalFooter,
} from "reactstrap";

import Header from "components/Headers/Header.jsx";
import { Cpu, ThumbsDown } from "react-feather";
import { Redirect } from "react-router-dom";
import RealTime from "services/RealTime";

// total user count
var totalUser = 0;

// super admin user details
var saUserDetails;
// user permission details
var saUserPermissionDetails;
// super admin logged in user id
var saID;

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    console.log("The current user: ", user.email);
    saID = user.email;
    saID = saID.replace("@", "_");
    saID = saID.replace(".", "_");

    var superAdminData;
    firebase
      .database()
      .ref("/twaddan_admin/")
      .on("value", function (snapshot) {
        superAdminData = snapshot.val();

        saUserDetails = superAdminData.super_admin_users;
        saUserPermissionDetails = superAdminData.userPermission;

        // active booking
        // console.log("super admin user data: ", saUserDetails);
        // console.log("permission = ", saUserPermissionDetails);

        // total user count
        totalUser = 0;
        if (saUserDetails) {
          totalUser = Object.keys(saUserDetails).length - 1;
        }

        // ...
      });
  } else {
    // No user is signed in.
  }
});

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // name:'',
      spName: "",
      description: "",
      downloadURL: "",
      file: "",
      imagePreviewUrl: "",
      spemail: "",
      additionalNote: "",
      number: "",
      modal: false,
      permission: "",
    };
    this.toggle = this.toggle.bind(this);
    this.togglePermissionChange = this.togglePermissionChange.bind(this);
    this.updatePermission = this.updatePermission.bind(this);
    this.navigateAddUser = this.navigateAddUser.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });

    var superAdminData;
    firebase
      .database()
      .ref("/twaddan_admin/")
      .on("value", function (snapshot) {
        superAdminData = snapshot.val();

        saUserDetails = superAdminData.super_admin_users;
        saUserPermissionDetails = superAdminData.userPermission;

        // active booking
        console.log("super admin user data: ", saUserDetails);
        console.log("permission = ", saUserPermissionDetails);

        // total user count
        totalUser = 0;
        if (saUserDetails) {
          totalUser = Object.keys(saUserDetails).length - 1;
        }

        // ...
      });
  }

  async componentDidMount() {
    const data = await RealTime.getDetails("/twaddan_admin/");
    this.getdata(data);
  }
  getdata = (superAdminData) => {
    saUserDetails = superAdminData.super_admin_users;
    saUserPermissionDetails = superAdminData.userPermission;

    // active booking
    console.log("super admin user data: ", saUserDetails);
    console.log("permission = ", saUserPermissionDetails);

    // total user count
    totalUser = 0;
    if (saUserDetails) {
      totalUser = Object.keys(saUserDetails).length - 1;
    }
  };

  updatePermission = (id) => {
    console.log("idid = ", id);
    console.log("updated permission = ", saUserPermissionDetails[id]);
    const { permission } = this.state;

    firebase
      .database()
      .ref("twaddan_admin/userPermission/")
      .update({
        [id]: permission,
      });

    var username;
    var description;
    firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + id + "/personal_information/")
      .on("value", function (snapshot) {
        username = snapshot.val();
        description = username.description;
        username = username.name;
      });
    var time = new Date();
    time = time.getTime();
    var logAction =
      "Edited Permissions of Super Admin User " +
      username +
      " with SuperAdmin User ID " +
      id +
      " as a description " +
      description;
    var logs = firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/logs/" + time + "/");
    logs.update({
      action: logAction,
    });

    this.setState({ disabledButton: false });

    this.toggle();
  };

  togglePermissionChange = (stateName, id) => {
    var permissionOfID = saUserPermissionDetails[id];
    if (stateName == "addServiceCenter") {
      permissionOfID.page_permission.addServiceCenter =
        !permissionOfID.page_permission.addServiceCenter;
    } else if (stateName == "addDriver") {
      permissionOfID.page_permission.addDriver =
        !permissionOfID.page_permission.addDriver;
    } else if (stateName == "addVehicle") {
      permissionOfID.page_permission.addVehicle =
        !permissionOfID.page_permission.addVehicle;
    } else if (stateName == "addBooking") {
      permissionOfID.page_permission.addBooking =
        !permissionOfID.page_permission.addBooking;
    } else if (stateName == "addServices") {
      permissionOfID.page_permission.addServices =
        !permissionOfID.page_permission.addServices;
    } else if (stateName == "createPermission") {
      permissionOfID.crud_permission.createPermission =
        !permissionOfID.crud_permission.createPermission;
    } else if (stateName == "readPermission") {
      permissionOfID.crud_permission.readPermission =
        !permissionOfID.crud_permission.readPermission;
    } else if (stateName == "updatePermission") {
      permissionOfID.crud_permission.updatePermission =
        !permissionOfID.crud_permission.updatePermission;
    } else if (stateName == "deletePermission") {
      permissionOfID.crud_permission.deletePermission =
        !permissionOfID.crud_permission.deletePermission;
    }

    this.setState({ permission: permissionOfID });
    saUserPermissionDetails[id] = permissionOfID;
  };

  navigateAddUser = () => {
    this.setState({
      addUser: 1,
    });
  };

  render() {
    if (this.state.addUser == 1) {
      return <Redirect to="/admin/addUser" />;
    }

    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }

    return (
      <>
        <Header />
        {/* Page content */}

        <Container className="mt--7" fluid>
          <br />

          <Container fluid>
            <div className="header-body">
              Card stats
              <Row>
                <Col lg="6" xl="3" key={1}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Users
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {totalUser}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div
                            className={
                              "icon icon-shape bg-success text-white rounded-circle shadow "
                            }
                          >
                            <i className="fas fa-bus" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className={"mr-2 text-success"}>
                          <i className={"fas fa-arrow-up"} /> 100%
                        </span>{" "}
                        <span className="text-nowrap">Our Users</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>

          <Row className="mt-5">
            <div className="col text-right">
              <Button color="danger" onClick={this.navigateAddUser}>
                ADD NEW USER
              </Button>
            </div>
          </Row>

          <Container fluid>
            <div className="header-body">
              <br></br>
              <br></br>
              {/* <h1>User Management List</h1> */}
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">User Management Details</h3>
                    </div>
                  </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">EMail ID</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Permission Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {saUserDetails &&
                      Object.keys(saUserDetails).map((line, key) => {
                        return (
                          line !== "superadmin_twaddan_com" && (
                            <>
                              <tr>
                                <th scope="row">
                                  <div class="media align-items-center">
                                    <a
                                      href="#"
                                      class="avatar rounded-circle mr-3"
                                    >
                                      <img
                                        alt="Image placeholder"
                                        src={
                                          saUserDetails[line]
                                            ?.personal_information?.image
                                        }
                                      />
                                    </a>
                                    <div class="media-body">
                                      <span class="name mb-0 text-sm">
                                        {
                                          saUserDetails[line]
                                            ?.personal_information?.name
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </th>
                                <th scope="row">
                                  {
                                    saUserDetails[line]?.personal_information
                                      ?.email
                                  }
                                </th>
                                <th scope="row">
                                  {
                                    saUserDetails[line]?.personal_information
                                      ?.phone_number
                                  }
                                </th>
                                <td>
                                  <div>
                                    <Button
                                      onClick={() =>
                                        this.setState({
                                          descriptionID: line,
                                          modal: true,
                                          permission:
                                            saUserPermissionDetails[line],
                                        })
                                      }
                                      color="primary"
                                    >
                                      PERMISSIONS
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )
                        );
                      })}
                  </tbody>
                </Table>

                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className={this.props.className}
                  size="lg"
                >
                  <ModalHeader toggle={this.toggle}>
                    <h1>Permission Details</h1>
                  </ModalHeader>
                  <ModalBody>
                    <p className="mb-0">
                      <h1 style={{ color: "red" }}>User ID : </h1>
                      {this.state.descriptionID}
                    </p>
                    {saUserDetails &&
                      saUserDetails[this.state.descriptionID] &&
                      saUserDetails[this.state.descriptionID]
                        .personal_information && (
                        <>
                          <p className="mb-0">
                            <h3>Name: </h3>
                            {
                              saUserDetails[this.state.descriptionID]
                                ?.personal_information?.name
                            }
                          </p>
                          <p className="mb-0">
                            <h3>Email: </h3>
                            {
                              saUserDetails[this.state.descriptionID]
                                ?.personal_information?.email
                            }
                          </p>
                          <p className="mb-0">
                            <h3>Phone Number: </h3>
                            {
                              saUserDetails[this.state.descriptionID]
                                ?.personal_information?.phone_number
                            }
                          </p>
                          <hr className="my-4" />
                          <p className="mb-0">
                            <h3>Edit Permissions</h3>
                          </p>
                          <h4 className="heading-small text-muted mb-4">
                            Permissions to Accessible Pages
                          </h4>
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="4">
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    id="addservicecenter"
                                    type="checkbox"
                                    onClick={() => {
                                      this.togglePermissionChange(
                                        "addServiceCenter",
                                        this.state.descriptionID
                                      );
                                    }}
                                    checked={
                                      this.state.permission.page_permission
                                        .addServiceCenter
                                    }
                                  />

                                  <label
                                    className="custom-control-label"
                                    htmlFor="addservicecenter"
                                  >
                                    Add Service Provider
                                  </label>
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    id="adddriver"
                                    type="checkbox"
                                    onClick={() => {
                                      this.togglePermissionChange(
                                        "addDriver",
                                        this.state.descriptionID
                                      );
                                    }}
                                    checked={
                                      this.state.permission.page_permission
                                        .addDriver
                                    }
                                  />

                                  <label
                                    className="custom-control-label"
                                    htmlFor="adddriver"
                                  >
                                    Add Driver
                                  </label>
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    id="addvehicle"
                                    type="checkbox"
                                    onClick={() => {
                                      this.togglePermissionChange(
                                        "addVehicle",
                                        this.state.descriptionID
                                      );
                                    }}
                                    checked={
                                      this.state.permission.page_permission
                                        .addVehicle
                                    }
                                  />

                                  <label
                                    className="custom-control-label"
                                    htmlFor="addvehicle"
                                  >
                                    Add Vehicle
                                  </label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="4">
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    id="addBooking"
                                    type="checkbox"
                                    onClick={() => {
                                      this.togglePermissionChange(
                                        "addBooking",
                                        this.state.descriptionID
                                      );
                                    }}
                                    checked={
                                      this.state.permission.page_permission
                                        .addBooking
                                    }
                                  />

                                  <label
                                    className="custom-control-label"
                                    htmlFor="addBooking"
                                  >
                                    Add Booking
                                  </label>
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    id="addServices"
                                    type="checkbox"
                                    onClick={() => {
                                      this.togglePermissionChange(
                                        "addServices",
                                        this.state.descriptionID
                                      );
                                    }}
                                    checked={
                                      this.state.permission.page_permission
                                        .addServices
                                    }
                                  />

                                  <label
                                    className="custom-control-label"
                                    htmlFor="addServices"
                                  >
                                    Add Services
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <hr className="my-4" />

                          {/* Address */}
                          <h4 className="heading-small text-muted mb-4">
                            Permissions to Create, Read, Update, and Delete
                          </h4>
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="4">
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    id="createPermission"
                                    type="checkbox"
                                    onClick={() => {
                                      this.togglePermissionChange(
                                        "createPermission",
                                        this.state.descriptionID
                                      );
                                    }}
                                    checked={
                                      this.state.permission.crud_permission
                                        .createPermission
                                    }
                                  />

                                  <label
                                    className="custom-control-label"
                                    htmlFor="createPermission"
                                  >
                                    Create
                                  </label>
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                  <input
                                    disabled
                                    className="custom-control-input"
                                    id="readPermission"
                                    type="checkbox"
                                    onClick={() => {
                                      this.togglePermissionChange(
                                        "readPermission",
                                        this.state.descriptionID
                                      );
                                    }}
                                    checked={
                                      this.state.permission.crud_permission
                                        .readPermission
                                    }
                                  />

                                  <label
                                    className="custom-control-label"
                                    htmlFor="readPermission"
                                  >
                                    Read
                                  </label>
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    id="updatePermission"
                                    type="checkbox"
                                    onClick={() => {
                                      this.togglePermissionChange(
                                        "updatePermission",
                                        this.state.descriptionID
                                      );
                                    }}
                                    checked={
                                      this.state.permission.crud_permission
                                        .updatePermission
                                    }
                                  />

                                  <label
                                    className="custom-control-label"
                                    htmlFor="updatePermission"
                                  >
                                    Update
                                  </label>
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    id="deletePermission"
                                    type="checkbox"
                                    onClick={() => {
                                      this.togglePermissionChange(
                                        "deletePermission",
                                        this.state.descriptionID
                                      );
                                    }}
                                    checked={
                                      this.state.permission.crud_permission
                                        .deletePermission
                                    }
                                  />

                                  <label
                                    className="custom-control-label"
                                    htmlFor="deletePermission"
                                  >
                                    Delete
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <hr className="my-4" />
                        </>
                      )}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="warning"
                      onClick={() => {
                        this.updatePermission(this.state.descriptionID);
                      }}
                    >
                      UPDATE
                    </Button>
                    <Button color="secondary" onClick={this.toggle}>
                      CLOSE
                    </Button>
                  </ModalFooter>
                </Modal>
              </Card>
            </div>
          </Container>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
