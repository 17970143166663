import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  CardHeader,
  Form,
  Label,
  FormText,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import "../styles/Vehicles.css";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";
import { Edit } from "react-feather";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ConvertArabic from "../../services/ConvertArabic";
import RealTime from "services/RealTime";
import PageLoader from "components/PageLoader/PageLoader";
import paginationFactory from "react-bootstrap-table2-paginator";

var spdetails = [];

var spDetailsCopy = [];

var vehicleList = [];
var LocationList = [];
var serviceDetailsFromDB = null;

// CRUD permission details
var crudPermission;
// super admin ID
var saID = localStorage.getItem("admin_email");
var editVehicle = {};
var spId;
crudPermission = JSON.parse(localStorage.getItem("crudPermissions"));

const vehicleColumns = [
  {
    text: "SL.No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "spid",
    dataField: "sp_id",
    sort: true,
    align: "center",
    hidden: true,

    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Provider",
    dataField: "sp_name",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter({
      style: {
        minWidth: "150px",
        placeholder: "Enter Name ",
      },
    }),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Vehicle Name",
    dataField: "vehicle_name",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter({
      style: {
        minWidth: "150px",
        placeholder: "Enter Emirate ",
      },
    }),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Vehicle Number",
    dataField: "vehicle_id",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter({
      style: {
        minWidth: "150px",
        placeholder: "Enter Emirate ",
      },
    }),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const table3Columns = [
  {
    text: "Area",
    dataField: "area",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Price",
    dataField: "price",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },

  {
    text: "Status",
    dataField: "status",
    sort: true,
    hidden: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
class Vehicles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: true,
      modal: false,
      modalArea: false,
      spid: 0,
      searchBarID: "",
      edit: false,
      addVehicle: false,
      vehicleDetails: "",
      selectedVehicle: "",
      selectedVehicleArea: null,
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };
    this.toggle = this.toggle.bind(this);
    this.toggleArea = this.toggleArea.bind(this);
    this.navigateAddVehicle = this.navigateAddVehicle.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isloaded: false,
    });
    this.getData();
  }
  getData = async () => {
    var VehicleDetails = [];
    var i = 1;
    const spFromDB = await RealTime.getDetails(
      "service_providers/" + localStorage.getItem("sp_id") + "/"
    );
    const vehiclesFromDb = await RealTime.getDetails("vehicles/");

    if (spFromDB) {
      Object.entries(spFromDB.vehicles).forEach(([key, vehicle]) => {
        VehicleDetails.push({
          i: i++,
          sp_id: localStorage.getItem("sp_id"),
          sp_name: spFromDB?.personal_information?.name,
          vehicle_id: key,
          vehicle_name: vehiclesFromDb[key]?.name,
          emirate: spFromDB.emirate,
          vehicle_details: vehiclesFromDb[key],
          vehicle_area_type: vehicle,
        });
      });
      // Object.entries(data).map((sp, key) => {
      //   if (sp[1].vehicles) {
      //     Object.entries(sp[1].vehicles).map((vehicle, key) => {

      //   }
      // });
    }
    this.setState({
      vehicleDetails: VehicleDetails,
      isloaded: true,
    });
    return true;
  };
  // onChange = (stateName, value) => {
  //   this.setState({
  //     [stateName]: value,
  //   });

  //   if (stateName == "searchBarID") {
  //     if (value == "") {
  //       spdetails = spDetailsCopy;
  //     } else {
  //       spdetails = [];
  //       Object.keys(spDetailsCopy).map((line, key) => {
  //         var IDSearch = spDetailsCopy[line].name.toLowerCase();

  //         if (IDSearch.startsWith(value.toLowerCase())) {
  //           spdetails.push(spDetailsCopy[line]);
  //         }
  //       });
  //     }
  //   }
  // };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  toggleArea() {
    this.setState({
      modalArea: !this.state.modalArea,
    });
  }
  getVehicleDetails = (idd) => {
    spId = idd;
    vehicleList = [];
    const dr1 = firebase
      .database()
      .ref("service_providers/" + idd + "/vehicles/");
    dr1.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        var keys = Object.keys(drs);
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          vehicleList.push({
            detail: drs[k],
          });
        }
      }
    });
  };

  getLocationDetails = (number) => {
    LocationList = [];
    const dr1 = firebase
      .database()
      .ref(
        "service_providers/" +
          spId +
          "/vehicles/" +
          number +
          "/serviceable_areas"
      );
    dr1.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        var keys = Object.keys(drs);
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          LocationList.push({
            area: drs[k].area,
            price: drs[k].price,
            status: drs[k].status,
          });
        }
      }
    });
  };
  getServiceSetails = async (number) => {
    serviceDetailsFromDB = null;
    serviceDetailsFromDB = await RealTime.getDetails(
      "service_providers/" + spId + "/vehicles/" + number + "/vehicle_type/"
    );
  };

  navigateAddVehicle = () => {
    this.setState({
      addVehicle: true,
    });
  };
  disableVehicle = (vehicle_id, status, serviceableAreas) => {
    RealTime.updateNode(
      "service_providers/" + spId + "/vehicles/" + vehicle_id,
      {
        status: !status,
      }
    );
    let vehicleCopy = this.state.selectedVehicle;
    vehicleCopy.status = !status;
    serviceableAreas.map((item) => {
      if (status) {
        RealTime.updateNode(
          "/twaddan_admin/emirates/" +
            this.state.emirateSelectedId +
            "/serviceable_areas/" +
            item.area +
            "/service_providers/" +
            spId +
            "/vehicles/" +
            vehicle_id,
          {
            status: false,
          }
        );
      } else {
        RealTime.updateNode(
          "/twaddan_admin/emirates/" +
            this.state.emirateSelectedId +
            "/serviceable_areas/" +
            item.area +
            "/service_providers/" +
            spId +
            "/vehicles/" +
            vehicle_id,
          {
            status: item.status,
          }
        );
      }
    });

    this.setState({ selectedVehicle: vehicleCopy });
  };

  storeCurrentVehicle = (
    name,
    name_ar,
    number,
    description,
    description_ar,
    additionalNote,
    additionalNote_ar,
    helperName,
    helperName_ar,
    helperNumber,
    driver,
    driver_status,
    status,
    image_url,
    qrcode_url
  ) => {
    editVehicle["number"] = number;
    editVehicle["name"] = name;
    editVehicle["name_ar"] = name_ar;
    editVehicle["description"] = description;
    editVehicle["description_ar"] = description_ar;
    editVehicle["additionalNote"] = additionalNote;
    editVehicle["additionalNote_ar"] = additionalNote_ar;
    editVehicle["helperName"] = helperName;
    editVehicle["helperName_ar"] = helperName_ar;
    editVehicle["helperNumber"] = helperNumber;
    editVehicle["driver"] = driver;
    editVehicle["driver_status"] = driver_status;
    editVehicle["status"] = status;
    editVehicle["downloadURL"] = image_url;
    editVehicle["qrcode_url"] = qrcode_url;
    editVehicle["serviceableAreas"] = LocationList;
    this.setState({
      edit: true,
    });
  };
  rowStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex % 2 === 0) {
      style.backgroundColor = "#e6e6e6";
    }
    style.cursor = "pointer";
    return style;
  };
  details = (text, data) => {
    return (
      <>
        <Col xs="5" md="5">
          <p>
            <b>{text}</b>
          </p>
        </Col>
        <Col xs="7" md="7">
          <p>: {data}</p>
        </Col>
      </>
    );
  };
  render() {
    const rowEvents = {
      onClick: async (e, row, rowIndex) => {
        console.log(row.vehicle_details.status);
        let emirates = await RealTime.getDetails("/twaddan_admin/emirates/");
        let emirateSelectedId = "";
        let emirateSelected = null;
        Object.entries(emirates).forEach(([key, emirate]) => {
          if (emirate.service_providers)
            Object.entries(emirate.service_providers).forEach(([key2, sp]) => {
              if (key2 === row.sp_id) {
                this.setState({ emirate: key });
                //console.log("emirate", key);
                emirateSelectedId = key;
                emirateSelected = emirate;
                return emirateSelected;
              }
              //console.log("key2", key2);
            });
        });
        this.setState(
          {
            modal: true,
            selectedVehicle: row.vehicle_details,
            selectedVehicleArea: row.vehicle_area_type.serviceable_areas,
            emirateSelectedId: emirateSelectedId,
            emirateSelected: emirateSelected,
          },
          () => {
            spId = row.sp_id;
          }
        );
      },
    };
    if (this.state.addVehicle) {
      return <Redirect to="/admin/addVehicle" />;
    }
    if (this.state.edit === true) {
      return <EditVehicle />;
    }

    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isloaded}></PageLoader>
        <Container className="mt--7" fluid>
          <Row className="mt-8 mb-5">
            <Col sm={12} md={7} lg={8} xl={9}>
              <h1 className="mb-0">Vehicles</h1>
            </Col>
            <Col sm={6} md={5} lg={4} xl={3}>
              {/* <Button
                block
                color='primary'
                style={{ right: "5px" }}
                onClick={this.navigateAddVehicle}>
                ADD VEHICLE
              </Button> */}
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <BootstrapTable
                keyField="id"
                data={this.state.vehicleDetails}
                columns={vehicleColumns}
                rowStyle={this.rowStyle}
                filter={filterFactory()}
                filterPosition="top"
                pagination={paginationFactory()}
                bordered={false}
                hover
                wrapperClasses="table-responsive"
                condensed
                rowEvents={rowEvents}
              />
            </Col>
          </Row>
        </Container>

        {this.state.selectedVehicle && (
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
            size="md"
          >
            <ModalHeader toggle={this.toggle}>
              <Row>
                <Col lg="10" md="10" sm="10" xs="10">
                  <h1> Vehicle Details</h1>
                </Col>
                <Col lg="2" md="2" sm="2" xs="2">
                  {/* <Button
                    color={
                      this.state.selectedVehicle?.status ? "danger" : "success"
                    }
                    onClick={() => {
                      this.disableVehicle(
                        this.state.selectedVehicle?.number,
                        this.state.selectedVehicle?.status,
                        this.state.selectedVehicle?.serviceable_areas
                      );
                    }}>
                    {this.state.selectedVehicle?.status ? "DISABLE" : "ENABLE"}
                  </Button> */}
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody className="p-0">
              {
                <Row>
                  <Col>
                    <Card>
                      <CardImg
                        top
                        className="img-fluid"
                        src={this.state.selectedVehicle?.image_url}
                        alt="card image cap"
                      />
                      <CardBody className="pb-0">
                        <Row>
                          {this.details(
                            "Name",
                            this.state.selectedVehicle?.name
                          )}
                          {this.details(
                            "",
                            this.state.selectedVehicle?.name_ar
                          )}
                          {this.details(
                            "Description",
                            this.state.selectedVehicle?.description
                          )}
                          {this.details(
                            " ",
                            this.state.selectedVehicle?.description_ar
                          )}
                          {this.details(
                            "Number",
                            this.state.selectedVehicle?.number
                          )}
                          {this.details(
                            "Status",
                            this.state.selectedVehicle?.status
                              ? "Online"
                              : "Offline"
                          )}
                        </Row>
                        <Row>
                          <Col>
                            {this.state.selectedVehicleArea && (
                              <BootstrapTable
                                keyField="id"
                                data={this.state.selectedVehicleArea}
                                columns={table3Columns}
                                filter={filterFactory()}
                                filterPosition="top"
                                rowStyle={this.rowStyle}
                                bordered={false}
                                hover
                                wrapperClasses="table-responsive"
                                condensed
                                // rowEvents={rowEvents}
                              />
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              }
            </ModalBody>
            <ModalFooter className="py-1">
              <a
                href={this.state.selectedVehicle.qrcode_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button color="warning">QR CODE</Button>
              </a>
              {/* <Button
                color='primary'
                onClick={() => {
                  this.getLocationDetails(this.state.selectedVehicle?.number);
                  this.getServiceSetails(this.state.selectedVehicle?.number);
                  this.storeCurrentVehicle(
                    this.state.selectedVehicle?.name,
                    this.state.selectedVehicle?.name_ar
                      ? this.state.selectedVehicle?.name_ar
                      : "",
                    this.state.selectedVehicle?.number,
                    this.state.selectedVehicle?.description,
                    this.state.selectedVehicle?.description_ar
                      ? this.state.selectedVehicle?.description_ar
                      : "",
                    this.state.selectedVehicle?.additionalNote
                      ? this.state.selectedVehicle?.additionalNote
                      : "",
                    this.state.selectedVehicle?.additionalNote_ar
                      ? this.state.selectedVehicle?.additionalNote_ar
                      : "",
                    this.state.selectedVehicle?.helperName
                      ? this.state.selectedVehicle?.helperName
                      : "",
                    this.state.selectedVehicle?.helperName_ar
                      ? this.state.selectedVehicle?.helperName_ar
                      : "",
                    this.state.selectedVehicle?.helperNumber
                      ? this.state.selectedVehicle?.helperNumber
                      : "",
                    this.state.selectedVehicle?.driver,
                    this.state.selectedVehicle?.driver_status
                      ? this.state.selectedVehicle?.driver_status
                      : null,
                    this.state.selectedVehicle?.status
                      ? this.state.selectedVehicle?.status
                      : null,
                    this.state.selectedVehicle?.image_url,
                    this.state.selectedVehicle?.qrcode_url
                  );
                }}>
                <Edit />
              </Button> */}

              <Button color="danger" onClick={this.toggle}>
                CLOSE
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  }
}
var locationEdit = false;
const columns = [
  {
    text: "Vehicle Type",
    dataField: "vehicle_type",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service ID",
    dataField: "service_id",
    footer: false,
    align: "center",
    filter: textFilter(),
    headerAlign: "center",
    sort: true,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
    style: {
      minWidth: "300px",
    },
  },
  {
    text: "Service Name",
    dataField: "service_name",
    footer: false,
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Price",
    dataField: "price",
    footer: false,
    sort: true,
    align: "center",
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
    style: {},
  },
];
const defaultSorted = [
  {
    dataField: "vehicle_type",
    order: "desc",
  },
];
class EditVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: editVehicle["number"],
      name: editVehicle["name"],
      name_ar: editVehicle["name_ar"],
      description: editVehicle["description"],
      description_ar: editVehicle["description_ar"],
      additionalNote: editVehicle["additionalNote"],
      additionalNote_ar: editVehicle["additionalNote_ar"],
      helperName: editVehicle["helperName"],
      helperName_ar: editVehicle["helperName_ar"],
      helperNumber: editVehicle["helperNumber"],
      selected_driver_id: editVehicle["driver"],
      driver: editVehicle["driver"],
      driver_status: editVehicle["driver_status"],
      status: editVehicle["status"],
      downloadURL: editVehicle["downloadURL"],
      qrURL: editVehicle["qrcode_url"],
      file: "",
      imagePreviewUrl: "",
      spemail: "",
      additionalNote: "",
      modal: false,
      qrCodeLink: "",
      disableVehicleField: false,
      edit: true,
      area: "",
      price: "",
      locationDetails: LocationList,
      serviceableAreas: "",
      selected: [],
      selectedId: [],
      selectedAddOn: [],
      selectedIdAddOn: [],
      driver_ids: [],
      previewModal: false,
      map_preview: "",
      map_preview_name: "",
      editToggle: false,
      editArea: "",
      editPrice: "",
    };

    this.toggle = this.toggle.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
    this.toggleBack = this.toggleBack.bind(this);
  }
  getData = async () => {
    this.setState({
      services: null,
      servicesFromDB: null,
      addOnService: null,
      servicesAreasFromDB: null,
      selected: null,
      selectedId: null,
      selectedAddOn: null,
      selectedIdAddOn: null,
      selectedIdStored: null,
      selectedStored: null,
      selectedIdAddOnStored: null,
      selectedAddOnStored: null,
    });
    let servicesFromDB = await RealTime.getDetails(
      "/service_providers/" + spId + "/new_services/"
    );
    let emirates = await RealTime.getDetails("/twaddan_admin/emirates/");

    let emirateSelected = "";
    Object.entries(emirates).forEach(([key, emirate]) => {
      if (emirate.service_providers)
        Object.entries(emirate.service_providers).forEach(([key2, sp]) => {
          if (key2 === spId) {
            this.setState({ emirate: key });
            emirateSelected = key;
            return emirateSelected;
          }
        });
    });
    let drivers = await RealTime.getDetails("drivers");
    let driver_ids = [{ id: "", name: "Select Driver" }];
    if (drivers) {
      Object.entries(drivers).forEach(([key, driver]) => {
        if (
          (driver.personal_information &&
            driver.personal_information.vehicle_number === "" &&
            driver.sp_related === spId) ||
          editVehicle["driver"] === key
        ) {
          driver_ids.push({
            id: key,
            name: driver.personal_information.name,
          });
        }
      });
    }

    let servicesAreasFromDB = await RealTime.getDetails(
      "/twaddan_admin/emirates/" + emirateSelected + "/serviceable_areas"
    );
    let addOnService = [];
    let services = [];
    let selected = [];
    let selectedStored = [];
    let selectedId = [];
    let selectedIdStored = [];
    let selectedAddOn = [];
    let selectedAddOnStored = [];
    let selectedIdAddOn = [];
    let selectedIdAddOnStored = [];
    if (servicesFromDB) {
      Object.entries(servicesFromDB).forEach(([key, vehicle_type]) => {
        if (vehicle_type.major_services)
          Object.entries(vehicle_type.major_services).forEach(
            ([key2, service]) => {
              if (service.status)
                services.push({
                  id: key + "_" + key2,
                  vehicle_type: key,
                  service_id: key2,
                  service_name: service.name,
                  price: service.price,
                });
            }
          );
        if (vehicle_type.add_on_services)
          Object.entries(vehicle_type.add_on_services).forEach(
            ([key2, service]) => {
              if (service.status)
                addOnService.push({
                  id: key + "_" + key2,
                  vehicle_type: key,
                  service_id: key2,
                  service_name: service.name,
                  price: service.price,
                });
            }
          );
      });
    }

    if (serviceDetailsFromDB) {
      Object.entries(serviceDetailsFromDB).forEach(([key, vehicle_type]) => {
        if (vehicle_type.major_services)
          Object.entries(vehicle_type.major_services).forEach(
            ([key2, service]) => {
              selected.push({
                id: key + "_" + key2,
                vehicle_type: key,
                service_id: key2,
                service_name: service.name,
                price: service.price,
              });
              selectedStored.push({
                id: key + "_" + key2,
                vehicle_type: key,
                service_id: key2,
                service_name: service.name,
                price: service.price,
              });
              selectedId.push(key + "_" + key2);
              selectedIdStored.push(key + "_" + key2);
            }
          );
        if (vehicle_type.add_on_services)
          Object.entries(vehicle_type.add_on_services).forEach(
            ([key2, service]) => {
              selectedAddOn.push({
                id: key + "_" + key2,
                vehicle_type: key,
                service_id: key2,
                service_name: service.name,
                price: service.price,
              });
              selectedAddOnStored.push({
                id: key + "_" + key2,
                vehicle_type: key,
                service_id: key2,
                service_name: service.name,
                price: service.price,
              });
              selectedIdAddOn.push(key + "_" + key2);
              selectedIdAddOnStored.push(key + "_" + key2);
            }
          );
      });
    }

    this.setState({
      driver_ids: driver_ids,
      services: services,
      servicesFromDB: servicesFromDB,
      addOnService: addOnService,
      servicesAreasFromDB: servicesAreasFromDB,
      selected: selected,
      selectedId: selectedId,
      selectedAddOn: selectedAddOn,
      selectedIdAddOn: selectedIdAddOn,
      selectedIdStored: selectedIdStored,
      selectedStored: selectedStored,
      selectedIdAddOnStored: selectedIdAddOnStored,
      selectedAddOnStored: selectedAddOnStored,
    });
  };

  async componentDidMount() {
    this.getData();

    this.getServiceableAreas();
  }

  getServiceableAreas = async () => {
    let availableArea = [];
    let vehicle_serviceable_area = [];
    let sp_serviceable_area = [];
    let SpServiceableAreas = await RealTime.getDetails(
      "service_providers/" + spId + "/serviceable_areas"
    );
    sp_serviceable_area = Object.keys(SpServiceableAreas);

    let VehicleServiceableAreas = await RealTime.getDetails(
      "service_providers/" +
        spId +
        "/vehicles/" +
        this.state.number +
        "/serviceable_areas"
    );
    Object.entries(VehicleServiceableAreas).forEach(([key, area]) => {
      vehicle_serviceable_area.push(area.area);
    });

    sp_serviceable_area.map((spArea) => {
      let flag = true;
      vehicle_serviceable_area.map((vhArea) => {
        if (spArea === vhArea) {
          flag = false;
        }
      });
      if (flag) {
        availableArea.push(spArea);
      }
    });
    this.setState({
      serviceableAreas: availableArea,
    });
  };

  selectDriver = (e) => {
    this.setState({ selected_driver_id: e.target.value });
  };
  convert = async () => {
    this.setState(
      {
        name_ar: await ConvertArabic(this.state.name),
        description_ar: await ConvertArabic(this.state.description),
        additionalNote_ar: await ConvertArabic(this.state.additionalNote),
        helperName_ar: await ConvertArabic(this.state.helperName),
      },
      () => {}
    );
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  toggleBack() {
    this.setState({
      edit: !this.state.edit,
    });
  }

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };

  _handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }
  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row],
        selectedId: [...this.state.selectedId, row.id],
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter((x) => x.id !== row.id),
        selectedId: this.state.selectedId.filter((x) => x !== row.id),
      }));
    }
  };
  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    const row = rows.map((r) => r);
    if (isSelect) {
      this.setState(() => ({
        selected: row,
        selectedId: ids,
      }));
    } else {
      this.setState(() => ({
        selected: [],
        selectedId: [],
      }));
    }
  };
  handleOnSelectAddOn = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selectedAddOn: [...this.state.selectedAddOn, row],
        selectedIdAddOn: [...this.state.selectedIdAddOn, row.id],
      }));
    } else {
      this.setState(() => ({
        selectedAddOn: this.state.selectedAddOn.filter((x) => x.id !== row.id),
        selectedIdAddOn: this.state.selectedIdAddOn.filter((x) => x !== row.id),
      }));
    }
  };
  handleOnSelectAllAddOn = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    const row = rows.map((r) => r);
    if (isSelect) {
      this.setState(() => ({
        selectedAddOn: row,
        selectedIdAddOn: ids,
      }));
    } else {
      this.setState(() => ({
        selectedAddOn: [],
        selectedIdAddOn: [],
      }));
    }
  };
  handleProfileSubmit(event) {
    const extension = this.state.file.name.split(".").pop();

    firebase
      .storage()
      .ref(
        "/service_providers/" +
          spId +
          "/vehicles/" +
          "vehicle_" +
          this.state.number +
          "." +
          extension
      )
      .put(this.state.file)
      .then((snapshot) => {
        // Will return a promise with the download link
        return snapshot.ref.getDownloadURL();
      })
      .then((downloadURL) => {
        console.log(
          `Successfully uploaded file and got download link - ${downloadURL}`
        );
        this.setState({
          downloadURL: downloadURL,
        });
        this.toggle();
      })
      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
    event.preventDefault();
  }
  pushData = (event) => {
    let servicesFromDB = this.state.servicesFromDB;
    let driver_assigned = this.state.selected_driver_id !== "";

    let vts = {};
    this.state.selected.map(async (item) => {
      if (!(item.vehicle_type in vts)) {
        vts[item.vehicle_type] = {
          name: servicesFromDB[item.vehicle_type].name,
          name_ar: servicesFromDB[item.vehicle_type].name_ar,
          status: servicesFromDB[item.vehicle_type].status,
          major_services: {
            [item.service_id]:
              servicesFromDB[item.vehicle_type].major_services[item.service_id],
          },
        };
      } else {
        if (vts[item.vehicle_type].major_services)
          if (!(item.service_id in vts[item.vehicle_type].major_services)) {
            vts[item.vehicle_type].major_services[item.service_id] =
              servicesFromDB[item.vehicle_type].major_services[item.service_id];
          }
      }
    });

    // this.state.selectedStored.map(async (item) => {
    //   if (!this.state.selectedId.includes(item.id))

    //   if (!(item.vehicle_type in vts)) {
    //     vts[item.vehicle_type] = {
    //       major_services: {
    //         [item.service_id]:
    //           servicesFromDB[item.vehicle_type].major_services[
    //             item.service_id
    //           ],
    //       },
    //     };
    //   } else {
    //     if (vts[item.vehicle_type].major_services)
    //       if (!(item.service_id in vts[item.vehicle_type].major_services)) {
    //         vts[item.vehicle_type].major_services[item.service_id] =
    //           servicesFromDB[item.vehicle_type].major_services[
    //             item.service_id
    //           ];
    //       }
    //   }
    // });
    this.state.selectedAddOn.map((item) => {
      if (vts[item.vehicle_type])
        if (!("add_on_services" in vts[item.vehicle_type])) {
          vts[item.vehicle_type].add_on_services = {
            [item.service_id]:
              servicesFromDB[item.vehicle_type].add_on_services[
                item.service_id
              ],
          };
        } else {
          vts[item.vehicle_type].add_on_services[item.service_id] =
            servicesFromDB[item.vehicle_type].add_on_services[item.service_id];
        }
    });

    let servicesAreasFromDB = this.state.servicesAreasFromDB;
    this.state.locationDetails.map((location) => {
      Object.entries(servicesAreasFromDB).forEach(([key, locationFromDB]) => {
        if (location.area === key) {
          if (!("service_providers" in locationFromDB)) {
            servicesAreasFromDB[location.area].service_providers = {
              [spId]: {
                status: true,
                vehicles: {
                  [this.state.number]: {
                    status: location.status && driver_assigned,
                    price: location.price,
                  },
                },
              },
            };
          } else {
            if (spId in servicesAreasFromDB[location.area].service_providers) {
              servicesAreasFromDB[location.area].service_providers[
                spId
              ].vehicles[this.state.number] = {
                status: location.status && driver_assigned,
                price: location.price,
              };
            } else {
              servicesAreasFromDB[location.area].service_providers[spId] = {
                status: true,
                vehicles: {
                  [this.state.number]: {
                    status: location.status && driver_assigned,
                    price: location.price,
                  },
                },
              };
            }
          }
        }
      });
    });
    firebase
      .database()
      .ref(
        "/twaddan_admin/emirates/" + this.state.emirate + "/serviceable_areas"
      )
      .update(servicesAreasFromDB);
    firebase
      .database()
      .ref("service_providers/" + spId + "/vehicles/" + this.state.number + "/")
      .update({
        name: this.state.name,
        name_ar: this.state.name_ar,
        number: this.state.number,
        description: this.state.description,
        description_ar: this.state.description_ar,
        additionalNote: this.state.additionalNote,
        additionalNote_ar: this.state.additionalNote_ar,
        helperName: this.state.helperName,
        helperName_ar: this.state.helperName_ar,
        helperNumber: this.state.helperNumber,
        driver: this.state.selected_driver_id,
        status: this.state.status,
        image_url: this.state.downloadURL,
        qrcode_url: this.state.qrURL,
        serviceable_areas: LocationList,
        vehicle_type: vts,
      });

    var spName;
    //updating vehicle number in driver node
    if (this.state.selected_driver_id !== "") {
      RealTime.updateNode(
        "drivers/" + this.state.selected_driver_id + "/personal_information/",
        { vehicle_number: this.state.number }
      );
    } else if (this.state.driver !== "") {
      RealTime.updateNode(
        "drivers/" + this.state.driver + "/personal_information/",
        { vehicle_number: "" }
      );
    }

    firebase
      .database()
      .ref("service_providers/" + spId + "/personal_information/")
      .on("value", function (snapshot) {
        spName = snapshot.val();
        spName = spName.name;
      });
    var time = new Date();
    time = time.getTime();
    var logAction =
      "Added New Vehicle named " +
      this.state.name +
      " with Vehicle Number " +
      this.state.number +
      " under " +
      spName +
      " Service Provider with SP ID " +
      spId;
    var logs = firebase
      .database()
      .ref(
        "/twaddan_admin/super_admin_users/" +
          this.state.saID +
          "/logs/" +
          time +
          "/"
      );
    logs.update({
      action: logAction,
    });
    this.toggleBack();
    event.preventDefault();
  };

  toggleMapPreview = () => {
    this.setState({
      previewModal: !this.state.previewModal,
    });
  };

  handleMapPreview = async (area) => {
    if (area) {
      this.setState({
        map_preview: "",
        map_preview_name: "",
      });
      const emirates = await RealTime.getDetails("twaddan_admin/emirates");
      if (emirates) {
        Object.entries(emirates).map(([key, value]) => {
          if (value.service_providers) {
            Object.keys(value.service_providers).map((value1, key1) => {
              if (value1 === spId) {
                if (value.serviceable_areas) {
                  Object.entries(value.serviceable_areas).map(
                    ([key2, value2]) => {
                      if (key2 === area) {
                        this.setState({
                          map_preview: value2.map_preview,
                          map_preview_name: area,
                          previewModal: true,
                        });
                      }
                    }
                  );
                }
              }
            });
          }
        });
      }
    }
    console.log(this.state.map_preview);
  };

  handleSubmitLocation = (event) => {
    var flag = true;

    LocationList.map((loc) => {
      if (loc.area === this.state.area) {
        flag = false;
      }
    });

    if (this.state.area !== "" && this.state.price !== "") {
      if (flag) {
        LocationList.push({
          area: this.state.area,
          price: this.state.price,
          status: true,
        });
        this.setState({
          locationDetails: LocationList,
        });
      }

      locationEdit = false;
    }
    this.setState({
      area: "",
      price: "",
    });
    event.preventDefault();
  };

  removeLocation = (area) => {
    LocationList.map((loc) => {
      if (loc.area === area) {
        loc.status = !loc.status;
      }
    });

    // var locationDetails = LocationList;
    // const filteredLocation = locationDetails.filter(
    //   (item) => item.area !== area
    // );
    // LocationList = filteredLocation;
    this.setState({
      locationDetails: LocationList,
    });
  };

  editToggle = (location) => {
    this.setState({
      editToggle: !this.state.editToggle,
      editArea: location?.area,
      editPrice: location?.price,
    });
  };

  editLocationPrice = () => {
    LocationList.map((loc) => {
      if (loc.area === this.state.editArea) {
        loc.price = this.state.editPrice;
      }
    });
    this.setState({
      locationDetails: LocationList,
      editToggle: false,
    });
  };

  render() {
    let { driver_ids } = this.state;
    let areaList =
      this.state.serviceableAreas.length > 0 &&
      this.state.serviceableAreas.map((i) => {
        return <option>{i}</option>;
      }, this);

    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selectedId,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };
    const selectRowAddOn = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selectedIdAddOn,
      onSelect: this.handleOnSelectAddOn,
      onSelectAll: this.handleOnSelectAllAddOn,
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }
    if (this.state.edit === false) {
      return <Vehicles />;
    }

    let driver_list =
      driver_ids.length > 0 &&
      driver_ids.map((i) => {
        return <option value={i.id}>{i.name}</option>;
      }, this);

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs={8} sm={6} md={7} lg={8} xl={10}>
                        <h3 className="mb-0">Edit Vehicle Details</h3>
                      </Col>
                      <Col xs={4} sm={6} md={5} lg={4} xl={2}>
                        <Button
                          block
                          color="primary"
                          className="button_back"
                          onClick={this.toggleBack}
                        >
                          BACK
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <CardImg
                      top
                      className="img-fluid"
                      style={{ width: "250px", height: "250px" }}
                      src={this.state.downloadURL}
                      alt="card image cap"
                    />
                    <div className="d-flex justify-content-between">
                      <Button
                        className="mr-4"
                        color="info"
                        href="#pablo"
                        onClick={this.toggle}
                        size="sm"
                      >
                        CHANGE IMAGE
                      </Button>
                    </div>
                    <Form onSubmit={this.pushData}>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-text"
                              >
                                Vehicle Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-number"
                                placeholder="Name"
                                defaultValue={editVehicle["name"]}
                                type="textarea"
                                onChange={(e) =>
                                  this.onChange("name", e.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-text"
                              >
                                Vehicle Name Arabic
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-number"
                                placeholder="Name"
                                defaultValue={editVehicle["name_ar"]}
                                value={this.state.name_ar}
                                type="textarea"
                                onChange={(e) =>
                                  this.onChange("name_ar", e.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-text"
                              >
                                Vehicle Number
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-number"
                                placeholder=" Number"
                                disabled
                                defaultValue={editVehicle["number"]}
                                type="textarea"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-text"
                              >
                                Description
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-number"
                                placeholder="Description"
                                defaultValue={editVehicle["description"]}
                                type="textarea"
                                onChange={(e) =>
                                  this.onChange("description", e.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-text"
                              >
                                Description Arabic
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-number"
                                placeholder="Description Arabic"
                                defaultValue={editVehicle["description_ar"]}
                                type="textarea"
                                value={this.state.description_ar}
                                onChange={(e) =>
                                  this.onChange(
                                    "description_ar",
                                    e.target.value
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-text"
                              >
                                Additional Note
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-number"
                                placeholder="Additional Note"
                                defaultValue={editVehicle["additionalNote"]}
                                type="textarea"
                                onChange={(e) =>
                                  this.onChange(
                                    "additionalNote",
                                    e.target.value
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-text"
                              >
                                Additional Note Arabic
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-number"
                                placeholder="Additional Note Arabic"
                                defaultValue={editVehicle["additionalNote_ar"]}
                                type="textarea"
                                value={this.state.additionalNote_ar}
                                onChange={(e) =>
                                  this.onChange(
                                    "additionalNote_ar",
                                    e.target.value
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <Label for="spid">Select Driver</Label>
                              <Input
                                type="select"
                                name="spid"
                                id="spid"
                                value={this.state.selected_driver_id}
                                onChange={(e) => this.selectDriver(e)}
                              >
                                {driver_list}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-text"
                              >
                                Helper Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-number"
                                placeholder="Helper Name"
                                defaultValue={editVehicle["helperName"]}
                                type="textarea"
                                onChange={(e) =>
                                  this.onChange("helperName", e.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-text"
                              >
                                Helper Name Arabic
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-number"
                                placeholder="Helper Name Arabic"
                                value={this.state.helperName_ar}
                                defaultValue={editVehicle["helperName_ar"]}
                                type="textarea"
                                onChange={(e) =>
                                  this.onChange("helperName_ar", e.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-text"
                              >
                                Helper Number
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-number"
                                placeholder="Helper Number"
                                defaultValue={editVehicle["helperNumber"]}
                                type="textarea"
                                onChange={(e) =>
                                  this.onChange("helperNumber", e.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>

                          <h6 className="heading-small text-muted mb-4">
                            Select Services
                          </h6>
                          <div>
                            {this.state.services && (
                              <BootstrapTable
                                keyField="id"
                                data={this.state.services}
                                columns={columns}
                                filter={filterFactory()}
                                filterPosition="top"
                                //pagination={paginationFactory()}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                selectRow={selectRow}
                                hover
                                wrapperClasses="table-responsive"
                                condensed
                                // rowEvents={rowEvents}
                              />
                            )}
                          </div>
                          <h6 className="heading-small text-muted mb-4">
                            Select Add On Services
                          </h6>
                          <div>
                            {this.state.addOnService && (
                              <BootstrapTable
                                keyField="id"
                                data={this.state.addOnService}
                                columns={columns}
                                filter={filterFactory()}
                                filterPosition="top"
                                //pagination={paginationFactory()}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                selectRow={selectRowAddOn}
                                hover
                                wrapperClasses="table-responsive"
                                condensed
                                // rowEvents={rowEvents}
                              />
                            )}
                          </div>
                          <Col md={4}>
                            <Input
                              type="select"
                              name="spid"
                              id="spid"
                              required
                              value={this.state.area}
                              onChange={(e) => {
                                this.setState({ area: e.target.value });
                              }}
                            >
                              <option hidden>Select Serviceable Area</option>
                              {areaList}
                            </Input>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-credit-card" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  type="number"
                                  name="price"
                                  id="price"
                                  placeholder="Enter price"
                                  value={this.state.price}
                                  onChange={(e) =>
                                    this.onChange("price", e.target.value)
                                  }
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Row>
                                <Col md={6}>
                                  <Button
                                    type="button"
                                    color="primary"
                                    disabled={this.state.area ? false : true}
                                    onClick={() => {
                                      this.handleMapPreview(this.state.area);
                                    }}
                                    block
                                  >
                                    PREVIEW
                                  </Button>
                                </Col>
                                <Col md={6}>
                                  <Button
                                    type="button"
                                    color="success"
                                    onClick={this.handleSubmitLocation}
                                    block
                                  >
                                    {locationEdit ? "UPDATE" : "ADD"}
                                  </Button>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          {this.state.locationDetails.length > 0 && (
                            <Col lg="12">
                              <Table responsive>
                                <thead>
                                  <tr>
                                    <th>Place</th>
                                    <th>Charge</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                {this.state.locationDetails.map((location) => (
                                  <tbody>
                                    <tr>
                                      <td>{location.area}</td>
                                      <td>{location.price}</td>
                                      <td>
                                        <Button
                                          color={
                                            location?.status
                                              ? "danger"
                                              : "success"
                                          }
                                          onClick={() => {
                                            this.removeLocation(location.area);
                                          }}
                                        >
                                          {location?.status
                                            ? "DISABLE"
                                            : "ENABLE"}
                                        </Button>
                                        <Button
                                          color="info"
                                          onClick={() => {
                                            this.editToggle(location);
                                          }}
                                        >
                                          EDIT
                                        </Button>
                                        <Button
                                          type="button"
                                          color="primary"
                                          onClick={() => {
                                            this.handleMapPreview(
                                              location.area
                                            );
                                          }}
                                        >
                                          PREVIEW
                                        </Button>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}{" "}
                              </Table>
                            </Col>
                          )}
                        </Row>
                      </div>
                      <div></div>
                      <br></br>
                      <br></br>
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={4} xl={2}>
                          <Button
                            className="buttonConvert"
                            color="primary"
                            block
                            onClick={this.convert}
                          >
                            CONVERT TO ARABIC
                          </Button>
                        </Col>
                        <Col
                          className="buttonCancelSubmitCol"
                          xs={6}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={2}
                        >
                          <Button block onClick={this.toggleBack}>
                            CANCEL
                          </Button>
                        </Col>
                        <Col
                          className="buttonCancelSubmitCol"
                          xs={6}
                          sm={6}
                          md={3}
                          lg={3}
                          xl={2}
                        >
                          <Button block color="success" value="Submit">
                            UPDATE
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br></br>
            <br></br>
            <br></br>
          </Container>

          <Container className="mt--7" fluid>
            <Row>
              <Card className="shadow">
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className={this.props.className}
                  size="lg"
                >
                  <ModalHeader toggle={this.toggle}>
                    <h1>Update Image Below</h1>
                  </ModalHeader>
                  <ModalBody>
                    <>
                      <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                          <Form onSubmit={this.handleProfileSubmit}>
                            <FormGroup>
                              <Label for="File">Add image</Label>
                              <br></br>
                              <input
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={this._handleImageChange}
                              />
                              <FormText color="muted">
                                Select the Image that you wanted to display.
                              </FormText>
                              <Row>
                                <Col md={4} xs={2}>
                                  <div style={imagestyle}>{$imagePreview}</div>
                                </Col>
                              </Row>
                            </FormGroup>

                            <Button color="primary" value="Submit">
                              UPDATE IMAGE
                            </Button>
                          </Form>
                        </CardBody>
                      </Card>
                    </>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>
                      CANCEL
                    </Button>
                  </ModalFooter>
                </Modal>
              </Card>
            </Row>
            <Modal
              isOpen={this.state.previewModal}
              toggle={this.toggleMapPreview}
              size="lg"
            >
              <ModalHeader toggle={this.toggleMapPreview}>
                {this.state.map_preview_name}
              </ModalHeader>
              <ModalBody>
                <>
                  <iframe
                    width="740"
                    height="400"
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&q=${this.state.map_preview}`}
                    allowfullscreen
                  ></iframe>
                </>
              </ModalBody>
              <ModalFooter>
                <Button onClick={this.toggleMapPreview}>CLOSE</Button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={this.state.editToggle} toggle={this.editToggle}>
              <ModalHeader toggle={this.editToggle}>
                <h2>{this.state.editArea}</h2>
              </ModalHeader>
              <ModalBody>
                <Label>Enter charge for {this.state.editArea}</Label>
                <Input
                  defaultValue={this.state.editPrice}
                  value={this.state.editPrice}
                  placeholder="Enter Price"
                  onChange={(e) => {
                    this.setState({
                      editPrice: e.target.value,
                    });
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button onClick={this.editToggle}>CANCEL</Button>
                <Button onClick={this.editLocationPrice}>UPDATE</Button>
              </ModalFooter>
            </Modal>
          </Container>
        </Container>
      </>
    );
  }
}

export default Vehicles;
