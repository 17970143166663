import React, { useState } from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
  CardBody,
  CardImg,
  CardHeader,
  Form,
  Input,
  FormText,
  FormGroup,
  Label,
} from "reactstrap";
import { Zap, Trash, Delete } from "react-feather";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import StatisticsCard from "../../components/statisticsCard/StatisticsCard";
import { Redirect } from "react-router-dom";
import RealTime from "services/RealTime";

var spdetails = [];

var spDetailsCopy = [];

var spid = "";
var CouponId = "";

// CRUD permission details
var crudPermission = JSON.parse(localStorage.getItem("crudPermissions"));

// super admin ID
var saID = localStorage.getItem("admin_email");

class Offers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: false,
      modal: false,
      modals: false,
      spid: "",
      offer: [],
      searchBarID: "",
      CouponId: "",
    };
    this.toggle = this.toggle.bind(this);
    this.toggles = this.toggles.bind(this);
    this.navigateAddDiscount = this.navigateAddDiscount.bind(this);
  }

  async componentDidMount() {
    const data = await RealTime.getDetails("service_providers/");
    this.getData(data);
  }
  getData = (drs) => {
    spdetails = [];

    if (drs != null) {
      var keys = Object.keys(drs);
      for (var dr = 0; dr < keys.length; dr++) {
        var k = keys[dr];
        if (drs[k].offers) {
          spdetails.push({
            offers: Object.keys(drs[k].offers).length,
            name: drs[k].personal_information.name,
            id: k,
          });
        } else {
          spdetails.push({
            offers: 0,
            name: drs[k].personal_information.name,
            id: k,
          });
        }
      }
    }

    spDetailsCopy = spdetails;
    this.setState({
      isloaded: true,
    });
    console.log(spdetails);
  };
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });

    if (stateName == "searchBarID") {
      if (value == "") {
        spdetails = spDetailsCopy;
      } else {
        spdetails = [];
        Object.keys(spDetailsCopy).map((line, key) => {
          var IDSearch = spDetailsCopy[line].name.toLowerCase();

          if (IDSearch.startsWith(value.toLowerCase())) {
            spdetails.push(spDetailsCopy[line]);
          }
        });
      }
    }
  };

  getofferDetails = (id) => {
    let offers = [];
    console.log(id);
    const dr1 = firebase.database().ref("service_providers/" + id + "/offers/");
    dr1.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        var key = Object.keys(drs);
        for (var dr = 0; dr < key.length; dr++) {
          var k = key[dr];
          offers.push({
            description: drs[k].description,
            image: drs[k].image,
            min_cart_value: drs[k].min_cart_value,
            percentage: drs[k].percentage,
            promo_code: drs[k].promo_code,
            terms_and_conditions: drs[k].terms_and_conditions,
            id: k,
          });
        }
      }
      this.setState({
        offer: offers,
        modal: true,
      });
    });
  };
  navigate = () => {
    this.setState({
      AddDiscount: 1,
    });
  };

  deleteCode = () => {
    console.log(this.state.spid);

    console.log(this.state.CouponId);
    var img = this.state.CouponId + ".png";
    // Create a reference to the file to delete
    var storageRef = firebase.storage().ref();
    var desertRef = storageRef.child(
      "/service_provider/" + this.state.spid + "/offers/" + img
    );

    // Delete the file
    desertRef
      .delete()
      .then(function () {
        // File deleted successfully
      })
      .catch(function (error) {
        // Uh-oh, an error occurred!
      });
    let userRef = firebase
      .database()
      .ref(
        "service_providers/" +
          this.state.spid +
          "/offers/" +
          this.state.CouponId
      );
    userRef.remove();
    this.toggle();
  };
  navigate = () => {
    this.setState({
      addDiscount: 1,
    });
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  toggles() {
    this.setState({
      modals: !this.state.modals,
    });
  }
  navigateAddDiscount = () => {
    this.setState({
      addoffer: 1,
    });
  };

  render() {
    if (this.state.addoffer === 1) {
      return <Redirect to='/admin/addDiscount' />;
    }

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <div className='h5 mt-8'>
            <h1>Promo Code</h1>

            <Row className='mt-5'>
              {
                <div className='col text-right'>
                  <Button color='danger' onClick={this.navigateAddDiscount}>
                    ADD NEW COUPON
                  </Button>
                </div>
              }
            </Row>
            <br></br>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    className='form-control-alternative'
                    defaultValue={this.state.searchBarID}
                    id='input-price'
                    placeholder='Search'
                    type='text'
                    onChange={(e) =>
                      this.onChange("searchBarID", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            {spdetails.map((i) => {
              return (
                <div>
                  <h2>{i.name}</h2>
                  <br></br>
                  <Row>
                    <Col lg='3' md='6' sm='12'>
                      <StatisticsCard
                        hideChart
                        iconBg='success'
                        icon={<Zap className='' size={30} />}
                        stat={i.offers}
                        statTitle='Total Coupons'
                      />
                    </Col>

                    <Col lg='3' md='6' sm='12'>
                      <Button
                        color='info'
                        onClick={() => {
                          this.setState({ spid: [i.id] });
                          this.getofferDetails(i.id);
                        }}
                        style={{ position: "absolute", top: "50%" }}>
                        SHOW DETAILS
                      </Button>
                    </Col>
                  </Row>
                  <br></br>
                  <br></br>
                </div>
              );
            })}
          </div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
            size='lg'>
            <ModalHeader toggle={this.toggle}>
              <h1>Coupons</h1>
            </ModalHeader>
            <ModalBody>
              {
                <Row>
                  {this.state.offer.map((i) => {
                    return (
                      <Col sm='5'>
                        <Card
                          style={{
                            minHeight: "300",
                          }}>
                          <CardImg
                            top
                            className='img-fluid'
                            src={i.image}
                            style={{
                              height: "250px",
                              width: "400px",
                            }}
                            alt='card image cap'
                          />
                          <CardBody>
                            <h4>{i.promo_code}</h4>

                            <p className='mb-0'>Percentage: {i.percentage}</p>

                            <p className='mb-0'>Description: {i.description}</p>
                            <p className='mb-0'>
                              Minimum Cart Value: {i.min_cart_value}
                            </p>
                            <p className='mb-0'>
                              T n C: {i.terms_and_conditions}
                            </p>
                            <Button
                              color='danger'
                              style={{
                                position: "absolute",
                                bottom: "10px",
                                right: "10px",
                              }}
                              onClick={() =>
                                this.setState({
                                  CouponId: i.id,
                                  modals: true,
                                })
                              }>
                              <Delete />
                            </Button>

                            <br></br>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              }
              <Modal isOpen={this.state.modals} toggle={this.toggles}>
                <ModalBody>Do you want to delete</ModalBody>
                <ModalFooter>
                  <Button color='info' onClick={this.toggles}>
                    N0
                  </Button>
                  <Button color='danger' onClick={this.deleteCode}>
                    YES
                  </Button>
                </ModalFooter>
              </Modal>
            </ModalBody>
            <ModalFooter>
              <Button color='secondary' onClick={this.toggle}>
                CLOSE
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </>
    );
  }
}
export default Offers;
