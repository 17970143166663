import React from "react";
import firebase from "firebaseConfig";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.jsx";
import { textSpanIntersectsWithPosition } from "typescript";
import RealTime from "services/RealTime";

// sp details in firebase Auth
var adminData;

// super admin logged in user id
var superAdminID;
var superAdminDetails = firebase.auth().currentUser;
// firebase.auth().onAuthStateChanged(function (user) {
//   if (user) {
//     // User is signed in.
//     console.log("The current user: ", user.email);
//     superAdminDetails = user;

//     superAdminID = superAdminDetails.email;
//     superAdminID = superAdminID.replace("@", "_");
//     superAdminID = superAdminID.replace(".", "_");

//     firebase
//       .database()
//       .ref(
//         "/twaddan_admin/super_admin_users/" +
//           superAdminID +
//           "/personal_information/"
//       )
//       .on("value", function (snapshot) {
//         adminData = snapshot.val() || "Anonymous";
//       });
//   }
// });
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: localStorage.getItem("admin_name"),
      email: localStorage.getItem("admin_email"),
      number: "",
      aboutUs: "",
      modal: false,
      changePassword: "",
      changeRetypePassword: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  async componentDidMount() {
    const data = await RealTime.getDetails(
      `/twaddan_admin/super_admin_users/${this.state.email}/personal_information/`
    );

    this.setState({
      number: data.phone_number,
      aboutUs: data.description,
    });
  }
  changePassword() {
    const password = this.state.changePassword;
    firebase
      .auth()
      .currentUser.updatePassword(password)
      .then(function () {
        var message = "Password Changed Successfully";
        alert(message);

        var time = new Date();
        time = time.getTime();
        var logAction =
          "Super Admin User " +
          this.state.username +
          " with Super Admin User ID " +
          superAdminID +
          " Changed Account Password";
        var logs = firebase
          .database()
          .ref(
            "/twaddan_admin/super_admin_users/" +
              superAdminID +
              "/logs/" +
              time +
              "/"
          );
        logs.update({
          action: logAction,
        });
      })
      .catch(function (error) {
        alert(error);
      });
  }

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };

  pushData = () => {
    console.log(superAdminID);
    firebase
      .database()
      .ref(
        "/twaddan_admin/super_admin_users/" +
          superAdminID +
          "/personal_information/"
      )
      .update({
        name: this.state.username,
        email: this.state.email,
        phone_number: this.state.number,
        description: this.state.aboutUs,
      });

    var time = new Date();
    time = time.getTime();
    var logAction =
      "Super Admin User " +
      this.state.username +
      " with Super Admin User ID " +
      superAdminID +
      " Edited the Profile";
    var logs = firebase
      .database()
      .ref(
        "/twaddan_admin/super_admin_users/" +
          superAdminID +
          "/logs/" +
          time +
          "/"
      );
    logs.update({
      action: logAction,
    });
  };

  // uploadImage=()=>{
  //   console.log("file: ",this.state.file,"name: ",this.state.file.name);
  //   const extension = this.state.file.name.split('.').pop();

  //   firebase.storage().ref("/service_provider/"+spID+"/personal_information/"+"profile_"+spData.personal_information.name+"."+extension).put(this.state.file)
  //   .then(snapshot => {
  //       // Will return a promise with the download link
  //       return snapshot.ref.getDownloadURL();
  //   }).then(downloadURL => {
  //     console.log(`Successfully uploaded file and got download link - ${downloadURL}`);
  //     this.setState({
  //       downloadURL:downloadURL,
  //     })
  //     firebase.database().ref("service_providers/"+spID+"/personal_information/").update({
  //       image: this.state.downloadURL
  //     })

  //   }).catch(error => {
  //       // Use to signal error if something goes wrong.
  //       console.log(`Failed to upload file and get link - ${error}`);
  //   });
  // }

  // _handleImageChange(e) {
  //   e.preventDefault();

  //   let reader = new FileReader();
  //   let file = e.target.files[0];

  //   reader.onloadend = () => {
  //     this.setState({
  //       file: file,
  //       imagePreviewUrl: reader.result
  //     });
  //   }

  //   reader.readAsDataURL(file)
  // }

  // handleProfileSubmit(event) {
  //   this.uploadImage();
  //   event.preventDefault();
  //   this.toggle();
  // }

  handleSubmit(event) {
    this.pushData();
    this.toggle();
    event.preventDefault();
  }

  render() {
    const { changePassword, changeRetypePassword } = this.state;
    const isInvalid =
      changePassword.length <= 0 || changePassword != changeRetypePassword;
    const incorrectRetypePassword =
      changeRetypePassword.length > 0 && changePassword != changeRetypePassword;

    const passwordLength_LT6 =
      changePassword.length > 0 && changePassword.length < 6;
    const passwordLength_GT6_LT9 =
      changePassword.length >= 6 && changePassword.length < 9;
    const passwordLength_GT9 = changePassword.length >= 9;

    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <Container className='mt--7' fluid>
            <Row>
              <Col className='order-xl-1' xl='8'>
                <Card className='bg-secondary shadow'>
                  <CardHeader className='bg-white border-0'>
                    <Row className='align-items-center'>
                      <Col xs='8'>
                        <h3 className='mb-0'>My account</h3>
                      </Col>
                      <Col className='text-right' xs='4'></Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <h6 className='heading-small text-muted mb-4'>
                        User information
                      </h6>
                      <div className='pl-lg-4'>
                        <Row>
                          <Col lg='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-username'>
                                Admin Details
                              </label>
                              <Input
                                className='form-control-alternative'
                                defaultValue={this.state.username}
                                id='input-username'
                                placeholder='Username'
                                type='text'
                                onChange={(e) =>
                                  this.onChange("username", e.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-email'>
                                Email address
                              </label>
                              <Input
                                disabled
                                className='form-control-alternative'
                                id='input-email'
                                placeholder={this.state.email}
                                type='email'
                                onChange={(e) =>
                                  this.onChange("email", e.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className='my-4' />
                      {/* Address */}
                      <h6 className='heading-small text-muted mb-4'>
                        Contact information
                      </h6>
                      <div className='pl-lg-4'>
                        <Row>
                          <Col md='12'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-address'>
                                Number
                              </label>
                              <Input
                                disabled
                                className='form-control-alternative'
                                defaultValue={this.state.number}
                                id='input-address'
                                placeholder='Number'
                                type='text'
                                onChange={(e) =>
                                  this.onChange("number", e.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className='my-4' />
                      {/* Description */}
                      <h6 className='heading-small text-muted mb-4'>
                        About us
                      </h6>
                      <div className='pl-lg-4'>
                        <FormGroup>
                          {/* <label>About Us</label> */}
                          <Input
                            className='form-control-alternative'
                            placeholder='A few words about your company ...'
                            rows='4'
                            defaultValue={this.state.aboutUs}
                            type='textarea'
                            onChange={(e) =>
                              this.onChange("aboutUs", e.target.value)
                            }
                          />
                        </FormGroup>

                        <Button color='primary' value='Submit'>
                          UPDATE PROFILE
                        </Button>
                      </div>
                      <hr className='my-4' />
                      {/* Address */}
                      <h6 className='heading-small text-muted mb-4'>
                        Change Password
                      </h6>
                      <div className='pl-lg-4'>
                        <Row>
                          <Col md='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-address'>
                                Password
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input-address'
                                placeholder='Enter Password'
                                type='password'
                                onChange={(e) =>
                                  this.onChange(
                                    "changePassword",
                                    e.target.value
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-city'>
                                Re-Type Password
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input-city'
                                placeholder='Retype Password'
                                type='password'
                                onChange={(e) =>
                                  this.onChange(
                                    "changeRetypePassword",
                                    e.target.value
                                  )
                                }
                              />
                            </FormGroup>

                            <div className='text-muted font-italic'>
                              {passwordLength_LT6 && (
                                <small>
                                  {" "}
                                  password strength:{" "}
                                  <span className='text-danger font-weight-700'>
                                    Low
                                  </span>{" "}
                                </small>
                              )}
                              {passwordLength_GT6_LT9 && (
                                <small>
                                  {" "}
                                  password strength:{" "}
                                  <span className='text-warning font-weight-700'>
                                    Medium
                                  </span>{" "}
                                </small>
                              )}
                              {passwordLength_GT9 && (
                                <small>
                                  {" "}
                                  password strength:{" "}
                                  <span className='text-success font-weight-700'>
                                    High
                                  </span>{" "}
                                </small>
                              )}

                              <div>
                                <small>
                                  {incorrectRetypePassword && (
                                    <span className='text-danger font-weight-700'>
                                      Password Mismatch
                                    </span>
                                  )}
                                </small>
                              </div>
                            </div>
                            <br />
                          </Col>
                        </Row>
                        <Button
                          color='primary'
                          disabled={isInvalid}
                          onClick={() => this.changePassword()}>
                          CHANGE PASSWORD
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br></br>
            <br></br>
            <br></br>
          </Container>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalBody>Congratulations update Successful</ModalBody>
            <ModalFooter>
              <Button color='primary' onClick={this.toggle}>
                CLOSE
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </>
    );
  }
}

export default Profile;
