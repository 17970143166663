import React, { Component } from "react";

// reactstrap components
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Container,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  CardBody,
} from "reactstrap";

import {
  InfoWindow,
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { Multiselect } from "multiselect-react-dropdown";
import Geocode from "react-geocode";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";

var spid = "";

var lat = 12.9716;
var lng = 77.5946;

var finaladdress = "";

Geocode.setApiKey("AIzaSyC73FXvsbaH0gxh1lHY5CUjpAfmIGFx7KQ");
Geocode.enableDebug();

// super admin logged in user id
var saID = "superadmin_twaddan_com";
// super admin logged in name
var saName;

// firebase.auth().onAuthStateChanged(function (user) {
//   if (user) {
//     // User is signed in.
//     console.log("The current user: ", user.email);

//     saID = user.email;
//     saID = saID.replace("@", "_");
//     saID = saID.replace(".", "_");

//     firebase
//       .database()
//       .ref("/twaddan_admin/super_admin_users/" + saID + "/")
//       .on("value", function (snapshot) {
//         saName = snapshot.val();
//         saName = saName.personal_information.name;
//       });
//   }
// });

export default class NewBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spidd: "",
      sparray: [],
      booking: 0,
      order: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  handleSubmit(event) {
    console.log(this.state.spidd);
    this.storeCurrentSpid();
    event.preventDefault();
  }
  getData = () => {
    const dr = firebase.database().ref("service_providers/");
    let newService = [];
    newService.push({
      name: "select",
    });
    dr.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        var keys = Object.keys(drs);
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          newService.push({
            name: drs[k].personal_information.name,
            id: k,
          });
        }
      }
      this.setState({
        sparray: newService,
      });
    });
  };
  storeCurrentSpid = () => {
    this.state.sparray.map((i) => {
      if (i.name === this.state.spidd) {
        spid = i.id;
        this.setState({
          booking: 1,
        });
        console.log(spid);
      }
    });
  };
  navigate = () => {
    this.setState({
      order: 1,
    });
  };
  render() {
    const { sparray } = this.state;

    let sparrayList =
      sparray.length > 0 &&
      sparray.map((i) => {
        return <option>{i.name}</option>;
      }, this);
    if (this.state.booking === 1) {
      return <Booking />;
    }
    if (this.state.order === 1) {
      return <Redirect to='/admin/orders' />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <Card>
            <CardHeader>
              <CardTitle>New Booking</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                <Row form>
                  <Col md={8}>
                    <FormGroup>
                      <Label for='spid'>Select Service Provider</Label>
                      <Input
                        type='select'
                        name='spid'
                        id='spid'
                        value={this.state.spidd}
                        onChange={(e) => {
                          this.setState({ spidd: e.target.value });
                        }}>
                        {sparrayList}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Button onClick={this.navigate}>CANCEL</Button>
                <Button color='success' value='Submit'>
                  NEXT
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      area: "",
      state: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
    };
  }
  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    Geocode.fromLatLng(
      this.state.mapPosition.lat,
      this.state.mapPosition.lng
    ).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);
        finaladdress = address;
        console.log("city", city, area, state);

        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.markerPosition.lat !== this.props.center.lat ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state
    ) {
      return true;
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false;
    }
  }
  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {};

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    lat = newLat;
    lng = newLng;

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);
        finaladdress = address;
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  // onPlaceSelected = ( place ) => {
  // 	console.log( 'plc', place );
  // 	const address = place.formatted_address,
  // 	      addressArray =  place.address_components,
  // 	      city = this.getCity( addressArray ),
  // 	      area = this.getArea( addressArray ),
  // 	      state = this.getState( addressArray ),
  // 	      latValue = place.geometry.location.lat(),
  // 	      lngValue = place.geometry.location.lng();
  // 	// Set these values in the state.
  // 	this.setState({
  // 		address: ( address ) ? address : '',
  // 		area: ( area ) ? area : '',
  // 		city: ( city ) ? city : '',
  // 		state: ( state ) ? state : '',
  // 		markerPosition: {
  // 			lat: latValue,
  // 			lng: lngValue
  // 		},
  // 		mapPosition: {
  // 			lat: latValue,
  // 			lng: lngValue
  // 		},
  // 	})
  // };

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng,
          }}>
          {/* InfoWindow on top of marker */}
          <InfoWindow
            onClose={this.onInfoWindowClose}
            position={{
              lat: this.state.markerPosition.lat + 0.0018,
              lng: this.state.markerPosition.lng,
            }}>
            <div>
              <span style={{ padding: 0, margin: 0 }}>
                {this.state.address}
              </span>
            </div>
          </InfoWindow>
          {/*Marker*/}
          <Marker
            google={this.props.google}
            name={"Dolores park"}
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{
              lat: this.state.markerPosition.lat,
              lng: this.state.markerPosition.lng,
            }}
          />
          <Marker />
          {/* For Auto complete Search Box */}
        </GoogleMap>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>
          <div>
            {/* <div className="form-group">
            <label htmlFor="">City</label>
            <input type="text" name="city" className="form-control" onChange={ this.onChange } readOnly="readOnly" value={ this.state.city }/>
          </div>
          <div className="form-group">
            <label htmlFor="">Area</label>
            <input type="text" name="area" className="form-control" onChange={ this.onChange } readOnly="readOnly" value={ this.state.area }/>
          </div>
          <div className="form-group">
            <label htmlFor="">State</label>
            <input type="text" name="state" className="form-control" onChange={ this.onChange } readOnly="readOnly" value={ this.state.state }/>
          </div> */}
            <div className='form-group'>
              <label htmlFor=''>Address</label>
              <input
                type='text'
                name='address'
                className='form-control'
                onChange={this.onChange}
                readOnly='readOnly'
                value={this.state.address}
              />
            </div>
          </div>

          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC73FXvsbaH0gxh1lHY5CUjpAfmIGFx7KQ&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}

class Booking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      carType: "bike",
      vehicleNumber: "",
      driver: "",
      driver_id: "",
      mobileNumber: "",
      note: "",
      detailService: [],
      detailaddon: [],
      driverIdList: [],
      cararray: [],
      offerarray: [],
      offer: "",
      driverarray: [],
      services: [],
      addon: [],
      totalAmount: 0,
      totalDue: 0,
      discount: 0,
      options: [],
      selectedService: [],
      selectedAddOn: [],
      email: "",
      timeRequired: 0,
      spname: "",
      spemail: spid,
      modal: false,
      isMarkerShown: false,
      appartment: "",
      landmark: "",
      offermodal: false,
    };
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSelect1 = this.onSelect1.bind(this);
    this.onRemove1 = this.onRemove1.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.offertoggle = this.offertoggle.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getcartype();
    this.getaddon();
    this.getspname();
    this.getOffers();
    //  this.getservice('bike');
  }

  getData = () => {
    const spp = firebase
      .database()
      .ref(
        "service_providers/" +
          this.state.spemail +
          "/drivers_info/active_drivers_id"
      );
    console.log(spp);
    spp.on("value", (snapshot) => {
      let sps = snapshot.val();
      let newState = [];
      for (let spp in sps) {
        newState.push({
          id: spp,
        });
      }
      this.setState({
        driverIdList: newState,
      });
      const dr = firebase.database().ref("drivers/");
      dr.on("value", (snapshot) => {
        var drs = snapshot.val();
        var keys = Object.keys(drs);
        console.log(drs);
        let newDriver = [];
        newDriver.push({
          name: "select",
          id: "",
          vehicle_number: 0,
        });
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          newState.map((i) => {
            if (i["id"] == k && drs[k].status == 1) {
              newDriver.push({
                name: drs[k].personal_information.name,
                id: k,
                vehicle_number: drs[k].personal_information.vehicle_number,
              });
            }
          });
        }
        this.setState({
          driverarray: newDriver,
        });
      });
    });
  };
  // getdriver=()=>{
  //   const dr =firebase.database().ref('drivers/');
  //   dr.on('value',(snapshot)=>{
  //     var drs =snapshot.val();
  //     var keys =Object.keys(drs);
  //     console.log(drs);
  //     let newDriver =[];
  //     newDriver.push({
  //       name: "select",
  //       id:""
  //     });
  //     for(var dr = 0 ;dr<keys.length;dr++){
  //       var k=keys[dr];
  //       this.state.driverIdList.map((i) =>{
  //         if(i['id']==k&&drs[k].status==1)
  //         {
  //           newDriver.push({
  //           name: drs[k].personal_information.name,
  //           id:k
  //         });
  //         }
  //       })
  //     }
  //     this.setState({
  //       driverarray:newDriver
  //   });
  //   })
  // }
  getspname = () => {
    const spp = firebase
      .database()
      .ref(
        "service_providers/" + this.state.spemail + "/personal_information/"
      );
    spp.on("value", (snapshot) => {
      let sps = snapshot.val();
      this.setState({
        spname: sps.name,
      });
    });
  };
  getcartype = () => {
    var z = 0;
    const dr = firebase
      .database()
      .ref("service_providers/" + this.state.spemail + "/services/");
    dr.on("value", (snapshot) => {
      var drs = snapshot.val();
      var keys = Object.keys(drs);
      let newService = [];
      // newService.push({
      //   name: "select",
      // });
      for (var dr = 0; dr < keys.length; dr++) {
        var k = keys[dr];
        if (z === 0) {
          this.getservice(k);
        }
        z++;
        newService.push({
          name: k,
        });
      }
      this.setState({
        cararray: newService,
      });
    });
  };
  getOffers = () => {
    const dr = firebase
      .database()
      .ref("service_providers/" + this.state.spemail + "/offers/");
    let newService = [];
    newService.push({
      name: "select",
    });
    dr.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        var keys = Object.keys(drs);
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          newService.push({
            name: drs[k].promo_code,
            percentage: Number(drs[k].percentage),
            min_cart_value: Number(drs[k].min_cart_value),
          });
        }
      }
      this.setState({
        offerarray: newService,
      });
    });
  };
  applyOffer = (code) => {
    this.state.offerarray.map((i) => {
      if (i.name === code) {
        if (Number(this.state.totalAmount) >= i.min_cart_value) {
          var discount1 = (this.state.totalAmount * i.percentage) / 100;
          this.setState({
            discount: discount1,
            totalDue: this.state.totalAmount - discount1,
          });
          this.offertoggle();
        }
      }
    });
    if (code === "select") {
      this.setState({
        totalDue: this.state.totalAmount,
        discount: 0,
      });
    }
  };
  getservice = (cartype) => {
    console.log(cartype);
    const dr = firebase
      .database()
      .ref(
        "service_providers/" + this.state.spemail + "/services/" + cartype + "/"
      );
    dr.on("value", (snapshot) => {
      var drs = snapshot.val();
      this.setState({
        detailService: drs,
      });
      var keys = Object.keys(drs);
      console.log(drs);
      let newService = [];
      for (var dr = 0; dr < keys.length; dr++) {
        var k = keys[dr];
        if (drs[k].status === true) {
          newService.push({
            name: drs[k].name,
            id: drs[k].price,
            time: drs[k].time_required,
          });
        }
      }
      this.setState({
        options: newService,
      });
    });
  };
  getaddon = () => {
    const dr = firebase
      .database()
      .ref("service_providers/" + this.state.spemail + "/add_on_services");
    dr.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        this.setState({
          detailaddon: drs,
        });
        var keys = Object.keys(drs);
        console.log(drs);
        let newService = [];
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          newService.push({
            name: drs[k].name,
            id: drs[k].price,
          });
        }
        this.setState({
          addon: newService,
        });
      }
    });
  };
  onSelect(selectedList, selectedItem) {
    console.log(selectedItem.time);
    this.setState({
      totalAmount: this.state.totalAmount + parseInt(selectedItem.id),
      totalDue: this.state.totalAmount + parseInt(selectedItem.id),
      timeRequired: this.state.timeRequired + parseInt(selectedItem.time),
      offer: "select",
      discount: 0,
      selectedService: selectedList,
    });
  }

  onRemove(selectedList, removedItem) {
    this.setState({
      totalAmount: this.state.totalAmount - parseInt(removedItem.id),
      totalDue: this.state.totalAmount - parseInt(removedItem.id),
      timeRequired: this.state.timeRequired - parseInt(removedItem.time),
      offer: "select",
      discount: 0,
      selectedService: selectedList,
    });
  }
  onSelect1(selectedList, selectedItem) {
    console.log(selectedItem.id);
    this.setState({
      totalAmount: this.state.totalAmount + parseInt(selectedItem.id),
      totalDue: this.state.totalAmount + parseInt(selectedItem.id),
      offer: "select",
      discount: 0,
      selectedAddOn: selectedList,
    });
  }

  onRemove1(selectedList, removedItem) {
    this.setState({
      totalAmount: this.state.totalAmount + -parseInt(removedItem.id),
      totalDue: this.state.totalAmount - parseInt(removedItem.id),
      offer: "select",
      discount: 0,
      selectedAddOn: selectedList,
    });
  }
  pushdata = () => {
    var ref = firebase.database().ref("orders/all_orders/");
    var d = new Date();
    var timeStamp = d.getTime();
    var email_id = this.state.email.replace("@", "_");
    var email_idd = email_id.replace(".", "_");
    var id = timeStamp.toString() + " & " + email_idd.toString();
    var ser = this.state.carType + " & " + this.state.vehicleNumber;
    var finalservice = {};
    var finaladdon = {};
    var driverid = "";
    var Vehicle = "";
    this.state.driverarray.map((j) => {
      console.log(j.name);
      if (j.name + " " + j.id == this.state.driver) {
        driverid = j.id;
        Vehicle = j.vehicle_number;
      }
    });
    this.state.selectedAddOn.map((i) => {
      var keys = Object.keys(this.state.detailaddon);
      for (var j = 0; j < keys.length; j++) {
        var k = keys[j];
        console.log(this.state.detailaddon[k]);
        if (i.name == this.state.detailaddon[k].name) {
          finaladdon[this.state.detailaddon[k].name] = this.state.detailaddon[
            k
          ];
        }
      }
    });
    this.state.selectedService.map((i) => {
      console.log(i.name);
      var keys = Object.keys(this.state.detailService);
      for (var j = 0; j < keys.length; j++) {
        var k = keys[j];
        console.log(this.state.detailService[k]);
        if (i.name == this.state.detailService[k].name) {
          finalservice[
            this.state.detailService[k].name
          ] = this.state.detailService[k];
          // finaladdon.push({
          //   [k]:this.state.detailService[k]
          // })
        }
      }
    });
    console.log(this.state.finalservice);
    ref.child(id).set({
      customer_id: email_idd,
      customer_gmail_id: this.state.email,
      customer_latitude: lat,
      customer_longitude: lng,
      customer_mobile_number: Number("91" + this.state.mobileNumber),
      customer_name: this.state.firstName + " " + this.state.lastName,
      customer_raw_address: finaladdress,
      driver_id: driverid,
      number_of_services: 1,
      order_id_number: timeStamp + " & " + email_idd,
      payment_mode: 0,
      rating: 0,
      review: "",
      services: {
        [ser]: {
          services: finalservice,
          add_on_services: finaladdon,
        },
      },
      sp_id: this.state.spemail,
      sp_name: this.state.spname,
      status: 0,
      time_order_placed: timeStamp,
      total_number_of_vehicles: 1,
      total_price_of_order: this.state.totalAmount,
      vehicle_number: Vehicle,
      total_time: this.state.timeRequired,
      total_discount: this.state.discount,
      total_due: this.state.totalDue,
      order_placed_from: "web",
      appartment: this.state.appartment,
      landmark: this.state.landmark,
    });

    var time = new Date();
    time = time.getTime();
    var logAction = "Booked Service order with Order ID : " + id;
    var logs = firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/logs/" + time + "/");
    logs.update({
      action: logAction,
    });

    var db = firebase.database().ref("drivers/" + driverid + "/new_orders/");
    db.update({
      [id]: timeStamp + " & " + email_idd,
    });
    this.toggle();
  };
  handleSubmit(event) {
    console.log(this.state.firstName);
    console.log(this.state.carType);
    console.log(this.state.driverIdList);
    console.log(lng);
    console.log(finaladdress);
    this.pushdata();
    event.preventDefault();
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  offertoggle() {
    this.setState({
      offermodal: !this.state.offermodal,
    });
  }
  navigate = () => {
    this.setState({
      booking: 1,
    });
  };
  render() {
    if (this.state.booking == 1) {
      return <NewBooking />;
    }
    const { cararray } = this.state;

    let cararrayList =
      cararray.length > 0 &&
      cararray.map((i) => {
        return <option>{i.name}</option>;
      }, this);
    const { driverarray } = this.state;

    let driverarrayList =
      driverarray.length > 0 &&
      driverarray.map((i) => {
        return (
          <option>
            {i.name} {i.id}
          </option>
        );
      }, this);

    const { offerarray } = this.state;

    let offerarrayList =
      offerarray.length > 0 &&
      offerarray.map((i) => {
        return <option>{i.name}</option>;
      }, this);

    return (
      <>
        <Header />
        <Container className=' mt--7' fluid>
          <Card>
            <CardHeader>
              <CardTitle>New Booking</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                <h1>Billing amount : {this.state.totalDue}</h1>
                <Row form>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='Firstname'>Firstname</Label>
                      <Input
                        type='name'
                        id='firstName'
                        required
                        name='firstName'
                        value={this.state.firstName}
                        onChange={(e) => {
                          this.setState({ firstName: e.target.value });
                        }}
                        placeholder='First Name'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='Lastname'>Lastname</Label>
                      <Input
                        type='name'
                        name='lastName'
                        required
                        id='Lastname'
                        value={this.state.lastName}
                        onChange={(e) => {
                          this.setState({ lastName: e.target.value });
                        }}
                        placeholder='Last Name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='carType'>Car Type</Label>
                      <Input
                        type='select'
                        name='carType'
                        id='carType'
                        required
                        value={this.state.carType}
                        onChange={(e) => {
                          this.setState({ carType: e.target.value });
                          this.getservice(e.target.value);
                        }}>
                        {cararrayList}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='vehicleNumber'>Vehicle Number</Label>
                      <Input
                        type='text'
                        name='vehicleNumber'
                        id='vehicleNumber'
                        required
                        placeholder='XXXXXXX'
                        value={this.state.vehicleNumber}
                        onChange={(e) => {
                          this.setState({ vehicleNumber: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='driver'>Assign Driver|Bus</Label>
                      <Input
                        type='select'
                        name='driver'
                        id='driver'
                        required
                        value={this.state.driver}
                        onChange={(e) => {
                          this.setState({ driver: e.target.value });
                        }}>
                        {driverarrayList}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='mobileNumber'>Mobile Number</Label>
                      <Input
                        type='number'
                        name='mobileNumber'
                        required
                        id='mobileNumber'
                        placeholder='888888888'
                        value={this.state.mobileNumber}
                        onChange={(e) => {
                          this.setState({ mobileNumber: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Label>Select services</Label>
                    <Multiselect
                      required
                      options={this.state.options} // Options to display in the dropdown
                      selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue='name' // Property name to display in the dropdown options
                    />
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='Email'>Email</Label>
                      <Input
                        type='email'
                        id='email'
                        name='email'
                        required
                        value={this.state.email}
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                        placeholder='Email'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Add on services</Label>
                      <Multiselect
                        required
                        options={this.state.addon} // Options to display in the dropdown
                        selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                        onSelect={this.onSelect1} // Function will trigger on select event
                        onRemove={this.onRemove1} // Function will trigger on remove event
                        displayValue='name' // Property name to display in the dropdown options
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='offer'>Promo code</Label>
                      <Input
                        type='select'
                        name='offer'
                        id='offer'
                        value={this.state.offer}
                        onChange={(e) => {
                          this.setState({ offer: e.target.value });
                          this.applyOffer(e.target.value);
                        }}>
                        {offerarrayList}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='appartment'>Appartment</Label>
                      <Input
                        type='text'
                        name='appartment'
                        id='appartment'
                        required
                        placeholder='Enter the appartment'
                        value={this.state.appartment}
                        onChange={(e) => {
                          this.setState({ appartment: e.target.value });
                        }}></Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='landmark'>Landmark</Label>
                      <Input
                        type='text'
                        name='landmark'
                        id='landmark'
                        required
                        placeholder='Enter the landmark'
                        value={this.state.landmark}
                        onChange={(e) => {
                          this.setState({ landmark: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <Map
                      google={this.props.google}
                      center={{ lat: 12.9716, lng: 77.5946 }}
                      height='300px'
                      zoom={15}
                    />
                    <br></br>
                    <br></br>
                  </Col>
                </Row>
                {/* <FormGroup>
    <Label for="note">Note</Label>
    <Col sm={8}>
      <Input type="textarea" name="note" id="note" placeholder="Please type here" value={this.state.note} onChange={e =>{this.setState({note: e.target.value})}}/>
    </Col>
  </FormGroup> */}
                <Button onClick={this.navigate}>BACK</Button>{" "}
                <Button color='success' value='Submit'>
                  BOOK
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Congratulation new booking added Successfully</ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.navigate}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.offermodal} toggle={this.offertoggle}>
          <ModalBody>Congratulation Offer applied</ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.offertoggle}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
