import React, { Component } from "react";

// reactstrap components
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Container,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  CardBody,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";
import Geocode from "react-geocode";
import { Multiselect } from "multiselect-react-dropdown";
import {
  InfoWindow,
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";

var lat = 12.9716;
var lng = 77.5946;

var finaladdress = "";
// super admin logged in user id
var saID = "superadmin_twaddan_com";
// super admin logged in name
var saName;

// firebase.auth().onAuthStateChanged(function (user) {
//   if (user) {
//     // User is signed in.
//     console.log("The current user: ", user.email);

//     saID = user.email;
//     saID = saID.replace("@", "_");
//     saID = saID.replace(".", "_");

//     firebase
//       .database()
//       .ref("/twaddan_admin/super_admin_users/" + saID + "/")
//       .on("value", function (snapshot) {
//         saName = snapshot.val();
//         saName = saName.personal_information.name;
//       });
//   }
// });

Geocode.setApiKey("AIzaSyC73FXvsbaH0gxh1lHY5CUjpAfmIGFx7KQ");
Geocode.enableDebug();

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      area: "",
      state: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
    };
  }
  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    Geocode.fromLatLng(
      this.state.mapPosition.lat,
      this.state.mapPosition.lng
    ).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);
        finaladdress = address;
        console.log("city", city, area, state);

        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.markerPosition.lat !== this.props.center.lat ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state
    ) {
      return true;
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false;
    }
  }
  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {};

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    lat = newLat;
    lng = newLng;

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);
        finaladdress = address;
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  // onPlaceSelected = ( place ) => {
  // 	console.log( 'plc', place );
  // 	const address = place.formatted_address,
  // 	      addressArray =  place.address_components,
  // 	      city = this.getCity( addressArray ),
  // 	      area = this.getArea( addressArray ),
  // 	      state = this.getState( addressArray ),
  // 	      latValue = place.geometry.location.lat(),
  // 	      lngValue = place.geometry.location.lng();
  // 	// Set these values in the state.
  // 	this.setState({
  // 		address: ( address ) ? address : '',
  // 		area: ( area ) ? area : '',
  // 		city: ( city ) ? city : '',
  // 		state: ( state ) ? state : '',
  // 		markerPosition: {
  // 			lat: latValue,
  // 			lng: lngValue
  // 		},
  // 		mapPosition: {
  // 			lat: latValue,
  // 			lng: lngValue
  // 		},
  // 	})
  // };

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng,
          }}>
          {/* InfoWindow on top of marker */}
          <InfoWindow
            onClose={this.onInfoWindowClose}
            position={{
              lat: this.state.markerPosition.lat + 0.0018,
              lng: this.state.markerPosition.lng,
            }}>
            <div>
              <span style={{ padding: 0, margin: 0 }}>
                {this.state.address}
              </span>
            </div>
          </InfoWindow>
          {/*Marker*/}
          <Marker
            google={this.props.google}
            name={"Dolores park"}
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{
              lat: this.state.markerPosition.lat,
              lng: this.state.markerPosition.lng,
            }}
          />
          <Marker />
          {/* For Auto complete Search Box */}
        </GoogleMap>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>
          <div>
            {/* <div className="form-group">
              <label htmlFor="">City</label>
              <input type="text" name="city" className="form-control" onChange={ this.onChange } readOnly="readOnly" value={ this.state.city }/>
            </div>
            <div className="form-group">
              <label htmlFor="">Area</label>
              <input type="text" name="area" className="form-control" onChange={ this.onChange } readOnly="readOnly" value={ this.state.area }/>
            </div>
            <div className="form-group">
              <label htmlFor="">State</label>
              <input type="text" name="state" className="form-control" onChange={ this.onChange } readOnly="readOnly" value={ this.state.state }/>
            </div> */}
            <div className='form-group'>
              <label htmlFor=''>Address</label>
              <input
                type='text'
                name='address'
                className='form-control'
                onChange={this.onChange}
                readOnly='readOnly'
                value={this.state.address}
              />
            </div>
          </div>

          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC73FXvsbaH0gxh1lHY5CUjpAfmIGFx7KQ&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}

export default class AddRestrictedLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      radius: 0,
      options: [],
      selectedSp: [],
      modal: false,
      addon: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    const dr = firebase.database().ref("service_providers/");
    dr.on("value", (snapshot) => {
      var drs = snapshot.val();
      var keys = Object.keys(drs);
      console.log(drs);
      let newService = [];
      for (var dr = 0; dr < keys.length; dr++) {
        var k = keys[dr];
        newService.push({
          name: drs[k].personal_information.name,
          id: k,
        });
      }
      this.setState({
        options: newService,
      });
    });
  };
  pushdata = () => {
    var spallowed = {};
    for (let i = 0; i < this.state.selectedSp.length; i++) {
      spallowed[i + 1] = this.state.selectedSp[i].id;
    }
    var id = this.state.name.toLowerCase();
    id = id.trim();
    id = id.replace(" ", "_");
    var ref = firebase.database().ref("twaddan_admin/restricted_areas/");
    ref.child(id).set({
      name: this.state.name,
      lat: lat,
      long: lng,
      address: finaladdress,
      allowed_sp: spallowed,
      radius: Number(this.state.radius),
    });

    var time = new Date();
    time = time.getTime();
    var logAction = "Added Restricted Location " + this.state.name;
    var logs = firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/logs/" + time + "/");
    logs.update({
      action: logAction,
    });

    this.toggle();
  };
  handleSubmit(event) {
    this.pushdata();
    event.preventDefault();
  }
  onSelect(selectedList, selectedItem) {
    this.setState({
      selectedSp: selectedList,
    });
  }

  onRemove(selectedList, removedItem) {
    this.setState({
      selectedSp: selectedList,
    });
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  navigate = () => {
    this.setState({
      addon: 1,
    });
  };
  render() {
    console.log(this.state.selectedSp);
    if (this.state.addon == 1) {
      return <Redirect to='/admin/restrictedLocations' />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=' mt--7' fluid>
          <Card>
            <CardHeader>
              <CardTitle>Add Restricted Location</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                <Row form>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='name'>Name</Label>
                      <Input
                        type='name'
                        required
                        name='name'
                        id='name'
                        value={this.state.name}
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                        placeholder='Name'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='price'>Serving Radius</Label>
                      <Input
                        type='number'
                        required
                        name='raduis'
                        id='radius'
                        placeholder='Radius'
                        value={this.state.radius}
                        onChange={(e) => {
                          this.setState({ radius: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label>Select Allowed Service Provider</Label>
                    <Multiselect
                      required
                      options={this.state.options} // Options to display in the dropdown
                      selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue='name' // Property name to display in the dropdown options
                    />
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col md={8}>
                    <Map
                      google={this.props.google}
                      center={{ lat: 12.9716, lng: 77.5946 }}
                      height='300px'
                      zoom={15}
                    />
                    <br></br>
                    <br></br>
                  </Col>
                </Row>
                <br></br>
                <Button onClick={this.navigate}>CANCEL</Button>{" "}
                <Button color='success' value='Submit'>
                  ADD
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>
            Congratulation Restricted Location added Successfully
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.navigate}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
