import './Orders.css';
import React, { Component } from 'react';
import firebase from 'firebaseConfig';
import moment from 'moment';
import 'moment-timezone';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link, Redirect } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import RealTime from '../../services/RealTime';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import logo from './twaddan-react.0a3a7e5e.jpg';
import PageLoader from '../../components/PageLoader/PageLoader';
import Header from 'components/Headers/Header.jsx';
import userIcon from '../../assets/img/icons/orders/user.svg';
import briefCase from '../../assets/img/icons/orders/briefcase.svg';
import Driver from '../../assets/img/icons/orders/driver.svg';
import confirm from '../../assets/img/icons/orders/confirm.svg';
import car from '../../assets/img/icons/orders/car.svg';
import reached from '../../assets/img/icons/orders/reached.svg';
import started from '../../assets/img/icons/orders/started.svg';
import finished from '../../assets/img/icons/orders/finished.svg';
import StarRatings from 'react-star-ratings';
import Select from 'react-select';
import '../styles/Order.css';

import {
    Col,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Card,
    Container,
    CardHeader,
    CardTitle,
    Modal,
    ModalBody,
    ModalFooter,
    CardBody,
    BreadcrumbItem,
    Breadcrumb,
    ModalHeader,
} from 'reactstrap';
const columns = [
    {
        text: 'SL.No',
        dataField: 'i',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        footer: ' ',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Order Id',
        dataField: 'orderId',
        filter: textFilter({ style: { minWidth: '150px' } }),
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Customer Name',
        dataField: 'customerName',
        filter: textFilter({ style: { minWidth: '220px' } }),
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Requested Time',
        dataField: 'dateTimeString',
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Completed Time',
        dataField: 'completedTime',
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Driver',
        dataField: 'driverName',
        footer: false,
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
        style: {},
    },
    {
        text: 'Amount',
        dataField: 'amount',
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Mode of Payment',
        dataField: 'paymentMode',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    // {
    //   text: "Rating",
    //   align: "center",
    //   headerAlign: "center",
    //   dataField: "rating",
    //   headerStyle: {
    //     backgroundColor: "#00acf3"
    //   }
    // }
];
const defaultSorted = [
    {
        dataField: 'orderId',
        order: 'desc',
    },
];

const rowStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex % 2 === 0) {
        style.backgroundColor = '#e6e6e6';
    }
    style.cursor = 'pointer';
    return style;
};
var completedOrderDetails = [];
var completedServiceDetails = [];
var serviceDetails = [];
var serviceTotalPrice = 0;
var addonTotalPrice = 0;

var arabic = /[\u0600-\u06FF]/;
var allOrderDetails = [];
const translateToEn = async (text, value) => {
    if (arabic.test(text)) {
        console.log('text ', arabic.test, text);
        const axios = require('axios').default;
        const url = 'https://translation.googleapis.com/language/translate/v2';
        const apiKey = 'AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g';
        const reply = await axios.get(url, {
            params: {
                q: text,
                target: 'en',
                key: apiKey,
            },
        });
        // console.log('reply ', reply);
        // console.log("transated ",reply.data.data.translations[0].translatedText);
        return reply.data.data.translations[0].translatedText || '';
    } else return text;
};
const getOrderDetails = async () => {
    let sp_id = localStorage.getItem('sp_id');
    await firebase
        .database()
        .ref('/orders/all_orders')
        .once('value', (snapshot) => {
            let i = 1;
            snapshot.forEach(function (childSnapshot) {
                var orderDetails = childSnapshot.val();
                if (orderDetails.status == 5 && orderDetails.sp_id === sp_id) {
                    allOrderDetails.push({
                        i: i++,
                        id: orderDetails.order_id_number,
                        orderId: orderDetails.order_id_number.split(' ')[0],
                        dateTime: moment(orderDetails.time_order_placed).tz('Asia/Dubai').format('MM/DD/YYYY hh:mm a'),
                        dateTimeString: moment(orderDetails.time_order_placed)
                            .tz('Asia/Dubai')
                            .format('DD/MM/YYYY hh:mm a'),
                        // customerName: orderDetails.customer_name,
                        requestedTime: moment(orderDetails.time_order_placed).format('DD/MM/YYYY hh:mm a'),
                        completedTime: moment(orderDetails.time_order_job_finished)
                            .tz('Asia/Dubai')
                            .format('DD/MM/YYYY hh:mm a'),
                        customerName: orderDetails.customer_name_english
                            ? orderDetails.customer_name_english
                            : orderDetails.customer_name,
                        serviceProvider: orderDetails.sp_name_english
                            ? orderDetails.sp_name_english
                            : orderDetails.sp_name,
                        serviceProviderId: orderDetails.sp_id,
                        driverId: orderDetails.driver_id,
                        driverName: orderDetails.driver_name,
                        etc: moment(orderDetails.etc).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm a'),
                        amount: orderDetails.total_due,
                        amount: orderDetails.total_due,
                        paymentMode: orderDetails.payment_mode === 0 ? 'cash' : 'online',
                        location: 'Dubai',
                        rating: orderDetails.rating,
                    });
                }
            });
        });
    let j = 1;
    allOrderDetails.map((obj) => (obj.i = allOrderDetails.length - obj.i + 1));
    return allOrderDetails;
};

const getOrderDriverList = async () => {
    let sp_id = localStorage.getItem('sp_id');
    let drivers = await RealTime.getDetails('/drivers/');
    let newAllDriver = [];
    newAllDriver.push({
        label: 'All',
        value: 'All',
    });
    Object.entries(drivers).forEach(([key, driver]) => {
        if (driver?.sp_related === sp_id)
            newAllDriver.push({
                label: driver.personal_information.name,
                value: key,
            });
    });
    return newAllDriver;
};
const getSum = async (orderList) => {
    let sumOFCash = 0,
        sumOfOnline = 0,
        sumOfTotal = 0;
    orderList.forEach((element) => {
        if (element.paymentMode == 'cash') sumOFCash += element.amount;
        else sumOfOnline += element.amount;
    });
    return { sumOFCash, sumOfOnline };
};

//========================

class CompletedOrder extends React.Component {
    async componentDidMount() {
        allOrderDetails = [];
        let response1 = await getOrderDetails();
        let response2 = await getOrderDriverList();
        let { sumOFCash, sumOfOnline } = await getSum(response1);
        this.setState({
            dateRange: 'From 01/01/2020  To ' + moment(new Date()).format('DD-MM-YYYY'),
            allOrdersData: response1,
            AllOrdersData: response1,
            AAllOrdersData: response1,
            driverList: response2,
            selectedSp: response2[0],
            sumOFCash: sumOFCash,
            sumOfOnline: sumOfOnline,
            totalSum: sumOFCash + sumOfOnline,
            isLoadedCombo: true,
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            allOrdersData: [],
            AllOrdersData: [],
            AAllOrdersData: [],
            selectedDriver: { label: 'All', value: 'All' },
            dateRange: '',
            isLoaded: false,
            orderData: '',
            seviceData: '',
            popupToggle: false,
            orderId: '',
        };
    }

    dateRangeHandler = async (event, picker) => {
        let dateRange = '';
        if (picker)
            if (picker.startDate.format('DD-MM-YYYY') == picker.endDate.format('DD-MM-YYYY'))
                dateRange = picker.startDate.format('DD-MM-YYYY');
            else
                dateRange =
                    'From ' + picker.startDate.format('DD-MM-YYYY') + ' To ' + picker.endDate.format('DD-MM-YYYY');

        let i = 0;
        let newArray = [];
        this.state.AllOrdersData.map((odr) => {
            var nerOdr = Object.assign({}, odr);
            if (
                moment(nerOdr.dateTime) >= picker.startDate.toDate() &&
                moment(nerOdr.dateTime) <= picker.endDate.toDate()
            ) {
                i++;
                nerOdr.i = i;
                newArray.push(nerOdr);
            }
        });
        let j = -1;
        newArray = newArray.map((obj) => {
            j++;
            obj.i = newArray.length - j;
            return obj;
        });
        let { sumOFCash, sumOfOnline } = await getSum(newArray);
        this.setState({
            selectedDriver: { label: 'All', value: 'All' },
            allOrdersData: newArray,
            AAllOrdersData: newArray,
            sumOFCash: sumOFCash,
            dateRange: dateRange,
            sumOfOnline: sumOfOnline,
            totalSum: sumOFCash + sumOfOnline,
        });
    };

    pdfGenerator = () => {
        let dateRange = this.state.dateRange;
        let driver = this.state.selectedDriver;
        const doc = new jsPDF();
        let outerArray = [];
        let i = 0;
        console.log('this.state.allOrdersData ', this.state.allOrdersData.length);
        for (var keyOut in this.state.allOrdersData) {
            i++;
            let elementOut = this.state.allOrdersData[keyOut];
            elementOut.i = i;
            console.log('elementOut ', elementOut);
            let innerArray = [];
            for (var keyIn in elementOut)
                if (
                    !(
                        keyIn === 'id' ||
                        keyIn === 'requestedTime' ||
                        keyIn === 'completedTime' ||
                        keyIn === 'status' ||
                        keyIn === 'serviceProvider' ||
                        keyIn === 'serviceProviderId' ||
                        keyIn === 'driverId' ||
                        keyIn === 'etc' ||
                        keyIn === 'location' ||
                        keyIn === 'dateTime' ||
                        keyIn === 'rating'
                    )
                )
                    innerArray.push(elementOut[keyIn]);
            outerArray.push(innerArray);
        }
        var header = function (data) {
            doc.setFontSize(10);
            doc.addImage(logo, 'JPEG', 13, 3, 32, 12);
            doc.setLineWidth(1);
            doc.setDrawColor(62, 142, 222);
            doc.line(14, 14, 196, 14);
            doc.text('Driver Wise Sales Report', 14, 19);
            doc.text(dateRange, 14, 24);
            doc.text(driver.label == 'All' ? '' : driver.label, 196 - driver.label.length * 1.8, 19);
        };

        var options = {
            beforePageContent: header,
            margin: {
                top: 100,
            },
            startY: doc.autoTableEndPosY() + 20,
        };
        doc.autoTable([], [], options);
        doc.autoTable({
            head: [['SL.No', 'ORDER ID ', 'DATE & TIME', 'CUSTOMER NAME', 'DRIVER', 'AMOUNT', 'MODE OF PAYMENT']],
            body: outerArray,
        });
        var res = doc.autoTableHtmlToJson(document.getElementById('summery'));
        var option2 = {
            columnStyles: {
                0: { valign: 'top' },
                1: {
                    halign: 'center',
                },
                2: {
                    halign: 'right',
                },
            },
            headStyles: { halign: 'right' },
            willDrawCell: (data) => {
                if (data.section === 'head' && data.column.index === 0) {
                    data.halign = 'left';
                    data.cell.halign = 'left';
                    data.cell.styles.halign = 'left';
                    data.column.halign = 'left';
                }
            },
        };
        doc.autoTable(res.columns, res.data, option2);
        doc.save('Completed Order.pdf');
    };

    handleDriverChange = async (event) => {
        if (event.value === 'All') {
            var newArray = this.state.AAllOrdersData;
        } else {
            var newArray = [];
            let i = 0;
            this.state.AAllOrdersData.filter(function (odr) {
                var nerOdr = Object.assign({}, odr);
                if (nerOdr.driverId == event.value) {
                    i++;
                    nerOdr.i = i;
                    newArray.push(nerOdr);
                }
            });
        }
        let j = -1;
        newArray = newArray.map((obj) => {
            j++;
            obj.i = newArray.length - j;
            return obj;
        });
        let { sumOFCash, sumOfOnline, sumRefund } = await getSum(newArray);
        this.setState({
            selectedDriver: event,
            allOrdersData: newArray,
            sumOFCash: sumOFCash,
            sumRefund: sumRefund,
            sumOfOnline: sumOfOnline,
            totalSum: sumOFCash + sumOfOnline - sumRefund,
        });
    };
    popupToggle = () => {
        this.setState({
            popupToggle: !this.state.popupToggle,
            isLoadedCombo: true,
        });
    };
    getOrderDetailsPopup = async () => {
        var orderDetails;
        console.log(this.state.orderId);
        await firebase
            .database()
            .ref('/orders/all_orders/' + this.state.orderId)
            .once('value', async (snapshot) => {
                orderDetails = snapshot.val();
                completedOrderDetails['id'] = this.state.orderId.split(' ')[0];
                completedOrderDetails['status'] = orderDetails.status;
                completedOrderDetails['customer_name'] = orderDetails.customer_name;
                completedOrderDetails['customer_name_english'] = orderDetails.customer_name_english
                    ? orderDetails.customer_name_english
                    : null;
                completedOrderDetails['customer_id'] = orderDetails.customer_id;
                completedOrderDetails['customer_latitude'] = orderDetails.customer_latitude;
                completedOrderDetails['customer_longitude'] = orderDetails.customer_longitude;
                completedOrderDetails['sp_id'] = orderDetails.sp_id;
                completedOrderDetails['sp_name'] = orderDetails.sp_name;
                completedOrderDetails['orderRecievedDate'] = moment(orderDetails.time_order_accepted)
                    .tz('Asia/Dubai')
                    .format('DD MM  YYYY hh:mm a');
                completedOrderDetails['driver_id'] = orderDetails.driver_id;
                var res = orderDetails.customer_raw_address.split('Address from Location:');
                completedOrderDetails['customer_raw_address'] = res[1];
                completedOrderDetails['customer_mobile_number'] = '0' + orderDetails.customer_mobile_number;
                completedOrderDetails['customer_gmail_id'] = orderDetails.customer_gmail_id;
                completedOrderDetails['order_id_number'] = orderDetails.order_id_number.includes('_')
                    ? orderDetails.order_id_number.split(' ')[2].replace('_', '@').replace('_', '.').replace('_', '.')
                    : '';
                completedOrderDetails['payment_mode'] = orderDetails.payment_mode === 0 ? 'cash' : 'online';
                completedOrderDetails['payment_method'] = orderDetails.payment_mode;
                completedOrderDetails['service_charge'] = orderDetails.serviceCharge ? orderDetails.serviceCharge : 0;

                completedOrderDetails['total_due'] = orderDetails.total_due;
                completedOrderDetails['coupon_code'] = orderDetails.coupon_code;
                completedOrderDetails['total_discount'] = orderDetails.total_discount;
                completedOrderDetails['total_price_of_order'] = orderDetails.total_price_of_order;
                completedOrderDetails['rating'] = Number(orderDetails.rating);
                completedOrderDetails['orderRecievedTime'] = orderDetails.time_order_placed
                    ? moment(orderDetails.time_order_placed).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['orderAcceptedTime'] = orderDetails.time_order_accepted
                    ? moment(orderDetails.time_order_accepted).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['expectedTimeDriverStart'] = orderDetails.ets
                    ? moment(orderDetails.ets).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['timeDriverStarted'] = orderDetails.time_order_navigation_started
                    ? moment(orderDetails.time_order_navigation_started).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['expectedTimeArrival'] = orderDetails.eta
                    ? moment(orderDetails.eta).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['driverReachedTime'] = orderDetails.time_order_reached_location
                    ? moment(orderDetails.time_order_reached_location).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['expectedJobStartedTime'] = orderDetails.eta
                    ? moment(Number(orderDetails.eta) + 300000)
                          .tz('Asia/Dubai')
                          .format('hh:mm')
                    : 'Nil';
                completedOrderDetails['jobStartedTime'] = orderDetails.time_order_job_started
                    ? moment(orderDetails.time_order_job_started).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['expectedCompleteTime'] = orderDetails.etc
                    ? moment(orderDetails.etc).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['completedTime'] = orderDetails.time_order_job_finished
                    ? moment(orderDetails.time_order_job_finished).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                await firebase
                    .database()
                    .ref('/drivers/' + orderDetails.driver_id + '/personal_information/')
                    .once('value', (snapshot) => {
                        var driverData = snapshot.val();
                        completedOrderDetails['driver_name'] = driverData?.name;
                        completedOrderDetails['driver_phone'] = driverData?.phone_number;
                    });
            });
        {
            // completedOrderDetails.customer_name = await translateToEn(
            //   completedOrderDetails.customer_name
            // );
            completedOrderDetails.sp_name = await translateToEn(completedOrderDetails.sp_name);
            completedOrderDetails.customer_raw_address = await translateToEn(
                completedOrderDetails.customer_raw_address
            );
        }

        return completedOrderDetails;
    };

    getServiceDetailsPopup = async () => {
        await firebase
            .database()
            .ref('/orders/all_orders/' + this.state.orderId + '/services/')
            .once('value', (snapshot) => {
                completedServiceDetails = snapshot.val();
            });
        return completedServiceDetails;
    };

    setData = async () => {
        serviceDetails = [];
        serviceTotalPrice = 0;
        addonTotalPrice = 0;
        // this.getOrderDetails().then(response => {
        this.setState({
            orderData: await this.getOrderDetailsPopup(),
        });
        // });
        this.getServiceDetailsPopup().then((response) => {
            for (var key in response) {
                serviceDetails.push({
                    ...response[key],
                    vehicle: key,
                });
                for (var services in response[key].services) {
                    serviceTotalPrice += response[key].services[services].price;
                }
                if (response[key].add_on_services) {
                    for (var addonServices in response[key].add_on_services) {
                        addonTotalPrice += response[key].add_on_services[addonServices].price;
                    }
                }
            }
            this.setState({
                seviceData: response,
                popupToggle: true,
            });
        });
        this.setState({
            isLoadedCombo: true,
        });
    };

    render() {
        if (false) {
            return (
                <Redirect
                    to={{
                        pathname: '/admin/completeddetail',
                        state: { orderId: this.state.orderId },
                    }}
                />
            );
        }
        let data = this.state.allOrdersData;
        const tableData = {
            columns,
            data,
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState(
                    {
                        redirect: true,
                        order: row,
                        orderId: row.id,
                        isLoadedCombo: false,
                    },
                    () => {
                        this.setData();
                    }
                );
            },
            // onMouseEnter: (e, row, rowIndex) => {
            //   console.log(`enter on row with index: ${rowIndex}`);
            // }
        };

        return (
            <Container className="mt--7" fluid>
                <PageLoader loading={!this.state.isLoadedCombo}></PageLoader>
                <div>
                    <Row>
                        <div className="h5 mt-8 col-md-3">
                            <DateRangePicker
                                onApply={this.dateRangeHandler}
                                initialSettings={{
                                    locale: {
                                        format: 'DD/MM/YYYY',
                                    },
                                    startDate: moment(new Date()).tz('Asia/Dubai').format('DD MM YYYY hh:mm a'),
                                    endDate: moment(new Date()).tz('Asia/Dubai').format('DD MM YYYY hh:mm a'),
                                    ranges: {
                                        Today: [moment().tz('Asia/Dubai').toDate(), moment().tz('Asia/Dubai').toDate()],
                                        Yesterday: [
                                            moment().subtract(1, 'days').toDate(),
                                            moment().subtract(1, 'days').toDate(),
                                        ],
                                        'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                                        'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
                                        'This Month': [
                                            moment().startOf('month').toDate(),
                                            moment().endOf('month').toDate(),
                                        ],
                                        'Last Month': [
                                            moment().subtract(1, 'month').startOf('month').toDate(),
                                            moment().subtract(1, 'month').endOf('month').toDate(),
                                        ],
                                    },
                                }}
                            >
                                <input
                                    type="text"
                                    style={{ maxWidth: '300px', display: 'inline-block' }}
                                    className="form-control"
                                />
                            </DateRangePicker>
                        </div>
                        <div className="h5 mt-8 col-md-3" hidden={true}>
                            <Select
                                style={{
                                    width: '100%',
                                    minWidth: '300px',
                                    display: 'inline-block',
                                    marginLeft: '20px',
                                }}
                                value={this.state.selectedDriver}
                                onChange={this.handleDriverChange}
                                options={this.state.driverList}
                            />
                        </div>
                        <div className="h5 mt-8 col-md-3">
                            <Button
                                style={{
                                    maxWidth: '300px',
                                    display: 'inline-block',
                                    marginLeft: '20px',
                                }}
                                color="info"
                                onClick={this.pdfGenerator}
                            >
                                EXPORT
                            </Button>
                        </div>
                    </Row>

                    <div>
                        {this.state.allOrdersData.length > 0 ? (
                            <BootstrapTable
                                keyField="orderId"
                                data={this.state.allOrdersData}
                                columns={columns}
                                rowStyle={rowStyle}
                                filter={filterFactory()}
                                filterPosition="top"
                                pagination={paginationFactory()}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                hover
                                wrapperClasses="table-responsive"
                                condensed
                                rowEvents={rowEvents}
                            />
                        ) : (
                            <div>
                                <br />
                                <br />
                                <h3 color="red">{this.state.isLoadedCombo ? 'No Records Found' : 'Please Wait...'}</h3>
                                <br />
                                <br />
                            </div>
                        )}
                    </div>
                </div>
                <table id="summery" style={{ paddingLeft: '20px' }}>
                    <tbody>
                        <tr>
                            <td>Total cash orders </td>
                            <td>: </td>
                            <td
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '10px',
                                    textAlign: 'right',
                                }}
                            >
                                {this.state.sumOFCash}
                            </td>
                        </tr>
                        <tr>
                            <td>Total online payment</td>
                            <td>: </td>
                            <td
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '10px',
                                    textAlign: 'right',
                                }}
                            >
                                {' '}
                                {this.state.sumOfOnline}
                            </td>
                        </tr>
                        <tr>
                            <td>Net Amount Received</td>
                            <td>: </td>
                            <td
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '10px',
                                    textAlign: 'right',
                                }}
                            >
                                {this.state.sumOFCash + this.state.sumOfOnline}
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th>Order summary </th>
                            <td> </td>
                            <th></th>
                        </tr>
                    </thead>
                </table>
                <Modal isOpen={this.state.popupToggle} toggle={this.popupToggle} size="lg">
                    <ModalHeader toggle={this.popupToggle}>
                        <h1>Completed Order</h1>
                    </ModalHeader>
                    <ModalBody>
                        <Col md="12">
                            <div className="orderId">
                                <h3>{this.state.orderData.id}</h3>
                                <p>(Completed Orders)</p>
                            </div>
                        </Col>
                        <Col md="12">
                            <div class="table-responsive">
                                <table className="tableOrder">
                                    <tr>
                                        <th className="tableItem">Order Received Time</th>
                                        <th className="tableItem">Expected Time Of Driver Start</th>
                                        <th className="tableItem">Expected Time Of Arrival</th>
                                        <th className="tableItem">Expected Time Of Job Start</th>
                                        <th className="tableItem">Expected Time Of Job Completion</th>
                                    </tr>
                                    <tr>
                                        <td className="tableItem"> {this.state.orderData.orderRecievedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedTimeDriverStart}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedTimeArrival}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedJobStartedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedCompleteTime}</td>
                                    </tr>
                                    <tr>
                                        <th className="tableItem">Order Accepted Time</th>
                                        <th className="tableItem">Driver Started Time</th>
                                        <th className="tableItem">Driver Arrived Time</th>
                                        <th className="tableItem">Job Started Time</th>
                                        <th className="tableItem">Job Completed Time</th>
                                    </tr>
                                    <tr>
                                        <td className="tableItem"> {this.state.orderData.orderAcceptedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.timeDriverStarted}</td>
                                        <td className="tableItem"> {this.state.orderData.driverReachedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.jobStartedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.completedTime}</td>
                                    </tr>
                                </table>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="allCards">
                                <Card>
                                    <CardHeader className="secondCard">
                                        <Row>
                                            <Col md="12" lg="4">
                                                <p style={{ textAlign: 'center' }}>Customer info</p>
                                                <Row>
                                                    <Col md="12" lg="12" style={{ display: 'flex' }}>
                                                        <img
                                                            height={40}
                                                            src={userIcon}
                                                            alt=""
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Col>
                                                    <Col md="12" lg="12" style={{ margin: 'auto' }}>
                                                        <h4 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.customer_name}
                                                        </h4>

                                                        {this.state.orderData.customer_name_english &&
                                                            this.state.orderData.customer_name_english !==
                                                                this.state.orderData.customer_name && (
                                                                <h4 style={{ textAlign: 'center' }}>
                                                                    {this.state.orderData.customer_name_english}
                                                                </h4>
                                                            )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="12" lg="4">
                                                <p style={{ textAlign: 'center' }}>Service Provider info</p>
                                                <Row>
                                                    <Col md="12" lg="12" style={{ display: 'flex' }}>
                                                        <img
                                                            height={40}
                                                            src={briefCase}
                                                            alt=""
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Col>
                                                    <Col md="12" lg="12" style={{ margin: 'auto' }}>
                                                        <h4 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.sp_name}
                                                        </h4>
                                                        {/* <h5 style={{ textAlign: "center" }}>
                              {this.state.orderData.orderRecievedDate}
                            </h5> */}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="12" lg="4">
                                                <p style={{ textAlign: 'center' }}>Technician info</p>
                                                <Row>
                                                    <Col md="12" lg="12" style={{ display: 'flex' }}>
                                                        <img
                                                            height={40}
                                                            src={Driver}
                                                            alt=""
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Col>
                                                    <Col md="12" lg="12" style={{ margin: 'auto' }}>
                                                        <h4 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.driver_name}
                                                        </h4>
                                                        <h5 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.driver_phone}
                                                        </h5>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs="12" sm="12" md="6">
                                                <p>
                                                    <b>Address:</b> {this.state.orderData.customer_raw_address}
                                                </p>
                                                <p>
                                                    <b>Phone:</b> {this.state.orderData.customer_mobile_number}
                                                </p>
                                                <p>
                                                    <b>Email:</b> {this.state.orderData.customer_gmail_id}
                                                </p>
                                            </Col>
                                            <Col xs="12" sm="12" md="6">
                                                <p>
                                                    <p>
                                                        <b>Mode of Payment::</b> {this.state.orderData.payment_mode}
                                                    </p>
                                                    <p>
                                                        <b>Coupon:</b>
                                                        {this.state.orderData.coupon_code
                                                            ? this.state.orderData.coupon_code
                                                            : 'No coupon applied'}
                                                    </p>
                                                    <p>
                                                        <b>Discount:</b> {this.state.orderData.total_discount}
                                                    </p>
                                                    <b>Rating</b>

                                                    <StarRatings
                                                        rating={this.state.orderData.rating}
                                                        starRatedColor="#12ABEA"
                                                        numberOfStars={5}
                                                        starDimension="20px"
                                                        starSpacing="5px"
                                                        name="rating"
                                                    />
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>

                        <Col md="12">
                            <div className="allCards">
                                {serviceDetails.map((serviceDetail) => (
                                    <Card>
                                        <CardHeader className="main-service-vehicle-header">
                                            <h1>
                                                {serviceDetail.vehicle
                                                    .replace('&', ', Vehicle No:')
                                                    .replace('_', ' ')
                                                    .replace('“', ' ')
                                                    .replace('”', ' ')}
                                            </h1>
                                        </CardHeader>
                                        <CardHeader>
                                            <Row>
                                                <p>Main services</p>
                                                {(() => {
                                                    let servicesList = [];
                                                    for (var service in serviceDetail.services)
                                                        servicesList.push(
                                                            <tr className="col-md-12">
                                                                <td className="col-md-2">
                                                                    {serviceDetail.services[service].name}
                                                                </td>
                                                                <td className="col-md-8">
                                                                    {serviceDetail.services[service].price}
                                                                </td>
                                                            </tr>
                                                        );
                                                    return servicesList;
                                                })()}
                                            </Row>
                                            {serviceDetail.add_on_services ? (
                                                <Row className="AddOn">
                                                    <tr className="col-md-12">
                                                        <p>Add on’s</p>
                                                        <div>
                                                            {(() => {
                                                                let addonList = [];
                                                                for (var addon in serviceDetail.add_on_services) {
                                                                    addonList.push(
                                                                        <tr className="col-md-12">
                                                                            <td className="col-md-2">
                                                                                {
                                                                                    serviceDetail.add_on_services[addon]
                                                                                        .name
                                                                                }
                                                                            </td>
                                                                            <td className="col-md-8">
                                                                                {
                                                                                    serviceDetail.add_on_services[addon]
                                                                                        .price
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                                return addonList;
                                                            })()}
                                                        </div>
                                                    </tr>
                                                </Row>
                                            ) : (
                                                ''
                                            )}
                                        </CardHeader>
                                    </Card>
                                ))}
                                <br />
                                {this.state.orderData.total_price_of_order > 0 && (
                                    <Card>
                                        <CardBody>
                                            <tr className="col-md-12">
                                                <th className="col-md-2">Sub Total</th>
                                                <td className="col-md-8">
                                                    {this.state.orderData.total_price_of_order}
                                                </td>
                                            </tr>
                                        </CardBody>
                                    </Card>
                                )}
                                {this.state.orderData.total_discount > 0 && (
                                    <Card>
                                        <CardBody>
                                            <tr className="col-md-12">
                                                <th className="col-md-2">Discount</th>
                                                <td className="col-md-8">-{this.state.orderData.total_discount}</td>
                                            </tr>
                                        </CardBody>
                                    </Card>
                                )}
                                {this.state.orderData.service_charge > 0 && (
                                    <Card>
                                        <CardBody>
                                            <tr className="col-md-12">
                                                <th className="col-md-2">Sub Total</th>
                                                <td className="col-md-8">+{this.state.orderData.service_charge}</td>
                                            </tr>
                                        </CardBody>
                                    </Card>
                                )}
                                <Card>
                                    <CardBody>
                                        <tr className="col-md-12">
                                            <th className="col-md-2">Total Amount</th>
                                            <td className="col-md-8">{this.state.orderData.total_due}</td>
                                        </tr>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.popupToggle} color="danger">
                            CLOSE
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}

export default CompletedOrder;
