import firebase from "firebaseConfig";

const authUser = async () => {
  try {
    let user = await firebase.auth().currentUser;
    if (user) {
      // var userID = user.user.email.replace("@", "_");
      // userID = userID.replace(".", "_");
      // localStorage.setItem("admin_email", userID);
      return user;
    } else {
      // No user is signed in.
      return false;
    }
  } catch (error) {
    throw error;
  }
};

// const setPermission = async (userID) => {
//   var permissionPages = [];

//   let userDetails;
//   await firebase
//     .database()
//     .ref("/twaddan_admin/userPermission/" + userID)
//     .once("value", function (snapshot) {
//       userDetails = snapshot.val();

//       permissionPages = [];
//       if (userDetails.page_permission.addVehicle == true) {
//         permissionPages.push("addVehicle");
//         if (userDetails.crud_permission.createPermission == true) {
//           permissionPages.push("addVehicle_Create");
//         }
//       }
//       if (userDetails.page_permission.addBooking == true) {
//         permissionPages.push("addBooking");
//         if (userDetails.crud_permission.createPermission == true) {
//           permissionPages.push("addBooking_Create");
//         }
//       }
//       if (userDetails.page_permission.addServices == true) {
//         permissionPages.push("addServices");
//         if (userDetails.crud_permission.createPermission == true) {
//           permissionPages.push("addServices_Create");
//         }
//       }
//       if (userDetails.page_permission.addServiceCenter == true) {
//         permissionPages.push("addServiceCenter");
//         if (userDetails.crud_permission.createPermission == true) {
//           permissionPages.push("addServiceCenter_Create");
//         }
//       }
//       if (userDetails.page_permission.addDriver == true) {
//         permissionPages.push("addDriver");
//         if (userDetails.crud_permission.createPermission == true) {
//           permissionPages.push("addDriver_Create");
//         }
//       }
//       permissionPages.push("All");
//     });
//   localStorage.setItem("permissions", permissionPages);
//   localStorage.setItem(
//     "crudPermissions",
//     JSON.stringify(userDetails.crud_permission)
//   );

//   return permissionPages;
// };

const getDetails = async (path) => {
  try {
    let ref = firebase.database().ref(path);
    let response = await ref.once("value");
    return response.val();
  } catch (error) {
    throw error;
  }
};
const removeNode = async (path) => {
  try {
    let ref = firebase.database().ref(path);
    ref.remove();
    return true;
  } catch (error) {
    throw error;
  }
};
const updateNode = async (path, data) => {
  try {
    let ref = firebase.database().ref(path);
    ref.update(data);
    return true;
  } catch (error) {
    throw error;
  }
};

const RealTime = {
  authUser,
  getDetails,
  removeNode,
  updateNode,
};
export default RealTime;
