import React from "react";
import firebase from "firebaseConfig";

// reactstrap components
import {
  Container,
  Jumbotron,
  Button,
  Row,
  Col,
  Toast,
  ToastBody,
  ToastHeader,
  Table,
  CardHeader,
  CardBody,
  Card,
  Input,
  FormGroup,
  Label,
  CardImg,
  CardTitle,
  CardText,
  CardSubtitle,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";

// super admin user details
var userDetails;
// super admin user names list
var saUserList = [];
// super admin user id list
var saUserListID = [];
// employee logs details
var employeeLogs = [];
// super admin logged in user id
var saID = localStorage.getItem("admin_email");

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/")
      .on("value", function (snapshot) {
        userDetails = snapshot.val();

        saUserList = [];
        saUserListID = [];
        if (userDetails) {
          saUserList[0] = "Select User";
          saUserList[1] = "All User";
          saUserListID[0] = "Select User";
          saUserListID[1] = "All User";
          Object.keys(userDetails).map((line, key) => {
            saUserListID[key + 2] = line;
            saUserList[key + 2] = userDetails[line].personal_information?.name;
          });
        }
      });
  }
});

class EmployeeLogs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userID: "",
      searchUserID: "",
    };

    this.updateSearch = this.updateSearch.bind(this);
  }

  updateSearch = () => {
    var searchUserID;
    const { userID } = this.state;

    saUserList.map((line, key) => {
      if (line == userID) {
        var id = saUserListID[key];
        this.setState({ searchUserID: id });
        searchUserID = saUserListID[key];
      }
    });

    employeeLogs = [];
    var index = 0;
    if (searchUserID == "All User") {
      if (userDetails) {
        Object.keys(userDetails).map((line, key) => {
          if (userDetails[line].logs) {
            Object.keys(userDetails[line].logs).map((lineLogs, keyLogs) => {
              var dateDecode = new Date(Number(lineLogs));
              dateDecode = dateDecode.toString();
              // dateDecode = dateDecode.toGMTString();
              employeeLogs[index] = {
                userID: line,
                userName: userDetails[line]?.personal_information?.name,
                details: userDetails[line]?.logs[lineLogs]?.action,
                time: lineLogs,
                date: dateDecode,
              };
              index = index + 1;
            });
          }
        });
      }
    } else {
      if (userDetails[searchUserID].logs) {
        Object.keys(userDetails[searchUserID].logs).map((lineLogs, keyLogs) => {
          var dateDecode = new Date(Number(lineLogs));
          dateDecode = dateDecode.toString();
          // dateDecode = dateDecode.toGMTString();
          employeeLogs[index] = {
            userID: searchUserID,
            userName: userDetails[searchUserID].personal_information.name,
            details: userDetails[searchUserID].logs[lineLogs].action,
            time: lineLogs,
            date: dateDecode,
          };
          index = index + 1;
        });
      }
    }

    if (employeeLogs.length > 0) {
      employeeLogs.sort(function (a, b) {
        return b.time - a.time;
      });
    }

    console.log("employee logs = ", employeeLogs);
  };

  render() {
    let superAdminUsersList =
      saUserList.length > 0 &&
      saUserList.map((key) => {
        return <option>{key}</option>;
      }, this);

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <div className='h5 mt-8'>
            <h1> Twaddan Super Admin and Employee Logs</h1>
            <br />
          </div>
          <div>
            <Row>
              <Col>
                <FormGroup>
                  <Label for='saUser'>Select User Log</Label>
                  <Input
                    type='select'
                    name='saUser'
                    id='saUser'
                    required
                    value={this.state.userID}
                    onChange={(e) => {
                      this.setState({ userID: e.target.value });
                    }}>
                    {superAdminUsersList}
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <br />
                <Button
                  color='info'
                  href='#pablo'
                  onClick={this.updateSearch}
                  size='bm'>
                  SEARCH
                </Button>
              </Col>
            </Row>
          </div>
          <div>
            <Container fluid>
              <div className='header-body'>
                <Row className='mt-5'>
                  {employeeLogs.length <= 0 && (
                    <>
                      <div>
                        <h1>No records found</h1>
                      </div>
                    </>
                  )}
                  {employeeLogs.length > 0 &&
                    employeeLogs.map((line, key) => {
                      return (
                        <Col key={key} lg='30' md='12' sm='24'>
                          <Card>
                            <CardBody>
                              <Row>
                                <Col>
                                  {" "}
                                  <h3>{employeeLogs[key].date}</h3>{" "}
                                </Col>
                                <Col>
                                  {" "}
                                  <p className='text-right'>
                                    <b style={{ color: "red" }}>
                                      Login Session ID :{" "}
                                    </b>
                                    {employeeLogs[key].userID}
                                  </p>{" "}
                                </Col>
                              </Row>
                              <p className='text-right'>
                                <b style={{ color: "red" }}>User Name : </b>
                                {employeeLogs[key].userName}
                              </p>
                              <p>
                                <b style={{ color: "red", fontSize: 20 }}>
                                  Action :{" "}
                                </b>
                                {employeeLogs[key].details}
                              </p>
                            </CardBody>
                          </Card>
                          <br />
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </Container>
          </div>
        </Container>
      </>
    );
  }
}

export default EmployeeLogs;
