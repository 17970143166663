import './Orders.css';
import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from 'components/Headers/Header.jsx';
import AllOrders from './AllOrders.jsx';
import ActiveOrders from './ActiveOrders.jsx';
import CompletedOrders from './CompletedOrders.jsx';
import CancelledOrders from './CancelledOrders.jsx';
import ScheduledOrders from './ScheduledOrders.jsx';

class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <Header />
                <Tabs defaultIndex={0} className="customTabs">
                    <TabList>
                        <Tab>
                            <p>Active Orders</p>
                        </Tab>
                        <Tab>
                            <p>All Orders</p>
                        </Tab>
                        <Tab>
                            <p>Scheduled Orders</p>
                        </Tab>
                        <Tab>
                            <p>Completed Orders</p>
                        </Tab>
                        <Tab>
                            <p>Cancelled Orders</p>
                        </Tab>
                    </TabList>

                    <TabPanel>
                        <ActiveOrders />
                    </TabPanel>
                    <TabPanel>
                        <AllOrders />
                    </TabPanel>
                    <TabPanel>
                        <ScheduledOrders />
                    </TabPanel>
                    <TabPanel>
                        <CompletedOrders />
                    </TabPanel>
                    <TabPanel>
                        <CancelledOrders />
                    </TabPanel>
                </Tabs>
            </div>
        );
    }
}

export default Orders;
