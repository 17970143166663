import React from "react";
import firebase from "firebaseConfig";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
// super admin image url

var superAdminImageURL =
  "https://firebasestorage.googleapis.com/v0/b/twaddan-87437.appspot.com/o/twaddan_admin%2Fuser.png?alt=media&token=806fd29e-e9c9-4add-893a-7077f4d484f9";
let sp_image = null;
let sp_name = null;
class AdminNavbar extends React.Component {
  doSignOut = () => {
    localStorage.clear();
    firebase
      .auth()
      .signOut()
      .then(() => console.log("User signed out!"));
  };
  search = (e) => {
    e.preventDefault();
  };
  async componentDidMount() {
    await firebase
      .database()
      .ref(
        "service_providers/" +
          localStorage.getItem("sp_id") +
          "/personal_information/"
      )
      .on("value", function (snapshot) {
        localStorage.setItem("sp_name", snapshot.val().name || "Anonymous");
        localStorage.setItem("sp_image", snapshot.val().image);
        sp_image = snapshot.val().image;
        sp_name = snapshot.val().name;
      });
  }
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              Dashboard
              {/* {this.props.brandText} */}
            </Link>

            {/* <Form
              className='navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto'
              onSubmit={this.search}>
              <FormGroup className='mb-0'>
                <InputGroup className='input-group-alternative'>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>
                      <i className='fas fa-search' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder='Search' type='text' />
                </InputGroup>
              </FormGroup>
            </Form> */}

            <Nav className="align-items-center" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={
                          localStorage.getItem("sp_image")
                            ? localStorage.getItem("sp_image")
                            : superAdminImageURL
                        }
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {localStorage.getItem("sp_name")
                          ? localStorage.getItem("sp_name")
                          : "Welcome"}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-arrow" right>
                  {/* <DropdownItem className='noti-title' header tag='div'>
                    {/* <h6 className='text-overflow m-0'>Welcome!</h6> 
                  </DropdownItem> 
                  {/* <DropdownItem to='/admin/profile' tag={Link}>
                    <i className='ni ni-single-02' />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem to='/admin/employeeLogs' tag={Link}>
                    <i className='ni ni-calendar-grid-58' />
                    <span>Activity Logs</span>
                  </DropdownItem> */}
                  {/* <DropdownItem to='/admin/pageNotFound' tag={Link} disabled>
                    <i className='ni ni-settings-gear-65' />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem to='/admin/pageNotFound' tag={Link} disabled>
                    <i className='ni ni-support-16' />
                    <span>Support</span>
                  </DropdownItem> */}
                  <DropdownItem divider />
                  <DropdownItem href="/auth/login" onClick={this.doSignOut}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
