import React from "react";
import { Card, CardBody } from "reactstrap";

class StatisticsCards extends React.Component {
  render() {
    return (
      <Card className="card-stats mb-4 mb-xl-0">
        <CardBody
          className={`${
            this.props.className ? this.props.className : "stats-card-body"
          } d-flex ${
            !this.props.iconRight && !this.props.hideChart
              ? "flex-column align-items-start"
              : this.props.iconRight
              ? "justify-content-between flex-row-reverse align-items-center"
              : this.props.hideChart && !this.props.iconRight
              ? "justify-content-center flex-column text-center"
              : null
          } pb-2 pt-2`}
        >
          <div className="icon-section">
            <div className={`avatar avatar-stats bg-${this.props.iconBg}`}>
              <div className="avatar-content">
                <i className={`fas fa-${this.props.icon}`} />
              </div>
            </div>
          </div>
          <div className="title-section justify-content-center text-center m-auto">
            <h5 className="text-muted ">{this.props.statTitle}</h5>
            <h2 className="text-bold-600 ">{this.props.stat}</h2>
          </div>
        </CardBody>
        {/* {!this.props.hideChart && (
          <Chart
            options={this.props.options}
            series={this.props.series}
            type={this.props.type}
            height={this.props.height ? this.props.height : 100}
          />
        )} */}
      </Card>
    );
  }
}
export default StatisticsCards;
