import React from "react";

// reactstrap components
import {
  Col,
  Row,
  Button,
  Card,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { Zap, Delete } from "react-feather";
import StatisticsCard from "../../components/statisticsCard/StatisticsCard";
import firebase from "../../firebaseConfig";
import StarRatings from "react-star-ratings";

var spid = "";

class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      review: [],
      spemail: "",
      modal: false,
      CouponId: "",
      sparray: [],
    };
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  handleSubmit(event) {
    console.log(this.state.spidd);
    this.storeCurrentSpid();
    event.preventDefault();
  }
  getData = () => {
    const dr = firebase.database().ref("service_providers/");
    let newService = [];
    newService.push({
      name: "select",
    });
    dr.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        var keys = Object.keys(drs);
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          newService.push({
            name: drs[k].personal_information.name,
            id: k,
          });
        }
      }
      this.setState({
        sparray: newService,
      });
    });
  };
  storeCurrentSpid = () => {
    this.state.sparray.map((i) => {
      if (i.name === this.state.spidd) {
        spid = i.id;
        this.setState({
          spemail: i.id,
        });
        this.getRatings();
      }
    });
  };
  getRatings = () => {
    const dr = firebase
      .database()
      .ref("service_providers_ratings/" + spid + "/");
    dr.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        var keys = Object.keys(drs);
        let newService = [];
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          newService.push({
            arrivalTimeRating: drs[k].arrivalTimeRating,
            customerName: drs[k].customerName,
            driver_id: drs[k].driver_id,
            qualityOfServiceRating: drs[k].qualityOfServiceRating,
            rating: drs[k].rating,
            review: drs[k].review,
            valueOfServiceRating: drs[k].valueOfServiceRating,
            id: k,
          });
        }
        console.log(newService);
        this.setState({
          review: newService,
        });
      } else {
        this.setState({
          review: [],
        });
      }
    });
  };
  deletePromoCode = () => {
    console.log(this.state.spemail);
    console.log(this.state.CouponId);
    let userRef = firebase
      .database()
      .ref(
        "service_providers_ratings/" +
          this.state.spemail +
          "/" +
          this.state.CouponId
      );
    userRef.remove();
    this.toggle();
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    const { sparray } = this.state;

    let sparrayList =
      sparray.length > 0 &&
      sparray.map((i) => {
        return <option>{i.name}</option>;
      }, this);

    const { review } = this.state;

    let reviewList =
      review.length > 0 &&
      review.map((i) => {
        return (
          <Col lg='6' md='8' sm='12'>
            <Card>
              <CardBody>
                <h4>{i.customerName}</h4>
                <h4>Driver: {i.driver_id}</h4>
                <h4>Review : {i.review}</h4>
                <Button
                  color='danger'
                  style={{ position: "absolute", top: "10%", right: "10px" }}
                  onClick={() =>
                    this.setState({
                      CouponId: i.id,
                      modal: true,
                    })
                  }>
                  <Delete />
                </Button>
                <span>Arrival Time Rating : </span>
                <StarRatings
                  rating={i.arrivalTimeRating}
                  starRatedColor='yellow'
                  starDimension='20px'
                  starSpacing='5px'
                />
                <br></br>
                <span>Quality Of Service : </span>
                <StarRatings
                  rating={i.qualityOfServiceRating}
                  starRatedColor='yellow'
                  starDimension='20px'
                  starSpacing='5px'
                />
                <br></br>
                <span>Value Of Service : </span>
                <StarRatings
                  rating={i.valueOfServiceRating}
                  starRatedColor='yellow'
                  starDimension='20px'
                  starSpacing='5px'
                />
                <br></br>
                <span>Overall Rating : </span>
                <StarRatings
                  rating={i.rating}
                  starRatedColor='yellow'
                  starDimension='20px'
                  starSpacing='5px'
                />
                <br></br>
              </CardBody>
            </Card>
          </Col>
        );
      }, this);
    return (
      <React.Fragment>
        <>
          <Header />
          {/* Page content */}
          <Container className='mt--7' fluid>
            <div className='h5 mt-8'>
              <Form onSubmit={this.handleSubmit}>
                <Row form>
                  <Col md={8}>
                    <FormGroup>
                      <Label for='spid'>Select Service Provider</Label>
                      <Input
                        type='select'
                        name='spid'
                        id='spid'
                        value={this.state.spidd}
                        onChange={(e) => {
                          this.setState({ spidd: e.target.value });
                        }}>
                        {sparrayList}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Button color='success' value='Submit'>
                  SEARCH
                </Button>
              </Form>
              <br></br>
              <h1>Review</h1>
              <Row>
                <Col lg='3' md='6' sm='12'>
                  <StatisticsCard
                    hideChart
                    iconBg='success'
                    icon={<Zap className='success' size={30} />}
                    stat={this.state.review.length}
                    statTitle='Total Reviews'
                  />
                </Col>
              </Row>
              <br></br>
              <br></br>
              <h2>Review List</h2>
              <br></br>
              <br></br>
              <Row>{reviewList ? reviewList : <p>No Review exsits</p>}</Row>
            </div>
          </Container>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalBody>Do you want to delete</ModalBody>
            <ModalFooter>
              <Button color='info' onClick={this.toggle}>
                N0
              </Button>
              <Button color='danger' onClick={this.deletePromoCode}>
                YES
              </Button>
            </ModalFooter>
          </Modal>
        </>
      </React.Fragment>
    );
  }
}

export default Reviews;
