import React, { Component } from "react";

// reactstrap components
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormText,
  CardTitle,
  Badge,
  CardFooter,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
  ModalHeader,
} from "reactstrap";

import PageLoader from "../../components/PageLoader/PageLoader";
import "../styles/ResponsiveIFrame.css";
// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";
import RealTime from "../../services/RealTime";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import QRious from "qrious";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import ConvertArabic from "../../services/ConvertArabic";
// sp ID
var spID;
// current sp data from spID node in database
var spData;
// total vehicles count
var totalVehicles = 0;
// active vehicles count
var unassignedVehicles = 0;
// total earning
var totalEarning = 0;

var spid = "";
var qrNumber;
const searchOptions = {
  componentRestrictions: { country: ["us"] },
  types: ["city"],
};

// super admin logged in user id
var saID = "superadmin_twaddan_com";
// super admin logged in name
var saName;

// firebase.auth().onAuthStateChanged(function (user) {
//   if (user) {
//     // User is signed in.
//     console.log("The current user: ", user.email);

//     saID = user.email;
//     saID = saID.replace("@", "_");
//     saID = saID.replace(".", "_");

//     firebase
//       .database()
//       .ref("/twaddan_admin/super_admin_users/" + saID + "/")
//       .on("value", function (snapshot) {
//         saName = snapshot.val();
//         saName = saName.personal_information.name;
//       });
//   }
// });
var location = [];
var locationEdit = false;
const columns = [
  {
    text: "Vehicle Type",
    dataField: "vehicle_type",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service ID",
    dataField: "service_id",
    footer: false,
    align: "center",
    filter: textFilter(),
    headerAlign: "center",
    sort: true,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
    style: {
      minWidth: "300px",
    },
  },
  {
    text: "Service Name",
    dataField: "service_name",
    footer: false,
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Price",
    dataField: "price",
    footer: false,
    sort: true,
    align: "center",
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
    style: {},
  },
];
const defaultSorted = [
  {
    dataField: "vehicle_type",
    order: "desc",
  },
];

var maplocation;
export default class AddVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      name: "",
      name_ar: "",
      description: "",
      description_ar: "",
      additionalNote: "",
      additionalNote_ar: "",
      helperName: "",
      helperName_ar: "",
      helperNumber: "",
      downloadURL: "",
      file: "",
      imagePreviewUrl: "",
      spemail: "",
      additionalNote: "",
      number: "",
      modal: false,
      qrCodeLink: "",
      qrURL: "",
      disableVehicleField: false,
      count: 1,
      area: "",
      price: "",
      locationDetails: "",
      selectedLocation: "",
      services: null,
      selected: [],
      selectedId: [],
      selectedAddOn: [],
      selectedIdAddOn: [],
      selectedVehicleType: [],
      selectedServices: [],
      selectedAddOnServices: [],
      isUploading: false,
      serviceableAreas: "",
      sparray: [],
      driver_ids: [],
      selected_driver_id: "",
      previewModal: false,
      selectEmirate: "",
      map_preview: "",
      map_preview_name: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.generateQR = this.generateQR.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  handleChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
    }
  };
  generateQR = () => {
    const qr = new QRious();
    qr.value = qrNumber;
    qr.padding = 10;
    this.setState({ qrCodeLink: qr.toDataURL() });
  };

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
    if (stateName == "number") {
      qrNumber = spID + "-" + value;
      this.generateQR();
    }

    if (stateName == "description" && this.state.count == 1) {
      this.setState({
        count: 2,
        disableVehicleField: true,
      });
      this.generateQR();
    }
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
      isUploading: false,
    });
  }
  getEmirate = (emirates) => {
    let emi = "";
    Object.entries(emirates).forEach(([key, emirate]) => {
      if (emirate.service_providers)
        Object.entries(emirate.service_providers).forEach(([key2, sp]) => {
          if (key2 === spID) {
            this.setState({ emirate: emirate });
            emi = emirate;
            return emi;
          }
          console.log("key2", key2);
        });
    });
  };
  getSP = async () => {
    this.setState({ isLoaded: true });

    const services = await RealTime.getDetails("/service_providers/");
    let newService = [];
    newService.push({
      name: "select",
      id: null,
    });
    Object.entries(services).forEach(([key, service]) => {
      newService.push({
        name: service.personal_information
          ? service.personal_information.name
          : key,
        id: key,
      });
    });
    this.setState(
      {
        sparray: newService,
        isLoaded: false,
      },
      () => {
        console.log("this.state.isloaded", this.state.isLoaded);
      }
    );
  };
  selectSp = (e) => {
    console.log("e", e);
    spID = e.target.value;
    this.getData();
    e.preventDefault();
  };
  selectDriver = (e) => {
    this.setState({ selected_driver_id: e.target.value });
  };

  getData = async () => {
    this.setState({
      isLoaded: true,
    });
    const area = await RealTime.getDetails(
      "service_providers/" + spID + "/serviceable_areas"
    );

    let drivers = await RealTime.getDetails("drivers");
    console.log("drivers", drivers);

    let driver_ids = [{ id: "", name: "Select Driver" }];
    if (drivers) {
      Object.entries(drivers).forEach(([key, driver]) => {
        if (
          driver.personal_information &&
          driver.personal_information.vehicle_number === "" &&
          driver.sp_related === spID
        ) {
          console.log("driver-" + key, driver);
          driver_ids.push({
            id: key,
            name: driver.personal_information.name,
          });
        }
      });
    }
    if (area) {
      this.setState({
        isLoaded: false,
        serviceableAreas: Object.keys(area),
      });
    }
    this.setState({
      spemail: localStorage.getItem("email"),
    });
    let servicesFromDB = await RealTime.getDetails(
      "/service_providers/" + spID + "/new_services/"
    );
    let vehicle_types = [];
    Object.entries(servicesFromDB).forEach(([key, vehhicle_type]) => {
      if (vehhicle_type.status) {
        vehicle_types.push(key);
      }
    });

    let emirates = await RealTime.getDetails("/twaddan_admin/emirates/");

    let emirateSelected = "";
    Object.entries(emirates).forEach(([key, emirate]) => {
      if (emirate.service_providers)
        Object.entries(emirate.service_providers).forEach(([key2, sp]) => {
          if (key2 === spID) {
            this.setState({ emirate: key });
            //console.log("emirate", key);
            emirateSelected = key;
            return emirateSelected;
          }
          //console.log("key2", key2);
        });
    });
    //console.log("emirate", emirateSelected);

    let servicesAreasFromDB = await RealTime.getDetails(
      "/twaddan_admin/emirates/" + emirateSelected + "/serviceable_areas"
    );
    //console.log("servicesAreasFromDB", servicesAreasFromDB);
    let addOnService = [];
    let services = [];
    if (servicesFromDB) {
      Object.entries(servicesFromDB).forEach(([key, vehicle_type]) => {
        if (vehicle_type.major_services)
          Object.entries(vehicle_type.major_services).forEach(
            ([key2, service]) => {
              if (service.status)
                services.push({
                  id: key + "_" + key2,
                  vehicle_type: key,
                  service_id: key2,
                  service_name: service.name,
                  price: service.price,
                });
            }
          );
        if (vehicle_type.add_on_services)
          Object.entries(vehicle_type.add_on_services).forEach(
            ([key2, service]) => {
              if (service.status)
                addOnService.push({
                  id: key + "_" + key2,
                  vehicle_type: key,
                  service_id: key2,
                  service_name: service.name,
                  price: service.price,
                });
            }
          );
      });
    }

    this.setState({
      services: services,
      servicesFromDB: servicesFromDB,
      vehicle_types: vehicle_types,
      addOnServices: addOnService,
      servicesAreasFromDB: servicesAreasFromDB,
      isLoaded: false,
      driver_ids: driver_ids,
    });
  };

  async componentDidMount() {
    this.getSP();
    this.setState({ isLoaded: true });
  }
  validate = () => {
    if (this.state.selected.length < 1) {
      alert("Please select atleast one major service");
      return;
    } else if (this.state.locationDetails.length < 1) {
      alert("Please select atleast one serviceable area");
      return;
    } else {
      this.uploadImage();
    }
  };
  convert = async () => {
    if (this.state.name_ar === "")
      this.setState({
        name_ar: await ConvertArabic(this.state.name),
      });
    if (this.state.description_ar === "")
      this.setState({
        description_ar: await ConvertArabic(this.state.description),
      });
    if (this.state.additionalNote_ar === "")
      this.setState({
        additionalNote_ar: await ConvertArabic(this.state.additionalNote),
      });
    if (this.state.helperName_ar === "")
      this.setState({
        helperName_ar: await ConvertArabic(this.state.helperName),
      });
  };
  uploadImage = () => {
    // console.log("file: ", this.state.file, "name: ", this.state.file.name);
    const extension = this.state.file.name?.split(".").pop();
    this.setState({ isUploading: true });

    firebase
      .storage()
      .ref(
        "/service_providers/" +
          spID +
          "/vehicles/" +
          "qrcode_" +
          this.state.number +
          ".png"
      )
      .putString(this.state.qrCodeLink, "data_url")
      .then(function (snapshot) {
        console.log("Uploaded a data_url string!");
        return snapshot.ref.getDownloadURL();
      })
      .then((qrURL) => {
        console.log(
          `Successfully uploaded file and got download link - ${qrURL}`
        );
        this.setState({
          qrURL: qrURL,
        });
      })
      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
    // this.pushData();

    firebase
      .storage()
      .ref(
        "/service_providers/" +
          spID +
          "/vehicles/" +
          "vehicle_" +
          this.state.number +
          "." +
          extension
      )
      .put(this.state.file)
      .then((snapshot) => {
        // Will return a promise with the download link
        return snapshot.ref.getDownloadURL();
      })
      .then((downloadURL) => {
        console.log(
          `Successfully uploaded file and got download link - ${downloadURL}`
        );
        this.setState({
          downloadURL: downloadURL,
        });
        this.pushData();
      })
      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
  };

  pushData = () => {
    let servicesFromDB = this.state.servicesFromDB;
    let driver_assigned = this.state.selected_driver_id !== "";
    let vts = {};
    this.state.selected.map(async (item) => {
      if (!(item.vehicle_type in vts)) {
        vts[item.vehicle_type] = {
          name: servicesFromDB[item.vehicle_type].name,
          name_ar: servicesFromDB[item.vehicle_type].name_ar,
          status: servicesFromDB[item.vehicle_type].status,
          major_services: {
            [item.service_id]:
              servicesFromDB[item.vehicle_type].major_services[item.service_id],
          },
        };
      } else {
        if (vts[item.vehicle_type].major_services)
          if (!(item.service_id in vts[item.vehicle_type].major_services)) {
            vts[item.vehicle_type].major_services[item.service_id] =
              servicesFromDB[item.vehicle_type].major_services[item.service_id];
          }
      }
    });
    this.state.selectedAddOn.map((item) => {
      console.log("vts[item.vehicle_type]", vts[item.vehicle_type]);
      if (vts[item.vehicle_type])
        if (!("add_on_services" in vts[item.vehicle_type])) {
          vts[item.vehicle_type].add_on_services = {
            [item.service_id]:
              servicesFromDB[item.vehicle_type].add_on_services[
                item.service_id
              ],
          };
        } else {
          vts[item.vehicle_type].add_on_services[item.service_id] =
            servicesFromDB[item.vehicle_type].add_on_services[item.service_id];
        }
    });
    let servicesAreasFromDB = this.state.servicesAreasFromDB;
    this.state.locationDetails.map((location) => {
      Object.entries(servicesAreasFromDB).forEach(([key, locationFromDB]) => {
        if (location.area === key) {
          if (!("service_providers" in locationFromDB)) {
            servicesAreasFromDB[location.area].service_providers = {
              [spID]: {
                status: true,
                vehicles: {
                  [this.state.number]: {
                    status: driver_assigned,
                    price: location.price,
                  },
                },
              },
            };
          } else {
            if (spID in servicesAreasFromDB[location.area].service_providers) {
              servicesAreasFromDB[location.area].service_providers[
                spID
              ].vehicles[this.state.number] = {
                status: driver_assigned,
                price: location.price,
              };
            } else {
              servicesAreasFromDB[location.area].service_providers[spID] = {
                status: true,
                vehicles: {
                  [this.state.number]: {
                    status: driver_assigned,
                    price: location.price,
                  },
                },
              };
            }
          }
        }
      });
    });
    firebase
      .database()
      .ref(
        "/twaddan_admin/emirates/" + this.state.emirate + "/serviceable_areas"
      )
      .update(servicesAreasFromDB);
    var time = new Date();
    time = time.getTime();
    firebase
      .database()
      .ref("service_providers/" + spID + "/vehicles/" + this.state.number + "/")
      .set({
        name: this.state.name,
        name_ar: this.state.name_ar,
        number: this.state.number,
        description: this.state.description,
        description_ar: this.state.description_ar,
        additionalNote: this.state.additionalNote,
        additionalNote_ar: this.state.additionalNote_ar,
        helperName: this.state.helperName,
        helperName_ar: this.state.helperName_ar,
        helperNumber: this.state.helperNumber,
        driver: this.state.selected_driver_id,
        driver_status: false,
        status: true,
        image_url: this.state.downloadURL,
        qrcode_url: this.state.qrURL,
        serviceable_areas: this.state.locationDetails,
        vehicle_type: vts,
        registeredDate: time,
        emirate: this.state.emirate,
      });

    var spName;

    //updating vehicle number in driver node
    if (this.state.selected_driver_id !== "") {
      let driver_history = [
        {
          driver_id: this.state.selected_driver_id,
          driver_assigned: time,
          driver_removed: "",
        },
      ];
      firebase
        .database()
        .ref(
          "service_providers/" + spID + "/vehicles/" + this.state.number + "/"
        )
        .update({ driver_history: driver_history });
      RealTime.updateNode(
        "drivers/" + this.state.selected_driver_id + "/personal_information/",
        { vehicle_number: this.state.number }
      );
    }

    // Add vehcles under the vehcle node
    firebase
      .database()
      .ref("vehicles/" + this.state.number + "/")
      .set({ status: true });
    firebase
      .database()
      .ref("service_providers/" + spID + "/personal_information/")
      .on("value", function (snapshot) {
        spName = snapshot.val();
        spName = spName.name;
      });
    var time = new Date();
    time = time.getTime();
    var logAction =
      "Added New Vehicle named " +
      this.state.name +
      " with Vehicle Number " +
      this.state.number +
      " under " +
      spName +
      " Service Provider with SP ID " +
      spID;
    var logs = firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/logs/" + time + "/");
    logs.update({
      action: logAction,
    });
    this.toggle();
  };
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state.file);
    this.validate();
  }

  navigate = () => {
    this.setState({
      vehicle: 1,
    });
  };

  toggleMapPreview = () => {
    this.setState({
      previewModal: !this.state.previewModal,
    });
  };

  handleMapPreview = async (area) => {
    if (area) {
      this.setState({
        map_preview: "",
        map_preview_name: "",
      });
      const emirates = await RealTime.getDetails("twaddan_admin/emirates");
      if (emirates) {
        Object.entries(emirates).map(([key, value]) => {
          if (value.service_providers) {
            Object.keys(value.service_providers).map((value1, key1) => {
              if (value1 === spID) {
                if (value.serviceable_areas) {
                  Object.entries(value.serviceable_areas).map(
                    ([key2, value2]) => {
                      if (key2 === area) {
                        this.setState({
                          map_preview: value2.map_preview,
                          map_preview_name: area,
                          previewModal: true,
                        });
                      }
                    }
                  );
                }
              }
            });
          }
        });
      }
    }
    console.log(this.state.map_preview);
  };

  handleSubmitLocation = (event) => {
    var flag = true;
    if (this.state.area !== "" && this.state.price !== "") {
      location.map((loc) => {
        if (loc.area === this.state.area) {
          flag = false;
        }
      });
      if (flag) {
        location.push({
          area: this.state.area,
          price: this.state.price,
          status: true,
        });
      }
      this.setState({
        locationDetails: location,
      });
      locationEdit = false;
    }
    this.setState({
      area: "",
      price: "",
    });
    event.preventDefault();
  };

  removeLocation = (area) => {
    var locationDetails = location;
    const filteredLocation = locationDetails.filter(
      (item) => item.area !== area
    );
    location = filteredLocation;
    this.setState({
      locationDetails: location,
    });
  };

  editLocation = (location) => {
    this.setState({
      area: location.area,
      price: location.price,
    });
    this.removeLocation(location.area);
  };

  handleChangePlace = (area) => {
    this.setState({
      area,
    });
  };

  handleSelectPlace = (area) => {
    this.setState({
      area: area,
    });
    geocodeByAddress(area)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  // toggleCheckBoxChange = (name) => {
  //   const { services, addOnServices } = this.state;
  //   // list[name] = !list[name];
  //   let isSelect = false;
  //   if (!this.state.selectedVehicleType.includes(name)) {
  //     isSelect = true;
  //     this.setState(() => ({
  //       selectedVehicleType: [...this.state.selectedVehicleType, name],
  //     }));
  //   } else {
  //     this.setState(() => ({
  //       selectedVehicleType: this.state.selectedVehicleType.filter(
  //         (x) => x !== name
  //       ),
  //     }));
  //   }
  //   console.log(
  //     "this.state.selectedVehicleType",
  //     this.state.selectedVehicleType
  //   );

  //   if (isSelect) {
  //     Object.entries(services).forEach(([key, service]) => {
  //       if (service.vehicle_type === name) {
  //         console.log("service", service);

  //         this.setState(() => ({
  //           selectedServices: [...this.state.selectedServices, service],
  //         }));
  //       }
  //     });
  //     Object.entries(addOnServices).forEach(([key, addOnService]) => {
  //       if (addOnService.vehicle_type === name) {
  //         this.setState(() => ({
  //           selectedAddOnServices: [
  //             ...this.state.selectedAddOnServices,
  //             addOnService,
  //           ],
  //         }));
  //       }
  //     });
  //   } else {
  //     // this.setState(() => ({
  //     //   selectedServices: this.state.selectedServices.filter((x) => x !== row),
  //     //   selectedAddOnServices: this.state.selectedAddOnServices.filter(
  //     //     (x) => x !== row.id
  //     //   ),
  //     // }));
  //   }
  //   // this.setState({ vehicleTypesList: list });
  //   // console.log("list[name] = !list[name];", list[name], !list[name]);
  //   console.log("this.state.selectedServices", this.state.selectedServices);

  //   console.log("Service key = ", name);
  //   console.log("vehicle types selected = ", services);
  // };
  handleOnSelect = (row, isSelect) => {
    console.log("row");
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row],
        selectedId: [...this.state.selectedId, row.id],
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter((x) => x !== row),
        selectedId: this.state.selectedId.filter((x) => x !== row.id),
      }));
    }
  };
  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    const row = rows.map((r) => r);
    if (isSelect) {
      this.setState(() => ({
        selected: row,
        selectedId: ids,
      }));
    } else {
      this.setState(() => ({
        selected: [],
        selectedId: [],
      }));
    }
  };
  handleOnSelectAddOn = (row, isSelect) => {
    console.log("row", row);

    if (isSelect) {
      this.setState(() => ({
        selectedAddOn: [...this.state.selectedAddOn, row],
        selectedIdAddOn: [...this.state.selectedIdAddOn, row.id],
      }));
    } else {
      this.setState(() => ({
        selectedAddOn: this.state.selectedAddOn.filter((x) => x !== row),
        selectedIdAddOn: this.state.selectedIdAddOn.filter((x) => x !== row.id),
      }));
    }
  };
  handleOnSelectAllAddOn = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    const row = rows.map((r) => r);
    if (isSelect) {
      this.setState(() => ({
        selectedAddOn: row,
        selectedIdAddOn: ids,
      }));
    } else {
      this.setState(() => ({
        selectedAddOn: [],
        selectedIdAddOn: [],
      }));
    }
  };

  render() {
    if (this.state.vehicle == 1) {
      return <Redirect to='/admin/vehicles' />;
    }
    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl, sparray, driver_ids } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }

    let { qrCodeLink } = this.state;
    let $qrcodePreview = null;
    if (qrCodeLink) {
      $qrcodePreview = <img src={qrCodeLink} style={imagestyle} />;
    }
    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selectedId,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };
    const selectRowAddOn = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selectedIdAddOn,
      onSelect: this.handleOnSelectAddOn,
      onSelectAll: this.handleOnSelectAllAddOn,
    };
    let areaList =
      this.state.serviceableAreas.length > 0 &&
      this.state.serviceableAreas.map((i) => {
        return <option>{i}</option>;
      }, this);
    let driver_list =
      driver_ids.length > 0 &&
      driver_ids.map((i) => {
        return <option value={i.id}>{i.name}</option>;
      }, this);
    let sparrayList =
      sparray.length > 0 &&
      sparray.map((i) => {
        return <option value={i.id}>{i.name}</option>;
      }, this);
    return (
      <>
        <Header />
        {/* Page content */}
        <PageLoader loading={this.state.isLoaded}></PageLoader>
        <PageLoader loading={this.state.isUploading}></PageLoader>
        <Container className=' mt--7' fluid>
          <Card>
            <CardHeader>
              <Row className='align-items-center'>
                <Col xs={8} sm={6} md={7} lg={8} xl={10}>
                  <h3 className='mb-0'>Vehicle Details</h3>
                </Col>
                <Col xs={4} sm={6} md={5} lg={4} xl={2}>
                  <Button
                    block
                    color='primary'
                    className='button_back'
                    onClick={this.navigate}>
                    BACK
                  </Button>
                </Col>
              </Row>
              {/* <CardTitle>Add Vehicle</CardTitle> */}
            </CardHeader>
            <CardBody>
              <Row form>
                <Col xs={12}>
                  <FormGroup row>
                    <Label xs={12} sm={4} md={4} lg={4} xl={3} for='spid'>
                      Select Service Provider
                    </Label>
                    <Col xs={12} sm={8} md={8} lg={8} xl={9}>
                      <Input
                        type='select'
                        name='spid'
                        id='spid'
                        value={this.state.spidd}
                        onChange={(e) => this.selectSp(e)}>
                        {sparrayList}
                      </Input>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              {spID && (
                <Form onSubmit={this.handleSubmit}>
                  {/* <Button onClick={this.test}>test</Button> */}
                  <FormGroup>
                    <Label for='File'>Add image</Label>
                    <br></br>
                    <input
                      type='file'
                      accept='image/png, image/jpeg'
                      onChange={this._handleImageChange}
                    />
                    <FormText color='muted'>
                      Select the picture of the vehicle that you wanted to
                      display.
                    </FormText>
                    <Row>
                      <Col md={4} xs={2}>
                        <div style={imagestyle}>{$imagePreview}</div>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className='input-group-alternative mb-3'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-delivery-fast' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Vehicle Name'
                        type='text'
                        value={this.state.name}
                        required
                        onChange={(e) => this.onChange("name", e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className='input-group-alternative mb-3'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-delivery-fast' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Vehicle Name Arabic'
                        type='text'
                        value={this.state.name_ar}
                        required
                        onChange={(e) =>
                          this.onChange("name_ar", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className='input-group-alternative mb-3'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-credit-card' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        // disabled = {this.state.disableVehicleField}
                        placeholder='Vehicle Number'
                        type='text'
                        required
                        onChange={(e) =>
                          this.onChange("number", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className='input-group-alternative mb-3'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-bullet-list-67' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        // disabled={this.state.number ===""?true:false}
                        placeholder='Vehicle Description'
                        type='text'
                        required
                        value={this.state.description}
                        onChange={(e) =>
                          this.onChange("description", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className='input-group-alternative mb-3'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-bullet-list-67' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        // disabled={this.state.number ===""?true:false}
                        placeholder='Vehicle Description arabic'
                        type='text'
                        value={this.state.description_ar}
                        required
                        onChange={(e) =>
                          this.onChange("description_ar", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className='input-group-alternative'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-ruler-pencil' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type='textarea'
                        name='note'
                        value={this.state.additionalNote}
                        id='note'
                        placeholder='Additional Information to know more about vehicle'
                        onChange={(e) =>
                          this.onChange("additionalNote", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className='input-group-alternative'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-ruler-pencil' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type='textarea'
                        name='note'
                        id='note'
                        value={this.state.additionalNote_ar}
                        placeholder='Additional Information to know more about vehicle arabic'
                        onChange={(e) =>
                          this.onChange("additionalNote_ar", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className='input-group-alternative mb-3'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-delivery-fast' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Helper Name'
                        type='text'
                        value={this.state.helperName}
                        required
                        onChange={(e) =>
                          this.onChange("helperName", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className='input-group-alternative mb-3'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-delivery-fast' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Helper Name Arabic'
                        type='text'
                        value={this.state.helperName_ar}
                        required
                        onChange={(e) =>
                          this.onChange("helperName_ar", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className='input-group-alternative mb-3'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-delivery-fast' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Helper Number'
                        type='text'
                        value={this.state.helperNumber}
                        required
                        onChange={(e) =>
                          this.onChange("helperNumber", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label for='spid'>Select Driver</Label>
                    <Input
                      type='select'
                      name='spid'
                      id='spid'
                      value={this.state.selected_driver_id}
                      onChange={(e) => this.selectDriver(e)}>
                      {driver_list}
                    </Input>
                  </FormGroup>
                  {/* Services adding */}
                  {/* <h6 className="heading-small text-muted mb-4">
                    Select Vehicle Type
                  </h6>
                  <div className="pl-lg-4">
                    {this.state.vehicle_types &&
                      this.state.vehicle_types.map((line, key) => {
                        return (
                          <>
                            <Col lg="4">
                              <div className="custom-control custom-control-alternative custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  id={line}
                                  // defaultChecked={this.state.vehicleTypesList[line]}
                                  onClick={() => {
                                    this.toggleCheckBoxChange(line);
                                  }}
                                />

                                <label
                                  className="custom-control-label"
                                  htmlFor={line}
                                >
                                  {line.replace("_", " ")}
                                </label>
                              </div>
                            </Col>
                          </>
                        );
                      })}
                  </div> */}
                  <h6 className='heading-small text-muted mb-4'>
                    Select Services
                  </h6>
                  <div>
                    {this.state.services && (
                      <BootstrapTable
                        keyField='id'
                        data={this.state.services}
                        columns={columns}
                        filter={filterFactory()}
                        filterPosition='top'
                        //pagination={paginationFactory()}
                        defaultSorted={defaultSorted}
                        bordered={false}
                        selectRow={selectRow}
                        hover
                        wrapperClasses='table-responsive'
                        condensed
                        // rowEvents={rowEvents}
                      />
                    )}
                  </div>
                  <h6 className='heading-small text-muted mb-4'>
                    Select Add On Services
                  </h6>
                  <div>
                    {this.state.addOnServices && (
                      <BootstrapTable
                        keyField='id'
                        data={this.state.addOnServices}
                        columns={columns}
                        filter={filterFactory()}
                        filterPosition='top'
                        //pagination={paginationFactory()}
                        defaultSorted={defaultSorted}
                        bordered={false}
                        selectRow={selectRowAddOn}
                        hover
                        wrapperClasses='table-responsive'
                        condensed
                        // rowEvents={rowEvents}
                      />
                    )}
                  </div>
                  {/* --------------------------------------------------------------------------------------------------------- */}
                  <Form name='placeCharge' id='placeCharge'>
                    <Row form>
                      <Col md={4}>
                        <Input
                          type='select'
                          name='spid'
                          id='spid'
                          required
                          value={this.state.area}
                          onChange={(e) => {
                            this.setState({ area: e.target.value });
                          }}>
                          <option hidden>Select Serviceable Area</option>
                          {areaList}
                        </Input>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                              <InputGroupText>
                                <i className='ni ni-credit-card' />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              name='price'
                              id='price'
                              placeholder='Enter price'
                              required
                              value={this.state.price}
                              onChange={(e) =>
                                this.onChange("price", e.target.value)
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Row>
                            <Col md={6}>
                              <Button
                                type='button'
                                color='primary'
                                disabled={this.state.area ? false : true}
                                onClick={() => {
                                  this.handleMapPreview(this.state.area);
                                }}
                                block>
                                PREVIEW
                              </Button>
                            </Col>
                            <Col md={6}>
                              <Button
                                type='button'
                                color='success'
                                onClick={this.handleSubmitLocation}
                                block>
                                {locationEdit ? "UPDATE" : "ADD"}
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <FormGroup>
                    {this.state.locationDetails.length > 0 && (
                      <div>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Area</th>
                              <th>Price</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          {this.state.locationDetails.map((location) => (
                            <tbody>
                              <tr>
                                <td>{location.area}</td>
                                <td>{location.price}</td>
                                <td>
                                  <Button
                                    type='button'
                                    color='primary'
                                    onClick={() => {
                                      this.handleMapPreview(location.area);
                                    }}>
                                    PREVIEW
                                  </Button>
                                  <Button
                                    color='danger'
                                    onClick={() => {
                                      this.removeLocation(location.area);
                                    }}>
                                    REMOVE
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          ))}{" "}
                        </Table>
                      </div>
                    )}

                    {this.state.number && (
                      <Row>
                        {/* 
                        <Col>
                          <QRCode
                            id="qrcode"
                            value={this.state.number}
                            size={290}
                            level={"H"}
                            includeMargin={true}
                          />
                        </Col> 
                      */}
                        <Col>
                          <Col md={4} xs={4}>
                            <div style={imagestyle}>{$qrcodePreview}</div>
                          </Col>
                        </Col>
                        <Col>
                          <br />
                          <br />
                          <br />
                          <br />
                          <div className='col'>
                            <h3 className='mb-0'>
                              QR Code will be generated for the vehicle ...
                            </h3>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </FormGroup>
                  <Row>
                    <Col xs={12} sm={12} md={5} lg={4} xl={2}>
                      <Button
                        className='buttonConvert'
                        color='primary'
                        block
                        onClick={this.convert}>
                        CONVERT TO ARABIC
                      </Button>
                    </Col>
                    <Col
                      className='buttonCancelSubmitCol'
                      xs={6}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2}>
                      <Button block onClick={this.navigate}>
                        CANCEL
                      </Button>
                    </Col>
                    <Col
                      className='buttonCancelSubmitCol'
                      xs={6}
                      sm={6}
                      md={3}
                      lg={3}
                      xl={2}>
                      <Button block color='success' value='Submit'>
                        ADD VEHICLE
                      </Button>
                    </Col>
                  </Row>
                  {/* <Button onClick={this.navigate}>CANCEL</Button>{" "}
                  <Button color="success" value="Submit">
                    {" "}
                    ADD VEHICLE{" "}
                  </Button> */}
                </Form>
              )}
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Congratulation new vehicle added Successfully</ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.navigate}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.previewModal}
          toggle={this.toggleMapPreview}
          size='lg'>
          <ModalHeader toggle={this.toggleMapPreview}>
            {this.state.map_preview_name}
          </ModalHeader>
          <ModalBody>
            <>
              <div className='containerIframe'>
                <iframe
                  class='responsive-iframe'
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&q=${this.state.map_preview}`}
                  allowfullscreen></iframe>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleMapPreview}>CLOSE</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
