import React, { Component } from "react";
import firebaseConfig from "../../firebaseConfig";

// reactstrap components
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Form,
  FormGroup,
  Label,
  Input,
  Multiselect,
  Table,
  CardImg,
  Row,
  Col,
  FormText,
  CardTitle,
  Badge,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";

// sp ID
var spID;
// current sp data from spID node in database
var spData;
// total vehicles count
var totalVehicles = 0;
// active vehicles count
var unassignedVehicles = 0;

// total drivers
var totalDrivers = 0;
// active drivers
var activeDrivers = 0;

// driver details of current sp
var driversDetails = [];

// total drivers list
var totalDriversList = [];
// active drivers list
var activeDriversList = [];

// vehicle list
var vehicleList = [];
//emirates filter
var emirates = [
  "Abu Dhabi",
  "Ajman",
  "Dubai",
  "Fujairah",
  "Ras al-Khaimah",
  "Sharjah",
  "Umm al-Quwain",
];

var activeEmirates = {};

emirates.map((i) => {
  activeEmirates[i] = false;
});

var spid = "";
// super admin logged in user id
var saID = "superadmin_twaddan_com";
// super admin logged in name
var saName;

// firebase.auth().onAuthStateChanged(function (user) {
//   if (user) {
//     // User is signed in.
//     console.log("The current user: ", user.email);

//     saID = user.email;
//     saID = saID.replace("@", "_");
//     saID = saID.replace(".", "_");

//     firebase
//       .database()
//       .ref("/twaddan_admin/super_admin_users/" + saID + "/")
//       .on("value", function (snapshot) {
//         saName = snapshot.val();
//         saName = saName.personal_information.name;
//       });
//   }
// });

export default class AddDrivers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spidd: "",
      sparray: [],
      driver: 0,
      cancel: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  handleSubmit(event) {
    console.log(this.state.spidd);
    this.storeCurrentSpid();
    event.preventDefault();
  }
  getData = () => {
    const dr = firebase.database().ref("service_providers/");
    let newService = [];
    newService.push({
      name: "select",
    });
    dr.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        var keys = Object.keys(drs);
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          newService.push({
            name: drs[k].personal_information.name,
            id: k,
          });
        }
      }
      this.setState({
        sparray: newService,
      });
    });
  };
  storeCurrentSpid = () => {
    this.state.sparray.map((i) => {
      if (i.name === this.state.spidd) {
        spid = i.id;
        this.setState({
          driver: 1,
        });
        spID = i.id;

        console.log(spid);
      }
    });

    // console.log('The current user email: ', this.state.sp_email);
    console.log("The current  selected spid: ", spID);

    firebase
      .database()
      .ref("/service_providers/" + spID + "/")
      .on("value", function (snapshot) {
        spData = snapshot.val() || "Anonymous";

        // // total vehicles
        // totalVehicles = 0;
        // unassignedVehicles = 0;
        // if (spData.vehicles) {
        //   totalVehicles = Object.keys(spData.vehicles).length;
        //   vehicleList = [];
        //   vehicleList[0] = "Select Vehicle";
        //   Object.keys(spData.vehicles).map((line, key) => {
        //     vehicleList[key + 1] = line;
        //   });
        // }

        // console.log("total vehicles = ", totalVehicles);

        // total drivers
        totalDrivers = 0;
        if (spData.drivers_info) {
          if (spData.drivers_info.drivers_id) {
            totalDrivers = Object.keys(spData.drivers_info.drivers_id).length;
            totalDriversList = spData.drivers_info.drivers_id;
            driversDetails = [];
            Object.keys(spData.drivers_info.drivers_id).map((line, key) => {
              firebase
                .database()
                .ref("/drivers/" + line + "/")
                .on("value", function (snapshot) {
                  var driver = snapshot.val() || "Anonymous";

                  driversDetails[key] = {
                    driver,
                    driverID: line,
                    DriverStatus: spData.drivers_info.drivers_id[line],
                  };
                });
            });
          }
        }

        console.log("total drivers = ", totalDrivers);
        console.log("driver details = ", driversDetails);
        // active drivers
        activeDrivers = 0;
        if (spData.drivers_info) {
          if (spData.drivers_info.active_drivers_id) {
            activeDrivers = Object.keys(spData.drivers_info.active_drivers_id)
              .length;
            activeDriversList = spData.drivers_info.active_drivers_id;
          }
        }

        console.log("active drivers = ", activeDrivers);
      });
  };
  navigate = () => {
    this.setState({
      cancel: 1,
    });
  };
  render() {
    const { sparray } = this.state;

    let sparrayList =
      sparray.length > 0 &&
      sparray.map((i) => {
        return <option>{i.name}</option>;
      }, this);
    if (this.state.driver === 1) {
      return <AddDriver />;
    }
    if (this.state.cancel === 1) {
      return <Redirect to='/admin/drivers' />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <Card>
            <CardHeader>
              <CardTitle>Add Driver</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                <Row form>
                  <Col md={8}>
                    <FormGroup>
                      <Label for='spid'>Select Service Provider</Label>
                      <Input
                        type='select'
                        name='spid'
                        id='spid'
                        value={this.state.spidd}
                        onChange={(e) => {
                          this.setState({ spidd: e.target.value });
                        }}>
                        {sparrayList}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Button color='primary' onClick={this.navigate}>
                  CANCEL
                </Button>
                <Button color='success' value='Submit'>
                  NEXT
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

class AddDriver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      username_ar: "",
      email: "",
      driverID: "",
      phoneNumber: "",
      password: "",
      passwordRetype: "",
      description: "",
      isChecked: false,
      downloadURL: "",
      file: "",
      imagePreviewUrl: "",
      spemail: "",
      additionalNote: "",
      modal: false,
      vehicleNumber: "",
      vehicleList: "",
      status: "1",
      driverStatus: [],
      latitude: "",
      longitude: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.createDriverAccount = this.createDriverAccount.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.pushData = this.pushData.bind(this);
    this.navigate = this.navigate.bind(this);
    this.toggleCheckBoxEmirates = this.toggleCheckBoxEmirates.bind(this);
  }

  handleChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
    }
  };

  toggleChange() {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  navigate = () => {
    this.setState({
      driver: 1,
    });
  };

  toggleSwitch = (key) => {
    this.setState((state) => {
      const driverStatus = state.driverStatus.map((ID, driverKey) => {
        if (driverKey == key) {
          console.log(" from : ", ID, "to : ", !ID);
          if (ID == false) {
            delete activeDriversList[driverIDList];
          } else {
            activeDriversList[driverIDList] = true;
          }
          return !ID;
        } else {
          return ID;
        }
      });

      return {
        driverStatus,
      };
    });

    let driverIDList = driversDetails[key].driverID;
    totalDriversList[driverIDList] = !totalDriversList[driverIDList];

    firebase
      .database()
      .ref("service_providers/" + spID + "/drivers_info/")
      .update({
        driver_id: totalDriversList,
        active_drivers_id: activeDriversList,
      });
    // console.log("checking status : ", totalDriversList, activeDriversList);
  };
  createAuthUser = async (email, password) => {
    var uid = "";
    const axios = require("axios").default;
    const url = `https://us-central1-${firebaseConfig.options.projectId}.cloudfunctions.net/createUser`;
    const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios
      .post(url, {
        data: { email, password },
      })
      .catch(() => {
        return (uid = false);
      });
    if (reply && reply.data && reply.data.result && reply.data.result.uid)
      uid = reply.data.result.uid;
    else uid = false;
    return uid;
  };
  createDriverAccount = async () => {
    const uid = await this.createAuthUser(
      this.state.email,
      this.state.password
    );
    var urlLink;
    const {
      username,
      username_ar,
      email,
      driverID,
      phoneNumber,
      password,
      passwordRetype,
      description,
      isChecked,
      downloadURL,
      file,
      imagePreviewUrl,
      spemail,
      additionalNote,
      modal,
      vehicleNumber,
      vehicleList,
      status,
      driverStatus,
      latitude,
      longitude,
    } = this.state;

    //  createUser({ email, password })
    //   .then(({ data: user }) => {
    if (uid) {
      var userId = email;
      userId = userId.replace("@", "_");
      userId = userId.replace(".", "_");
      var time = new Date();
      time = time.getTime();
      console.log("driver = ", email, " is created sucessfull");

      // we take the user id and it's name and we add it in our
      // user-details table
      firebase
        .database()
        .ref("drivers/" + driverID + "/personal_information/")
        .set({
          userId: uid,
          name: username,
          username_ar: username_ar,
          times_rated: Number(0),
          description: description,
          email: email,
          phone_number: phoneNumber,
        });

      console.log("file: ", file, "name: ", file.name);
      const extension = file.name.split(".").pop();
      console.log("spspspspspspsp == ", spID);

      firebase
        .storage()
        .ref(
          "/service_providers/" +
            spID +
            "/drivers_info/" +
            "driver_" +
            phoneNumber +
            "." +
            extension
        )
        .put(file)
        .then((snapshot) => {
          // Will return a promise with the download link
          return snapshot.ref.getDownloadURL();
        })
        .then((downloadURL) => {
          console.log(
            `Successfully uploaded file and got download link - ${downloadURL}`
          );
          // this.setState({
          //   downloadURL:downloadURL,
          // })
          urlLink = downloadURL;
          console.log("driver ID : ", driverID);

          firebase
            .database()
            .ref("drivers/" + driverID + "/")
            .set({
              sp_related: spID,
              status: Number(0),
              created_on: time,
            });

          firebase
            .database()
            .ref("drivers/" + driverID + "/live_location/")
            .set({
              latitude: Number(25.7863988),
              longitude: Number(87.4658145),
              lc_lat: Number(25.7863988),
              lc_lng: Number(87.4658145),
              time_driver_engaged: 0,
            });

          firebase
            .database()
            .ref("drivers/" + driverID + "/personal_information/")
            .update({
              name: username,
              username_ar: username_ar,
              email: email,
              phone_number: phoneNumber,
              image_url: urlLink,
              times_rated: 0,
              rating: "0",
              vehicle_number: "",
              employeeBehaviourRating: "",
              professionalAppearanceRating: "",
              qualityOfServiceRating: "",
              valueOfServiceRating: "",
              arrivalTimeRating: "",
            })
            .catch((error) => {
              console.log(`Failed to update personal information - ${error}`);
            });
          // emirates.map((i)=>{
          //   if(activeEmirates[i]===true)
          //   {
          //     firebase.database().ref("twaddan_admin/emirates/" + i +'/').update({
          //       [driverID]:true,
          //     })
          //   }
          // })

          firebase
            .database()
            .ref("service_providers/" + spID + "/drivers_info/drivers_id/")
            .update({
              [driverID]: false,
            });

          // firebase
          //   .database()
          //   .ref(
          //     "service_providers/" + spID + "/vehicles/" + vehicleNumber + "/"
          //   )
          //   .update({
          //     driver: driverID,
          //   });

          var totalDriversCount = spData.drivers_info.total_drivers;
          totalDriversCount = Number(totalDriversCount);
          totalDriversCount = totalDriversCount + 1;

          firebase
            .database()
            .ref("service_providers/" + spID + "/drivers_info/")
            .update({
              total_drivers: Number(totalDriversCount),
            });

          var spName;
          firebase
            .database()
            .ref("service_providers/" + spID + "/personal_information/")
            .on("value", function (snapshot) {
              spName = snapshot.val();
              spName = spName.name;
            });

          var logAction =
            "Added New Driver named " +
            username +
            " with Driver ID " +
            driverID +
            " under " +
            spName +
            " Service Provider with SP ID " +
            spID;
          var logs = firebase
            .database()
            .ref(
              "/twaddan_admin/super_admin_users/" + saID + "/logs/" + time + "/"
            );
          logs.update({
            action: logAction,
          });
        })
        .catch((error) => {
          // Use to signal error if something goes wrong.
          console.log(`Failed to upload file and get link - ${error}`);
        });

      this.toggle();
    } else {
      alert("Email already Exist");
    }
  };

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });

    // console.log(stateName,value);
    if (stateName == "email") {
      let driverID = value;
      driverID = driverID.replace("@", "_");
      driverID = driverID.replace(".", "_");

      this.setState({
        driverID: driverID,
      });
    }
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount() {
    this.setState({
      spemail: localStorage.getItem("email"),
    });

    this.getDriverStatus();
  }

  getDriverStatus = () => {
    let status = [];
    if (spData.drivers_info) {
      if (spData.drivers_info.drivers_id) {
        Object.keys(spData.drivers_info.drivers_id).map((line, key) => {
          firebase
            .database()
            .ref("/drivers/" + line + "/")
            .on("value", function (snapshot) {
              var driver = snapshot.val() || "Anonymous";

              status[key] = spData.drivers_info.drivers_id[line];
            });
        });
      }
    }

    this.setState({ driverStatus: status });

    // if(spData.vehicles) {
    //   this.setState({
    //     vehicleList: spData.vehicles
    //   })
    // }
  };

  uploadImage = () => {
    console.log("file: ", this.state.file, "name: ", this.state.file.name);
    const extension = this.state.file.name.split(".").pop();

    firebase
      .storage()
      .ref(
        "/service_providers/" +
          spID +
          "/drivers_info/" +
          "driver_" +
          this.state.phoneNumber +
          "." +
          extension
      )
      .put(this.state.file)
      .then((snapshot) => {
        // Will return a promise with the download link
        return snapshot.ref.getDownloadURL();
      })
      .then((downloadURL) => {
        console.log(
          `Successfully uploaded file and got download link - ${downloadURL}`
        );
        this.setState({
          downloadURL: downloadURL,
        });
        this.pushData();
      })
      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
  };

  pushData = () => {
    console.log("driver ID : ", this.state.driverID);

    firebase
      .database()
      .ref("drivers/" + this.state.driverID + "/personal_information/")
      .update({
        name: this.state.username,
        email: this.state.email,
        phone_number: this.state.phoneNumber,
        image_url: this.state.downloadURL,
        timesRated: Number(0),
        rating: Number(0),
        vehicle_number: "",
      })
      .catch((error) => {
        console.log(`Failed to update personal information - ${error}`);
      });

    firebase
      .database()
      .ref("drivers/" + this.state.driverID + "/")
      .set({
        sp_related: spID,
        status: Number(0),
      });

    firebase
      .database()
      .ref("drivers/" + this.state.driverID + "/live_location/")
      .set({
        latitude: Number(25.7863988),
        longitude: Number(87.4658145),
      });
  };
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  toggleCheckBoxEmirates = (name) => {
    activeEmirates[name] = !activeEmirates[name];
  };

  handleSubmit(event) {
    console.log(this.state.file);
    this.uploadImage();
    event.preventDefault();
    this.toggle();
  }

  render() {
    if (this.state.driver == 1) {
      return <Redirect to='/admin/drivers' />;
    }

    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }

    let vehicleListNumber =
      vehicleList.length > 0 &&
      vehicleList.map((key) => {
        return <option>{key}</option>;
      }, this);

    const {
      username,
      email,
      password,
      passwordRetype,
      description,
      phoneNumber,
      isChecked,
      error,
      vehicleNumber,
    } = this.state;

    const isInvalid =
      password !== passwordRetype ||
      password === "" ||
      email === "" ||
      !isChecked ||
      username === "" ||
      phoneNumber === "" ||
      !/^\d{10}$/.test(phoneNumber);

    const passwordMisMatch =
      password !== passwordRetype && passwordRetype.length > 0;

    const incorrectPhoneNumber =
      phoneNumber !== "" && !/^\d{10}$/.test(phoneNumber);

    const passwordLength_LT6 = password.length < 6;
    const passwordLength_GT6_LT9 = password.length >= 6 && password.length < 9;
    const passwordLength_GT9 = password.length >= 9;

    return (
      <>
        <Header />
        {/* Page content */}

        <Container className='mt--7' fluid>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-5'>
              <div className='text-center text-muted mb-4'>
                <small>Enter Driver Credentials to create account</small>
              </div>
              <Form role='form'>
                <FormGroup>
                  <Label for='File'>Add image</Label>
                  <br></br>
                  <input
                    type='file'
                    accept='image/png, image/jpeg'
                    required
                    onChange={this._handleImageChange}
                  />
                  <FormText color='muted'>
                    Select the picture of the Driver you wanted to display.
                  </FormText>
                  <Row>
                    <Col md={4} xs={2}>
                      <div style={imagestyle}>{$imagePreview}</div>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-circle-08' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='User Name'
                      required
                      type='text'
                      onChange={(e) =>
                        this.onChange("username", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-circle-08' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='User Name In Arabic'
                      type='text'
                      onChange={(e) =>
                        this.onChange("username_ar", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-bullet-list-67' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Description'
                      type='text'
                      onChange={(e) =>
                        this.onChange("description", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-mobile-button' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Phone number'
                      required
                      type='text'
                      onChange={(e) =>
                        this.onChange("phoneNumber", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-email-83' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Email'
                      required
                      type='email'
                      onChange={(e) => this.onChange("email", e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-lock-circle-open' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Password'
                      type='password'
                      required
                      onChange={(e) =>
                        this.onChange("password", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-lock-circle-open' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Retype Password'
                      required
                      type='password'
                      onChange={(e) =>
                        this.onChange("passwordRetype", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                {/* 
                <FormGroup>
                  <Label for="driver">Select Vehicle</Label>
                  <Input
                    type="select"
                    name="vehicle"
                    id="vehicle"
                    // required
                    value={this.state.vehicleNumber}
                    onChange={(e) =>
                      this.onChange("vehicleNumber", e.target.value)
                    }
                  >
                    {vehicleListNumber}
                  </Input>
                </FormGroup> */}

                {/* <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">
                  Select Serving Emirates
                </h6>
                <div className="pl-lg-4">
                {
                  emirates.map((line) => {
                    return(
                      <>
                        <Col lg="4">
                          <div className="custom-control custom-control-alternative custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id={line}
                              onClick={() => {this.toggleCheckBoxEmirates(line)}} 
                            />
                                        
                            <label
                              className="custom-control-label"
                              htmlFor={line}
                            >
                              {line}  
                            </label>
                          </div>
                        </Col> 
                      </>
                    )
                  })
                }
                </div> */}

                <div className='text-muted font-italic'>
                  <small>
                    password strength:{" "}
                    {passwordLength_LT6 && (
                      <span className='text-danger font-weight-700'>Low</span>
                    )}
                    {passwordLength_GT6_LT9 && (
                      <span className='text-warning font-weight-700'>
                        Medium
                      </span>
                    )}
                    {passwordLength_GT9 && (
                      <span className='text-success font-weight-700'>High</span>
                    )}
                  </small>
                  <div>
                    <small>
                      {incorrectPhoneNumber && (
                        <span className='text-danger font-weight-700'>
                          Incorrect Phone Number
                        </span>
                      )}
                    </small>
                  </div>
                  <div>
                    <small>
                      {passwordMisMatch && (
                        <span className='text-danger font-weight-700'>
                          Password Mismatch
                        </span>
                      )}
                    </small>
                  </div>
                </div>

                <Row className='my-4'>
                  <Col xs='12'>
                    <div className='custom-control custom-control-alternative custom-checkbox'>
                      <input
                        className='custom-control-input'
                        id='customCheckRegister'
                        type='checkbox'
                        onClick={() => {
                          this.toggleChange();
                        }}
                        checked={this.state.isChecked}
                      />

                      <label
                        className='custom-control-label'
                        htmlFor='customCheckRegister'>
                        <span className='text-muted'>
                          I agree with the{" "}
                          <a href='#pablo' onClick={(e) => e.preventDefault()}>
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>

                <div className='text-center'>
                  <Button
                    disabled={isInvalid}
                    className='mt-4'
                    color='primary'
                    type='button'
                    onClick={this.createDriverAccount}>
                    CREATE DRIVER ACCOUNT
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Congratulation new driver added Successfully</ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.navigate}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
