import "./Orders.css";
import React, { Component } from "react";
import Header from "components/Headers/Header.jsx";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  BreadcrumbItem,
  Breadcrumb,
} from "reactstrap";

import userIcon from "../../assets/img/icons/orders/user.svg";
import briefCase from "../../assets/img/icons/orders/briefcase.svg";
import moment from "moment";
import { Link } from "react-router-dom";

import RealTime from "../../services/RealTime";
const BreadCrumb = (props) => {
  return (
    <div>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to="/index">Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/admin/users">Users</Link>
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};
const details = (text, data) => {
  return (
    <>
      <Col md="5">
        <p>
          <b>{text}</b>
        </p>
      </Col>
      <Col md="7">
        <p>: {data}</p>
      </Col>
    </>
  );
};
var arabic = /[\u0600-\u06FF]/;
const translateToEn = async (text, value) => {
  if (arabic.test(text)) {
    console.log("text ", arabic.test, text);
    const axios = require("axios").default;
    const url = "https://translation.googleapis.com/language/translate/v2";
    const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios.get(url, {
      params: {
        q: text,
        target: "en",
        key: apiKey,
      },
    });
    return reply.data.data.translations[0].translatedText || "";
  } else return text;
};

class UserDetails extends Component {
  constructor() {
    super();
    this.state = {
      userDetails: {},
    };
  }
  getUser = async (id) => {
    let i = 1;
    let savedAddressLength = 0;
    let emirate = "";
    let user = await RealTime.getDetails("/Users/" + id + "/");
    let pastOrder = 0;
    let activeOrder = 0;
    let cancelOrder = 0;
    let userDetails = {};
    if (user) {
      savedAddressLength =
        user.saved_address !== undefined
          ? Object.keys(user.saved_address).length
          : 0;
      if (savedAddressLength !== 0) {
        var prop = Object.keys(user.saved_address)[savedAddressLength - 1];
        emirate =
          user.saved_address[prop].emirate !== undefined
            ? user.saved_address[prop].emirate
            : "Nil";
      }
      pastOrder =
        user.past_orders !== undefined
          ? Object.keys(user.past_orders.order_ids).length
          : 0;
      cancelOrder =
        user.cancelled_orders !== undefined
          ? Object.keys(user.cancelled_orders.order_ids).length
          : 0;
      activeOrder =
        user.active_orders !== undefined
          ? Object.keys(user.active_orders.order_ids).length
          : 0;
      console.log(
        user.personal_details !== undefined
          ? user.personal_details.registeredDate != undefined
            ? new Date(user.personal_details.registeredDate)
            : "Nil"
          : "Nil"
      );

      userDetails = {
        id: id,
        name:
          user.personal_details !== undefined
            ? user.personal_details.name !== undefined
              ? user.personal_details.name.concat(
                  user.personal_details.lastName !== undefined
                    ? user.personal_details.lastName
                    : ""
                )
              : "Nil"
            : "Nil",
        phoneNumber:
          user.personal_details !== undefined
            ? user.personal_details.phoneNumber
            : "Nil",
        email:
          user.personal_details !== undefined
            ? user.personal_details.email
            : "Nil",
        typeOfUser:
          user.personal_details !== undefined
            ? user.personal_details.guest !== undefined
              ? user.personal_details.guest
                ? user.past_orders !== undefined
                  ? "Active Guest user"
                  : user.active_orders !== undefined
                  ? "Active Guest user"
                  : "Guest user"
                : user.past_orders !== undefined
                ? "Active Registered user"
                : user.active_orders !== undefined
                ? "Active Registered user"
                : "Registered user"
              : "Nil"
            : "Nil",
        emirate: emirate,
        numberOfOrder: pastOrder + activeOrder + cancelOrder,
        cancelOrder: cancelOrder,
        pastOrder: pastOrder,
        activeOrder: activeOrder,
        dateOfReg:
          user.personal_details !== undefined
            ? user.personal_details.registeredDate != undefined
              ? new Date(user.personal_details.registeredDate)
              : "Nil"
            : "Nil",
        dateOfRegString:
          user.personal_details !== undefined
            ? user.personal_details.registeredDate != undefined
              ? moment(user.personal_details.registeredDate)
                  .tz("Asia/Dubai")
                  .format("DD/MM/YYYY hh:mm a")
              : "Nil"
            : "Nil",

        //   :  moment(1604188800000)
        //   .tz("Asia/Dubai")
        //   .format("DD/MM/YYYY hh:mm a")
        // :  moment(1604188800000)
        // .tz("Asia/Dubai")
        // .format("DD/MM/YYYY hh:mm a"),
        os:
          user.personal_details !== undefined
            ? user.personal_details.Device != undefined
              ? user.personal_details.Device.os
              : "Nil"
            : "Nil",
        deviceName:
          user.personal_details !== undefined
            ? user.personal_details.Device != undefined
              ? user.personal_details.Device.deviceName
              : "Nil"
            : "Nil",
      };
    }
    return userDetails;
  };

  async componentDidMount() {
    let userDetails = await this.getUser(this.props.location.state.userId);
    console.log("userDetails", userDetails);
    this.setState({ userDetails: userDetails });
  }

  render() {
    return (
      <div className="trackingComponent">
        <Header />
        <BreadCrumb />
        <Col md="12">
          <div className="allCards">
            <Card>
              <CardHeader className="secondCard">
                <Row>
                  <Col xs="12" md="6" sm="12" lg="6" xl="6">
                    <Row>
                      <Col md="2">
                        <img height={40} src={userIcon} alt="" />
                      </Col>
                      <Col md="6" className="text-center">
                        <p>Customer Name</p>

                        <h4>{this.state.userDetails.name}</h4>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" md="6" sm="12" lg="6" xl="6">
                    <Row>
                      <Col md="2">
                        <img height={40} src={briefCase} alt="" />
                      </Col>
                      <Col md="6" className="text-center">
                        <p>Type of user</p>
                        <h5>{this.state.userDetails.typeOfUser}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <Row>
                      {details("phone", this.state.userDetails.phoneNumber)}
                      {details("Email", this.state.userDetails.email)}
                      {details(
                        "Date of Registration",
                        this.state.userDetails.dateOfRegString
                      )}
                      {details("Emirate", this.state.userDetails.emirate)}
                      {details("OS", this.state.userDetails.os)}
                      {details(
                        "Device Name",
                        this.state.userDetails.deviceName
                      )}
                    </Row>
                  </Col>
                  <Col md="6">
                    <Row>
                      {details(
                        "Total orders",
                        this.state.userDetails.numberOfOrder
                      )}
                      {details(
                        "Active orders",
                        this.state.userDetails.activeOrder
                      )}
                      {details("Past orders", this.state.userDetails.pastOrder)}
                      {details(
                        "Cancelled orders",
                        this.state.userDetails.cancelOrder
                      )}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Col>

        {/* <Col md="12">
          <div className="allCards">
            {serviceDetails.map(serviceDetail => (
              <Card>
                <CardHeader className="main-service-vehicle-header">
                  <h1>
                    {serviceDetail.vehicle
                      .replace("&", ", Vehicle No:")
                      .replace("_", " ")
                      .replace("“", " ")
                      .replace("”", " ")}
                  </h1>
                </CardHeader>
                <CardHeader>
                  <Row>
                    <p>Main services</p>
                    {(() => {
                      let servicesList = [];
                      for (var service in serviceDetail.services)
                        servicesList.push(
                          <tr className="col-md-12">
                            <td className="col-md-2">
                              {serviceDetail.services[service].name}
                            </td>
                            <td className="col-md-8">
                              {serviceDetail.services[service].price}
                            </td>
                          </tr>
                        );
                      return servicesList;
                    })()}
                  </Row>
                  {serviceDetail.add_on_services ? (
                    <Row className="AddOn">
                      <tr className="col-md-12">
                        <p>Add on’s</p>
                        <div>
                          {(() => {
                            let addonList = [];
                            for (var addon in serviceDetail.add_on_services) {
                              addonList.push(
                                <tr className="col-md-12">
                                  <td className="col-md-2">
                                    {serviceDetail.add_on_services[addon].name}
                                  </td>
                                  <td className="col-md-8">
                                    {serviceDetail.add_on_services[addon].price}
                                  </td>
                                </tr>
                              );
                            }
                            return addonList;
                          })()}
                        </div>
                      </tr>
                    </Row>
                  ) : (
                    ""
                  )}
                </CardHeader>
              </Card>
            ))}
            <Card>
              <CardBody>
                <tr className="col-md-12">
                  <th className="col-md-2">Total Amount</th>
                  <td className="col-md-8">
                    {serviceTotalPrice + addonTotalPrice}
                  </td>
                </tr>
              </CardBody>
            </Card>
            <Modal isOpen={this.state.modals} toggle={this.toggles}>
              <ModalBody>
                <p>Select Reason of Cancelation</p>
                <Select
                  style={{}}
                  onChange={this.handleSpChange}
                  options={options}
                />
                {this.state.showReasonTextfield ? (
                  <Input
                    placeholder="Enter your reason"
                    value={this.state.cancel_reason}
                    onChange={this.handleReasonText}
                  />
                ) : null}
              </ModalBody>
              <ModalFooter>
                <Button color="info" onClick={this.toggles}>
                  N0
                </Button>
                <Button
                  color="danger"
                  disabled={!this.state.cancel_reason}
                  onClick={this.cancelOrder}
                >
                 YES
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </Col> */}
      </div>
    );
  }
}

export default UserDetails;
