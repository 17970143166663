import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.js";
import firebase from "firebaseConfig";
import RealTime from "services/RealTime";

var userID = "superadmin_twaddan_com";

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs && this.refs.mainContent && (this.refs.mainContent.scrollTop = 0);
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.layout === "/admin" &&
        (userID !== "superadmin_twaddan_com" ||
          (userID == "superadmin_twaddan_com" && true))
      ) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  render() {
    let userLoggedIn = localStorage.getItem("userLoggedIn") || "";
    if (!userLoggedIn) {
      return <Redirect to='/auth/login' />;
    }
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("assets/img/brand/twaddan-react.png"),
            imgAlt: "...",
          }}
        />
        <div className='main-content' ref='mainContent'>
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps, {})(Admin);
